
import { Component, Mixins } from 'vue-property-decorator';
import { pick } from 'lodash';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import { Action } from 'vuex-class';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, GridApi, GridReadyEvent, SortChangedEvent, ColumnApi, RowNode, GridOptions,
} from 'ag-grid-community';

import buildOnePageWorkbook, { convertColDefToXLSWriteOptions } from '@/helpers/exports/xls/ExcelUtil';

import CheckboxCellRenderer from '@/components/ag-grid/CheckboxCellRenderer.vue';
import VerifiedCellRenderer from '@/components/ag-grid/VerifiedCellRenderer.vue';

import LoadingCircleOverlay, { LoadingCircleOverlayParams } from '@/components/ag-grid/overlays/LoadingCircleOverlay.vue';
import DateCellEditor from '@/components/ag-grid/DateCellEditor.vue';
import ShortDateCellEditor from '@/components/ag-grid/ShortDateCellEditor.vue';
import CurrencyCellEditor from '@/components/ag-grid/CurrencyCellEditor.vue';
import ComboboxEditor from '@/components/ag-grid/ComboboxCellEditor.vue';

import formatters from '@/components/ag-grid/formatters';

import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';
import KeyboardNavigation from '@/mixins/KeyboardNavigation.vue';
import CancelSource from '@/mixins/CancelSource.vue';
import ExportDataParams from './models/ExportDataParams';

@Component({
  name: 'grid-report',
  components: {
    AgGridVue,
  },
})
export default class GridReport<R = any> extends Mixins(PreventDirtyLeave, UserPermissions, KeyboardNavigation, CancelSource) {
  protected isLoading = false;
  protected isProcessing = false;

  @Action('showSuccess') showSuccess!: (val: any) => {};
  @Action('showError') showError!: (val: any) => {};

  // Results
  protected results: R[] = [];
  protected original: R[] = [];

  // Grid
  protected gridApi: GridApi = null;
  protected columnApi: ColumnApi = null;
  protected gridOptions: GridOptions = {
    enableCellTextSelection: true,
    enterMovesDownAfterEdit: true,
    columnTypes: {
      currency: {
        valueFormatter: formatters.currencyFormatter,
        cellEditor: 'currencyCellEditor',
      },
      date: {
        cellEditor: 'dateCellEditor',
      },
      shortDate: {
        cellEditor: 'shortDateCellEditor',
      },
      verified: {
        cellRenderer: 'verifiedRenderer',
        minWidth: 100,
      },
      boolean: {
        cellRenderer: 'checkboxRenderer',
      },
    },
  };
  protected columnDefs: ColDef[] = [];
  protected sortModel: { colId: string, sort: 'asc' | 'desc' } = null;
  protected frameworkComponents: any = null;
  protected loadingOverlayComponentParams: LoadingCircleOverlayParams = {
    context: this.gridOptions.context,
    api: this.gridApi,
    columnApi: this.columnApi,
    text: 'Loading',
  };

  private ignoreComparisonComparator = (
    valueA: any,
    valueB: any,
    nodeA: RowNode,
    nodeB: RowNode,
    isInverted: boolean,
  ) => (isInverted
    ? nodeB.childIndex - nodeA.childIndex
    : nodeA.childIndex - nodeB.childIndex)

  protected defaultColDef: ColDef = {
    minWidth: 100,
    width: 175,
    editable: false,
    resizable: true,
    sortable: true,
    comparator: this.ignoreComparisonComparator,
  };

  // Computed
  get columnFields(): string[] {
    return this.columnDefs.map((colDef) => colDef.field);
  }

  protected setLoading() {
    this.isLoading = true
    console.log('Loading....')
  }

  protected resetLoading() {
    this.isLoading = false
    console.log('Loading reset')
  }

  // Hooks
  beforeMount() {
    this.frameworkComponents = {
      checkboxRenderer: CheckboxCellRenderer,
      verifiedRenderer: VerifiedCellRenderer,
      loadingCircleOverlay: LoadingCircleOverlay,
      dateCellEditor: DateCellEditor,
      shortDateCellEditor: ShortDateCellEditor,
      currencyCellEditor: CurrencyCellEditor,
      comboboxEditor: ComboboxEditor,
    }
  }

  protected onGridReadyComplete(): void {}

  protected onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    if (this.isLoading || this.isProcessing) {
      this.gridApi.showLoadingOverlay();
    }

    this.onGridReadyComplete();
  }

  protected exportData(params: ExportDataParams) {
    const fieldsToUse = params.colDefs ? params.colDefs.map((colDef) => colDef.field) : this.columnFields;
    const pickSet = new Set(params.ignoreGrid ? [] : fieldsToUse);
    params.inclusions.forEach((inclusion) => pickSet.add(inclusion));
    params.exclusions.forEach((exclusion) => pickSet.delete(exclusion));
    const data = params.suppliedData || this.results;
    const parsedData = data
      .map((row) => pick(row, Array.from(pickSet)));

    parsedData.forEach((row: any) => {
      if (row.verified) {
        row.verified = row.verified.verified;
      }
    });

    if (params.format === 'xlsx') {
      const wb = buildOnePageWorkbook(parsedData, convertColDefToXLSWriteOptions(params.colDefs || this.columnDefs), 'Report');
      XLSX.writeFile(wb, `${params.file}.xlsx`, {
        type: 'file',
      });
    } else if (params.format === 'csv') {
      const csv = Papa.unparse(parsedData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

      saveAs(blob, `${params.file}.csv`);
    }
  }

  onGridSortChanged(params: SortChangedEvent) {
    // Commenting this line because, the Property 'getSortModel' does not exist on type 'GridApi<any>'
    // The sort model is available in request object of getRows method of datasource
    // [this.sortModel] = params.api.getSortModel();
  }

  // Methods
  onGridComplete(): void {}

  resetResults() {
    this.results = [];
    this.original = [];
    // this.gridApi.setRowData([]);
  }
}
