var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"no-chip-gutter",class:{ 'required': _vm.$attrs.required },attrs:{"label":_vm.$attrs.label ? _vm.$attrs.label : 'Select User(s)',"items":_vm.foundUsers,"item-value":"id","chips":"","clearable":"","multiple":_vm.multiple,"search-input":_vm.userSearch,"cache-items":"","return-object":"","filter":_vm.filterUsers},on:{"update:searchInput":function($event){_vm.userSearch=$event},"update:search-input":function($event){_vm.userSearch=$event},"input":_vm.update,"change":_vm.change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var parent = ref.parent;
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"close":parent.multiple},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":_vm.getUserProfilePic(item.id),"alt":"Avatar"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-img',{attrs:{"src":"/profile_placeholder.jpg"}})]},proxy:true}],null,true)})],1),_vm._v(" "+_vm._s(item)+" ")],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.getUserProfilePic(item.id),"alt":"Avatar"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-img',{attrs:{"src":"/profile_placeholder.jpg"}})]},proxy:true}],null,true)})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getUserDescription(item)))])],1)],1)]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})}
var staticRenderFns = []

export { render, staticRenderFns }