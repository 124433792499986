import TaxCollectingFrequency from '@/entities/TaxCollectingFrequency';

enum Term {
  annual = 'Annual',
  firstHalf = 'First Half',
  secondHalf = 'Second Half',
  firstQuarter = 'First Quarter',
  secondQuarter = 'Second Quarter',
  thirdQuarter = 'Third Quarter',
  fourthQuarter = 'Fourth Quarter',
}

export class TermUtil {
  public static matchesFrequency(term: Term, frequency: keyof typeof TaxCollectingFrequency): boolean {
    switch (frequency) {
      case 'annually':
        return term === Term.annual;

      case 'semiannually':
        return term === Term.firstHalf || term === Term.secondHalf;

      case 'quarterly':
        return term === Term.firstQuarter || term === Term.secondQuarter || term === Term.thirdQuarter || term === Term.fourthQuarter;

      case 'annualOrSemiannually':
        return term === Term.annual || term === Term.firstHalf || term === Term.secondHalf;

      case 'annualOrQuarterly':
        return term === Term.annual || term === Term.firstQuarter || term === Term.secondQuarter || term === Term.thirdQuarter || term === Term.fourthQuarter;

      default:
        return false;
    }
  }

  public static keyMatchesFrequency(term: keyof typeof Term, frequency: keyof typeof TaxCollectingFrequency): boolean {
    switch (frequency) {
      case 'annually':
        return term === 'annual';

      case 'semiannually':
        return term === 'firstHalf' || term === 'secondHalf';

      case 'quarterly':
        return term === 'firstQuarter' || term === 'secondQuarter' || term === 'thirdQuarter' || term === 'fourthQuarter';

      case 'annualOrSemiannually':
        return term === 'annual' || term === 'firstHalf' || term === 'secondHalf';

      case 'annualOrQuarterly':
        return term === 'annual' || term === 'firstQuarter' || term === 'secondQuarter' || term === 'thirdQuarter' || term === 'fourthQuarter';

      default:
        return false;
    }
  }

  public static getTermsForFrequency(frequency: keyof typeof TaxCollectingFrequency): Term[] {
    switch (frequency) {
      case 'annually':
        return [Term.annual];

      case 'semiannually':
        return [Term.firstHalf, Term.secondHalf];

      case 'quarterly':
        return [Term.firstQuarter, Term.secondQuarter, Term.thirdQuarter, Term.fourthQuarter];

      case 'annualOrSemiannually':
        return [Term.annual, Term.firstHalf, Term.secondHalf];

      case 'annualOrQuarterly':
        return [Term.annual, Term.firstQuarter, Term.secondQuarter, Term.thirdQuarter, Term.fourthQuarter];

      default:
        return [];
    }
  }

  public static getTermKeysForFrequency(frequency: keyof typeof TaxCollectingFrequency): string[] {
    switch (frequency) {
      case 'annually':
        return ['annual'];

      case 'semiannually':
        return ['firstHalf', 'secondHalf'];

      case 'quarterly':
        return ['firstQuarter', 'secondQuarter', 'thirdQuarter', 'fourthQuarter'];

      case 'annualOrSemiannually':
        return ['annual', 'firstHalf', 'secondHalf'];

      case 'annualOrQuarterly':
        return ['annual', 'firstQuarter', 'secondQuarter', 'thirdQuarter', 'fourthQuarter'];

      default:
        return [];
    }
  }
}

export default Term;
