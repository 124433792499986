




















import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'action-button',
})
export default class ActionButton extends Vue {
  @Prop({
    type: String,
    default: 'Submit',
  }) readonly buttonLabel!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  private text: string = '';

  @Emit('submit')
  submit() {
    return this.text;
  }
}
