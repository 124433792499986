enum ReportName {
  TaxBillRequest = 'TaxBillRequest',
  AddTaxOffice = 'AddTaxOffice',
  TaxDataSheet = 'TaxDataSheet',
  EscrowIdSheet = 'EscrowIdSheet',
  EscrowMemoBill = 'EscrowMemoBill',
  PNV = 'PNV',
  DelinquentMemoBill = 'DelinquentMemoBill',
  ModifyReportedDate = 'ModifyReportedDate',
  Audit = 'Audit',
  AuditSearch = 'AuditSearch',
  EscrowBalanceSheet = 'EscrowBalanceSheet',
  PackList = 'PackList',
  ParcelFormatFlag = 'ParcelFormatFlag',
  AddParcel = 'AddParcel',
  AgencyLabel = 'AgencyLabel',

  AgencyData = 'AgencyData',
  LenderData = 'LenderData',
  LoanData = 'LoanData',
}
export default ReportName;
