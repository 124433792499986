import { CancelToken } from 'axios';

import AgencyApiService from '@/services/api/AgencyApiService';
import Agency from '@/entities/Agency';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import EntityCountResponse from '../api/models/EntityCountResponse';

interface AgencyCollection {
  count: number,
  results: Agency[],
}

class AgencyService {
  private lastRetrieval: string;

  async getAllAgencies(params: any, cancelToken?: CancelToken): Promise<AgencyCollection> {
    const response = await AgencyApiService.getAllAgencies(params, cancelToken);

    return {
      count: parseInt(response.count, 10),
      results: response.agencies.map((agency) => new Agency(agency)),
    };
  }

  async getTotalAgencies(params: any, cancelToken?: CancelToken): Promise<EntityCountResponse> {
    return AgencyApiService.getTotalAgencies(params, cancelToken);
  }

  async getAllAgencyCodeIdPairs(): Promise<any> {
    const response = await AgencyApiService.getAllAgencies({
      advanced_search: {
        get_id_code_pair: true,
      },
    });

    return response;
  }

  async getAgency(id: string, cancelToken?: CancelToken): Promise<Agency> {
    const response = await AgencyApiService.getAgency(id, cancelToken);
    this.lastRetrieval = response.headers.etag;

    return new Agency(response.data);
  }

  async updateAgency(id: string, payload: JsonPatchPayload): Promise<Agency> {
    const response = await AgencyApiService.updateAgency(id, payload, this.lastRetrieval);
    this.lastRetrieval = response.headers.etag;
    return new Agency(response.data);
  }

  async addAgency(payload: any): Promise<Agency> {
    const response = await AgencyApiService.addAgency(payload);
    return new Agency(response);
  }

  async uploadFile(id: string, file: File): Promise<void> {
    const formData: FormData = new FormData();
    formData.set('file', file);
    await AgencyApiService.uploadFile(id, formData);
  }

  async deleteAgency(id: string): Promise<any> {
    const response = await AgencyApiService.deleteAgency(id);

    return new Agency(response);
  }

  async deleteFile(id: string, name: string): Promise<void> {
    await AgencyApiService.deleteFile(id, name)
  }

  async batchPatchAgencies(payload: JsonPatchPayload): Promise<any> {
    const response = await AgencyApiService.batchPatchAgencies(payload);
    return response;
  }
}

export { AgencyService as default };
