














import { Vue, Component } from 'vue-property-decorator';
import { ILoadingOverlayParams } from 'ag-grid-community';

export interface LoadingCircleOverlayParams extends ILoadingOverlayParams {
  size?: number,
  text?: string,
}

@Component({
  name: 'loading-circle-overlay',
})
export default class LoadingCircleOverlay extends Vue {
  private params: LoadingCircleOverlayParams = null;
}
