import SearchResponse from '@/services/responses/SearchResponse'
import ReportName from '@/views/reports/models/ReportName'
import { SortModelItem } from 'ag-grid-community'
import IRowFetcherParams from '../params/IRowFetcherParams'
import LastRowFetcher from '../rowFetcher/LastRowFetcher'
import { GetAllType, GetTotalType } from '../types'

export default class ReportDatasourceParamBuilder<T, R = any> {
  constructor(
    private reportName: ReportName,
    private getAll: GetAllType<T>,
    private getTotal: GetTotalType,
    private sortModel: SortModelItem,
    private onResultsChanged: (val: T[]) => R[],
    private getParams: () => {
      advanced_search: any
      include_agency: boolean
      select?: any[]
      search?: any[]
      sort?: any
    },
  ) {
    this.reportName = reportName
    this.getAll = getAll
    this.getTotal = getTotal
    this.sortModel = sortModel
    this.onResultsChanged = onResultsChanged
    this.getParams = getParams
  }

  public build() {
    const onSortModelChanged = (sortModel1: SortModelItem[]) => {
      [this.sortModel] = sortModel1
    }
    const lastRowFetcher = new LastRowFetcher(this.reportName, this.getTotal)
    const successCallbackParams = {
      lastRowFetcher,
      onSuccess: this.onResultsChanged,
    }
    const rowFetcherParams: IRowFetcherParams<T> = {
      getAll: this.getAll,
      successCallbackParams,
    }
    const httpRequestParams = {
      reportName: this.reportName,
      getParams: this.getParams,
    }
    return {
      onSortModelChanged, rowFetcherParams, httpRequestParams,
    }
  }
}
