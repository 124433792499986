import ReportName from '@/views/reports/models/ReportName'
import { SortModelItem } from 'ag-grid-community'
import ReportDatasource from '../ReportDatasource'
import { GetAllType, GetTotalType } from '../types'
import ReportDatasourceParamBuilder from './ReportDatasourceParamBuilder'

export default class ReportDatasourceBuilder<T, RR> {
  constructor(
    private reportName: ReportName,
    private getAll: GetAllType<T>,
    private getTotal: GetTotalType,
    private sortModel: SortModelItem,
    private setLoading: () => void,
    private resetLoading: () => void,
    private onResultsChanged: (val: T[]) => RR[],
    private getParams: () => any,
    private showLoading?: () => void,
    private failureHandler?: (e: any) => void,
    private finallyCallback?: () => void,
  ) {
    this.reportName = reportName
    this.getAll = getAll
    this.getTotal = getTotal
    this.sortModel = sortModel
    this.setLoading = setLoading
    this.resetLoading = resetLoading
    this.onResultsChanged = onResultsChanged
    this.getParams = getParams
    this.showLoading = showLoading
    this.failureHandler = failureHandler
    this.finallyCallback = finallyCallback
  }

  public build() {
    const {
      onSortModelChanged,
      rowFetcherParams,
      httpRequestParams,
    } = new ReportDatasourceParamBuilder<T, RR>(
      this.reportName,
      this.getAll,
      this.getTotal,
      this.sortModel,
      this.onResultsChanged,
      this.getParams,
    ).build()
    return new ReportDatasource<T, RR>(
      onSortModelChanged,
      this.setLoading,
      this.resetLoading,
      rowFetcherParams,
      httpRequestParams,
      this.showLoading,
      this.failureHandler,
      this.finallyCallback,
    )
  }
}
