import { DateTime } from 'luxon';
import _ from 'lodash';

import IAddress from '@/entities/IAddress';
import IAgency, {
  IDelinquentTaxCollectingOffice, IParcelConfiguration, IRelatedAgency, ITaxProcessor, IAgencyContact, ICollectingSchedule,
} from '@/entities/IAgency';
import Verified from '@/entities/Verified';
import User from '@/entities/User';
import IServiceAgency from '@/services/api/models/IServiceAgency';
import IFile from '@/entities/IFile';

export default class Agency implements IAgency {
  [key: string]: any;

  agencyId: string;
  name: string;

  capAgency?: string;
  taxType?: string;
  postMarkIndicator: boolean = false;
  duplicateBillFee?: boolean = false;
  duplicateBillAmount?: number;
  cooperationIndicator: boolean = false;
  website?: string;
  dueDateNotes?: string;
  auditStartDate?: string;
  auditNotes?: string;
  taxPayableTo?: string;
  phoneNumber?: string;
  taxBillCollection?: string;
  payableTo?: string;
  billsMailed?: string;
  countyLines?: string;
  countyLinesNotes?: string;
  parcelCoding?: string;
  agencyLenders?: boolean = false;
  capitalNotes?: string;
  fullTaxFileAvailable?: boolean;
  fullTaxFileAvailableAmount?: number;
  electronicFile?: boolean;
  electronicFileAmount?: number;
  listingRequiredWithPayment?: boolean;
  usesThirdParty?: boolean;
  payableToAddress?: Verified<IAddress>;
  websiteNotes?: string;
  delinquentCollectingWebsiteNotes?: string;
  contractId?: number;
  discountedBill?: boolean;
  originalBill?: string;
  originalListingRequired?: boolean;
  delinquentTaxCollectingId?: string;

  // Data System
  dataSystemUsed?: string;
  publicTerminalsAvailable?: number;
  currentTaxWebsite?: string;

  // Collection information
  nonEscrowCollectingYear?: string;
  collectingFrequency?: string;
  collectingYear?: string;

  // Address
  address?: Verified<IAddress>;

  // Websites
  agencyWebsite?: string;
  taxWebsite?: string;
  priorTaxWebsite?: string;

  // Phone number
  collectorPhone?: string;
  assessorPhone?: string;

  // Collections and sub objects
  delinquentTaxCollectingOffice?: IDelinquentTaxCollectingOffice;
  collectingSchedule?: ICollectingSchedule;
  parcelConfigurations: IParcelConfiguration[] = [];
  contacts: IAgencyContact[] = [];
  taxProcessors: ITaxProcessor[] = [];
  relatedAgencies: IRelatedAgency[] = [];
  crossReferenceCodes: any[] = [];

  // Files
  files: IFile[] = [];

  agencyVerified: boolean = false;
  agencyVerifiedBy?: User;
  agencyVerifiedOn?: Date;

  constructor(serviceObj: IServiceAgency) {
    this.agencyId = serviceObj.agency_id;
    this.name = serviceObj.name;
    this.capAgency = serviceObj.cap_agency;
    this.taxType = serviceObj.tax_type;

    // Address w/ verification parameters
    let addressVerifyUser;
    if (serviceObj.address_verified && serviceObj.address_verified_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.address_verified_by;

      addressVerifyUser = new User(id, given_name, family_name, email);
    }

    this.address = new Verified<IAddress>({
      address1: serviceObj.street_1,
      address2: serviceObj.street_2,
      city: serviceObj.city,
      state: serviceObj.state,
      county: serviceObj.county,
      zipCode: serviceObj.zip_code,
    }, Boolean(serviceObj.address_verified), addressVerifyUser, serviceObj.address_verified_on ? DateTime.fromISO(serviceObj.address_verified_on).toJSDate() : undefined);

    this.collectorPhone = serviceObj.collector_phone;
    this.assessorPhone = serviceObj.assessor_phone;
    this.postMarkIndicator = Boolean(serviceObj.post_mark_indicator);
    this.duplicateBillFee = serviceObj.duplicate_bill_fee;
    this.duplicateBillAmount = serviceObj.duplicate_bill_amount;
    this.cooperationIndicator = !!serviceObj.cooperation_indicator;

    if (serviceObj.delinquent_tax_collecting_office) {
      this.delinquentTaxCollectingOffice = {
        agencyId: serviceObj.delinquent_tax_collecting_id,
        capAgency: serviceObj.delinquent_tax_collecting_office.cap_agency,
        name: serviceObj.delinquent_tax_collecting_office.name,
        website: serviceObj.delinquent_tax_collecting_office.website,
        address1: serviceObj.delinquent_tax_collecting_office.street_1,
        address2: serviceObj.delinquent_tax_collecting_office.street_2,
        city: serviceObj.delinquent_tax_collecting_office.city,
        county: serviceObj.delinquent_tax_collecting_office.county,
        state: serviceObj.delinquent_tax_collecting_office.state,
        zipCode: serviceObj.delinquent_tax_collecting_office.zip_code,
        collectingFrequency: serviceObj.delinquent_tax_collecting_office.collecting_frequency,
        collectingYear: serviceObj.delinquent_tax_collecting_office.collecting_year,
        nonEscrowCollectingYear: serviceObj.delinquent_tax_collecting_office.non_escrow_collecting_year,
        payableTo: serviceObj.delinquent_tax_collecting_office.payable_to,
      };
    }

    this.delinquentTaxCollectingId = serviceObj.delinquent_tax_collecting_id;
    this.website = serviceObj.website;
    this.dueDateNotes = serviceObj.due_date_notes;
    this.auditStartDate = serviceObj.audit_start_date;
    this.auditNotes = serviceObj.audit_notes;
    this.payableTo = serviceObj.tax_payable_to;
    this.discountedBill = serviceObj.discounted_bill;

    let agencyVerifyUser;
    if (serviceObj.agency_verified && serviceObj.agency_verified_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.agency_verified_by;

      agencyVerifyUser = new User(id, given_name, family_name, email);
    }
    this.agencyVerified = serviceObj.agency_verified;
    this.agencyVerifiedOn = serviceObj.agency_verified_on ? DateTime.fromISO(serviceObj.agency_verified_on).toJSDate() : undefined;
    this.agencyVerifiedBy = agencyVerifyUser;

    this.phoneNumber = serviceObj.phone_number;
    this.taxBillCollection = serviceObj.tax_bill_collection;
    this.payableTo = serviceObj.payable_to;
    this.currentTaxWebsite = serviceObj.current_tax_website;
    this.billsMailed = serviceObj.bills_mailed;
    this.countyLines = serviceObj.county_lines;
    this.countyLinesNotes = serviceObj.county_lines_notes;
    this.parcelCoding = serviceObj.parcel_coding;
    this.agencyLenders = serviceObj.agency_lenders;
    this.capitalNotes = serviceObj.capital_notes;
    this.dataSystemUsed = serviceObj.data_system_used;
    this.publicTerminalsAvailable = serviceObj.public_terminals_available;
    this.fullTaxFileAvailable = serviceObj.full_tax_file_available;
    this.fullTaxFileAvailableAmount = serviceObj.full_tax_file_available_amount;
    this.electronicFile = serviceObj.electronic_file;
    this.electronicFileAmount = serviceObj.electronic_file_amount;
    this.listingRequiredWithPayment = serviceObj.listing_required_with_payment;
    this.usesThirdParty = serviceObj.uses_third_party;
    this.websiteNotes = serviceObj.website_notes;
    this.priorTaxWebsite = serviceObj.prior_tax_website;
    this.delinquentCollectingWebsiteNotes = serviceObj.delinquent_collecting_website_notes;
    this.contractId = serviceObj.contract_id;
    this.originalBill = serviceObj.original_bill;
    this.originalListingRequired = serviceObj.original_listing_required;

    let payableToAddressVerifyUser;
    if (serviceObj.payable_to_address_verified && serviceObj.payable_to_address_verified_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.payable_to_address_verified_by;

      payableToAddressVerifyUser = new User(id, given_name, family_name, email);
    }

    this.payableToAddress = new Verified<IAddress>({
      address1: serviceObj.payable_to_address,
      address2: serviceObj.payable_to_address_2,
      city: serviceObj.payable_to_city,
      state: serviceObj.payable_to_state,
      zipCode: serviceObj.payable_to_zip,
    }, Boolean(serviceObj.payable_to_address_verified), payableToAddressVerifyUser, serviceObj.payable_to_address_verified_on ? DateTime.fromISO(serviceObj.payable_to_address_verified_on).toJSDate() : undefined);

    this.nonEscrowCollectingYear = serviceObj.non_escrow_collecting_year;
    this.collectingFrequency = serviceObj.collecting_frequency;
    this.collectingYear = serviceObj.collecting_year;

    // Collections stuff
    this.collectingSchedule = serviceObj.collecting_schedule ? serviceObj.collecting_schedule.map((entry) => ({
      agencyCollectingScheduleId: entry.agency_collecting_schedule_id,
      agencyId: entry.agency_id,
      term: entry.term,
      billRequestDate: entry.bill_request_date,
      billReleaseDate: entry.bill_release_date,
      dueDate: entry.due_date
        ? new Verified(
          entry.due_date,
          entry.due_date_verified,
          new User(entry.due_date_verified_by),
          entry.due_date_verified_on ? DateTime.fromISO(entry.due_date_verified_on).toJSDate() : undefined,
        ) : new Verified(),
      taxBillCheckIn: new Verified(
        undefined,
        entry.tax_bill_check_in,
        new User(entry.tax_bill_check_in_by),
        entry.tax_bill_check_in_on ? DateTime.fromISO(entry.tax_bill_check_in_on).toJSDate() : undefined,
      ),
      lastDayToPay: entry.last_day_to_pay,
      lenderId: entry.lender_id,
    })) : [];

    this.parcelConfigurations = serviceObj.parcel_configurations
      ? serviceObj.parcel_configurations.map((parcelConfig): IParcelConfiguration => ({
        agencyConfigurationId: parcelConfig.agency_configuration_id,
        configuration: parcelConfig.configuration,
      }))
      : [];

    this.contacts = serviceObj.contacts
      ? serviceObj.contacts.map((contact) => ({
        agencyContactId: contact.agency_contact_id,
        type: contact.type,
        name: contact.name,
        phone: contact.phone,
        extension: contact.extension,
        fax: contact.fax,
        email: contact.email,
        notes: contact.notes,
        hours: contact.hours,
      }))
      : [];

    this.taxProcessors = serviceObj.tax_processors
      ? serviceObj.tax_processors.map((processor) => ({
        agencyTaxProcessorId: processor.agency_tax_processor_id,
        taxOffice: processor.tax_office,
        agencyCode: processor.agency_code,
        type: processor.type,
        contact: processor.contact,
        phone: processor.phone,
        fax: processor.fax,
        email: processor.email,
        mortgageCode: processor.mortgage_code,
        notes: processor.notes,
        mailing1: processor.mailing_1,
        mailing2: processor.mailing_2,
      }))
      : [];

    this.relatedAgencies = serviceObj.related_agencies
      ? serviceObj.related_agencies.map((relatedAgency): IRelatedAgency => ({
        relatedAgencyId: relatedAgency.related_agency_id,
        agencyId: relatedAgency.agency_id,
        name: relatedAgency.name,
        county: relatedAgency.county,
        state: relatedAgency.state,
        agencyCode: relatedAgency.agency_code,
      }))
      : [];

    this.crossReferenceCodes = serviceObj.cross_reference_codes
      ? serviceObj.cross_reference_codes.map((code) => ({
        agencyCrossReferenceCodeId: code.agency_cross_reference_code_id,
        agencyId: code.agency_id,
        lenderId: code.lender_id,
        lenderName: code.lender_name,
        lenderNumber: code.lender_number,
        crossReferenceCode: code.cross_reference_code,
      }))
      : [];
    this.crossReferenceCodes = _.sortBy(this.crossReferenceCodes, 'lenderNumber')

    this.files = serviceObj.files || [];

    return Agency.upper(this);
  }

  static upper(obj: any): any {
    if (!obj) return obj;

    Object.keys(obj).forEach((prop: string) => {
      if (prop === 'website' || prop === 'currentTaxWebsite' || prop === 'agencyWebsite' || prop === 'taxWebsite' || prop === 'priorTaxWebsite' || prop === 'capAgency' || prop === 'term' || prop === 'collectingFrequency' || prop === 'collectingYear' || prop === 'nonEscrowCollectingYear' || prop === 'countyLines' || prop === 'type' || prop === 'url' || prop === 'email') return;
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = obj[prop].toUpperCase();
        }
        if (typeof obj[prop] === 'object') {
          this.upper(obj[prop]);
        }
      }
    });

    return obj;
  }

  static clone(instance: Agency): Agency {
    const copy = new (instance.constructor as { new (): Agency })();
    Object.assign(copy, instance);
    return copy;
  }
}
