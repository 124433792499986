import jsPDF, { jsPDFOptions } from 'jspdf';
import { jsPDFDocument } from 'jspdf-autotable';

export interface PdfBuilderOptions extends jsPDFOptions {}

class PdfBuilder {
  protected doc: jsPDF;

  constructor(options: jsPDFDocument = {}) {
    this.doc = new jsPDF(options);
  }

  build(): jsPDF {
    return this.doc;
  }
}

export { PdfBuilder as default };
