const defaultNumberFilterParams = {
  filter: 'agNumberColumnFilter',
  filterParams: {
    buttons: ['reset'],
    debounceMs: 1000,
    suppressAndOrCondition: true,
    allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
    numberParser: (text: string) => (text == null ? null : parseFloat(text.replace(',', '.'))),
  },
}
export default defaultNumberFilterParams
