import Axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

enum ImportOp {
  add = 'add',
  delete = 'delete',
  full = 'full_file'
}

interface ImportPayload {
  file_name: string,
  rows: any[],
}

export interface ImportResult {
  added_loans: any[],
  updated_loans: any[],
  deleted_loans: any[],
  failed_rows: { index: number, reason: string }[],
  job_id?: any
}

export default {
  async postPartialAddImport(payload: ImportPayload, preview: boolean = false): Promise<ImportResult> {
    const result = await preview ? this.previewImport(payload, ImportOp.add) : this.commitImport(payload, ImportOp.add);

    return result;
  },

  async postPartialDeleteImport(payload: ImportPayload, preview: boolean = false): Promise<ImportResult> {
    const result = await preview ? this.previewImport(payload, ImportOp.delete) : this.commitImport(payload, ImportOp.delete);

    return result;
  },

  async postFullFileImport(payload: ImportPayload, preview: boolean = false): Promise<ImportResult> {
    const result = await preview ? this.previewImport(payload, ImportOp.full) : this.commitImport(payload, ImportOp.full);

    return result;
  },

  async previewImport(payload: ImportPayload, operation: ImportOp): Promise<ImportResult> {
    const config: AxiosRequestConfig = {
      params: {
        operation,
        preview: true,
      },
    };

    const result = await Axios.post<ImportPayload, AxiosResponse<ImportResult>>('/import', payload, config);

    return result.data;
  },

  async commitImport(payload: ImportPayload, operation: ImportOp): Promise<ImportResult> {
    const config: AxiosRequestConfig = {
      params: {
        operation,
        preview: false,
      },
    };

    const result = await Axios.post<ImportPayload, AxiosResponse<ImportResult>>('/import', payload, config);

    return result.data;
  },
};
