





































































































































































































































































































































































import {
  Component, Watch,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { DateTime } from 'luxon';
import { snakeCase } from 'change-case';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, ServerSideTransaction, SortChangedEvent,
} from 'ag-grid-community';
import jsPDF from 'jspdf';

import LoanService from '@/services/loans';
import EscrowHistoryService from '@/services/escrowHistories';
import NonEscrowHistoryService from '@/services/nonEscrowHistories';

import { JsonPatchOperator } from '@/helpers/vuelidateToPatch';
import ReportPdfBuilder from '@/helpers/exports/pdf/ReportPdfBuilder';

import Loan from '@/entities/Loan';
import Parcel from '@/entities/Parcel';
import Term from '@/entities/Term';
import NonEscrowHistory from '@/entities/NonEscrowHistory';
import EscrowHistory from '@/entities/EscrowHistory';
import Axios, { AxiosResponse, CancelToken } from 'axios';

import states from '@/data/states';

import SsrmGridReport from '@/views/reports/SsrmGridReport.vue';
import AgencySearch from '@/views/agencies/AgencySearch.vue';
import LenderSearch from '@/views/lenders/LenderSearch.vue';

import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import SimpleChipBox from '@/components/inputs/SimpleChipBox.vue';
import SimpleChipAutocomplete from '@/components/inputs/SimpleChipAutocomplete.vue';
import ReportedDateSelection, { ReportedDateOption } from '@/components/inputs/queries/ReportedDateSelection.vue';
import { formatCurrency } from '@/components/ag-grid/formatters/CurrencyFormatter';
import DateField from '@/components/inputs/dates/DateField.vue'
import ExportDataParams from './models/ExportDataParams';

import ReportName from './models/ReportName';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';

interface BaseResult {
  [index: string]: any,

  loanId: string,
  parcelId: string,
  parcelAgencyId: string,

  lenderNumber: string,
  loanNumber: string,
  parcelNumber: string,
  reportedDate: string,
  year: string,
}

interface EscrowResult extends BaseResult {
  period: string,
  amountReported: number,
  agencyCounty: string,
  agencyState: string,
  capAgency: string,

  batchNumber: string,
}

interface NonEscrowResult extends BaseResult {
  county: string,
  state: string,
  dtcoNumber: string,
  status: string,
}

@Component({
  name: 'modify-reported-date-report',
  components: {
    DateField,
    AgGridVue,
    SsrmGridOmnifilter,
    SimpleChipBox,
    SimpleChipAutocomplete,
    ReportedDateSelection,
    AgencySearch,
    LenderSearch,
  },
})
export default class ModifyReportedDateReport extends SsrmGridReport<any> {
  protected pageSizes = [500, 10000];
  private useEscrow: boolean = true;
  private isAdding: boolean = true;

  private isGeneratingPDF = false;
  private showResults = false;
  private selectedModificationDate = '';
  private allowCommitChange = false;
  private committingChanges = false;

  private loanService: LoanService = new LoanService();
  private escrowHistoryService: EscrowHistoryService = new EscrowHistoryService();
  private nonEscrowHistoryService: NonEscrowHistoryService = new NonEscrowHistoryService();

  private parcels: Parcel[] = [];
  private loans: Loan[] = [];

  // Search variables
  private dateOption: ReportedDateOption = {
    reported_date_mode: 'all',
  };
  private escrowStates: string[] = [];
  private escrowAgencyNumbers: string[] = [];
  private escrowTerm: string = '';
  private escrowYears: string[] = [];
  private escrowBatchNumbers: string[] = [];

  private nonEscrowLenderNumbers: string[] = [];
  private nonEscrowStates: string[] = [];
  private nonEscrowCounty: string = '';
  private nonEscrowDTCONumbers: string[] = [];
  private nonEscrowStatus: 'delinquent' | 'paid' | 'not_null' = 'not_null';
  private nonEscrowYears: string[] = [];
  private escrowTaxAmount: 'null' | 'not_null' | 'any' = 'any';
  private reportedDateForAdd: 'isNull' | 'isNotNull' | 'all' = 'isNull';

  // Mark all variables
  private reportedDateDialog: boolean = false;
  private selectedDate: string = DateTime.local().toFormat('MM/dd/yyyy');
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
    validDate: (value: any) => DateTime.fromFormat(value, 'MM/dd/yyyy').isValid || 'Must be a valid MM/DD/YYYY date',
  };

  private escrowYearItems: string[] = [];
  private termItems: any[] = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  private statusItems: DataTableHeader[] = [{
    text: 'Not Null',
    value: 'not_null',
  }, {
    text: 'Delinquent',
    value: 'delinquent',
  }, {
    text: 'Paid',
    value: 'paid',
  }];

  private states: DataTableHeader[] = states;

  private exportResults: any[] = [];

  protected limit = 500;

  private serverSideStoreType = 'full'
  private cacheBlockSize = 100
  private rowModelType = 'serverSide';
  private paginationPageSize = 15;

  private sharedColumnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Batch #',
      field: 'batchNumber',
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  private escrowColumnDefs: ColDef[] = this.sharedColumnDefs.concat([
    {
      headerName: 'Agency Code',
      field: 'capAgency',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency County',
      field: 'agencyCounty',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency State',
      field: 'agencyState',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Period',
      field: 'term',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Tax Due',
      field: 'amountReported',
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      flex: 1,
      minWidth: 225,
      type: 'date',
      ...defaultDateFilterParams,
    },
  ]);

  private nonEscrowColumnDefs: ColDef[] = this.sharedColumnDefs.concat([
    {
      headerName: 'DTCO #',
      field: 'dtcoNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'County',
      field: 'county',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'State',
      field: 'state',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'PAID / DELQ',
      field: 'status',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      flex: 1,
      minWidth: 225,
      type: 'date',
      ...defaultDateFilterParams,
    },
  ]);

  // Watchers
  @Watch('useEscrow')
  onEscrowModeChanged(val: boolean) {
    this.resetTable();
  }

  // Computed
  get hasRequiredInput(): boolean {
    if (!this.isAdding) {
      if (this.dateOption.reported_date_mode === 'equal' && !this.dateOption.reported_dates[0]) {
        return false;
      }

      if (this.dateOption.reported_date_mode === 'between' && (!this.dateOption.reported_dates[0] || !this.dateOption.reported_dates[1])) {
        return false;
      }
    }

    if (this.useEscrow) {
      return this.escrowStates.length > 0 || this.escrowAgencyNumbers.length > 0 || this.escrowBatchNumbers.length > 0;
    }

    return this.nonEscrowLenderNumbers.length > 0 || this.nonEscrowStates.length > 0 || this.nonEscrowDTCONumbers.length > 0;
  }

  get advancedSearch() {
    const advancedSearch: any = {};

    if (!this.isAdding) {
      const OPTION_MAP = {
        all: 'all',
        null: 'isNull',
        not_null: 'isNotNull',
        between: 'dateRange',
        equal: 'specificDate',
      };

      advancedSearch.report_date = OPTION_MAP[this.dateOption.reported_date_mode];

      if (this.dateOption.reported_date_mode === 'between') {
        [advancedSearch.start_date, advancedSearch.end_date] = this.dateOption.reported_dates;
      } else if (this.dateOption.reported_date_mode === 'equal') {
        [advancedSearch.specific_date] = this.dateOption.reported_dates;
      }
    } else {
      advancedSearch.report_date = this.reportedDateForAdd;
    }

    if (this.useEscrow) {
      Object.assign(advancedSearch, {
        history_term_in: [this.escrowTerm],
        amount_reported: this.escrowTaxAmount,
      });

      if (this.escrowYears) {
        Object.assign(advancedSearch, {
          history_year_in: this.escrowYears,
        });
      }

      if (this.escrowStates.length > 0) {
        Object.assign(advancedSearch, {
          agency_states: this.escrowStates,
        });
      }

      if (this.escrowAgencyNumbers.length > 0) {
        Object.assign(advancedSearch, {
          agency_numbers: this.escrowAgencyNumbers,
        });
      }

      if (this.escrowBatchNumbers.length > 0) {
        Object.assign(advancedSearch, {
          batch_number_in: this.escrowBatchNumbers,
        });
      }

      return advancedSearch;
    }

    // Non escrow handling
    if (this.nonEscrowLenderNumbers.length > 0) {
      Object.assign(advancedSearch, {
        lender_numbers: this.nonEscrowLenderNumbers,
      });
    }

    if (this.nonEscrowStates.length > 0) {
      Object.assign(advancedSearch, {
        agency_states: this.nonEscrowStates,
      });
    }

    if (this.nonEscrowDTCONumbers.length > 0) {
      Object.assign(advancedSearch, {
        delinquent_tax_collecting_offices: this.nonEscrowDTCONumbers,
      });
    }

    Object.assign(advancedSearch, {
      delinquent_filter: this.nonEscrowStatus,
    });

    if (this.nonEscrowCounty) {
      Object.assign(advancedSearch, {
        agency_county: this.nonEscrowCounty,
      });
    }

    if (this.nonEscrowYears && this.nonEscrowYears.length > 0) {
      Object.assign(advancedSearch, {
        history_year_in: [this.nonEscrowYears],
      });
    }

    return advancedSearch;
  }

  get colDefs() {
    return this.useEscrow ? this.escrowColumnDefs : this.nonEscrowColumnDefs;
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 2; i >= 0; i -= 1) {
      const relevantYear = currentYear + i - 1;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [, ...this.escrowYears] = this.escrowYearItems;
    this.escrowTerm = this.termItems[1].value;

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = currentYear - i;
      this.escrowYearItems.push(relevantYear.toString());
    }
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);

    // if (this.hasRequiredInput) {
    //   this.refreshRows();
    // }
  }

  refreshRows() {
    this.resetTable();
    this.gridApi.showLoadingOverlay();
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts();
        this.datasource = this.reportDatasource();
        this.gridApi.setServerSideDatasource(this.datasource);
      }
    }, 0);
  }

  private reportDatasource() {
    if (this.useEscrow) {
      return new ReportDatasourceBuilder<EscrowHistory, EscrowResult>(
        ReportName.ModifyReportedDate,
        this.escrowHistoryService.getAllEscrowHistories,
        this.escrowHistoryService.getTotalEscrowHistories,
        this.sortModel,
        this.setLoading,
        this.resetLoading,
        this.onResultsChanged,
        this.getParams,
      ).build();
    }
    return new ReportDatasourceBuilder<NonEscrowHistory, NonEscrowResult>(
      ReportName.ModifyReportedDate,
      this.nonEscrowHistoryService.getAllNonEscrowHistories,
      this.nonEscrowHistoryService.getTotalNonEscrowHistories,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build();
  }

  convertResults(results: (EscrowHistory | NonEscrowHistory)[]): BaseResult[] {
    if (this.useEscrow) {
      return (results as EscrowHistory[]).map((history: EscrowHistory) => ({
        loanId: history.loanId,
        parcelId: history.parcelId,
        parcelAgencyId: history.parcelAgencyId,
        taxHistoryEntryId: history.parcelEscrowHistoryId,
        lenderNumber: history.lenderNumber,
        loanNumber: history.loanNumber,
        parcelNumber: history.parcelNumber,
        batchNumber: history.batchNumber,
        capAgency: history.agency.capAgency,
        agencyCounty: history.agency.address.value ? history.agency.address.value.county : '',
        agencyState: history.agency.address.value ? history.agency.address.value.state : '',
        reportedDate: history ? history.reportedDate : null,
        year: history.year,
        term: Term[history.term as keyof typeof Term],
        amountReported: history && (history.amountReported || history.amountReported === 0) ? history.amountReported : null,
      }));
    }

    return (results as NonEscrowHistory[]).map((history: NonEscrowHistory) => ({
      loanId: history.loanId,
      parcelId: history.parcelId,
      parcelAgencyId: history.parcelAgencyId,
      taxHistoryEntryId: history.parcelNonEscrowHistoryId,
      lenderNumber: history.lenderNumber,
      loanNumber: history.loanNumber,
      parcelNumber: history.parcelNumber,
      state: history.agency.address && history.agency.address.value ? history.agency.address.value.state : '',
      year: history.year,
      reportedDate: history.reportedDate,
      county: history.agency.address && history.agency.address.value ? history.agency.address.value.county : '',
      dtcoNumber: history.delinquentTaxCollectingOffice ? history.delinquentTaxCollectingOffice.capAgency : '',
      status: history.status && history.status.value,
    }));
  }

  getParams(): any {
    return {
      advanced_search: this.advancedSearch,
      search_type: this.useEscrow ? 'escrow_history' : 'non_escrow_history',
      include_agency: true,
    };
  }

  resetTable() {
    this.parcels = [];
    this.results = [];
  }

  commitChanges() {
    if (this.committingChanges) return;
    this.committingChanges = true;
    const value = this.selectedModificationDate;
    const key = 'reportedDate';
    const patchEntries = this.results.map((result: BaseResult, index: number) => {
      result[key] = value;
      return {
        op: JsonPatchOperator.replace,
        path: `/${result.loanId}/parcels/${result.parcelId}/agencies/${result.parcelAgencyId}/${this.useEscrow ? 'escrow_history' : 'non_escrow_history'}/${result.taxHistoryEntryId}/${snakeCase(key)}`,
        value,
      };
    });
    this.loadingOverlayComponentParams = {
      context: this.gridOptions.context,
      api: this.gridApi,
      columnApi: this.columnApi,
      text: 'Updating',
    };
    this.gridApi.showLoadingOverlay();
    this.loanService.batchPatchLoans(patchEntries)
      .then(() => {
        this.showResults = true;
        this.exportResults = this.results;
      })
      .finally(() => {
        this.refreshRows();
        this.allowCommitChange = false;
        this.committingChanges = false;
        this.selectedModificationDate = '';
      });
  }

  markAll(key: string, value: any) {
    this.results = this.results.map((result) => {
      result.reportedDate = value;
      return result;
    });

    const tx: ServerSideTransaction = {
      update: this.results,
    };

    this.gridApi.applyServerSideTransaction(tx);
    this.gridApi.refreshCells();

    if (value !== this.selectedModificationDate) {
      this.selectedModificationDate = value;
    }
    this.allowCommitChange = true;
  }

  removeAll(key: string) {
    const patchEntries = this.results.map((result: BaseResult, index: number) => {
      result[key] = null;
      return {
        op: JsonPatchOperator.replace,
        path: `/${result.loanId}/parcels/${result.parcelId}/agencies/${result.parcelAgencyId}/${this.useEscrow ? 'escrow_history' : 'non_escrow_history'}/${result.taxHistoryEntryId}/${snakeCase(key)}`,
        value: null,
      };
    });

    this.loadingOverlayComponentParams = {
      context: this.gridOptions.context,
      api: this.gridApi,
      columnApi: this.columnApi,
      text: 'Updating',
    };
    this.gridApi.showLoadingOverlay();

    this.loanService.batchPatchLoans(patchEntries)
      .then(() => {
        this.showResults = true;
        this.exportResults = this.results;
      })
      .finally(() => {
        this.refreshRows();
      });
  }

  setCurrentDate() {
    this.selectedDate = DateTime.local().toFormat('MM/dd/yyyy');
  }

  async createPDF() {
    this.gridApi.hideOverlay();
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally();
      this.generatePDFOnServer();
      this.gridApi.hideOverlay();
    }, 100);
  }

  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = this.useEscrow
      ? await this.escrowHistoryService.getAllEscrowHistories(searchParams)
      : await this.nonEscrowHistoryService.getAllNonEscrowHistories(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const res = await this.exportPdf(
      newResults,
      '/pdf/modify-reported-date',
      {
        resData: JSON.stringify(newResults),
        escrowTerm: this.escrowTerm,
        useEscrow: this.useEscrow.toString(),
      },
      'results',
    );
    this.isGeneratingPDF = false;
    return window.open(res.data.link, '_blank');
  }

  generatePDFLocally() {
    this.isGeneratingPDF = true;

    const pdfBuilder: ReportPdfBuilder<BaseResult> = new ReportPdfBuilder<BaseResult>(this.results, {
      groupBy: 'lenderNumber',
      columnNames: new Map([
        ['lenderNumber', 'Lender #'],
        ['loanNumber', 'Loan #'],
        ['parcelNumber', 'Parcel #'],
        ['capAgency', 'Agency #'],
      ]),
      dataFormatters: new Map([
        ['amountReported', (data) => formatCurrency(data)],
      ]),
      footer: 5,
      orientation: 'l',
      exclusions: ['taxHistoryEntryId', 'loanId', 'parcelId', 'parcelAgencyId'],
    });

    pdfBuilder.setHeaderDraw((doc: jsPDF, height, width, pageNumber, grouping: BaseResult[]) => {
      const marginLeft = 40;

      doc.setFontSize(20);
      doc.text(`${this.useEscrow ? 'ESCROW' : 'NON-ESCROW'}${this.useEscrow ? ` ${(Term[this.escrowTerm as keyof typeof Term]).toUpperCase()}` : ''} MODIFY REPORT DATE`, marginLeft, 58, { align: 'left' });
    });

    const pdf = pdfBuilder.build();

    const pdfName = `ModifyReportedDate-${this.useEscrow ? 'Escrow' : 'NonEscrow'}${this.useEscrow ? `-${this.escrowTerm}` : ''}`;
    pdf.save(pdfName);

    this.isGeneratingPDF = false;
  }

  async exportTable() {
    if (this.useEscrow) {
      this.exportReportTable(
        new ExportDataParams({
          file: 'ModifyReportedDateReport',
          exclusions: ['loanId', 'parcelId', 'parcelAgencyId', 'taxHistoryEntryId'],
          colDefs: this.colDefs,
        }),
        this.escrowHistoryService.getAllEscrowHistories,
      )
    } else {
      this.exportReportTable(
        new ExportDataParams({
          file: 'ModifyReportedDateReport',
          exclusions: ['loanId', 'parcelId', 'parcelAgencyId', 'taxHistoryEntryId'],
          colDefs: this.colDefs,
        }),
        this.nonEscrowHistoryService.getAllNonEscrowHistories,
      )
    }
  }
}
