import { helpers } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';

const phoneNumber = helpers.regex('phoneNumber', /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)
const usZipCode = helpers.regex('zipCode', /^\d{5}(-\d{4})?$/);
const permissiveUrl = helpers.regex('permissiveUrl', /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/)
const fullDate = (value: any) => (!helpers.req(value) || ((DateTime.fromFormat(value, 'MM/dd/yyyy').isValid) && DateTime.fromFormat(value, 'MM/dd/yyyy').year >= 1582));
const fullDateShortYear = (value: any) => (!helpers.req(value) || DateTime.fromFormat(value.splice(6, 0, '20'), 'MM/dd/yyyy').isValid);
const shortDate = (value: any) => (!helpers.req(value) || DateTime.fromFormat(`${value}/2020`, 'MM/dd/yyyy').isValid);
const stateTerritoryAbbr = helpers.regex('stateTerritoryAbbr', /^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$/);

// eslint-disable-next-line import/prefer-default-export
export {
  phoneNumber, usZipCode, permissiveUrl, fullDate, fullDateShortYear, shortDate, stateTerritoryAbbr,
};
