






























import {
  Component, Watch, Vue, Emit,
} from 'vue-property-decorator';
import { debounce } from 'lodash';

import LoanService from '@/services/loans';

import Loan from '@/entities/Loan';

@Component({
  name: 'loan-search',
})
export default class LoanSearch extends Vue {
  // Services
  private loanService: LoanService = new LoanService();

  private loanSearch: string = '';
  private loanDebounce: Function = null;
  private foundLoans: Loan[] = [];
  private selectedLoans: Loan[] = [];

  private isSearching: boolean = false;

  // Watchers
  @Watch('loanSearch')
  async onLoanSearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.loanDebounce) {
      this.loanDebounce = debounce(async () => {
        this.isSearching = true;
        const response = await this.loanService.getAllLoans({
          search_field: 'all',
          search_value: this.loanSearch,
          limit: 100,
        });
        this.isSearching = false;

        this.foundLoans = response.results;
      }, 500);
    }

    this.loanDebounce();
  }

  // Methods
  determineLoanName(item: Loan): string {
    return `${item.borrowerName || item.borrowerName2 || item.companyName} - ${item.loanNumber}`;
  }

  remove(item: any) {
    const newValue = [...this.selectedLoans];
    newValue.splice(
      this.selectedLoans.findIndex((value) => value === item),
      1,
    );

    this.update(newValue);
  }

  @Emit('input')
  update(items: Loan[]) {
    this.selectedLoans = items;
    return this.selectedLoans.map((loan) => (this.$attrs['item-value'] ? (loan as any)[this.$attrs['item-value']] : loan));
  }
}
