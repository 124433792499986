








import { Vue, Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

import VerifiedCheckbox from '@/components/VerifiedCheckbox.vue';

export type VerifiedCellRendererParams = ICellRendererParams & {
  onToggle: (data: any, checked: boolean) => boolean,
};

@Component({
  name: 'verified-cell-renderer',
  components: {
    VerifiedCheckbox,
  },
})
export default class VerifiedCellRenderer extends Vue {
  private params: VerifiedCellRendererParams = null;
  private initialChecked: boolean = false;

  get editMode(): boolean {
    return this.params.column.getColDef().editable as boolean;
  }

  mounted() {
    console.log('mounted running to update', this.params);
    this.initialChecked = this.params.node.data.originalVerified;
  }

  toggledHandler(checked: boolean) {
    const colId = this.params.column.getColId();
    if (this.params.onToggle) {
      const verified = this.params.onToggle(this.params.node.data[colId], checked);
      console.log('is verified or not', verified)
      if (verified) {
        this.params.node.setDataValue(colId, verified);
      }
    } else {
      this.params.node.setDataValue(colId, checked);
    }
  }
}
