import { IServerSideGetRowsParams, IServerSideGetRowsRequest, SortModelItem } from 'ag-grid-community'
import _ from 'lodash'
import IReportServerSideDatasource from './IReportServerSideDatasource'
import HttpRequestBuilder from './builder/HttpRequestBuilder'
import RowFetcher from './rowFetcher/RowFetcher'
import IRowFetcherParams from './params/IRowFetcherParams'
import IHttpRequestParams from './params/IHttpRequestBuilderParams'

export default class ReportDatasource<T, RR> implements IReportServerSideDatasource {
  public lastRow: number = -1
  public filteredLastRow: number = -1
  private httpRequestBuilder: HttpRequestBuilder
  private rowFetcher: RowFetcher<T, RR>

  constructor(
    private onSortModelChanged: (sortModel: SortModelItem[]) => void,
    private setLoading: () => void,
    private resetLoading: () => void,
    rowFetcherParams: IRowFetcherParams<T>,
    httpRequestParams: IHttpRequestParams,
    private showLoading?: () => void,
    private failureHandler?: (e: any) => void,
    private finallyCallback?: () => void,
  ) {
    this.onSortModelChanged = onSortModelChanged
    this.setLoading = setLoading
    this.resetLoading = resetLoading
    this.httpRequestBuilder = new HttpRequestBuilder(httpRequestParams)
    this.rowFetcher = new RowFetcher(rowFetcherParams)
    this.failureHandler = failureHandler
    this.finallyCallback = finallyCallback
    this.showLoading = showLoading
  }

  public onFilterChanged() {
    this.filteredLastRow = -1
    this.rowFetcher.reset()
  }

  public cancel() {
    this.rowFetcher.cancel()
  }

  public reset(httpRequestParams: IHttpRequestParams) {
    this.httpRequestBuilder = new HttpRequestBuilder(httpRequestParams)
    this.lastRow = -1
    this.filteredLastRow = -1
    this.rowFetcher.reset()
    this.resetLoading()
  }

  public async getRows(serverSideGetRowsParams: IServerSideGetRowsParams) {
    if (typeof this.showLoading === 'function') {
      this.showLoading()
    }
    this.setLoading()
    const {
      request,
      request: { sortModel },
    } = serverSideGetRowsParams
    // TODO check if sortModel changed then only call this method,
    // but not found any issue to set it every time getRows called
    this.onSortModelChanged(sortModel)
    const {
      quickSearch,
      columnFilter,
      entitySearchParams: searchQueryParams,
    } = this.httpRequestBuilder.buildRequest(request)
    const filteredSearchRequested = quickSearch || columnFilter
    this.logRequest(request, filteredSearchRequested, quickSearch, columnFilter)
    this.rowFetcher.getRows(
      searchQueryParams,
      serverSideGetRowsParams,
    )
      .then((result) => this.updateLastRow(filteredSearchRequested, result))
      .catch((e) => {
        if (typeof this.failureHandler === 'function') {
          this.failureHandler(e)
        }
      })
      .finally(() => {
        this.resetLoading()
        if (typeof this.finallyCallback === 'function') {
          this.finallyCallback()
        }
      })
  }

  private updateLastRow(filteredSearchRequested: boolean, result: number) {
    if (filteredSearchRequested) {
      this.filteredLastRow = result
    } else {
      this.lastRow = result
    }
  }

  private logRequest(
    request: IServerSideGetRowsRequest,
    filteredSearchRequested: boolean,
    quickSearch: boolean,
    columnFilter: boolean,
  ) {
    let msg = `asking for ${request.startRow} to ${request.endRow}`
    if (filteredSearchRequested) {
      msg += ' with quick search and column filter'
    } else if (quickSearch) {
      msg += ' with quick search'
    } else if (columnFilter) {
      msg += ' with column filter'
    }
    console.log(msg)
  }
}
