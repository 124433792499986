import { CancelToken } from 'axios';

import SearchResponse from '@/services/responses/SearchResponse';
import EscrowHistory from '@/entities/EscrowHistory';
import EscrowHistoryApiService from '@/services/api/EscrowHistoryApiService';
import SearchRequestParams from '@/services/requests/SearchRequestParams';
import IEntityCountResponse from '../api/models/IEntityCountResponse';

type EscrowHistoryCollection = SearchResponse<EscrowHistory>;

interface AdvancedSearch {
  agency_numbers?: string[],
  agency_states?: string[],
  lender_numbers?: string[],
  delinquent_tax_collecting_offices?: string[],
  delinquent_filter?: string,
}

interface EscrowSearchParams extends SearchRequestParams<AdvancedSearch> {
  search_field: string,
  search_value: string,
}

class EscrowHistoryService {
  async getAllEscrowHistories(params: EscrowSearchParams, cancelToken?: CancelToken): Promise<EscrowHistoryCollection> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);

    const response = await EscrowHistoryApiService.getAllEscrowHistories(stringifiedParams, cancelToken);

    return {
      count: response.count,
      results: response.results.map((result) => new EscrowHistory(result)),
    };
  }

  async getEscrowHistory(id: string, cancelToken?: CancelToken): Promise<EscrowHistory> {
    const response = await EscrowHistoryApiService.getEscrowHistory(id, cancelToken);

    return new EscrowHistory(response);
  }

  async getTotalEscrowHistories(params: EscrowSearchParams, cancelToken?: CancelToken): Promise<IEntityCountResponse> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);
    return EscrowHistoryApiService.getTotalEscrowHistories(stringifiedParams, cancelToken);
  }
}

export { EscrowHistoryService as default };
