import IAddress from '@/entities/IAddress';

export default class Address implements IAddress {
  [index: string]: any;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
  lot?: string;
  block?: string;
  unit?: string;
  building?: string;

  static fullAddress(address: Address, newLine: boolean = false): string {
    return `${address.address1 ? address.address1 : ''}${newLine ? '\n' : ' '}${address.city ? `${address.city}, ` : ''}${address.state ? `${address.state} ` : ''}${address.zipCode ? address.zipCode : ''}`;
  }

  static firstLineAddress(address: Address): string {
    return address.address1 || '';
  }

  static secondLineAddress(address: Address): string {
    return `${address.city ? `${address.city}, ` : ''}${address.state ? `${address.state} ` : ''}${address.zipCode ? `${address.zipCode}` : ''}`;
  }

  static combineAddressFields(address: Address, props: string[], separator: string = ','): string {
    return props.reduce((addressString: string, addressProp: string, idx: number): string => {
      if (!address[addressProp]) return addressString;

      addressString += `${address[addressProp]}`;

      if (idx + 1 !== props.length) {
        addressString += ', ';
      }

      return addressString;
    }, '');
  }
}
