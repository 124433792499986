





































































































import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { DateTime } from 'luxon';
import jsPDF from 'jspdf';
import { ColDef, SortChangedEvent } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { DataTableHeader, InputValidationRule } from 'vuetify';
import autoTable from 'jspdf-autotable';

import NonEscrowHistoryService from '@/services/nonEscrowHistories';

import Lender from '@/entities/Lender';
import NonEscrowHistory from '@/entities/NonEscrowHistory';
import Agency from '@/entities/Agency';

import SsrmGridReport from '@/views/reports/SsrmGridReport.vue';
import LenderSearch from '@/views/lenders/LenderSearch.vue';
import AgencyCountySearch from '@/views/agencies/AgencyCountySearch.vue';

import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import SimpleChipAutocomplete from '@/components/inputs/SimpleChipAutocomplete.vue';
import ReportedDateSelection, { ReportedDateOption } from '@/components/inputs/queries/ReportedDateSelection.vue';

import states from '@/data/states';

import { fullDate } from '@/validations/vuetify';
import { formatCurrency } from '@/components/ag-grid/formatters/CurrencyFormatter';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import NonEscrowHistoryCollection from '@/services/nonEscrowHistories/NonEscrowHistoryCollection';
import ExportDataParams from './models/ExportDataParams';
import ReportName from './models/ReportName';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';

interface ReportRow {
  lenderNumber: string,
  loanNumber: string,
  parcelNumber: string,
  state: string,
  dtco: string,
  parcelType: string,
  reportedDate: string,
  name: string,
  status: string,
  year: string,
  base: number,
  address: string,
}

@Component({
  name: 'audit-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
    ReportedDateSelection,
    LenderSearch,
    SimpleChipAutocomplete,
    AgencyCountySearch,
  },
})
export default class AuditReport extends SsrmGridReport<NonEscrowHistory, ReportRow> {
  // Root entities
  private pages: any = {};

  private lenders: (Lender & { displayString?: string })[] = [];

  private generatePDFText = 'Generate Labels';
  private isGeneratingPDF: boolean = false;

  // Services
  private service: NonEscrowHistoryService = new NonEscrowHistoryService();

  // User options
  private states: DataTableHeader[] = states;

  // User inputs
  private pickedStates: string[] = [];
  private countySearch: Agency[] = null;
  private selectedLenders: string[] = [];
  private reportedDates: Date[] = [];
  private parcelType: 'e_and_en' | 'n_and_en' | 'all' = 'e_and_en';
  private title: string = '';
  private selectionMode: 'all' | 'delinquent' = 'delinquent';
  private reportDate: 'all' | 'isNull' | 'isNotNull' | 'dateRange' | 'specificDate' = 'all';
  private dateOption: ReportedDateOption = {
    reported_date_mode: 'all',
  };

  private rules: { [index: string]: InputValidationRule } = {
    validDate: fullDate,
  };

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'State',
      field: 'state',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'DTCO',
      field: 'dtco',
      width: 225,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Status',
      field: 'status',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Base Amount',
      field: 'base',
      minWidth: 150,
      flex: 1,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Parcel E/N',
      field: 'parcelType',
      minWidth: 100,
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  private pdfColumnDefs: ColDef[] = [
    {
      headerName: 'Loan #',
      field: 'loanNumber',
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
    },
    {
      headerName: 'E/N',
      field: 'parcelType',
    },
    {
      headerName: 'Borrower / Company Name',
      field: 'name',
    },
    {
      headerName: 'Status',
      field: 'status',
    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
      width: 100,
    },
    {
      headerName: 'Base Amount',
      field: 'base',
      minWidth: 150,
      flex: 1,
      type: 'currency',
    },
  ];

  // Computed
  get hasRequiredInput(): boolean {
    if (this.dateOption.reported_date_mode === 'equal' && !this.dateOption.reported_dates[0]) {
      return false;
    }

    if (this.dateOption.reported_date_mode === 'between' && (!this.dateOption.reported_dates[0] || !this.dateOption.reported_dates[1])) {
      return false;
    }

    return this.isLenderUser || this.selectedLenders.length > 0;
  }

  get advancedSearch() {
    const advancedSearch: any = {
      report_date: this.reportDate,
      non_zero_base_amount: false,
    };

    if (this.parcelType && this.parcelType !== 'all') {
      if (this.parcelType === 'e_and_en') {
        advancedSearch.parcel_type_in = ['E', 'EN'];
      } else if (this.parcelType === 'n_and_en') {
        advancedSearch.parcel_type_in = ['N', 'EN'];
      }
    }

    if (this.selectionMode === 'delinquent') {
      advancedSearch.delinquent_filter = 'delinquent';
    } else {
      advancedSearch.delinquent_filter = 'not_null';
    }

    // Report date handling
    const OPTION_MAP = {
      all: 'all',
      null: 'isNull',
      not_null: 'isNotNull',
      between: 'dateRange',
      equal: 'specificDate',
    };

    advancedSearch.report_date = OPTION_MAP[this.dateOption.reported_date_mode];

    if (this.dateOption.reported_date_mode === 'between') {
      [advancedSearch.start_date, advancedSearch.end_date] = this.dateOption.reported_dates;
    } else if (this.dateOption.reported_date_mode === 'equal') {
      [advancedSearch.specific_date] = this.dateOption.reported_dates;
    }

    if (this.selectedLenders.length > 0) {
      advancedSearch.lender_numbers = this.selectedLenders;
    }

    if (this.pickedStates.length > 0) {
      advancedSearch.agency_states = this.pickedStates;
    }

    if (this.countySearch) {
      const counties: string[] = [];
      this.countySearch.map((county) => counties.push(county.address.value.county))
      advancedSearch.dtco_county = counties;
    }

    return advancedSearch;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
    // this.refreshRows();
  }

  refreshRows() {
    this.results = [];
    this.latestResults = [];
    this.pages = {};
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  omnifilterSearchValueUpdated() {
    this.pages = {};
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<NonEscrowHistory, ReportRow>(
      ReportName.Audit,
      this.service.getAllNonEscrowHistories,
      this.service.getTotalNonEscrowHistories,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build()
  }

  convertResults(results: NonEscrowHistory[]): ReportRow[] {
    const converted = results.map((nonEscrowHistory) => {
      const result = {
        lenderNumber: nonEscrowHistory.lenderNumber,
        loanNumber: nonEscrowHistory.loanNumber,
        parcelNumber: nonEscrowHistory.altParcelNumber
          ? `${nonEscrowHistory.parcelNumber} (${nonEscrowHistory.altParcelNumber})`
          : nonEscrowHistory.parcelNumber,
        state: nonEscrowHistory.state,
        dtco: nonEscrowHistory.delinquentTaxCollectingOffice ? nonEscrowHistory.delinquentTaxCollectingOffice.name : '',
        parcelType: nonEscrowHistory.parcelType,
        reportedDate: nonEscrowHistory.reportedDate,
        name: nonEscrowHistory.name,
        status: nonEscrowHistory.status ? nonEscrowHistory.status.value : '',
        year: nonEscrowHistory.year,
        base: nonEscrowHistory.base,
        address: nonEscrowHistory.address,
      };

      // Everything below is for PDFs
      if (!this.pages[result.lenderNumber]) {
        this.pages[result.lenderNumber] = {}
      }
      if (nonEscrowHistory.delinquentTaxCollectingOffice) {
        if (!this.pages[result.lenderNumber][nonEscrowHistory.delinquentTaxCollectingOffice.address.value.state]) {
          this.pages[result.lenderNumber][nonEscrowHistory.delinquentTaxCollectingOffice.address.value.state] = {}
        }
        if (!this.pages[result.lenderNumber][nonEscrowHistory.delinquentTaxCollectingOffice.address.value.state][nonEscrowHistory.agency.delinquentTaxCollectingId]) {
          this.pages[result.lenderNumber][nonEscrowHistory.delinquentTaxCollectingOffice.address.value.state][nonEscrowHistory.agency.delinquentTaxCollectingId] = []
        }
        this.pages[result.lenderNumber][nonEscrowHistory.delinquentTaxCollectingOffice.address.value.state][nonEscrowHistory.agency.delinquentTaxCollectingId].push({
          history: nonEscrowHistory,
          result,
        })
      }
      return result;
    });

    return converted;
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      include_agency: true,
    };
  }

  async exportTable() {
    this.exportReportTable(
      new ExportDataParams({ file: 'AuditReport' }),
      this.service.getAllNonEscrowHistories,
    )
  }

  createPDF() {
    this.generatePDFText = 'Generating File';
    this.isGeneratingPDF = true;
    setTimeout(() => {
      this.generatePDF();
      this.generatePDFText = 'Create PDF';
      this.gridApi.hideOverlay();
      this.isGeneratingPDF = false;
    }, 100);
  }

  async generatePDF() {
    const searchParams = this.buildSearchParams();
    this.pages = {}
    const getAllResults = await this.service.getAllNonEscrowHistories(searchParams);
    const newResults = this.convertResults(getAllResults.results);

    if (newResults.length === 0) {
      return;
    }

    const reportPdf: jsPDF = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'letter',
    });

    /**
     * Moves the cursor in the pdf
     * @param cursor The current location of the currsor
     * @param amount The amount to add to the cursor
     * @param addPage Whether or not to add another page if necessary
     */
    function addToCursor(cursor: number, amount: number, addPage: boolean = true): number {
      cursor += amount;

      if (cursor > reportPdf.internal.pageSize.height - 30 && addPage) {
        reportPdf.addPage();
        cursor = 10;
      }

      return cursor;
    }

    Object.entries(this.pages).sort((firstEntry, secondEntry) => (firstEntry[0] < secondEntry[0] ? -1 : 1)).forEach((pageEntry, index) => {
      const page: { [index: string]: any } = pageEntry[1];
      const exampleStateForPage = page[Object.keys(page)[0]];
      const exampleDTCOForPage = exampleStateForPage[Object.keys(exampleStateForPage)[0]];
      const exampleRowForPage = exampleDTCOForPage[0];
      const lender = this.lenders.find((l) => l.id === exampleRowForPage.history.lenderNumber);
      const lenderName = lender ? lender.name : '';

      const lineWidth = 0.5;
      reportPdf.setLineWidth(lineWidth);
      const xLineMargin = 5;
      let cursor = 5;
      reportPdf.line(xLineMargin, cursor, reportPdf.internal.pageSize.getWidth() - xLineMargin, cursor);
      reportPdf.setFontStyle('bold');
      reportPdf.setFontSize(20);
      cursor = addToCursor(cursor, 8);
      reportPdf.text(`${exampleRowForPage.history.lenderNumber} ${lenderName}`, 10, cursor);
      cursor = addToCursor(cursor, 8);
      reportPdf.setFontSize(14);
      reportPdf.text(this.title || 'Conversion Audit', 10, cursor);
      cursor = addToCursor(cursor, 2);
      reportPdf.line(xLineMargin, cursor, reportPdf.internal.pageSize.getWidth() - xLineMargin, cursor);
      Object.entries(page).sort((firstEntry, secondEntry) => (firstEntry[0] < secondEntry[0] ? -1 : 1)).forEach((stateEntry) => {
        const state: { [index: string]: any } = stateEntry[1];
        const exampleDTCOForState = state[Object.keys(state)[0]];
        const exampleRowForState = exampleDTCOForState[0];

        reportPdf.setFontStyle('bold');
        reportPdf.setFontSize(14);
        cursor = addToCursor(cursor, 8);
        reportPdf.text(`State ${exampleRowForState.history.delinquentTaxCollectingOffice.address.value.state}`, 10, cursor);
        cursor = addToCursor(cursor, 1);
        Object.entries(state).sort((firstEntry, secondEntry) => (firstEntry[1][0].history.delinquentTaxCollectingOffice.name < secondEntry[1][0].history.delinquentTaxCollectingOffice.name ? -1 : 1)).forEach((dtcoEntry) => {
          const dtco: any[] = dtcoEntry[1];
          const exampleRowForDTCO = dtco[0];

          reportPdf.setFontStyle('regular');
          reportPdf.setFontSize(11);
          cursor = addToCursor(cursor, 5);
          reportPdf.text('Delinquent Tax', 10, cursor);
          cursor = addToCursor(cursor, 5);
          reportPdf.text(`Collecting Office    ${exampleRowForDTCO.history.delinquentTaxCollectingOffice.name}`, 10, cursor);

          cursor = addToCursor(cursor, 5);
          autoTable(reportPdf, {
            columns: this.pdfColumnDefs.map((header) => ({
              dataKey: header.field,
              header: header.headerName,
            })),
            styles: {
              fontSize: 8,
              cellWidth: 'wrap',
            },
            bodyStyles: {
              fontSize: 7,
              cellWidth: 18,
              overflow: 'linebreak',
            },
            columnStyles: {
              loanNumber: {
                cellWidth: 19,
              },
              parcelType: {
                cellWidth: 10,
              },
              parcelNumber: {
                cellWidth: 'auto',
              },
              name: {
                cellWidth: 55,
              },
              status: {
                cellWidth: 30,
              },
              year: {
                cellWidth: 15,
              },
            },
            body: dtco.sort((firstRow: any, secondRow: any) => {
              if (firstRow.result.loanNumber !== secondRow.result.loanNumber) {
                return firstRow.result.loanNumber > secondRow.result.loanNumber ? -1 : 1;
              }

              if (firstRow.result.parcelNumber !== secondRow.result.parcelNumber) {
                return firstRow.result.parcelNumber < secondRow.result.parcelNumber ? -1 : 1;
              }

              return firstRow.result.year > secondRow.result.year ? -1 : 1;
            }).map((row: any) => {
              const entry = cloneDeep(row.result);
              if (entry.status !== null && entry.status.toLowerCase() !== 'paid') {
                entry.base = formatCurrency(entry.base);
              } else {
                entry.base = '';
              }
              return entry;
            }),
            startY: cursor,
            margin: {
              left: 10,
              right: 10,
              bottom: 20,
              top: 10,
            },
            tableWidth: 'auto',
            didDrawPage: ((data) => {
              cursor = data.cursor.y
            }),
          });
          cursor = addToCursor(cursor, 5, false);
        })
      });

      const pageCount = reportPdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i += 1) {
        reportPdf.setPage(i);
        reportPdf.line(xLineMargin, reportPdf.internal.pageSize.height - 15, reportPdf.internal.pageSize.getWidth() - xLineMargin, reportPdf.internal.pageSize.height - 15);
        reportPdf.setFontSize(10);
        reportPdf.text(`Page ${i}`, 10, reportPdf.internal.pageSize.height - 10);
        reportPdf.setFontSize(10);
        reportPdf.text(DateTime.local().toFormat('MM/dd/yyyy'), reportPdf.internal.pageSize.width - 30, reportPdf.internal.pageSize.height - 10);
        reportPdf.setFontStyle('bold');
        reportPdf.text('CAPITAL REAL ESTATE TAX SERVICES', reportPdf.internal.pageSize.width / 3, reportPdf.internal.pageSize.height - 10);
        reportPdf.setFontStyle('regular');
      }

      if (index !== Object.values(this.pages).length - 1) {
        reportPdf.addPage();
      }
    })

    reportPdf.save(`audit_report_${DateTime.local().toFormat('F')}.pdf`);
  }
}
