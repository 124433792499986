const quickSearchParams = {
  field: '-',
  hide: true,
  lockVisible: true,
  filter: 'agTextColumnFilter',
  filterParams: {
    newRowsAction: 'keep',
  },
}
export default quickSearchParams
