import Axios, { CancelToken } from 'axios';
import EntityCountResponse from './models/EntityCountResponse';

export type QueryRunResponse = {
  count: any;
  results: any[];
}

interface SearchSelection {
  table: string,
  field: string,
}

interface SearchOperator {
  conjunction?: 'and' | 'or',
  table: string,
  field: string,
  operator: string,
  value?: any,
}

export interface QueryRunBody {
  select: SearchSelection[],
  search: SearchOperator[],
}

export default {
  async runQuery(params: QueryRunBody, cancelToken?: CancelToken): Promise<QueryRunResponse> {
    const config = {};
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.post<QueryRunResponse>('/query', params, config);

    return response.data;
  },
  async runQueryTotal(params: QueryRunBody, cancelToken?: CancelToken): Promise<EntityCountResponse> {
    const config = {};
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.post<EntityCountResponse>('/query/count', params, config);

    return response.data;
  },
};
