














import { Vue, Component } from 'vue-property-decorator';
import { ICellEditorParams } from 'ag-grid-community';

import onlyAllowNumber, { isNumber } from '@/helpers/events/onlyAllowNumber';

@Component({
  name: 'short-date-cell-editor',
})
export default class ShortDateCellEditor extends Vue {
  private params: ICellEditorParams = null;
  private value: string = '';

  private isCleared: boolean = false;

  // Hooks
  created() {
    if (this.params.charPress) {
      this.value = this.params.charPress;
    } else {
      this.value = this.params.value;
    }
  }

  // Events
  inputHandler(value: string) {
    this.isCleared = !value;
  }

  onlyForNumber($event: KeyboardEvent) {
    onlyAllowNumber($event, this.getValue());
  }

  // Editor
  afterGuiAttached() {
    ((this.$refs.field as Vue).$refs.input as HTMLInputElement).click();
  }

  isCancelBeforeStart(): boolean {
    return this.params.charPress ? !isNumber(this.params.charPress.charCodeAt(0), this.params.charPress) : false;
  }

  getValue() {
    if (this.isCleared) {
      return null;
    }

    return this.value ? this.value : this.params.value;
  }
}
