




























import { fullDate } from '@/validations/vuetify';
import { cloneDeep, isEqual } from 'lodash';
import {
  Component, Prop, Emit, Vue,
} from 'vue-property-decorator';
import { InputValidationRule } from 'vuetify';

export type DateRange = [string, string]

@Component({
  name: 'date-range-picker',
})
export default class DateRangePicker extends Vue {
  @Prop({
    type: Array,
    default: () => [null, null] as string[],
  }) readonly value!: DateRange;

  @Prop({
    type: Boolean,
    default: true,
  }) readonly showFirstDate!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  }) readonly showSecondDate!: boolean;

  @Prop({
    type: String,
    default: 'Date 1',
  }) readonly firstDateLabel!: string;

  @Prop({
    type: String,
    default: 'Date 2',
  }) readonly secondDateLabel!: string;

  // Input rules
  private rules: { [index: string]: InputValidationRule } = {
    validDate: fullDate,
  };

  @Emit('input')
  update(newValue: DateRange) {
    return newValue;
  }

  @Emit('change')
  change(newValue: DateRange) {
    return newValue;
  }

  changeDate(date: string, index: 0 | 1) {
    let newValue: DateRange = cloneDeep(this.value);

    if (date) {
      if (!newValue) {
        newValue = [null, null];
      }

      newValue[index] = date;
    } else {
      newValue[index] = null;

      if (!newValue.some((resultDate) => Boolean(resultDate))) {
        newValue = null;
      }
    }

    if (!isEqual(newValue, this.value)) {
      this.change(newValue);
    }

    this.update(newValue);
  }
}
