import { DateTime } from 'luxon';

import IParcel, { IParcelAgency, IEscrowHistory, INonEscrowHistory } from '@/entities/IParcel';
import IAddress from '@/entities/IAddress';
import User from '@/entities/User';
import EscrowType from '@/entities/EscrowType';
import Verified from '@/entities/Verified';
import IServiceParcel, { IServiceParcelAgency } from '@/services/api/models/IServiceParcel';
import IServiceLoan from '@/services/api/models/IServiceLoan';
import Agency from '@/entities/Agency';

export class ParcelAgency extends Agency implements IParcelAgency {
  parcelAgencyId: string;
  delinquentTaxCollectingId?: string;
  sequenceNumber?: string;

  escrowHistory: IEscrowHistory[];
  nonEscrowHistory: INonEscrowHistory[];

  // Verification
  parcelAgencyVerified: Verified<void>;

  parcelAgencyCreatedBy?: User;
  parcelAgencyCreatedOn?: Date;

  constructor(serviceObj: IServiceParcelAgency) {
    super(serviceObj);

    this.parcelAgencyId = serviceObj.parcel_agency_id;
    this.delinquentTaxCollectingId = serviceObj.delinquent_tax_collecting_id;
    this.sequenceNumber = serviceObj.sequence_number;

    let parcelAgencyVerifiedBy;
    if (serviceObj.parcel_agency_verified && serviceObj.parcel_agency_verified_by) {
      parcelAgencyVerifiedBy = new User(serviceObj.parcel_agency_verified_by);
    }

    this.parcelAgencyVerified = new Verified(
      undefined,
      serviceObj.parcel_agency_verified,
      parcelAgencyVerifiedBy,
      serviceObj.parcel_agency_verified_on ? DateTime.fromISO(serviceObj.parcel_agency_verified_on).toJSDate() : undefined,
    );

    let parcelAgencyCreatedBy;
    if (serviceObj.parcel_agency_created_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.parcel_agency_created_by;

      parcelAgencyCreatedBy = new User(id, given_name, family_name, email);
    }
    this.parcelAgencyCreatedBy = parcelAgencyCreatedBy;
    this.parcelAgencyCreatedOn = serviceObj.parcel_agency_created_on ? DateTime.fromISO(serviceObj.parcel_agency_created_on).toJSDate() : undefined;

    this.escrowHistory = serviceObj.escrow_history ? serviceObj.escrow_history.map((history) => {
      let zeroVerifyUser;
      if (history.zero_verified && history.zero_verified_by) {
        const {
          id, given_name, family_name, email,
        } = history.zero_verified_by;

        zeroVerifyUser = new User(id, given_name, family_name, email);
      }

      const newHistory: IEscrowHistory = {
        parcelEscrowHistoryId: history.parcel_escrow_history_id,
        agencyId: history.agency_id,
        zeroVerified: new Verified(
          undefined,
          history.zero_verified,
          zeroVerifyUser,
          history.zero_verified_on ? DateTime.fromISO(history.zero_verified_on).toJSDate() : undefined,
        ),
      };

      newHistory.term = history.term;
      newHistory.parcelId = history.parcel_id;
      newHistory.year = history.year;
      newHistory.dueDate = history.due_date ? DateTime.fromISO(history.due_date).toFormat('MM/dd') : undefined;
      newHistory.reportedDate = history.reported_date ? DateTime.fromISO(history.reported_date).toFormat('MM/dd/yyyy') : undefined;
      newHistory.amountPaid = history.amount_paid;
      newHistory.amountReported = history.amount_reported;
      newHistory.zeroVerifiedReason = history.zero_verified_reason;
      if (history.reported_by) {
        const {
          id, given_name, family_name, email,
        } = history.reported_by;
        newHistory.reportedBy = new User(id, given_name, family_name, email);
      }
      newHistory.reportNotes = history.report_notes;
      newHistory.recentCorrection = history.recent_correction;
      newHistory.batchNumber = history.batch_number;

      return newHistory;
    }) : [];

    this.nonEscrowHistory = serviceObj.non_escrow_history ? serviceObj.non_escrow_history.map((history) => {
      const newHistory: INonEscrowHistory = {
        parcelNonEscrowHistoryId: history.parcel_non_escrow_history_id,
        agencyId: history.agency_id,
        year: history.year,
      };

      newHistory.parcelId = history.parcel_id;
      newHistory.year = history.year;
      newHistory.base = history.base;
      newHistory.amountDue = history.amount_due;
      newHistory.status = new Verified(
        history.status,
        true,
        history.status_updated_by,
        history.status_updated_on ? DateTime.fromISO(history.status_updated_on).toJSDate() : undefined,
      );
      newHistory.priorYearStatus = history.prior_year_status;
      newHistory.notes = history.notes;
      newHistory.dueDate = history.due_date ? DateTime.fromISO(history.due_date).toFormat('MM/dd/yyyy') : undefined;
      newHistory.reportedDate = history.reported_date ? DateTime.fromISO(history.reported_date).toFormat('MM/dd/yyyy') : undefined;
      if (history.reported_by) {
        const {
          id, given_name, family_name, email,
        } = history.reported_by;
        newHistory.reportedBy = new User(id, given_name, family_name, email);
      }
      newHistory.updatedOn = history.updated_on ? DateTime.fromISO(history.updated_on).toFormat('MM/dd') : undefined;
      if (history.updated_by) {
        const {
          id, given_name, family_name, email,
        } = history.updated_by;
        newHistory.updatedBy = new User(id, given_name, family_name, email);
      }

      return newHistory;
    }) : [];
  }
}

export default class Parcel implements IParcel {
  parcelId: string;
  loanId: string;
  loanNumber: string;
  lenderId?: string;
  lenderNumber?: string;
  parcelNumber?: string;
  name?: string;

  address?: Verified<IAddress>;
  mailingAddress?: Verified<IAddress>;

  legal?: string;
  deleted: boolean = false;
  escrowDateDelqSearched?: string;
  escrowDelqSearchNotes?: string;
  parcelType?: string;
  countyLines?: string;
  idTag?: string;
  parcelNotes?: string;
  problem: Verified<void>;
  activePrincipalBalance?: number;
  typeDescription?: string;
  collected?: string;
  taxLastPaidAmount?: number;
  taxPeriodPaid?: string;
  taxPaidDate?: string;
  maturityDate?: string;
  whenBilled?: string;
  billNumber?: string;
  billedReport?: string;
  lenderNotes?: string;
  legalNotes?: string;
  originalNoteDate?: string;
  altParcelNumber?: string;
  hasMatchingConfiguration?: boolean;

  dateDeleted?: Date;
  dateInactive?: string;
  dateCoded?: Date;
  dateAdded?: string;

  closestDueDate?: string;

  agencies: ParcelAgency[] = [];

  verified: boolean;
  verifiedBy?: User;
  verifiedOn?: Date;

  active: boolean;

  lenderName: string;
  lenderAddress1: string;
  lenderAddress2: string;
  lenderCity: string;
  lenderState: string;
  lenderZipCode: string;

  // View layer only variables
  maxAAgency?: string;
  maxAValue?: number;
  maxASeqNumber?: number | string;
  index: number;

  static upper(obj: any): any {
    if (!obj) return obj;

    Object.keys(obj).forEach((prop: string) => {
      if (prop === 'capAgency' || prop === 'term' || prop === 'collectingFrequency' || prop === 'collectingYear' || prop === 'nonEscrowCollectingYear' || prop === 'parcelType' || prop === 'countyLines' || prop === 'loanLocationType' || prop === 'url') return;
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = obj[prop].toUpperCase();
        }
        if (typeof obj[prop] === 'object') {
          this.upper(obj[prop]);
        }
      }
    });

    return obj;
  }

  constructor(serviceObj: IServiceParcel, lender?: IServiceLoan) {
    this.parcelId = serviceObj.parcel_id;
    this.loanId = serviceObj.loan_id;
    this.loanNumber = serviceObj.loan_number;
    this.lenderId = lender ? lender.lender_id : undefined;
    this.lenderNumber = lender ? lender.lender_number : serviceObj.lender_number;
    this.parcelNumber = serviceObj.parcel_number;
    this.name = serviceObj.name;

    // Address w/ verification parameters
    let addressVerifyUser;
    if (serviceObj.address_verified && serviceObj.address_verified_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.address_verified_by;

      addressVerifyUser = new User(id, given_name, family_name, email);
    }

    this.address = new Verified<IAddress>({
      address1: serviceObj.address,
      address2: serviceObj.address_2,
      city: serviceObj.city,
      state: serviceObj.state,
      zipCode: serviceObj.zip_code,
      county: serviceObj.county,
      lot: serviceObj.lot,
      block: serviceObj.block,
      unit: serviceObj.unit,
      building: serviceObj.building,
    }, serviceObj.address_verified, addressVerifyUser, serviceObj.address_verified_on ? DateTime.fromISO(serviceObj.address_verified_on).toJSDate() : undefined);

    let mailingAddressVerifyUser;
    if (serviceObj.mailing_address_verified && serviceObj.mailing_address_verified_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.mailing_address_verified_by;

      mailingAddressVerifyUser = new User(id, given_name, family_name, email);
    }

    this.mailingAddress = new Verified<IAddress>({
      address1: serviceObj.mailing_address_1,
      address2: serviceObj.mailing_address_2,
      city: serviceObj.mailing_city,
      state: serviceObj.mailing_state,
      zipCode: serviceObj.mailing_zip_code,
      county: serviceObj.mailing_county,
    }, serviceObj.mailing_address_verified, mailingAddressVerifyUser, serviceObj.mailing_address_verified_on ? DateTime.fromISO(serviceObj.mailing_address_verified_on).toJSDate() : undefined);

    let problemVerifyUser;
    if (serviceObj.problem && serviceObj.problem_reported_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.problem_reported_by;

      problemVerifyUser = new User(id, given_name, family_name, email);
    }

    this.problem = new Verified(
      undefined,
      serviceObj.problem,
      problemVerifyUser,
      serviceObj.problem_reported_on ? DateTime.fromISO(serviceObj.problem_reported_on).toJSDate() : undefined,
    );

    let verifyUser;
    if (serviceObj.verified && serviceObj.verified_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.verified_by;

      verifyUser = new User(id, given_name, family_name, email);
    }
    this.verified = serviceObj.verified;
    this.verifiedBy = verifyUser;
    this.verifiedOn = serviceObj.verified_on ? DateTime.fromISO(serviceObj.verified_on).toJSDate() : undefined;

    this.dateCoded = serviceObj.date_coded ? DateTime.fromISO(serviceObj.date_coded).toJSDate() : undefined;
    this.dateDeleted = serviceObj.date_deleted ? DateTime.fromISO(serviceObj.date_deleted).toJSDate() : undefined;
    this.dateInactive = serviceObj.date_inactive ? serviceObj.date_inactive : ''; // This field is a varchar in the database
    this.dateAdded = serviceObj.date_added ? DateTime.fromISO(serviceObj.date_added).toFormat('MM/dd/yyyy') : undefined;

    switch (serviceObj.parcel_type) {
      case 'EN':
        this.parcelType = EscrowType.both;
        break;

      case 'E':
        this.parcelType = EscrowType.escrow;
        break;

      case 'N':
        this.parcelType = EscrowType.nonEscrow;
        break;

      default:
        this.parcelType = EscrowType.neither;
        break;
    }

    this.legal = serviceObj.legal;
    this.deleted = serviceObj.deleted;
    this.countyLines = serviceObj.county_lines;
    this.idTag = serviceObj.id_tag;
    this.parcelNotes = serviceObj.parcel_notes;
    this.activePrincipalBalance = serviceObj.active_principal_balance;
    this.typeDescription = serviceObj.type_description;
    this.collected = serviceObj.collected;
    this.taxLastPaidAmount = serviceObj.tax_last_paid_amount;
    this.taxPeriodPaid = serviceObj.tax_period_paid;
    this.taxPaidDate = serviceObj.tax_paid_date ? DateTime.fromISO(serviceObj.tax_paid_date).toFormat('MM/dd/yyyy') : undefined;
    this.maturityDate = serviceObj.maturity_date ? DateTime.fromISO(serviceObj.maturity_date).toFormat('MM/dd/yyyy') : undefined;
    this.whenBilled = serviceObj.when_billed ? DateTime.fromISO(serviceObj.when_billed).toFormat('MM/dd/yyyy') : undefined;
    this.billedReport = serviceObj.billed_report;
    this.billNumber = serviceObj.bill_number;
    this.lenderNotes = serviceObj.lender_notes;
    this.legalNotes = serviceObj.legal_notes;
    this.originalNoteDate = serviceObj.original_note_date ? DateTime.fromISO(serviceObj.original_note_date).toFormat('MM/dd/yyyy') : undefined;
    this.altParcelNumber = serviceObj.alt_parcel_number;
    this.hasMatchingConfiguration = serviceObj.has_matching_configuration;

    this.lenderName = serviceObj.lender_name;
    this.lenderAddress1 = serviceObj.lender_address_1
    this.lenderAddress2 = serviceObj.lender_address_2
    this.lenderCity = serviceObj.lender_city
    this.lenderState = serviceObj.lender_state
    this.lenderZipCode = serviceObj.lender_zip_code

    if (serviceObj.agencies) {
      this.agencies = serviceObj.agencies.map((agency) => new ParcelAgency(agency));
    }

    this.active = serviceObj.active;

    Parcel.upper(this);
  }

  static isEscrow(parcel: Parcel): boolean {
    return parcel.parcelType === EscrowType.escrow;
  }

  static clone(instance: Parcel): Parcel {
    const copy = new (instance.constructor as { new(): Parcel })();
    Object.assign(copy, instance);
    return copy;
  }
}
