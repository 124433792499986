import QueryApiService, { QueryRunResponse, QueryRunBody } from '@/services/api/QueryApiService';
import { CancelToken } from 'axios';
import EntityCountResponse from '../api/models/EntityCountResponse';

class QueryService {
  async runQuery(params: QueryRunBody, cancelToken?: CancelToken): Promise<QueryRunResponse> {
    const result = await QueryApiService.runQuery(params, cancelToken);
    return result
  }
  async runQueryTotal(params: QueryRunBody, cancelToken?: CancelToken): Promise<EntityCountResponse> {
    const result = await QueryApiService.runQueryTotal(params, cancelToken);
    return result
  }
}

export { QueryService as default };
