

















import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Model,
  Watch,
} from 'vue-property-decorator';

import _ from 'lodash';

import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { validationMixin, Validation } from 'vuelidate';
import { Route } from 'vue-router';

import HistoryService from '@/services/history';
import HistoryEntry from '@/entities/history/HistoryEntry';

@Component({
  name: 'apiError',
})
export default class ApiError extends Vue {
  navigateBack() {
    this.$router.back();
  }

  tryAgain() {
    console.log('try again');
  }
}
