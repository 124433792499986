




























































































































































































import {
  Component, Watch,
} from 'vue-property-decorator';
import { DataTableHeader, InputValidationRule } from 'vuetify';
import Papa, { ParseResult } from 'papaparse';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, GridOptions, SortChangedEvent, RowDataTransaction,
} from 'ag-grid-community';
import { debounce } from 'lodash';

import EscrowHistoryService from '@/services/escrowHistories';
import LoanService from '@/services/loans';
import LenderService from '@/services/lenders';
import AgencyService from '@/services/agencies';

import Parcel, { ParcelAgency } from '@/entities/Parcel';
import Term from '@/entities/Term';
import Lender from '@/entities/Lender';
import Agency from '@/entities/Agency';

import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';
import ActionButton from '@/components/inputs/ActionButton.vue';

import BackgroundGridReport from '@/views/reports/BackgroundGridReport.vue';
import FieldPickerGrid from '@/views/import/FieldPickerGrid.vue';
import { JsonPatchEntry, JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import Loan from '@/entities/Loan';
import { IEscrowHistory } from '@/entities/IParcel';
import { fullDate } from '@/validations/vuetify';
import { snakeCase } from 'change-case';
import ExportDataParams from '../reports/models/ExportDataParams';

interface ReportRow {
  agencyId: string,
  parcelId: string,
  loanId: string,
  parcelAgencyId: string,
  parcelEscrowHistoryId: string,
  lenderNumber: string,
  loanNumber: string,
  parcelNumber: string,
  agencyNumber: string,
  parcelDateAdded: string,
  name: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  year: string,
  term: string,
  amountReported: number,
  reportedDate: string,
  batchNumber: string,
  status: RowStatus,
  existing: boolean,
}

export enum RowStatus {
  SKIPPED,
  POPULATED,
  OVERWRITTEN
}

@Component({
  name: 'import-tax-data',
  components: {
    AgGridVue,
    GridOmnifilter,
    FieldPickerGrid,
    ActionButton,
  },
})
export default class ImportTaxData extends BackgroundGridReport<Loan, ReportRow> {
  private file: File = null;
  private columnSelections: any[] = [];

  // Update
  private isUpdating: boolean = false;
  private updatePayload: JsonPatchPayload = [];
  private showUpdateDialog: boolean = false;

  // Warning
  private showOverwriteDialog: boolean = false;

  // Dialogs
  private showFileDialog: boolean = false;
  private filePickerOptions: DataTableHeader[] = [{
    text: 'Amount Due',
    value: 'amount_due',
  }, {
    text: 'Parcel Number',
    value: 'parcel_number',
  }];
  private parsedInput: any = null;
  private foundRows: number = 0;
  private skippedRows: number = 0;

  // Search options
  private termItems: any[] = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));
  private escrowYearItems: string[] = [];
  private lenders: Lender[] = [];
  private agencies: Agency[] = [];
  private agencySearch: string = '';
  private agencyDebounce: Function = null;
  private reportedDateMode: 'all' | 'isNull' | 'isNotNull' | 'dateRange' = 'isNull';
  private amountDueMode: 'all' | 'isNull' | 'isNotNull' = 'isNull';

  // Search inputs
  private term: string = null;
  private year: string = '';
  private selectedLender: Lender = null;
  private selectedAgencyNumber: string = null;
  private reportedDates: Date[] = [];
  private totalAmountDue: number = 0.0;

  // Services
  protected limit = 500;
  private service: EscrowHistoryService = new EscrowHistoryService();
  private loanService: LoanService = new LoanService();
  private lenderService: LenderService = new LenderService();
  private agencyService: AgencyService = new AgencyService();

  // Indicators
  private isPreviewLoading: boolean = false;

  // Controls
  private headerRow: boolean = false;
  private deleteOperation: boolean = false;

  private parseResult: ParseResult<any> = null;

  // Input rules
  private rules: { [index: string]: InputValidationRule } = {
    validDate: fullDate,
  };

  // Grid setup
  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      sortable: false,
      width: 100,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      sortable: false,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      sortable: false,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      sortable: false,
    },
    {
      headerName: 'Borrower / Company Name',
      field: 'name',
      sortable: false,
      width: 210,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
    },
    {
      headerName: 'City',
      field: 'city',
      sortable: false,
    },
    {
      headerName: 'State',
      field: 'state',
      sortable: false,
      width: 100,
    },
    {
      headerName: 'Zip',
      field: 'zip',
      sortable: false,
      width: 125,
    },
    {
      headerName: 'Parcel Date Added',
      field: 'parcelDateAdded',
      sortable: false,
      type: 'date',
    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
      width: 100,
    },
    {
      headerName: 'Term',
      field: 'term',
      sortable: false,
      width: 125,
    },
    {
      headerName: 'Batch #',
      field: 'batchNumber',
      sortable: false,
      width: 125,
    },
    {
      headerName: 'Taxes Due',
      field: 'amountReported',
      type: 'currency',
      sortable: false,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      sortable: false,
      minWidth: 175,
      flex: 1,
      type: 'date',
    },
  ];

  // Watchers
  @Watch('columnSelections')
  onColumnSelectionsChanged(val: any) {
    if (!this.hasRequiredColumnSelections) {
      return;
    }

    this.foundRows = 0;
    this.skippedRows = 0;

    this.findMatches(
      (row) => { this.foundRows += 1 },
      (row) => { this.skippedRows += 1 },
    );

    this.gridApi.setRowData(this.results);
  }

  @Watch('updatePayload', { deep: true })
  onUpdatePayloadChanged(val: JsonPatchPayload, oldVal: JsonPatchPayload) {
    if (val.length === 0) {
      this.$emit('clear');
    } else {
      this.$emit('update');
    }
  }

  @Watch('agencySearch')
  async onAgencySearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.agencyDebounce) {
      this.agencyDebounce = debounce(async () => {
        this.agencyService.getAllAgencies({
          search_field: 'name_or_number',
          search_value: this.agencySearch,
          limit: 100,
          agency_selector_search: true,
        }).then((response) => {
          this.agencies = response.results;
        });
      }, 500);
    }

    this.agencyDebounce();
  }

  // Computed
  get hasRequiredInput(): boolean {
    return Boolean(this.selectedAgencyNumber);
  }

  get overwrittenRows(): ReportRow[] {
    return this.results.filter((row) => row.status === RowStatus.OVERWRITTEN);
  }

  get hasRequiredColumnSelections(): boolean {
    const parcelNumberIndex = this.columnSelections.findIndex((header) => header === 'parcel_number');
    const amountDueIndex = this.columnSelections.findIndex((header) => header === 'amount_due');

    return parcelNumberIndex !== -1 && amountDueIndex !== -1;
  }

  get advancedSearch() {
    const advancedSearch: any = {
      agency_numbers: [this.selectedAgencyNumber],
      report_date: this.reportedDateMode,
      parcel_type_in: ['E', 'EN'],
      history_year_in: [this.year],
      history_term_in: [this.term],
    };

    if (this.reportedDateMode === 'dateRange') {
      [advancedSearch.start_date, advancedSearch.end_date] = this.reportedDates;
    }

    if (this.amountDueMode === 'isNull') {
      advancedSearch.amount_due = 'null';
    } else if (this.amountDueMode === 'isNotNull') {
      advancedSearch.amount_due = 'not_null';
    }

    if (this.selectedLender) {
      advancedSearch.included_lenders = [this.selectedLender];
    }

    return advancedSearch;
  }

  get fullGridOptions(): GridOptions {
    function hasStatus(status: RowStatus) {
      return (params: any) => params.data.status === status;
    }

    return Object.assign(this.gridOptions, {
      rowClassRules: {
        'new-row': (params: any) => !params.data.existing && hasStatus(RowStatus.SKIPPED)(params),
        'overwritten-row': hasStatus(RowStatus.OVERWRITTEN),
        'populated-row': hasStatus(RowStatus.POPULATED),
      },
    });
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 0; i <= 20; i += 1) {
      const relevantYear = currentYear - i + 1;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [, this.year] = this.escrowYearItems;
    this.term = this.termItems[1].value;

    this.getAllLenders();
  }

  beforeDestroy() {
    this.$emit('clear');
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onBackgroundSortChanged(params);
    this.refreshRows();
  }

  refreshRows(suppressFileDialog: boolean = false) {
    if (this.updatePayload.length > 0 && !this.showUpdateDialog) {
      this.showUpdateDialog = true;
      return;
    }

    this.totalAmountDue = 0.0;
    this.latestResults = [];
    this.results = [];
    this.updatePayload = [];
    this.gridApi.setRowData([]);

    this.file = null;
    this.parsedInput = null;

    this.getAllData()
      .then(() => {
        if (this.results.length > 0) {
          this.showFileDialog = !suppressFileDialog;
        }
      });
  }

  convertResults(results: Loan[]): ReportRow[] {
    const parcels = results.reduce<Parcel[]>((allParcels: Parcel[], loan: Loan) => {
      const validParcels = loan.parcels.filter((parcel) => {
        const matchingParcelAgencies = parcel.agencies.filter(
          (parcelAgency) => parcelAgency.collectingSchedule.find((scheduleEntry) => scheduleEntry.term === this.term) && this.selectedAgencyNumber === parcelAgency.capAgency,
        );

        return parcel.active && matchingParcelAgencies.length > 0;
      });

      allParcels.push(...validParcels);
      return allParcels;
    }, []);

    return parcels.reduce((allSummaries, parcel) => {
      // Get the relevant loan
      const loan: Loan = results.find((candidateLoan) => candidateLoan.loanNumber === parcel.loanNumber);

      // Get the relevant parcel agencies for each parcel - each gets a row
      const relevantParcelAgencies: ParcelAgency[] = [this.selectedAgencyNumber].reduce<ParcelAgency[]>((allParcelAgencies, agencyNumber) => {
        const foundParcelAgency = parcel.agencies.find((agency) => agency.capAgency && agency.capAgency === agencyNumber);
        const matchingScheduleEntry = foundParcelAgency && foundParcelAgency.collectingSchedule.find((scheduleEntry) => scheduleEntry.term === this.term);

        if (foundParcelAgency && matchingScheduleEntry) {
          allParcelAgencies.push(foundParcelAgency);
        }

        return allParcelAgencies;
      }, []);

      const relevantEscrowHistories: IEscrowHistory[] = relevantParcelAgencies.map(
        (parcelAgency) => parcelAgency.escrowHistory[0],
      );

      // Build the parcel summaries to show the user
      // Every parcel agency remaining matches term picked
      relevantParcelAgencies.forEach((agency, index) => {
        const existingHistory = relevantEscrowHistories[index];

        allSummaries.push({
          parcelId: parcel.parcelId,
          loanId: parcel.loanId,
          agencyId: agency.agencyId,
          parcelAgencyId: agency.parcelAgencyId,
          lenderNumber: parcel.lenderNumber,
          loanNumber: parcel.loanNumber,
          parcelNumber: parcel.parcelNumber,
          agencyNumber: agency.capAgency,
          parcelDateAdded: parcel.dateAdded,
          name: loan.name,
          address: parcel.address.value.address1,
          state: parcel.address.value.state,
          city: parcel.address.value.city,
          zip: parcel.address.value.zipCode,
          year: this.year,
          term: Term[this.term as keyof typeof Term],
          amountReported: existingHistory && (existingHistory.amountReported || existingHistory.amountReported === 0) ? existingHistory.amountReported : null,
          reportedDate: existingHistory ? existingHistory.reportedDate : null,
          batchNumber: existingHistory ? existingHistory.batchNumber : null,
          parcelEscrowHistoryId: existingHistory ? existingHistory.parcelEscrowHistoryId : null,
          status: RowStatus.SKIPPED,
          existing: Boolean(existingHistory),
        });
      });

      return allSummaries;
    }, []);
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      search_type: 'escrow_history',
      limit_final_query_flag: true,
    };
  }

  getRows(params: any, limit = this.limit, offset = 0) {
    const finalParams = { limit, offset, ...params };

    // Get all the loans
    return this.makeCancellableRequest(this.loanService.getAllLoans, finalParams)
      .then((response) => {
        const { results } = response;
        return results;
      });
  }

  writeColumn(field: keyof ReportRow, value: any) {
    // Update in the table
    this.results.forEach((row: any) => {
      if (row.status !== RowStatus.SKIPPED) {
        row[field] = value;
      }
    });

    const transaction: RowDataTransaction = {
      update: this.results,
    };

    this.gridApi.applyTransaction(transaction);

    // Update in the payload
    const newEntries: JsonPatchEntry[] = [];

    this.updatePayload = this.updatePayload.filter((payloadEntry) => {
      if (payloadEntry.op !== JsonPatchOperator.replace) {
        return true;
      }

      const pathTarget = payloadEntry.path.substr(payloadEntry.path.lastIndexOf('/') + 1);
      return pathTarget !== snakeCase(field);
    });

    this.updatePayload.forEach((payloadEntry) => {
      let fieldText: string = field
      if (field === 'batchNumber') {
        fieldText = 'batch_number';
      }
      if (payloadEntry.op === JsonPatchOperator.add) {
        payloadEntry.value[fieldText] = value;
      } else if (payloadEntry.op === JsonPatchOperator.replace) {
        const rootPath = payloadEntry.path.substr(0, payloadEntry.path.lastIndexOf('/'));
        newEntries.push({
          op: JsonPatchOperator.replace,
          path: `${rootPath}/${snakeCase(fieldText)}`,
          value,
        });
      }
    });

    this.updatePayload.push(...newEntries);
  }

  commitMatch() {
    if (!this.hasRequiredColumnSelections) {
      return;
    }

    this.findMatches(
      (row, amountDue) => {
        row.status = row.amountReported || row.amountReported === 0 ? RowStatus.OVERWRITTEN : RowStatus.POPULATED;
        row.amountReported = amountDue;
        if (amountDue) {
          this.totalAmountDue += +amountDue;
        }

        if (row.existing) {
          this.updatePayload.push({
            op: JsonPatchOperator.replace,
            path: `/${row.loanId}/parcels/${row.parcelId}/agencies/${row.parcelAgencyId}/escrow_history/${row.parcelEscrowHistoryId}/amount_reported`,
            value: amountDue,
          });
        } else {
          const fullEntry = {
            agency_id: row.agencyId,
            parcel_id: row.parcelId,
            parcel_agency_id: row.parcelAgencyId,
            year: this.year,
            term: this.term,
            amount_reported: amountDue,
          };

          this.updatePayload.push({
            op: JsonPatchOperator.add,
            path: `/${row.loanId}/parcels/${row.parcelId}/agencies/${row.parcelAgencyId}/escrow_history/-`,
            value: fullEntry,
          });
        }
      },
      () => {},
    );

    this.gridApi.setRowData(this.results);
    this.showFileDialog = false;
  }

  findMatches(onMatch: (row: ReportRow, value: any) => void, onMiss: (row: ReportRow, value: any) => void) {
    const parcelNumberIndex = this.columnSelections.findIndex((header) => header === 'parcel_number');
    const amountDueIndex = this.columnSelections.findIndex((header) => header === 'amount_due');

    this.parsedInput.forEach((inputRow: any) => {
      const candidateRow = this.results.find((resultRow) => resultRow.parcelNumber === inputRow[parcelNumberIndex]);
      if (candidateRow) {
        onMatch(candidateRow, inputRow[amountDueIndex].split(',').join('').split('$').join(''));
      } else {
        onMiss(candidateRow, inputRow[amountDueIndex]);
      }
    });
  }

  checkAndSubmit() {
    if (this.overwrittenRows.length > 0) {
      this.showOverwriteDialog = true;
      return;
    }

    this.submitChanges();
  }

  async submitChanges() {
    try {
      this.isUpdating = true;
      this.loadingOverlayComponentParams = {
        context: this.gridOptions.context,
        api: this.gridApi,
        columnApi: this.columnApi,
        text: 'Submitting Changes',
      };
      this.$nextTick(() => {
        this.gridApi.showLoadingOverlay();
      });
      await this.loanService.batchPatchLoans(this.updatePayload);
    } finally {
      this.isUpdating = false;
      this.gridApi.hideOverlay();
      this.updatePayload = [];
      this.refreshRows(true);
    }
  }

  exportTable() {
    this.exportData(new ExportDataParams({
      file: 'EscrowTaxDataImport',
    }))
  }

  async parsePapa(input: File): Promise<ParseResult<any>> {
    return new Promise((resolve, error) => {
      Papa.parse(input, {
        complete: (results) => {
          resolve(results);
        },
      });
    });
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_desc: false,
    };
    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
  }

  determineAgencyName(item: Agency): string {
    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`;
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`;
    }

    nameString += `, ${item.capAgency}`;

    return nameString;
  }
}
