






























































































import {
  Component, Mixins, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import Papa, { ParseResult } from 'papaparse';
import ParseAddress, { ParseAddressResult } from 'parse-address';
import XLSX from 'xlsx';
import { snakeCase } from 'change-case';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, GridApi, GridReadyEvent, GridOptions, ColumnApi,
} from 'ag-grid-community';
import _, {
  tap, cloneDeep,
} from 'lodash';
import { DateTime } from 'luxon';

import ImportService from '@/services/import';

import ImportHeader from '@/views/import/ImportHeader.vue';

import CheckboxCellRenderer from '@/components/ag-grid/CheckboxCellRenderer.vue';
import LoadingCircleOverlay, { LoadingCircleOverlayParams } from '@/components/ag-grid/overlays/LoadingCircleOverlay.vue';
import SimpleDateCellEditor from '@/components/ag-grid/SimpleDateCellEditor.vue';
import ShortDateCellEditor from '@/components/ag-grid/ShortDateCellEditor.vue';
import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';

import formatters from '@/components/ag-grid/formatters';

import KeyboardNavigation from '@/mixins/KeyboardNavigation.vue';

interface HiddenColumn {
  index: number;
  entry: string;
  selected: string;
}

type TypedHeader = DataTableHeader & {
  type?: string,
};

@Component({
  name: 'field-picker-grid',
  components: {
    AgGridVue,
    GridOmnifilter,
  },
})
export default class FieldPickerGrid extends Mixins(KeyboardNavigation) {
  // Props
  @Prop(File) readonly file: File | undefined;

  @Prop({
    type: Array,
    default: (): DataTableHeader[] => [],
  }) readonly options: DataTableHeader[];

  @Prop({
    type: Boolean,
    default: false,
  }) readonly searchable: boolean;

  @Prop({
    type: String,
    default: null,
    validator: (val) => ['partial', 'full', 'escrowTax'].findIndex((elt) => elt === val) !== -1,
  }) readonly fileLayout: 'partial' | 'full' | 'escrowTax';

  // Indicators
  private isCommitting: boolean = false;
  private isPreviewLoading: boolean = false;

  // Import
  private disableToggleHeaderRow: boolean = false;
  private showColumnDescription: boolean = false;

  // Constants
  private placeholderHeader: string = 'placeholder_header_';

  // Services
  private service = new ImportService();

  // Dialogs
  private columnWriteDialog: boolean = false;
  private columnWriteValue: string = '';
  private columnWriteIndex: number = -1;
  private describeColumnKeyedList = [''];
  private describeColumnTitle: String = 'Title';
  private describeColumnKeyedMap = [
    {
      key: 'parcel_type',
      value: ['E', 'N', 'EN'],
    },
    {
      key: 'zipCode',
      value: ['99999', '99999-9999'],
    },
    {
      key: 'loan_type',
      value: ['E', 'N', 'EN'],
    },
    {
      key: 'mailing_zip_code',
      value: ['99999', '99999-9999'],
    },
    {
      key: 'county_lines',
      value: ['OCL - Web', 'OCL - Parcel', 'OCL - Phone', 'ICL - Web', 'ICL - Parcel', 'ICL - Phone'],
    },
  ];

  private allColumns: TypedHeader[] = [
    {
      text: 'Lender Number',
      value: 'lender_number',
    }, {
      text: 'Loan Number',
      value: 'loan_number',
    },
    {
      text: 'Parcel Escrow Type',
      value: 'parcel_type',
    },
    {
      text: 'Parcel Number',
      value: 'parcel_number',
    }, {
      text: 'Street Address 1',
      value: 'address',
    },
    {
      text: 'Street Address 2',
      value: 'address2',
    }, {
      text: 'City',
      value: 'city',
    }, {
      text: 'State',
      value: 'state',
    }, {
      text: 'ZIP',
      value: 'zipCode',
    }, {
      text: 'County',
      value: 'county',
    }, {
      text: 'Agency Number',
      value: 'agency_id', // this is correct, backend is expecting this
    }, {
      text: 'Legal Description',
      value: 'legal',
    }, {
      text: 'Borrower Name',
      value: 'borrower_name',
    },
    { text: 'Borrower Name 2', value: 'borrower_name_2' },
    { text: 'Company Name', value: 'company_name' },
    {
      text: 'Branch Number',
      value: 'branch_number',
    }, {
      text: 'Date Received',
      value: 'date_received',
      type: 'date',
    }, {
      text: 'Added Date',
      value: 'date_added',
      type: 'date',
    }, {
      text: 'Sequence Number',
      value: 'sequence_number',
    },
    { text: 'Loan Type', value: 'loan_type' },
    { text: 'Loan Notes', value: 'notes' },
    { text: 'Contract Id', value: 'contract_id' },
    { text: 'Id Flag', value: 'id_flag' },
    { text: 'Cvt Number', value: 'cvt_number' },
    { text: 'Purpose Code', value: 'purpose_code' },
    { text: 'Class Code', value: 'class_code' },
    { text: 'Loan Location Type', value: 'loan_location_type' },
    { text: 'Billing Notes', value: 'billing_notes' },
    { text: 'Parcel Lot', value: 'lot' },
    { text: 'Parcel Block', value: 'block' },
    { text: 'Parcel Unit', value: 'unit' },
    { text: 'Parcel Building', value: 'building' },
    { text: 'Parcel Date Coded', value: 'date_coded', type: 'date' },
    {
      text: 'Parcel Escrow Date Delq Searched',
      value: 'escrow_date_delq_searched',
      type: 'date',
    },
    {
      text: 'Parcel Escrow Delq Search Notes',
      value: 'escrow_delq_search_notes',
    },
    { text: 'Parcel Mailing County', value: 'mailing_county' },
    { text: 'Parcel Mailing Address 1', value: 'mailing_address_1' },
    { text: 'Parcel Mailing Address 2', value: 'mailing_address_2' },
    { text: 'Parcel Mailing City', value: 'mailing_city' },
    { text: 'Parcel Mailing State', value: 'mailing_state' },
    { text: 'Parcel Mailing Zip Code', value: 'mailing_zip_code' },
    { text: 'Parcel County Lines', value: 'county_lines' },
    { text: 'Parcel Id Tag', value: 'id_tag' },
    { text: 'Parcel Notes', value: 'parcel_notes' },
    { text: 'Parcel Problem', value: 'problem', type: 'boolean' },
    {
      text: 'Parcel Active Principal Balance',
      value: 'active_principal_balance',
      type: 'currency',
    },
    { text: 'Parcel Type Description', value: 'type_description' },
    {
      text: 'Parcel Collected',
      value: 'collected',
      type: 'currency',
    },
    {
      text: 'Parcel Tax Last Paid Amount',
      value: 'tax_last_paid_amount',
      type: 'currency',
    },
    { text: 'Parcel Tax Period Paid', value: 'tax_period_paid' },
    { text: 'Parcel Tax Paid Date', value: 'tax_paid_date', type: 'date' },
    { text: 'Parcel Maturity Date', value: 'maturity_date', type: 'date' },
    { text: 'Parcel Lender Notes', value: 'lender_notes' },
    { text: 'Parcel Legal Notes', value: 'legal_notes' },
    {
      text: 'Parcel Original Note Date',
      value: 'original_note_date',
      type: 'date',
    },
    {
      text: 'Parcel Alt Parcel Number',
      value: 'alt_parcel_number',
    },
    {
      text: 'Amount Due',
      value: 'amount_due',
      type: 'currency',
    },
  ];

  // Controls
  private headerRow: boolean = false;

  private parseResult: ParseResult<any> = null;
  private parseData: any[] = [];
  private gridData: any[] = [];
  private headerFields: string[] = [];
  private selectedHeaders: string[] = [];
  private hiddenColumns: HiddenColumn[] = [];

  // Grid setup
  private gridApi: GridApi = null;
  private columnApi: ColumnApi = null;
  private gridOptions: GridOptions = {
    suppressColumnVirtualisation: true,
    enableCellTextSelection: true,
    enterMovesDownAfterEdit: true,
  };
  private defaultColDef: ColDef = {
    editable: true,
    resizable: true,
    sortable: false,
  };
  private frameworkComponents: any = null;
  private loadingOverlayComponentParams: LoadingCircleOverlayParams = {
    context: this.gridOptions.context,
    api: this.gridApi,
    columnApi: this.columnApi,
    text: 'Processing File',
  };

  // Watchers
  @Watch('file', { deep: true })
  async onFileChanged(val: File, oldVal: File) {
    if (!val) {
      this.resetGrid();
      return;
    }

    await this.parseFile(val);
    this.calculateHeaders();
  }

  @Watch('headerRow')
  async onHeadersChanged(hasHeaderRow: boolean, hadHeaderRow: boolean) {
    if (this.gridData.length === 0) {
      return;
    }

    if (hasHeaderRow && !hadHeaderRow) {
      // The logic here gets too painful to handle showing a warning that the file will be reparsed, then resetting the toggle, without
      // retriggering this event and re-calculating the headers anyway.
      this.disableToggleHeaderRow = true;
    }

    this.calculateHeaders();
  }

  @Watch('columnWriteDialog')
  onColumnWriteDialogChanged(val: boolean) {
    if (!val) this.closeDialog();
  }

  @Watch('parseData', { deep: true })
  onParseDataChanged(val: any) {
    if (!val || val.length === 0) {
      this.gridData = [];
      return;
    }

    this.gridData = val.map((entry: any, index: number) => {
      const keys: string[] = Object.keys(entry);
      const dataObject: any = {};

      keys.forEach((key) => {
        if (key === '') {
          dataObject[this.placeholderHeader] = entry[key];
        } else {
          dataObject[snakeCase(key)] = entry[key];
        }
      });

      dataObject.index = index;

      return dataObject;
    });
  }

  // Computed
  get gridHeaders(): ColDef[] {
    this.gridData.forEach((row: any) => {
      Object.keys(row).forEach((key) => {
        if (this.headerFields.includes(key) && row[`${key}_backup`]) {
          row[key] = row[`${key}_backup`];
          delete row[`${key}_backup`];
        }
      })
    });
    return this.headerFields.map((field: string, index: number): ColDef => {
      const column: ColDef = {
        headerName: field.includes(this.placeholderHeader) ? '' : field,
        field: snakeCase(field),
        headerComponentParams: {
          index,
          selectedHeader: this.selectedHeaders[index],
          allColumns: this.options,
        },
      };

      const selectedHeader = this.selectedHeaders[index];
      const columnDef = this.allColumns.find(
        (col: DataTableHeader) => col.text === selectedHeader || col.value === selectedHeader,
      );

      if (columnDef) {
        switch (columnDef.type) {
          case 'currency':
            this.gridData.forEach((row: any) => {
              row[`${field}_backup`] = row[field];
              row[field] = row[field].split(',').join('').split('$').join('');
            });
            Object.assign(column, {
              valueFormatter: formatters.currencyFormatter,
            });
            break;

          case 'date':
            Object.assign(column, {
              cellEditor: 'dateEditor',
            });
            this.gridData.forEach((row) => {
              if (row[field]) {
                const date = DateTime.fromFormat(row[field], 'M/d/yy');
                row[field] = date.isValid ? date.toFormat('MM/dd/yyyy') : '';
              }
            });
            break;

          case 'shortDate':
            Object.assign(column, {
              cellEditor: 'shortDateEditor',
            });
            this.gridData.forEach((row) => {
              if (row[field]) {
                const date = DateTime.fromFormat(row[field], 'M/d');
                row[field] = date.isValid ? date.toFormat('MM/dd') : '';
              }
            });
            break;

          case 'boolean':
            Object.assign(column, {
              cellRenderer: 'checkboxRenderer',
            });
            this.gridData.forEach((row) => {
              row[field] = Boolean(row[field]);
            });
            break;

          default:
            break;
        }
      }

      return column;
    });
  }

  get selectedData(): any[] {
    const keyPairs: any[][] = this.selectedHeaders.reduce(
      (fullKeyPairs: any, selectedHeader: any, index: number) => {
        if (selectedHeader) {
          fullKeyPairs.push([selectedHeader, this.gridHeaders[index].field]);
        }

        return fullKeyPairs;
      },
      [],
    );

    const arrayOfEntryArrays = this.gridData.map((row: any) => {
      const importEntryArray: any[] = [{}];

      keyPairs.forEach((keyPair) => {
        importEntryArray[0][keyPair[0]] = row[keyPair[1]] ? row[keyPair[1]].trim() : null;
      });
      const seenCombos = [`${row.tax_auth_1_parcel ? row.tax_auth_1_parcel.trim() : ''} ${row.tax_auth_1 ? row.tax_auth_1.trim() : ''}`]
      for (let i = 2; i <= 5; i += 1) {
        if (row[`tax_auth_${i}_parcel`] && row[`tax_auth_${i}_parcel`].trim() !== '') {
          const comboString = `${row[`tax_auth_${i}_parcel`].trim()} ${row[`tax_auth_${i}`] ? row[`tax_auth_${i}`].trim() : ''}`;
          if (!seenCombos.includes(comboString)) {
            const newEntry = _.cloneDeep(importEntryArray[0]);
            newEntry.parcel_number = row[`tax_auth_${i}_parcel`].trim();
            delete newEntry.agency_id;
            if (row[`tax_auth_${i}`] && row[`tax_auth_${i}`].trim() !== '') {
              newEntry.agency_id = row[`tax_auth_${i}`].trim();
            }
            importEntryArray.push(newEntry)
            seenCombos.push(comboString)
          }
        }
      }

      return importEntryArray;
    })

    return _.flatten(arrayOfEntryArrays).filter((row: any) => {
      if (('lender_number' in row) && row.lender_number !== null && row.lender_number !== '') {
        return true;
      }
      return false;
    });
  }

  // Hooks
  beforeMount() {
    this.frameworkComponents = {
      agColumnHeader: ImportHeader,
      checkboxRenderer: CheckboxCellRenderer,
      loadingCircleOverlay: LoadingCircleOverlay,
      dateEditor: SimpleDateCellEditor,
      shortDateEditor: ShortDateCellEditor,
    };
  }

  // Methods
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  async parseFile(input: File) {
    this.gridApi.showLoadingOverlay();
    const parsedInput = await this.csvifyExcelFile(input);
    this.parseResult = await this.parsePapa(parsedInput);
    this.gridApi.hideOverlay();

    // Eliminate any blank rows that the parser introduces
    this.parseResult.data = this.parseResult.data.filter((row) => {
      if (row.length === 1 && row[0] === '') {
        return false;
      }
      return true;
    });

    this.$emit('parse', this.parseResult.data);
  }

  resetGrid() {
    this.parseResult = null;
    this.parseData = [];
    this.gridData = [];
    this.headerFields = [];
    this.selectedHeaders = [];
    this.hiddenColumns = [];

    this.gridApi.setRowData([]);
  }

  calculateHeaders() {
    this.hiddenColumns = [];

    if (this.headerRow) {
      this.headerFields = [];
      this.headerFields = this.generateHeaders(this.parseResult.data[0]);

      if (this.gridData && this.gridData.length > 1) {
        // We have grid data (with potential changes) to preserve.
        const localHeaders = this.gridData[0];

        this.gridData = this.gridData.slice(1).map((entry: any, index: number) => {
          const keys: string[] = Object.keys(entry);
          const dataObject: any = {};

          let keyIndex = 0;
          keys.forEach((key) => {
            const headerKey = typeof (localHeaders[key]) === 'string' ? localHeaders[key] : localHeaders[key].toString();
            if (key === '' || key === undefined || key === null) {
              dataObject[`${this.placeholderHeader}_${keyIndex}`] = entry[key];
            } else {
              dataObject[snakeCase(headerKey)] = entry[key];
            }
            keyIndex += 1;
          });
          dataObject.index = index;

          return dataObject;
        });
      } else {
        // No grid data, reparse the file.
        this.parseData = this.determineData(this.parseResult.data.slice(1), this.headerFields);
      }

      // Try to auto-compute headers if there were none already.
      if (this.selectedHeaders.length === 0 || this.selectedHeaders.filter((h) => h).length === 0) {
        this.selectedHeaders = [];
        this.headerFields.forEach((entry, index) => {
          const possibleMatch = this.allColumns.find((column: any) => column.text === this.headerFields[index] || column.value === this.headerFields[index]);
          if (possibleMatch) {
            this.selectedHeaders.push(possibleMatch.value);
          } else {
            this.selectedHeaders.push(null);
          }
        });
      }
    } else { // First Row Header is not selected
      // TODO - fix this so that we only build out clear selected headers if we need to.  Also, do not reparse file, but
      // use existing grid data + add in the existing header fields, then wipe the header fields.

      const localHeaderFields = this.headerFields;

      this.headerFields = [];

      let buildSelectedHeaders = false;
      if (this.selectedHeaders.length === 0) {
        buildSelectedHeaders = true;
      }
      this.parseResult.data[0].forEach((entry: any, index: number) => {
        if (buildSelectedHeaders) {
          this.selectedHeaders.push(null);
        }
        this.headerFields.push(`${this.placeholderHeader}${index}`);
      });

      this.parseData = this.determineData(this.parseResult.data, this.headerFields);
    }
  }

  openColumnWriteDialog(index: number) {
    this.columnWriteIndex = index;
    this.columnWriteDialog = true;
  }

  closeDialog() {
    this.columnWriteDialog = false;
    this.columnWriteValue = '';
  }

  writeColumn(index: number) {
    this.gridData = this.gridData.map((row: any) => {
      const newRow = { ...row, [snakeCase(this.headerFields[index])]: this.columnWriteValue };

      return newRow;
    });

    this.closeDialog();
  }

  addColumn() {
    this.headerFields.push(`${this.placeholderHeader}${this.headerFields.length + this.hiddenColumns.length}`);
    this.gridData.forEach((row: any) => {
      row.push('');
    });
  }

  describeColumn(index: number) {
    const titleMatch = this.allColumns.find((column: any) => column.text === this.selectedHeaders[index] || column.value === this.selectedHeaders[index]);
    this.describeColumnTitle = titleMatch.text;
    const possibleMapMatch = this.describeColumnKeyedMap.find((column: any) => column.key === titleMatch.value);
    if (possibleMapMatch && possibleMapMatch !== undefined) {
      this.describeColumnKeyedList = possibleMapMatch.value;
    } else {
      this.describeColumnKeyedList = ['Text field (key any value)'];
    }

    this.showColumnDescription = true;
  }

  hideColumn(index: number) {
    // Remove from headers
    this.hiddenColumns.push({
      index,
      entry: this.headerFields[index],
      selected: this.selectedHeaders[index],
    });
    this.headerFields.splice(index, 1);
    this.selectedHeaders.splice(index, 1);
  }

  restoreColumns() {
    if (this.hiddenColumns.length === 0) {
      return;
    }

    while (this.hiddenColumns.length !== 0) {
      const latestColumn: HiddenColumn = this.hiddenColumns.pop();
      this.headerFields.splice(latestColumn.index, 0, latestColumn.entry);
      this.selectedHeaders.splice(latestColumn.index, 0, latestColumn.selected);
    }
  }

  splitAddressColumn(index: number) {
    function valueOrEmpty(value?: string): string {
      return value || '';
    }

    this.gridData = this.gridData.map((row: any): any => {
      const address = row[snakeCase(this.headerFields[index])];
      const addressResult: ParseAddressResult = ParseAddress.parseLocation(address);

      if (!addressResult) return row;

      const newRow = {
        ...row,
        address: `${valueOrEmpty(addressResult.prefix)} ${valueOrEmpty(addressResult.number)} ${valueOrEmpty(addressResult.street)} ${valueOrEmpty(addressResult.type)}`.trim(),
        city: valueOrEmpty(addressResult.city),
        state: valueOrEmpty(addressResult.state),
        zip_code: valueOrEmpty(addressResult.zip),
      };

      return newRow;
    });

    this.headerFields.splice(index, 1, ...[
      'Address', 'City', 'State', 'ZIP Code',
    ]);

    this.selectedHeaders.splice(index, 1, ...[
      'address', 'city', 'state', 'zip_code',
    ]);
  }

  selectColumn(index: number, header: string | null) {
    if (header !== null) {
      const existingHeaderIndex = this.selectedHeaders.findIndex((selectedHeader) => selectedHeader === header);
      if (existingHeaderIndex !== -1) {
        this.selectedHeaders[existingHeaderIndex] = null;
      }
    }

    const newHeaders = tap(cloneDeep(this.selectedHeaders), (v) => {
      v[index] = header;
    });

    this.selectedHeaders = newHeaders;

    this.$emit('input', [].concat(this.selectedHeaders));
  }

  generateHeaders(data: any[]): string[] {
    return data.map((entry: string, index: number) => (entry.trim() || `${this.placeholderHeader}${index}`));
  }

  determineData(dataMatrix: any[][], headerFields: string[]): any[] {
    return dataMatrix.map(
      (row: any[], rowIndex: number) => row.reduce(
        (dataEntry: any, entry: string, entryIndex: number) => {
          // eslint-disable-next-line no-param-reassign
          dataEntry[headerFields[entryIndex]] = entry;
          return dataEntry;
        },
        {},
      ),
    );
  }

  async parsePapa(input: File | string): Promise<ParseResult<any>> {
    return new Promise((resolve, error) => {
      Papa.parse(input, {
        complete: (results) => {
          resolve(results);
        },
      });
    });
  }

  async csvifyExcelFile(input: File): Promise<string> {
    const wb = XLSX.read(await input.arrayBuffer(), { type: 'array' });
    const csv = XLSX.utils.sheet_to_csv(wb.Sheets[wb.SheetNames[0]], { blankrows: false });

    return new Promise((resolve, error) => {
      resolve(csv);
    });
  }

  async csvifyFixedFile(input: File): Promise<string> {
    // Tasks:
    //  * fixed file needs to be manipulated for any fields that are e.g. 9(9)V99.
    //  * trim all cells (I think?) in all file types before using the data in the table (via export or pushing to endpoint)

    const commaPoints = [1, 5, 7, 1, 1, 2, 11, 8, 6, 1, 6, 3, 7, 21, 1, 30, 2, 12, 1, 36, 3, 36, 25, 2, 9, 38, 1, 640,
      /* tax1 */ 1, 1, 9, 36, 6, 50,
      /* tax2 */ 1, 1, 9, 36, 6, 50,
      /* tax3 */ 1, 1, 9, 36, 6, 50,
      /* tax4 */ 1, 1, 9, 36, 6, 50,
      /* tax5 */ 1, 1, 9, 36, 6, 50, 1, 25,
      /* ,43 */];

    let text = await input.text();
    text = text.replace(/,/g, ' '); // replace any extraneous commas
    let adjustedFileSize = input.size; // adjusts as data inserted

    let pos = 0;
    while (pos < adjustedFileSize) {
      commaPoints.forEach((cp) => { // eslint-disable-line
        pos += cp; // move our pointer forward the proper amount
        text = [text.slice(0, pos), ',', text.slice(pos)].join(''); // explicit comma
        pos += 1; // move forward past the comma
        adjustedFileSize += 1;
      });

      while (pos < adjustedFileSize && text[pos] !== '\r' && text[pos] !== '\n') { // until we hit newline
        pos += 1;
      }
      while (pos < adjustedFileSize && (text[pos] === '\r' || text[pos] === '\n')) { // past newline
        pos += 1;
      }
    }

    return new Promise((resolve, error) => {
      resolve(text);
    });
  }
}
