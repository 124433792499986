

































































































import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, CellValueChangedEvent, SortChangedEvent,
} from 'ag-grid-community';
import { DataTableHeader } from 'vuetify';

import ParcelService from '@/services/parcels';
import LoanService from '@/services/loans';
import AgencyService from '@/services/agencies';

import { JsonPatchOperator } from '@/helpers/vuelidateToPatch';
import Parcel from '@/entities/Parcel';
import IclOclType from '@/entities/IclOclType';

import DateField from '@/components/inputs/dates/DateField.vue'
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import SsrmGridReport from '@/views/reports/SsrmGridReport.vue';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AxiosError } from 'axios';
import ParcelCollection from '@/services/parcels/ParcelCollection';
import ExportDataParams from './models/ExportDataParams';
import ReportName from './models/ReportName';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';

@Component({
  name: 'add-tax-office-report',
  components: {
    DateField,
    AgGridVue,
    SsrmGridOmnifilter,
  },
})
export default class AddTaxOfficeReport extends SsrmGridReport<Parcel> {
  protected copyResults = true;

  private isUpdating = false;
  private showUpdateDialog = false;

  private countyLineOptions = IclOclType;

  private agencyCodeIdPairs: any[] = [];
  private agencyCodeList: [Parcel, { capAgency: string, valid: boolean }][] = [];
  private parcelNumberUpdateList: [Parcel, { original: string, new: string }][] = [];
  private parcelCountyLinesUpdateList: [Parcel, { original: string, new: string }][] = [];
  private parcelType: 'e_and_en' | 'n_and_en' | 'all' = 'e_and_en';

  private searchLoanEscrowType: string = null;
  private searchStartDate: string = null;
  private searchEndDate: string = null;

  private service: ParcelService = new ParcelService();
  private loanService: LoanService = new LoanService();
  private agencyService: AgencyService = new AgencyService();
  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel ICL/OCL',
      field: 'countyLines',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: Object.values(this.countyLineOptions),
      },
      valueFormatter: (params) => IclOclType[params.value as keyof typeof IclOclType],
      sortable: false,
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency Code',
      field: 'capAgency',
      sortable: false,
      editable: true,
      cellClassRules: {
        'code-matched': (params: any) => params.value && params.context.idPairs.find((a: { cap_agency: string }) => a.cap_agency === params.value),
        'code-not-matched': (params: any) => params.value && !params.context.idPairs.find((a: { cap_agency: string }) => a.cap_agency === params.value),
      },
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel E/N',
      field: 'parcelType',
      width: 125,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Date Added',
      field: 'dateAdded',
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Name / Company Name',
      field: 'name',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'City',
      field: 'city',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'State',
      field: 'state',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Zip',
      field: 'zipCode',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'County',
      field: 'county',
      minWidth: 200,
      flex: 1,
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  convertResults(results: Parcel[]) {
    return results.map((parcel: Parcel) => ({
      lenderNumber: parcel.lenderNumber,
      loanNumber: parcel.loanNumber,
      loanId: parcel.loanId,
      parcelNumber: parcel.parcelNumber,
      verified: parcel.verified,
      parcelType: parcel.parcelType,
      dateAdded: parcel.dateAdded,
      name: parcel.name,
      address: `${parcel.address.value.address1 || ''}\n ${parcel.address.value.address2 || ''}`,
      city: parcel.address.value.city,
      county: parcel.address.value.county,
      state: parcel.address.value.state,
      zipCode: parcel.address.value.zipCode,
      countyLines: IclOclType[parcel.countyLines as keyof typeof IclOclType],
      parcelId: parcel.parcelId,
    }));
  }

  getParams() {
    return {
      include_name: true,
      advanced_search: this.advancedSearch,
    };
  }

  // Computed
  get advancedSearch() {
    let selectedParcelType = [] as string[]
    if (this.parcelType && this.parcelType !== 'all') {
      if (this.parcelType === 'e_and_en') {
        selectedParcelType = ['E', 'EN'];
      } else if (this.parcelType === 'n_and_en') {
        selectedParcelType = ['N', 'EN'];
      }
      return {
        parcels_with_no_agency: true,
        added_between: [this.searchStartDate, this.searchEndDate],
        parcel_type_in: selectedParcelType,
      };
    }
    return {
      parcels_with_no_agency: true,
      added_between: [this.searchStartDate, this.searchEndDate],
    };
  }

  get fullGridOptions() {
    return Object.assign(this.gridOptions, {
      context: {
        idPairs: this.agencyCodeIdPairs,
      },
    });
  }

  get hasUpdate(): boolean {
    const validAgencyCodes = !this.agencyCodeList.some((agencyCodeEntry) => !agencyCodeEntry[1].valid);
    return (this.parcelNumberUpdateList.length > 0 || this.parcelCountyLinesUpdateList.length > 0 || this.agencyCodeList.length > 0) && validAgencyCodes;
  }

  // Mixins
  isDirty() {
    return this.hasUpdate;
  }

  // Hooks
  async created() {
    const agencies = await this.agencyService.getAllAgencyCodeIdPairs();
    this.agencyCodeIdPairs = agencies;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
    // this.refreshRows();
  }

  refreshRows() {
    if (this.hasUpdate && !this.showUpdateDialog) {
      this.showUpdateDialog = true;
      return;
    }

    this.agencyCodeList = [];
    this.parcelNumberUpdateList = [];
    this.parcelCountyLinesUpdateList = [];

    this.results = [];
    this.latestResults = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<Parcel, any>(
      ReportName.AddTaxOffice,
      this.service.getAllParcels,
      this.service.getTotalParcels,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams as any,
    ).build()
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    // This only works if we let ag grid handle the row ID assignment
    const index = parseInt(event.node.id, 10);

    if (Number.isNaN(index)) {
      throw new Error('Index could not be determined for cell. Custom ID?');
    }

    this.addToChangeList(event.newValue as string, index, event.colDef.field);
  }

  addToChangeList(value: string, index: number, header: string) {
    const parcel = this.original[index] as Parcel;
    const trimmedValue = value ? value.trim() : '';
    if (['capAgency'].includes(header)) {
      const agencyMap = new Map(this.agencyCodeList);
      const foundPair = this.agencyCodeIdPairs.find((a) => a.cap_agency === trimmedValue);

      // If the agency ID is found, it's valid
      // If it is falsy after trimming whitepsace, it's valid
      // Else it's not valid
      if (foundPair) {
        agencyMap.set(parcel, {
          capAgency: foundPair.agency_id,
          valid: true,
        });
      } else if (!trimmedValue) {
        agencyMap.delete(parcel);
      } else {
        agencyMap.set(parcel, {
          capAgency: trimmedValue,
          valid: false,
        });
      }

      this.agencyCodeList = Array.from(agencyMap);
    } else {
      const updateList = header === 'parcelNumber' ? this.parcelNumberUpdateList : this.parcelCountyLinesUpdateList;
      const updateMap = new Map(updateList);
      if (!updateMap.has(parcel) || updateMap.get(parcel).original !== trimmedValue) {
        if (updateMap.has(parcel)) {
          updateMap.get(parcel).new = trimmedValue;
        } else {
          updateMap.set(parcel, {
            original: parcel.parcelNumber,
            new: trimmedValue,
          });
        }
      } else {
        updateMap.delete(parcel);
      }

      if (header === 'parcelNumber') {
        this.parcelNumberUpdateList = Array.from(updateMap);
      } else {
        this.parcelCountyLinesUpdateList = Array.from(updateMap);
      }
    }
  }

  async submitChanges() {
    this.isUpdating = true;

    const updateParcelPayload = Array.from(this.agencyCodeList).filter((agencyCodeEntry) => agencyCodeEntry[1].valid).map((p) => ({
      op: JsonPatchOperator.add,
      path: `/${p[0].loanId}/parcels/${p[0].parcelId}/agencies/-`,
      value: p[1].capAgency,
    }));

    const numberUpdates = Array.from(this.parcelNumberUpdateList).map((p) => ({
      op: JsonPatchOperator.replace,
      path: `/${p[0].loanId}/parcels/${p[0].parcelId}/parcel_number`,
      value: p[1].new || null,
    }));

    const iclOclUpdates = Array.from(this.parcelCountyLinesUpdateList).map((p) => ({
      op: JsonPatchOperator.replace,
      path: `/${p[0].loanId}/parcels/${p[0].parcelId}/county_lines`,
      value: Object.keys(this.countyLineOptions).find((o) => this.countyLineOptions[o as keyof typeof IclOclType] === p[1].new),
    }));

    const allPatchOps = updateParcelPayload.concat(numberUpdates, iclOclUpdates);

    try {
      const response = await this.loanService.batchPatchLoans(allPatchOps);
      this.showSuccess(`Updated ${response.length} parcel(s) successfully.`);
      // Cast err to AxiosError - Property 'response' does not exist on type 'unknown'.Vetur
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update parcels - ${e.response.data.message}`);
      }
    } finally {
      this.isLoading = false;
    }

    this.agencyCodeList = [];
    this.parcelNumberUpdateList = [];
    this.parcelCountyLinesUpdateList = [];

    await this.refreshRows();
    this.isUpdating = false;
  }

  async exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'IBMCodeReport',
      }),
      this.service.getAllParcels,
    )
  }
}
