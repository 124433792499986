











import { Vue, Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  name: 'checkbox-cell-renderer',
})
export default class CheckboxCellRenderer extends Vue {
  private params: ICellRendererParams = null;

  get editMode(): boolean {
    return this.params.column.getColDef().editable as boolean;
  }

  checkedHandler(checked: boolean) {
    const colId = this.params.column.getColId();
    this.params.node.setDataValue(colId, checked);
  }
}
