<template>
  <div
    id="drop-area"
    @drop.prevent.stop="handleDrop"
    @dragenter.prevent.stop="handleDragStart"
    @dragover.prevent.stop="handleDragStart"
    @dragleave.prevent.stop="handleDragEnd"
    :class="[{ highlight: dragging }]"
  >
    <form class="my-form">
      <div v-if="!file">
        <p>
          {{ importText }}
        </p>
        <p>OR</p>
        <input
          type="file"
          id="fileElem"
          accept="accept"
          ref="fileInput"
          @change="handleFiles($event.target.files)"
        />
        <v-btn class="button" color="primary" @click="$refs.fileInput.click()">Browse Files</v-btn>
      </div>
      <div v-else>
        <div>
          {{ file.name }}
        </div>
        <div class="my-3">
          <v-btn color="danger" @click="removeFile">Remove File</v-btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return { file: null, dragging: false };
  },
  watch: {
    file(e) {
      this.$emit('file', e);
    },
  },
  props: {
    accept: { type: String, default: '*' },
    importText: { type: String, default: 'Drag a file or click to import' },
  },
  methods: {
    highlight(e) {
      dropArea.classList.add('highlight');
    },
    unhighlight(e) {
      dropArea.classList.remove('highlight');
    },
    handleDrop(e) {
      this.handleDragEnd();
      const dt = e.dataTransfer;
      const { files } = dt;
      this.handleFiles(files);
    },
    handleFiles(files) {
      if (files[0]) {
        const file = files[0];
        this.file = file;
      }
    },
    handleDragStart() {
      this.dragging = true;
    },
    handleDragEnd() {
      this.dragging = false;
    },
    removeFile() {
      this.file = null;
    },
  },
};
</script>

<style lang="scss" scoped>
#drop-area {
  border: 2px dashed rgb(121, 242, 255);
  border-radius: 20px;
  width: 480px;
  font-family: sans-serif;
  margin: 30px auto;
  padding: 20px;
}
#drop-area.highlight {
  border-color: rgb(13, 0, 128);
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
#fileElem {
  display: none;
}
</style>
