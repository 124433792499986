
import {
  Component,
  Vue,
} from 'vue-property-decorator';

import Axios, { CancelToken, CancelTokenSource } from 'axios';

@Component({
  name: 'cancel-source',
})
export default class CancelSource extends Vue {
  private source: CancelTokenSource = null;

  // Methods
  makeCancellableRequest<T>(requestFunction: (params: any, token?: CancelToken) => Promise<T>, params: any): Promise<T> {
    this.source = Axios.CancelToken.source();
    return requestFunction.call(null, params, this.source.token);
  }

  cancelQuery() {
    console.log('CANCELLED')
    console.log(this.source);
    if (this.source) {
      this.source.cancel();
    }
  }
}
