






































































































































































import {
  Component,
  Mixins,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import {
  tap, set, cloneDeep, isEmpty,
} from 'lodash';
import { DataTableHeader } from 'vuetify';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';

import { IServiceQueryDefinition } from '@/services/api/models/IServiceQuery';

import UserSearch from '@/views/users/UserSearch.vue';

import CurrencyFormatter from '@/mixins/CurrencyFormatter.vue';

export interface SearchProperty {
  text: string,
  field: string,
  type: QueryInputType,
  options?: string[],
}

export interface SearchSelection {
  table: string,
  field?: SearchProperty,
}

export interface Operator {
  text: string,
  value: string,
  numValues: number,
}

export interface SearchOperator {
  conjunction?: 'and' | 'or',
  table: string,
  field?: SearchProperty,
  type?: string,
  operator?: Operator,
  value?: any[],
}

export interface Query {
  select: SearchSelection[],
  search: SearchOperator[],
}

export type QueryInputType = 'date' | 'number' | 'string' | 'boolean' | 'list' | 'user'

@Component({
  name: 'query-definition',
  components: {
    UserSearch,
  },
})
export default class QueryDefinition extends Mixins(CurrencyFormatter) {
  @Prop({
    type: Object,
    default: () => ({
      select: [],
      search: [],
    } as IServiceQueryDefinition),
  }) private readonly value!: IServiceQueryDefinition;

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly readonly!: boolean;

  @Prop({
    type: Map,
    default: () => new Map(),
  }) private readonly selections!: Map<string, (string | SearchProperty)[]>;

  // TODO: Make this part of the config
  private tableNames: Map<string, string> = new Map([
    ['agencycollectingschedule', 'Collecting Schedule'],
    ['parcelescrowhistory', 'Escrow History'],
    ['parcelnonescrowhistory', 'Non Escrow History'],
    ['parcelagency', 'Parcel Agency'],
    ['dtco', 'DTCO'],
  ]);

  private booleanOptions: any[] = [
    {
      text: 'YES',
      value: true,
    },
    {
      text: 'NO',
      value: false,
    },
  ];

  private conjunctions: any[] = [{
    text: 'OR',
    value: 'or',
  }, {
    text: 'AND',
    value: 'and',
  }];

  private operators: Operator[] = [{
    text: 'EQUALS',
    value: 'equals',
    numValues: 1,
  }, {
    text: 'DOES NOT EQUAL',
    value: 'not_equals',
    numValues: 1,
  }, {
    text: 'LIKE',
    value: 'like',
    numValues: 1,
  }, {
    text: 'NOT LIKE',
    value: 'not_like',
    numValues: 1,
  }, {
    text: 'IS NULL',
    value: 'null',
    numValues: 0,
  }, {
    text: 'IS NOT NULL',
    value: 'not_null',
    numValues: 0,
  }, {
    text: '<',
    value: 'less_than',
    numValues: 1,
  }, {
    text: '>',
    value: 'greater_than',
    numValues: 1,
  }, {
    text: '<=',
    value: 'less_than_or_equal_to',
    numValues: 1,
  }, {
    text: '>=',
    value: 'greater_than_or_equal_to',
    numValues: 1,
  }, {
    text: 'BETWEEN',
    value: 'between',
    numValues: 2,
  }];

  // Computed
  get local(): Query {
    if (!this.value) {
      return { select: [], search: [] };
    }

    const queryDefinition = this.value;

    return {
      select: queryDefinition.select.map((selection) => ({
        table: selection.table,
        field: this.getField(selection.table, selection.field),
      })),
      search: queryDefinition.search.map((searchEntry) => {
        const field = this.getField(searchEntry.table, searchEntry.field);
        const finalOperator = {
          ...searchEntry,
          value: Array.isArray(searchEntry.value) ? searchEntry.value : [searchEntry.value],
          operator: this.getOperator(searchEntry.operator),
          type: field && field.type ? field.type : 'string',
          field,
        };

        return finalOperator;
      }),
    };
  }

  get selectionConfig(): Map<string, SearchProperty[]> {
    const selectionMap: Map<string, SearchProperty[]> = new Map();

    this.selections.forEach((value, key) => {
      const properties = value.map((property) => {
        // If it is not an object we just assume the field in question is a string type
        if (typeof property === 'string') {
          return {
            field: property,
            type: 'string' as QueryInputType,
            text: capitalCase(property),
          };
        }

        const newProperty = { ...property };
        if (!newProperty.text) {
          newProperty.text = capitalCase(newProperty.field);
        }

        return newProperty;
      });

      selectionMap.set(key, properties)
    });

    return selectionMap;
  }

  get tables(): DataTableHeader[] {
    const names = Array.from(
      (this.selectionConfig as Map<string, SearchProperty[]>).keys(),
    );

    return names.map((name) => ({
      text: this.resolveTableName(name),
      value: name,
    }));
  }

  getOperator(op: string): Operator {
    return this.operators.find((operator) => operator.value === op);
  }

  getTypeOperators(type: QueryInputType): Operator[] {
    if (type === 'boolean') {
      return [
        this.operators[0],
        this.operators[1],
        this.operators[4],
        this.operators[5],
      ];
    }

    return this.operators;
  }

  getField(table: string, field: string): SearchProperty {
    return (this.selectionConfig as Map<string, SearchProperty[]>)
      .get(table)
      .find((property) => property.field === field);
  }

  @Watch('local', { immediate: true })
  onQueryChanged(query: Query) {
    if (query && isEmpty(query)) {
      this.addSelection();
      this.addOperator();
    }
  }

  addSelection() {
    // When we add, autopick first table in config as the default table
    const newSelection = {
      table: this.selectionConfig.keys().next().value as string,
    };

    const uiQuery = tap(
      cloneDeep(this.local),
      (v) => v.select.push(newSelection),
    );

    this.updateQuery(uiQuery);
  }

  removeSelection(index: number) {
    const uiQuery = tap(
      cloneDeep(this.local),
      (v) => v.select.splice(index, 1),
    );

    this.updateQuery(uiQuery);
  }

  updateSelection(index: number, key: string, value: any) {
    if (key === 'table') {
      this.local.select[index] = {
        field: undefined,
        table: value,
      }
    }
    const uiQuery = tap(
      cloneDeep(this.local),
      (v) => set(v, `select.${index}.${key}`, value),
    );

    // If we set the field, we need to also attach the param type
    // if (key === 'field') {
    //   console.log(key, value)
    //   const allSelections = this.selectionConfig.size > 1
    //     ? this.selectionConfig.get(this.local.select[index].table)
    //     : this.selectionConfig.values().next().value as SearchProperty[];

    //   console.log(allSelections);

    //   const fieldType = allSelections.find((selection) => selection.field === value).type;

    //   console.log(fieldType);

    //   set(uiQuery, `select.${index}.type`, fieldType);
    // }

    this.updateQuery(uiQuery);
  }

  addOperator() {
    const newOperator: SearchOperator = {
      conjunction: 'and',
      table: this.selectionConfig.keys().next().value as string,
      value: [],
    };

    const uiQuery = tap(
      cloneDeep(this.local),
      (v) => v.search.push(newOperator),
    );

    this.updateQuery(uiQuery);
  }

  removeOperator(index: number) {
    const uiQuery = tap(
      cloneDeep(this.local),
      (v) => v.search.splice(index, 1),
    );

    this.updateQuery(uiQuery);
  }

  updateOperator(index: number, key: string, value: any, type?: QueryInputType) {
    console.log(index, key, value, type)
    if (key === 'table') {
      this.local.search[index] = {
        conjunction: this.local.search[index].conjunction,
        operator: undefined,
        table: key,
        field: undefined,
        type: undefined,
        value: [],
      }
    }
    const finalValue = type === 'date'
      ? DateTime.fromFormat(value, 'yyyy-MM-dd').toFormat('MM/dd/yyyy')
      : value;

    const uiQuery = tap(
      cloneDeep(this.local),
      (v) => set(v, `search.${index}.${key}`, finalValue),
    );

    // If the operator was changed, snip the values array appropriately
    if (key === 'operator') {
      uiQuery.search[index].value = uiQuery.search[index].value.slice(0, value.numValues);
    }

    this.updateQuery(uiQuery);
  }

  updateQuery(uiQuery: Query) {
    const newQuery = cloneDeep(uiQuery);
    this.$emit('input', {
      select: newQuery.select.map((selection) => ({
        table: selection.table,
        field: selection.field ? selection.field.field : undefined,
        type: selection.field ? selection.field.type : undefined,
      })),
      search: newQuery.search.map((operator) => {
        let value: any[];

        if (operator.operator && operator.value.length > 0 && (operator.operator.value === 'like' || operator.operator.value === 'not_like')) {
          let finalValue = (operator.value[0] as string).replace(/\*/gi, '%');
          finalValue = finalValue.endsWith('%') ? finalValue : `${finalValue}%`;
          finalValue = finalValue.startsWith('%') ? finalValue : `%${finalValue}`;
          value = [finalValue];
        } else if (operator.operator) {
          value = operator.value;
        } else {
          value = [];
        }

        const finalOperator = {
          ...operator,
          field: operator.field ? operator.field.field : undefined,
          value,
          operator: operator.operator && operator.operator.value,
        };

        return finalOperator;
      }),
    });
  }

  transformDate(date?: string): string {
    if (!date) {
      return '';
    }

    return DateTime.fromFormat(date, 'MM/dd/yyyy').toFormat('yyyy-MM-dd');
  }

  resolveTableName(table: string): string {
    if (this.tableNames.has(table)) {
      return this.tableNames.get(table);
    }

    return capitalCase(table);
  }
}
