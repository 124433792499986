import IEntityCountResponse from '@/services/api/models/IEntityCountResponse'
import { CancelToken } from 'axios'
import ReportName from '../../../models/ReportName'
import ILastRowFetcher from './ILastRowFetcher'
import { GetTotalType } from '../types'

export default class LastRowFetcher implements ILastRowFetcher {
  constructor(
      private reportName: ReportName,
      private getTotal: GetTotalType,
  ) {
    this.reportName = reportName
    this.getTotal = getTotal
  }

  public async fetch(searchQueryParams: any): Promise<IEntityCountResponse> {
    const totalRequestParams = this.buildTotalRequestParams(searchQueryParams)
    return this.getTotal(totalRequestParams)
  }

  private buildTotalRequestParams(searchQueryParams: any) {
    const totalRequestParams = { ...searchQueryParams }
    totalRequestParams.limit = undefined
    totalRequestParams.offset = 0
    totalRequestParams.report = this.reportName
    return totalRequestParams
  }
}
