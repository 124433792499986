export function isNumber(keyCode: number, value: string): boolean {
  if ((keyCode < 48 || keyCode > 57)) {
    return false;
  }

  return true;
}

export default function onlyAllowNumber($event: KeyboardEvent, value: string) {
  if (!value) {
    return;
  }

  const keyCode = ($event.keyCode ? $event.keyCode : $event.which);

  if (!isNumber(keyCode, value)) {
    $event.preventDefault();
  }
}
