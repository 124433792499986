import Axios, { AxiosResponse, CancelToken } from 'axios';

import IServiceLoan from '@/services/api/models/IServiceLoan';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import EntityCountResponse from './models/EntityCountResponse';

interface ServicesResponse {
  count: any,
  loans: IServiceLoan[],
  error: any,
}

export default {
  async getAllLoans(params: any, cancelToken?: CancelToken): Promise<ServicesResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<ServicesResponse>('/loans', config);

    return response.data;
  },

  async getTotalLoans(params: any, cancelToken?: CancelToken): Promise<EntityCountResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<ServicesResponse>('/loans/count', config);

    return response.data;
  },

  async getLoan(id: string, cancelToken?: CancelToken): Promise<AxiosResponse> {
    const config = {};
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    return Axios.get<IServiceLoan>(`/loans/${id}`, config);
  },

  async updateLoan(id: string, payload: JsonPatchPayload, etag: string): Promise<AxiosResponse> {
    const response = await Axios.patch<IServiceLoan>(`/loans/${id}`, payload, {
      headers: {
        'If-Match': etag,
      },
    });
    return response;
  },

  async createLoan(payload: any): Promise<any> {
    const response = await Axios.post<IServiceLoan, AxiosResponse<IServiceLoan>>('/loans', payload);
    return response.data;
  },

  async batchPatchLoans(payload: JsonPatchPayload): Promise<void> {
    const response = await Axios.patch<void>('/loans', payload);
    return response.data;
  },

  async uploadFile(id: string, formData: FormData): Promise<void> {
    await Axios.post<void, AxiosResponse<void>>(`/loans/${id}/files`, formData);
  },

  async deleteLoan(id: string): Promise<any> {
    const response = await Axios.delete<any>(`/loans/${id}`);

    return response.data;
  },
};
