import { CancelToken } from 'axios';

import SearchResponse from '@/services/responses/SearchResponse';
import NonEscrowHistory from '@/entities/NonEscrowHistory';
import NonEscrowHistoryApiService from '@/services/api/NonEscrowHistoryApiService';
import SearchRequestParams from '@/services/requests/SearchRequestParams';
import IEntityCountResponse from '../api/models/IEntityCountResponse';

type NonEscrowHistoryCollection = SearchResponse<NonEscrowHistory>;

interface AdvancedSearch {
  agency_numbers?: string[],
  agency_states?: string[],
  lender_numbers?: string[],
  delinquent_tax_collecting_offices?: string[],
  delinquent_filter?: string,
}

interface NonEscrowSearchParams extends SearchRequestParams<AdvancedSearch> {
  search_field: string,
  search_value: string,
}

class NonEscrowHistoryService {
  async getAllNonEscrowHistories(params: NonEscrowSearchParams, cancelToken?: CancelToken): Promise<NonEscrowHistoryCollection> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);

    const response = await NonEscrowHistoryApiService.getAllNonEscrowHistories(stringifiedParams, cancelToken)

    return {
      count: response.count,
      results: response.results.map((result) => new NonEscrowHistory(result)),
    };
  }

  async getNonEscrowHistory(id: string, cancelToken?: CancelToken): Promise<NonEscrowHistory> {
    const response = await NonEscrowHistoryApiService.getNonEscrowHistory(id, cancelToken);

    return new NonEscrowHistory(response);
  }

  async getTotalNonEscrowHistories(params: NonEscrowSearchParams): Promise<IEntityCountResponse> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);
    return NonEscrowHistoryApiService.getTotalNonEscrowHistories(stringifiedParams)
  }
}

export { NonEscrowHistoryService as default };
