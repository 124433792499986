


































































































import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { ColDef, CellValueChangedEvent, SortChangedEvent } from 'ag-grid-community';
import { DataTableHeader } from 'vuetify';

import LoanService from '@/services/loans';
import { JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';

import Loan from '@/entities/Loan';

import DateFieldOptional from '@/components/inputs/dates/DateFieldOptional.vue'
import { snakeCase } from 'change-case';
import { AxiosError } from 'axios';
import { isNull, omitBy } from 'lodash';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import ExportDataParams from './models/ExportDataParams';
import SsrmGridReport from './SsrmGridReport.vue';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import ReportName from './models/ReportName';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';
import quickSearchParams from './ag-grid/params/quickSearchParams';

interface ReportRow {
  [index: string]: any,

  parcelId: string,
  loanId: string,

  lenderNumber: string,
  loanNumber: string,
  loanType: string,
  parcelNumber: string,
  dateAdded: string,

  parcelType: string,
  address1: string,
  city: string,
  state: string,
  zipCode: string,
  county: string,

  existing: boolean,
}

@Component({
  name: 'add-parcel-report',
  components: {
    DateFieldOptional,
    AgGridVue,
    SsrmGridOmnifilter,
  },
})
export default class AddParcelReport extends SsrmGridReport<Loan, ReportRow> {
  protected copyResults = true;

  private isUpdating = false;
  private showUpdateDialog = false;

  private searchLoanEscrowType: string = null;
  private searchStartDate: string = null;
  private searchEndDate: string = null;

  private updateMap: Map<ReportRow, Map<string, { original: any, new: any }>> = new Map();

  private loanService: LoanService = new LoanService();

  private rowClassRules: any = {
    'new-row': (params: any) => {
      if (params.data) {
        return !params.data.existing
      }
      return false
    },
  }

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      editable: true,
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel Escrow Type',
      field: 'parcelType',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: Object.values(['E', 'N', 'EN']),
      },
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address 1',
      field: 'address1',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'City',
      field: 'city',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'State',
      field: 'state',
      width: 100,
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'ZIP',
      field: 'zipCode',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'County',
      field: 'county',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel Verified',
      field: 'parcelVerified',
      sortable: false,
      editable: true,
      minWidth: 150,
      flex: 1,
      type: 'boolean',
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  private loanEscrowTypes: DataTableHeader[] = [
    {
      text: 'All',
      value: null,
    },
    {
      text: 'Escrow',
      value: 'E',
    },
    {
      text: 'Non-Escrow',
      value: 'N',
    },
    {
      text: 'Escrow / Non-Escrow',
      value: 'EN',
    },
  ];

  // Computed
  get advancedSearch() {
    return {
      loans_with_no_parcels: true,
      added_between: [this.searchStartDate, this.searchEndDate],
      loan_type: this.searchLoanEscrowType,
    };
  }

  // Mixins
  isDirty() {
    return this.updateMap.size > 0;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
    // this.refreshRows();
  }

  refreshRows() {
    if (this.updateMap.size !== 0 && !this.showUpdateDialog) {
      this.showUpdateDialog = true;
      return;
    }

    this.latestResults = [];
    this.results = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<Loan, ReportRow>(
      ReportName.AddParcel,
      this.loanService.getAllLoans,
      this.loanService.getTotalLoans,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build()
  }

  convertResults(results: Loan[]): ReportRow[] {
    return results.reduce((allRows: ReportRow[], loan: Loan, index: number) => {
      if (!loan.parcels || loan.parcels.length === 0) {
        allRows.push({
          parcelId: null,
          loanId: loan.loanId,

          lenderNumber: loan.lenderNumber,
          loanNumber: loan.loanNumber,
          loanType: loan.loanType,
          parcelNumber: null,
          parcelType: null,
          dateAdded: loan.dateAdded,
          parcelVerified: false,
          address1: null,
          city: null,
          state: null,
          zipCode: null,
          county: null,

          existing: false,
        });
      } else {
        loan.parcels.forEach((parcel) => {
          allRows.push({
            parcelId: parcel.parcelId,
            loanId: loan.loanId,

            lenderNumber: loan.lenderNumber,
            loanNumber: loan.loanNumber,
            loanType: loan.loanType,
            parcelNumber: parcel.parcelNumber,
            parcelType: parcel.parcelType,
            dateAdded: loan.dateAdded,
            parcelVerified: parcel.verified,
            address1: parcel.address && parcel.address.value ? parcel.address.value.address1 : null,
            city: parcel.address && parcel.address.value ? parcel.address.value.city : null,
            state: parcel.address && parcel.address.value ? parcel.address.value.state : null,
            zipCode: parcel.address && parcel.address.value ? parcel.address.value.zipCode : null,
            county: parcel.address && parcel.address.value ? parcel.address.value.county : null,

            existing: true,
          });
        });
      }

      return allRows;
    }, []);
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      limit_final_query_flag: true,
    };
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    // This only works if we let ag grid handle the row ID assignment
    const index = parseInt(event.node.id, 10);

    if (Number.isNaN(index)) {
      throw new Error('Index could not be determined for cell. Custom ID?');
    }

    this.addToChangeList(event.newValue, index, event.colDef.field);
  }

  setVerify(fieldToVerify: any, verified: boolean) {
    const newVerifyField = { ...fieldToVerify };
    newVerifyField.verified = verified;

    if (verified) {
      newVerifyField.verifiedBy = this.user;
      newVerifyField.verifiedOn = new Date();
    } else {
      newVerifyField.verifiedBy = null;
      newVerifyField.verifiedOn = null;
    }

    return newVerifyField;
  }

  addToChangeList(value: any, index: number, header: string) {
    const row = this.results[index];
    const originalRow = this.original[index];

    let finalValue = value;

    if (typeof finalValue === 'string') {
      finalValue = finalValue.trim();
    }

    let rowMap;

    if (this.updateMap.has(row)) {
      rowMap = this.updateMap.get(row);
    } else {
      rowMap = new Map();
      this.updateMap.set(row, rowMap);
    }

    if (!rowMap.has(header) || rowMap.get(header).original !== finalValue) {
      if (rowMap.has(header)) {
        rowMap.get(header).new = finalValue;
      } else {
        rowMap.set(header, {
          original: originalRow ? originalRow[header] : null,
          new: finalValue,
        });
      }
    } else {
      rowMap.delete(header);
      if (rowMap.size === 0) {
        this.updateMap.delete(row);
      }
    }

    this.updateMap = new Map(this.updateMap);
  }

  async submitChanges() {
    this.isUpdating = true;

    // const creationRows = this.results.filter((row) => row.fieldsAdded && !row.existing);
    const creationRows = Array.from(this.updateMap.entries()).filter((entry) => !entry[0].existing);

    const creationPayload = creationRows.map((row) => {
      const value = {
        parcel_number: row[0].parcelNumber,
        parcel_type: row[0].parcelType,
        verified: row[0].parcelVerified,
        address: row[0].address,
        city: row[0].city,
        state: row[0].state,
        zip_code: row[0].zip,
        county: row[0].county,
      };

      return {
        op: JsonPatchOperator.add,
        path: `/${row[0].loanId}/parcels/-`,
        value: omitBy(value, isNull),
      };
    });

    const updatePayload: JsonPatchPayload = [];
    const updateRows = Array.from(this.updateMap.entries()).filter((entry) => entry[0].existing);
    updateRows.forEach((row) => {
      row[1].forEach((value, key) => {
        let finalKey = key;
        if (key === 'parcelVerified') {
          finalKey = 'verified';
        } else if (key === 'zip') {
          finalKey = 'zip_code';
        }

        updatePayload.push({
          op: JsonPatchOperator.replace,
          path: `/${row[0].loanId}/parcels/${row[0].parcelId}/${snakeCase(finalKey)}`,
          value: value.new,
        });
      });
    });

    try {
      const response = await this.loanService.batchPatchLoans([].concat(creationPayload, updatePayload));
      this.showSuccess(`Updated ${response.length} loan(s) successfully.`);
      // Cast err to AxiosError - Property 'response' does not exist on type 'unknown'.Vetur
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update loans - ${e.response.data.message}`);
      }
    } finally {
      this.isUpdating = false;
      this.updateMap = new Map();
    }

    await this.refreshRows();
  }

  async exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'AddParcelReport',
      }),
      this.loanService.getAllLoans,
    )
  }
}
