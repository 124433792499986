






































































import {
  Component, Watch,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { DateTime } from 'luxon';
import jsPDF from 'jspdf';
import { ColDef, RowDataTransaction, SortChangedEvent } from 'ag-grid-community';
import { debounce } from 'lodash';

import NonEscrowHistoryService from '@/services/nonEscrowHistories';
import AgencyService from '@/services/agencies';

import { inchesToMM } from '@/helpers/reportHelpers';

import Agency from '@/entities/Agency';
import NonEscrowHistory from '@/entities/NonEscrowHistory';
import Axios, { AxiosResponse, CancelToken } from 'axios';

import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue'
import SsrmGridReport from './SsrmGridReport.vue'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ReportName from './models/ReportName'
import ReportDatasource from './ag-grid/datasource/ReportDatasource'
import ExportDataParams from './models/ExportDataParams';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams'
import quickSearchParams from './ag-grid/params/quickSearchParams'
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams'
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams'
import ReportDatasourceParamBuilder from './ag-grid/datasource/builder/ReportDatasourceParamBuilder'
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';

interface ReportRow {
  lenderNumber: string,
  loanNumber: string,
  parcelNumber: string,
  agencyNumber: string,
  loanType: string,
  name: string,
  address: string,
  year: string,
  reportedDate: string,
  base: number,
  penalties: number,
  amountDue: number,
  dueDate: string,

  // PDF columns
  lenderName: string,
  lenderAddress1: string,
  lenderAddress2: string,
  lenderShortAddress: string,
  agencyName: string,
  agencyShortAddress: string,
  legalDescription: string,
  block: string,
  lot: string,
  unit: string,
  billNumber: string,
}
interface PageGroup { [index: string]: ReportRow[] }

@Component({
  name: 'delinquent-memo-bill-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
  },
})
export default class DelinquentMemoBillReport extends SsrmGridReport<NonEscrowHistory, ReportRow> {
  // Indicators
  protected pageSizes = [500, 10000]
  private isGeneratingPDF = false;
  private generatePDFText = 'Create PDF';

  // Root entities
  private pages: PageGroup = {}

  // Services
  private service: NonEscrowHistoryService = new NonEscrowHistoryService();
  private agencyService: AgencyService = new AgencyService();

  // User options
  private agencySearch: string = '';
  private agencyDebounce: Function = null;
  private foundAgencies: Agency[] = [];

  // User inputs
  private dtco: Agency = null;
  private loanNumbersString: string = '';

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  // Input rules
  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'E/N',
      field: 'loanType',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      width: 150,
      type: 'date',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Base Amount',
      field: 'base',
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Penalties / Interest',
      field: 'penalties',
      sortable: false,
      editable: true,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Payoff Amount',
      field: 'amountDue',
      sortable: false,
      editable: true,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Payoff By Date',
      field: 'dueDate',
      sortable: false,
      editable: true,
      flex: 1,
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  // Watchers
  @Watch('agencySearch')
  async onAgencySearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.agencyDebounce) {
      this.agencyDebounce = debounce(async () => {
        const response = await this.agencyService.getAllAgencies({
          search_field: 'name_or_number',
          search_value: this.agencySearch,
          limit: 100,
          agency_selector_search: true,
        });

        this.foundAgencies = response.results;
      }, 500);
    }

    this.agencyDebounce();
  }

  // Computed
  get hasRequiredInput(): boolean {
    return Boolean(this.dtco) || this.loanNumbersTextIsntBlank;
  }

  get loanNumbersTextIsntBlank(): boolean {
    return Boolean(this.loanNumbersString) && this.loanNumbersString !== '';
  }

  get advancedSearch() {
    const advancedSearch = {
      delinquent_tax_collecting_offices: this.dtco ? [this.dtco.capAgency] : null,
      delinquent_filter: 'not_paid',
      non_zero_base_amount: true,
    };

    if (this.loanNumbersTextIsntBlank) {
      Object.assign(advancedSearch, {
        loan_number_in: this.loanNumbersString.split(',').map((s) => s.trim()),
      });
    }

    return advancedSearch;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }
  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params)
  }

  refreshRows() {
    this.latestResults = []
    this.results = []
    this.pages = {}
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }
  private reportDatasource() {
    return new ReportDatasourceBuilder<NonEscrowHistory, ReportRow>(
      ReportName.DelinquentMemoBill,
      this.service.getAllNonEscrowHistories,
      this.service.getTotalNonEscrowHistories,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build();
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      include_agency: true,
    }
  }

  convertResults(results: NonEscrowHistory[]): ReportRow[] {
    const newResults: ReportRow[] = results.map((nonEscrowHistory) => {
      const result: ReportRow = {
        lenderNumber: nonEscrowHistory.lenderNumber,
        lenderName: nonEscrowHistory.lenderName,
        lenderAddress1: nonEscrowHistory.lenderAddress1,
        lenderAddress2: nonEscrowHistory.lenderAddress2,
        lenderShortAddress: `${nonEscrowHistory.lenderCity ? `${nonEscrowHistory.lenderCity}, ` : ''}${nonEscrowHistory.lenderState || ''} ${nonEscrowHistory.lenderZipCode || ''}`,
        loanNumber: nonEscrowHistory.loanNumber,
        loanType: nonEscrowHistory.parcelType,
        name: nonEscrowHistory.name,
        address: nonEscrowHistory.address,
        agencyNumber: nonEscrowHistory.agency ? nonEscrowHistory.agency.capAgency : '',
        agencyName: nonEscrowHistory.agency.name,
        agencyShortAddress: `${nonEscrowHistory.agency.address.value.city ? `${nonEscrowHistory.agency.address.value.city}, ` : ''}${nonEscrowHistory.agency.address.value.state || ''} ${nonEscrowHistory.agency.address.value.zipCode || ''}`,
        year: nonEscrowHistory.year,
        parcelNumber: nonEscrowHistory.parcelNumber,
        reportedDate: nonEscrowHistory.reportedDate,
        base: nonEscrowHistory.base,
        amountDue: nonEscrowHistory.amountDue,
        penalties: nonEscrowHistory.penalties,
        legalDescription: nonEscrowHistory.legal,
        block: nonEscrowHistory.block,
        lot: nonEscrowHistory.lot,
        unit: nonEscrowHistory.unit,
        billNumber: nonEscrowHistory.billNumber,
        dueDate: nonEscrowHistory.dueDate,
      };

      const idString = `${nonEscrowHistory.loanId}${nonEscrowHistory.parcelId}`;
      if (!this.pages[idString]) {
        this.pages[idString] = [];
      }
      this.pages[idString].push(result);

      return result;
    })

    return newResults;
  }
  determineName(item: Agency): string {
    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`;
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`;
    }

    nameString += `, ${item.capAgency}`;

    return nameString;
  }

  // PDF
  drawRectangle(pdf: jsPDF, x: number, y: number, w: number, h: number) {
    pdf.line(x, y, x, y + h); // left
    pdf.line(x + w, y, x + w, y + h); // right
    pdf.line(x, y, x + w, y); // top
    pdf.line(x, y + h, x + w, y + h); // bottom
  }

  drawPerforatedLines(pdf: jsPDF) {
    const y1 = inchesToMM(3.75);
    const y2 = y1 + inchesToMM(3.375);
    for (let i = 0; i < 63; i += 1) {
      const x1 = inchesToMM(0.375 + (i * 0.125));
      const x2 = x1 + inchesToMM(0.0625);
      pdf.line(x1, y1, x2, y1);
      pdf.line(x1, y2, x2, y2)
    }
  }

  createPDF() {
    this.generatePDFText = 'Generating File';
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally()
      this.generatePDFOnServer()
      this.generatePDFText = 'Create PDF';
      this.gridApi.hideOverlay();
    }, 100);
  }

  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllNonEscrowHistories(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const res = await this.exportPdf(
      newResults,
      '/pdf/delinquent-memo-bill-report',
      {
        dtco: JSON.stringify(this.dtco),
        loanNumbersString: this.loanNumbersString,
      },
      'results',
    );
    this.isGeneratingPDF = false;
    return window.open(res.data.link, '_blank');
  }

  async generatePDFLocally() {
    const leftBoxStartingX = inchesToMM(0.375);
    const leftBoxStartingY = inchesToMM(0.5);
    const leftBoxWidth = inchesToMM(3);
    const leftBoxHeight = inchesToMM(2.75);

    const centerBoxWidth = inchesToMM(2.75);
    const centerBoxHeight = inchesToMM(2.3);
    const centerBoxStartingX = leftBoxStartingX + inchesToMM(3.1);
    const centerBoxStartingY = leftBoxStartingY + leftBoxHeight - centerBoxHeight;
    const centerBoxSeparatorLineOffset = centerBoxHeight / 10;

    const rightBoxStartingX = leftBoxStartingX + inchesToMM(6);
    const rightBoxStartingY = leftBoxStartingY + inchesToMM(1.375);
    const rightBoxWidth = inchesToMM(1.8);
    const rightBoxHeight = inchesToMM(1.375);
    const rightBoxSeparatorLineOffset = inchesToMM(0.75);

    const repeatingFormVerticalPitch = inchesToMM(3.375);
    const smallBoxXOffset = 2;
    const smallBoxYOffset = 4;
    const dynamicDataXOffset = inchesToMM(1.66);
    const leftDynamicDataXOffset = inchesToMM(0.76);
    const footerArray: string[] = ['TAX OFFICE COPY', 'RECEIPT COPY', 'LENDER COPY'];

    const pdf: jsPDF = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',
    });

    const rows = this.results;
    const printedDate = DateTime.fromJSDate(new Date()).toFormat('MM/dd/yyyy');

    pdf.setLineWidth(0.3);

    let currentPage = 0;
    //  {"helvetica":["normal","bold","italic","bolditalic"],"Helvetica":["","Bold","Oblique","BoldOblique"],"courier":["normal","bold","italic","bolditalic"],"Courier":["","Bold","Oblique","BoldOblique"],"times":["normal","bold","italic","bolditalic"],"Times":["Roman","Bold","Italic","BoldItalic"],"zapfdingbats":["normal"],"ZapfDingbats":[""],"symbol":["normal"],"Symbol":[""]}

    rows.forEach((reportRow: ReportRow) => {
      this.drawPerforatedLines(pdf);

      for (let i = 0; i < 3; i += 1) {
        // Draw Boxes and lines
        this.drawRectangle(pdf, leftBoxStartingX, leftBoxStartingY + (i * repeatingFormVerticalPitch), leftBoxWidth, leftBoxHeight);

        this.drawRectangle(pdf, centerBoxStartingX, centerBoxStartingY + (i * repeatingFormVerticalPitch), centerBoxWidth, centerBoxHeight);
        for (let j = 1; j < 10; j += 1) {
          pdf.line(centerBoxStartingX, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (j * centerBoxSeparatorLineOffset), centerBoxStartingX + centerBoxWidth, centerBoxStartingY + (i * repeatingFormVerticalPitch) + +(j * centerBoxSeparatorLineOffset))
        }

        this.drawRectangle(pdf, rightBoxStartingX, rightBoxStartingY + (i * repeatingFormVerticalPitch), rightBoxWidth, rightBoxHeight);
        pdf.line(rightBoxStartingX, rightBoxStartingY + (i * repeatingFormVerticalPitch) + rightBoxSeparatorLineOffset, rightBoxStartingX + rightBoxWidth, rightBoxStartingY + (i * repeatingFormVerticalPitch) + rightBoxSeparatorLineOffset)

        // Draw static Labels
        pdf.setFontType('times');
        pdf.setFontSize(16);
        const titleHeight = (pdf.getTextDimensions('M') as any).h; // TODO: No h prop in documentation
        const titleOffset = titleHeight * 0.75
        pdf.text('DELINQUENT MEMORANDUM TAX BILL', centerBoxStartingX, leftBoxStartingY + (i * repeatingFormVerticalPitch) + titleOffset);

        pdf.setFontSize(8);

        pdf.text('PAID BY:', leftBoxStartingX + smallBoxXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset);

        pdf.text('LOAN #', leftBoxStartingX + smallBoxXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (3 * centerBoxSeparatorLineOffset));
        pdf.text('OWNER:', leftBoxStartingX + smallBoxXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (4 * centerBoxSeparatorLineOffset));
        pdf.text('PRIOR:', leftBoxStartingX + smallBoxXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (5 * centerBoxSeparatorLineOffset));
        pdf.text('PREMISE:', leftBoxStartingX + smallBoxXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (6 * centerBoxSeparatorLineOffset));

        pdf.text(`Printed date: ${printedDate}`, centerBoxStartingX, leftBoxStartingY + (i * repeatingFormVerticalPitch) + titleOffset + titleHeight);
        pdf.text('BILL NUMBER', centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset);
        pdf.text('YEAR', centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (2 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text('TYPE OF TAX', centerBoxStartingX + smallBoxXOffset + inchesToMM(1.8), centerBoxStartingY + (i * repeatingFormVerticalPitch) + (2 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text('PARCEL IDENTIFIER', centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (4 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text('BLOCK', centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (6 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text('LOT', centerBoxStartingX + smallBoxXOffset + inchesToMM(1), centerBoxStartingY + (i * repeatingFormVerticalPitch) + (6 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text('UNIT', centerBoxStartingX + smallBoxXOffset + inchesToMM(2), centerBoxStartingY + (i * repeatingFormVerticalPitch) + (6 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text('LEGAL DESCRIPTION', centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (8 * centerBoxSeparatorLineOffset) + smallBoxYOffset);

        pdf.text('BASE TAX:', rightBoxStartingX + smallBoxXOffset, rightBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset);
        pdf.text('PENALTY/INTEREST:', rightBoxStartingX + smallBoxXOffset, rightBoxStartingY + (i * repeatingFormVerticalPitch + centerBoxSeparatorLineOffset) + smallBoxYOffset);
        // pdf.text('INTEREST:', rightBoxStartingX + smallBoxXOffset, rightBoxStartingY + (i * repeatingFormVerticalPitch + (2 * centerBoxSeparatorLineOffset)) + smallBoxYOffset);

        pdf.setFontStyle('bold');
        pdf.text('PAYOFF:', rightBoxStartingX + smallBoxXOffset, rightBoxStartingY + (i * repeatingFormVerticalPitch) + rightBoxSeparatorLineOffset + smallBoxYOffset);
        pdf.text('TAX OFFICE:', leftBoxStartingX + smallBoxXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (8 * centerBoxSeparatorLineOffset));

        pdf.setFontSize(12);
        const footerDimensions = pdf.getTextDimensions(footerArray[i]);
        pdf.text(footerArray[i], rightBoxStartingX + rightBoxWidth - (footerDimensions as any).w, rightBoxStartingY + rightBoxHeight + (i * repeatingFormVerticalPitch) + (2 * (footerDimensions as any).h)); // TODO: No h or w prop in documentation

        // populate with dynamic data
        pdf.setFontSize(8);

        pdf.setFont('helvetica');
        pdf.text(reportRow.lenderName, leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset);
        let addressLine1 = reportRow.lenderAddress1 || '';
        if (reportRow.lenderAddress2) {
          addressLine1 += `, ${reportRow.lenderAddress2}`
        }
        pdf.text(addressLine1, leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (1 * centerBoxSeparatorLineOffset));
        pdf.text(reportRow.lenderShortAddress, leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (2 * centerBoxSeparatorLineOffset));
        pdf.text(`${reportRow.loanNumber}   ${reportRow.loanType}`, leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (3 * centerBoxSeparatorLineOffset));
        pdf.text(reportRow.name || '', leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (4 * centerBoxSeparatorLineOffset));
        pdf.text(reportRow.address || '', leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (6 * centerBoxSeparatorLineOffset))
        pdf.text(reportRow.agencyName || '', leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (8 * centerBoxSeparatorLineOffset))
        pdf.text(reportRow.agencyShortAddress || '', leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (9 * centerBoxSeparatorLineOffset))
        pdf.text(reportRow.agencyNumber || '', leftBoxStartingX + smallBoxXOffset + leftDynamicDataXOffset, leftBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset + (10 * centerBoxSeparatorLineOffset))

        pdf.text(reportRow.billNumber || '', centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (1 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text(reportRow.year, centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (3 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text('Real Estate', centerBoxStartingX + smallBoxXOffset + inchesToMM(1.8), centerBoxStartingY + (i * repeatingFormVerticalPitch) + (3 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text(reportRow.parcelNumber, centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (5 * centerBoxSeparatorLineOffset) + smallBoxYOffset);

        pdf.text(reportRow.block || '', centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (7 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text(reportRow.lot || '', centerBoxStartingX + smallBoxXOffset + inchesToMM(1), centerBoxStartingY + (i * repeatingFormVerticalPitch) + (7 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        pdf.text(reportRow.unit || '', centerBoxStartingX + smallBoxXOffset + inchesToMM(2), centerBoxStartingY + (i * repeatingFormVerticalPitch) + (7 * centerBoxSeparatorLineOffset) + smallBoxYOffset);
        let displayLegal = reportRow.legalDescription || '';
        if (displayLegal.length > 25) {
          displayLegal = `${displayLegal.substring(0, 24)}...`;
        }
        pdf.text(displayLegal, centerBoxStartingX + smallBoxXOffset, centerBoxStartingY + (i * repeatingFormVerticalPitch) + (9 * centerBoxSeparatorLineOffset) + smallBoxYOffset);

        pdf.text(reportRow.base ? `$${reportRow.base.toFixed(2)}` : '$0.00', rightBoxStartingX + smallBoxXOffset + dynamicDataXOffset, rightBoxStartingY + (i * repeatingFormVerticalPitch) + smallBoxYOffset, { align: 'right' });
        pdf.text((reportRow.penalties || reportRow.penalties === 0) ? `$${Number(reportRow.penalties).toFixed(2)}` : '', rightBoxStartingX + smallBoxXOffset + dynamicDataXOffset, rightBoxStartingY + (i * repeatingFormVerticalPitch + centerBoxSeparatorLineOffset) + smallBoxYOffset, { align: 'right' });

        pdf.text(reportRow.amountDue ? `$${reportRow.amountDue.toFixed(2)}` : '$0.00', rightBoxStartingX + smallBoxXOffset + dynamicDataXOffset, rightBoxStartingY + (i * repeatingFormVerticalPitch) + rightBoxSeparatorLineOffset + smallBoxYOffset, { align: 'right' });
      }

      currentPage += 1;
      if (currentPage < rows.length) {
        pdf.addPage();
      }
    });

    pdf.save(`DelinquentMemoBill-${this.dtco ? this.dtco.name : this.loanNumbersString}.pdf`);
    this.isGeneratingPDF = false;
  }

  async exportTable() {
    this.exportReportTable(new ExportDataParams({
      file: 'DelinquentMemoBill',
    }), this.service.getAllNonEscrowHistories)
  }
}
