import Axios, { CancelToken } from 'axios';

import { IServiceEscrowHistory } from '@/services/api/models/IServiceParcel';
import SearchResponse from '@/services/api/responses/SearchResponse';
import IEntityCountResponse from './models/IEntityCountResponse';

type EscrowHistoryResponse = SearchResponse<IServiceEscrowHistory>

export default {
  async getAllEscrowHistories(params: any, cancelToken?: CancelToken): Promise<EscrowHistoryResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<EscrowHistoryResponse>('/escrow-histories', config);

    return response.data;
  },

  async getEscrowHistory(id: string, cancelToken?: CancelToken): Promise<IServiceEscrowHistory> {
    const config = {};
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<IServiceEscrowHistory>(`/escrow-histories/${id}`, config);

    return response.data;
  },

  async getTotalEscrowHistories(params: any, cancelToken?: CancelToken): Promise<IEntityCountResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<IEntityCountResponse>('/escrow-histories/count', config);

    return response.data;
  },
};
