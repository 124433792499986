






























































































































import {
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import {
  cloneDeep, forEach, isObject, startCase,
} from 'lodash';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, CellValueChangedEvent, SortChangedEvent,
} from 'ag-grid-community';
import { DateTime } from 'luxon';

import QueryService from '@/services/queries';
import UserService, { QueryPost } from '@/services/users';
import { IServiceQueryDefinition } from '@/services/api/models/IServiceQuery';
import LoanService from '@/services/loans';
import AgencyService from '@/services/agencies';
import LenderService from '@/services/lenders';

import QueryDefinition, { QueryInputType } from '@/views/reports/builder/QueryDefinition.vue';

import { JsonPatchEntry, JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';

import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';

import GridReport from '@/views/reports/GridReport.vue';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue'
import SsrmGridReport from '../SsrmGridReport.vue'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ReportName from '../models/ReportName'
import ReportDatasource from '../ag-grid/datasource/ReportDatasource'
import ExportDataParams from '../models/ExportDataParams'
import defaultTextFilterParams from '../ag-grid/params/defaultTextFilterParams'
import quickSearchParams from '../ag-grid/params/quickSearchParams'
import defaultNumberFilterParams from '../ag-grid/params/defaultNumberFilterParams'
import defaultDateFilterParams from '../ag-grid/params/defaultDateFilterParams'
import ReportDatasourceParamBuilder from '../ag-grid/datasource/builder/ReportDatasourceParamBuilder'

interface PageGroup { [index: string]: any[] }
export interface SearchProperty {
  text?: string,
  field: string,
  type: QueryInputType,
  options?: string[],
  roles?: string[],
}

interface SearchSelection {
  table: string,
  field: string,
  type: QueryInputType,
}

interface Operator {
  text: string,
  value: string,
  numValues: number,
}

interface SearchOperator {
  conjunction?: 'and' | 'or',
  table: string,
  field: string,
  operator: Operator,
  values?: any[],
}

@Component({
  name: 'query-builder',
  components: {
    QueryDefinition,
    AgGridVue,
    SsrmGridOmnifilter,
  },
})
export default class QueryBuilder extends SsrmGridReport<any, any> {
  @Prop({
    type: String,
    default: 'Query Builder',
  }) private readonly title!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly readonly!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  }) private readonly runnable!: boolean;

  @Prop({
    type: Map,
    default: () => new Map(),
  }) private readonly selections!: Map<string, (string | SearchProperty)[]>;

  @Prop({
    type: Object,
    default: (): IServiceQueryDefinition => null,
  }) private readonly value!: IServiceQueryDefinition;

  // TODO: Make this part of the config
  private tableNames: Map<string, string> = new Map([
    ['agencycollectingschedule', 'Collecting Schedule'],
    ['parcelescrowhistory', 'Escrow History'],
    ['parcelnonescrowhistory', 'Non Escrow History'],
    ['parcelagency', 'Parcel Agency'],
    ['dtco', 'DTCO'],
  ]);

  private local: { select: any[], search: any[], sort?: any } = { select: [], search: [] };

  private service: QueryService = new QueryService();
  private userService: UserService = new UserService();
  private loanService: LoanService = new LoanService();
  private agencyService: AgencyService = new AgencyService();
  private lenderService: LenderService = new LenderService();

  protected serverSideStoreType = 'full';
  protected cacheBlockSize = 100;
  protected rowModelType = 'serverSide';
  protected paginationPageSize = 15;

  protected pageSizes = [500, 10000];
  private pages: PageGroup = {};

  private name: string = '';
  private description: string = '';

  // private queryRunning: boolean = false;

  private saveQueryMode: boolean = false;
  private saveQueryError: boolean = false;
  private showQueryError: boolean = false;
  private queryError: any = null;
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
  };

  private changeMap: Map<any, Map<string, JsonPatchEntry>> = new Map();
  private createMap: Map<any, any> = new Map();
  protected sortModel: { colId: string, sort: 'asc' | 'desc' } = null;

  // Watchers
  @Watch('value', { immediate: true })
  private onValueChanged(value: IServiceQueryDefinition) {
    this.local = value;
    // console.log(`value: ${value}`)
  }

  @Watch('local')
  private onLocalChanged(value: IServiceQueryDefinition) {
    this.$emit('input', value);
    // console.log(`value: ${value}`)
  }

  // Computed
  get defaultQueryColDef() {
    return Object.assign(this.defaultColDef, {
      flex: 1,
    });
  }

  get operators() : any {
    return {
      equals: 1,
      not_equals: 1,
      like: 1,
      not_like: 1,
      null: 0,
      not_null: 0,
      less_than: 1,
      greater_than: 1,
      less_than_or_equal_to: 1,
      greater_than_or_equal_to: 1,
      between: 2,
    };
  }

  get disableRunQuery() {
    let search = false;
    let select = false;
    if (this.local && this.local.select.length > 0) {
      for (let i = 0; i < this.local.select.length; i += 1) {
        const selectValue = this.local.select[i];
        const keys = Object.keys(selectValue)
        for (let j = 0; j < keys.length; j += 1) {
          const key = keys[j];
          if (selectValue[key] === undefined) return true;
        }
      }
      select = false;
    } else {
      return true;
    }
    if (this.local && this.local.search.length > 0) {
      for (let i = 0; i < this.local.search.length; i += 1) {
        const searchValue = this.local.search[i];
        const keys : any = Object.keys(searchValue)
        for (let j = 0; j < keys.length; j += 1) {
          const key = keys[j];
          if (key === 'value') {
            if (searchValue[key] !== undefined) {
              const numValue = this.operators[searchValue.operator]
              if (searchValue[key].length !== numValue) return true;
              let index = 0;
              while (index < numValue) {
                if (searchValue[key][index] === '') return true
                index += 1;
              }
            }
          }
          if (searchValue[key] === undefined) return true;
        }
      }
      search = false;
    } else {
      return true;
    }
    return search || select;
  }

  get columnFields(): string[] {
    return this.colDefs.map((colDef) => colDef.field);
  }

  get advancedSearch() {
    const advancedSearch: any = {};
    return advancedSearch;
  }

  get colDefs(): ColDef[] {
    if (!this.local) return [];

    const colDefs = this.local.select.map((selection: any): ColDef => {
      const colDef: any = {
        field: this.getFullFieldName(selection.table, selection.field),
        colId: null,
      };
      const selectionType = selection.type
      this.addColDefType(colDef, selectionType);
      this.addColDefFilter(colDef, selectionType);

      const configEntry = this.availableSelections.get(selection.table).find((config) => {
        if (isObject(config)) {
          return config.field === selection.field;
        }

        return config === selection.field;
      });

      let headerName;
      if (isObject(configEntry)) {
        headerName = configEntry.text ? configEntry.text : startCase(configEntry.field);
      } else {
        headerName = startCase(configEntry);
      }
      Object.assign(colDef, {
        headerName: `${this.resolveTableName(selection.table)} - ${headerName}`,
        ...defaultTextFilterParams,
      });
      colDef.colId = colDef.field;
      return colDef;
    });
    colDefs.push(quickSearchParams);
    // console.log(`colDefs: ${JSON.stringify(colDefs, null, 2)}`)
    return colDefs
  }

  private addColDefType(colDef: any, selectionType: any) {
    if (selectionType) {
      switch (selectionType) {
        case 'string':
          // colDef.type = selectionType
          break;
        case 'date':
          colDef.type = selectionType;
          break;
        case 'number':
          colDef.type = selectionType;
          break;
        default:
          break;
      }
    }
  }

  private addColDefFilter(colDef: any, selectionType: string) {
    if (selectionType) {
      switch (selectionType) {
        case 'string':
          Object.assign(colDef, defaultTextFilterParams);
          break;
        case 'date':
          Object.assign(colDef, defaultDateFilterParams);
          break;
        case 'number':
          Object.assign(colDef, defaultNumberFilterParams);
          break;
        default:
          Object.assign(colDef, defaultTextFilterParams);
          break;
      }
    } else {
      Object.assign(colDef, defaultTextFilterParams);
    }
    // console.log(colDef)
  }

  get availableSelections(): Map<string, (string | SearchProperty)[]> {
    const finalSelections = cloneDeep(this.selections);

    finalSelections.forEach((selectionGroup, key, selections) => {
      selections.set(
        key,
        selectionGroup.filter((field) => typeof field === 'string' || !field.roles || this.hasRole(field.roles)),
      );
    });

    return finalSelections;
  }

  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  runQuery() {
    if (this.sortModel) {
      this.local.sort = {
        sort_by: this.sortModel.colId,
        sort_order: this.sortModel.sort,
      }
    } else {
      this.local.sort = null;
    }

    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private handleFailure(e: any) {
    this.showQueryError = e.response && e.response.data && e.response.data.detail;
    this.queryError = e.response && e.response.data && e.response.data.detail;
    this.resetResults();
  }

  private finallyCallback() {
    this.isLoading = false;
  }

  convertResults(results: any[]): any[] {
    console.log('the results to convert are', results)
    let finalResults = results.map((result: any) => Object.keys(result).reduce((object: any, key: string) => {
      const newKey = key.replace('.', '-');
      object[newKey] = result[key];
      return object;
    }, {}));
    if (finalResults.length === 0) return [];

    // These results are not of a concrete entity, so we need to do date conversions here
    const resultKeys = Object.keys(finalResults[0]).filter((key) => !key.includes('_id'));
    const dateKeys = resultKeys.filter((resultKey) => {
      const selection = this.local.select.find(
        (select: any) => this.getFullFieldName(select.table, select.field) === resultKey,
      );
      return selection.type === 'date';
    });
    const shortDateKeys = resultKeys.filter((resultKey) => {
      const selection = this.local.select.find(
        (select: any) => this.getFullFieldName(select.table, select.field) === resultKey,
      );
      return selection.type === 'shortDate';
    });

    if (dateKeys.length > 0 || shortDateKeys.length > 0) {
      finalResults = finalResults.map((result) => {
        const newResult = cloneDeep(result);
        dateKeys.forEach((dateKey) => {
          newResult[dateKey] = newResult[dateKey] ? DateTime.fromISO(newResult[dateKey]).toFormat('MM/dd/yyyy') : undefined;
        });
        shortDateKeys.forEach((dateKey) => {
          newResult[dateKey] = newResult[dateKey] ? DateTime.fromISO(newResult[dateKey]).toFormat('MM/dd') : undefined;
        });
        return newResult;
      });
    }

    return finalResults;
  }

  private getReportName() {
    switch (this.title) {
      case 'Agency Data Report':
        return ReportName.AgencyData;

      case 'Lender Data Report':
        return ReportName.LenderData;

      default:
        return ReportName.AgencyData;
    }
  }

  private reportDatasource() {
    const {
      onSortModelChanged,
      rowFetcherParams,
      httpRequestParams,
    } = new ReportDatasourceParamBuilder<any, any>(
      this.getReportName(),
      this.service.runQuery,
      this.service.runQueryTotal,
      this.sortModel,
      this.onResultsChanged,
      this.getParams,
    ).build()
    return new ReportDatasource<any, any>(
      onSortModelChanged,
      this.setLoading,
      this.resetLoading,
      rowFetcherParams,
      httpRequestParams,
      this.resetResults,
      this.finallyCallback,
    )
  }

  async saveQuery(name: string, description: string) {
    if (!(this.$refs.form as any).validate()) {
      return;
    }

    this.isLoading = true;

    const payload: QueryPost = {
      name,
      description,
      query: this.local,
    };

    try {
      this.saveQueryError = false;
      const query = await this.userService.addUserQuery(payload);
      this.name = '';
      this.description = '';
      this.saveQueryMode = false;
      this.$emit('save', query);
    } catch (e) {
      console.log(e);
      this.saveQueryError = true;
    } finally {
      this.isLoading = false;
    }
  }
  getParams() {
    if (this.sortModel) {
      this.local.sort = {
        sort_by: this.sortModel.colId,
        sort_order: this.sortModel.sort,
      }
    } else {
      this.local.sort = null;
    }

    return {
      advanced_search: this.advancedSearch,
      include_agency: false,
      ssrm_mode: true,
      ...this.local,
    };
  }
  handleCellChangeEvent(event: CellValueChangedEvent) {
    // This only works if we let ag grid handle the row ID assignment
    const index = parseInt(event.node.id, 10);

    if (Number.isNaN(index)) {
      throw new Error('Index could not be determined for cell. Custom ID?');
    }

    this.addToChangeList(event, index, event.colDef.field);
  }

  addToChangeList(event: CellValueChangedEvent, index: number, header: string) {
    const row = this.results[index];

    const patchMap = this.changeMap.get(row) || new Map();

    let idName = '';
    if (event.data.loan_id) {
      idName = 'loan_id';
    } else if (event.data.agency_id) {
      idName = 'agency_id';
    } else if (event.data.lender_id) {
      idName = 'lender_id';
    }
    const patchObject = {
      op: JsonPatchOperator.replace,
      path: `/${event.data[idName]}/${header}`,
      value: event.newValue,
    };
    if (event.colDef.headerName.split('.')[0] === 'parcel') {
      patchObject.path = `/${event.data[idName]}/parcels/${event.data.parcel_id}/${header}`;
    }
    patchMap.set(header, patchObject);
    this.changeMap.set(row, patchMap);
  }

  async submitChanges() {
    const payload: JsonPatchPayload = [];

    this.changeMap.forEach((patchMap: Map<string, JsonPatchEntry>, summary: any) => {
      const parcelPatches = Array.from(patchMap.values());
      payload.push(...parcelPatches);
    });

    if (this.title === 'Loan Data Report') {
      const response = await this.loanService.batchPatchLoans(payload);
    } else if (this.title === 'Agency Data Report') {
      const response = await this.agencyService.batchPatchAgencies(payload);
    } else if (this.title === 'Lender Data Report') {
      const response = await this.lenderService.batchPatchLenders(payload);
    }

    this.changeMap.clear();
    this.createMap.clear();
  }

  exportTable() {
    let exportTitle: string;
    if (this.title === 'Loan Data Report') {
      exportTitle = 'LoanDataReport';
    } else if (this.title === 'Agency Data Report') {
      exportTitle = 'AgencyDataReport';
    } else if (this.title === 'Lender Data Report') {
      exportTitle = 'LenderDataReport';
    } else {
      exportTitle = 'Report';
    }

    this.exportReportTable(
      new ExportDataParams({
        file: exportTitle,
        colDefs: this.colDefs,
      }),
      this.service.runQuery,
    )
  }

  private copyError(e: Error) {
    const errorSummary = {
      error: e,
    };

    this.$copyText(JSON.stringify(errorSummary));
  }

  private getFullFieldName(table: string, column: string): string {
    return `${table}-${column}`;
  }

  private resolveTableName(table: string): string {
    if (this.tableNames.has(table)) {
      return this.tableNames.get(table);
    }

    return startCase(table);
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
  }

  onGridSortChanged(params: SortChangedEvent) {
    // Commented as setSortModel method not available in latest version
    // [this.sortModel] = params.api.getSortModel();
  }
  refreshRows() {
    this.latestResults = [];
    this.results = [];
    this.pages = {};
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts();
        this.datasource = this.reportDatasource();
        this.gridApi.setServerSideDatasource(this.datasource);
      }
    }, 0)
  }
}
