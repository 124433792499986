







































































import {
  Component, Mixins, Prop, Vue,
} from 'vue-property-decorator';

import DragAndDropUploader from '@/views/import/DragAndDropUploader.vue';
import ImportMatcher from '@/views/import/ImportMatcher.vue';
import ImportTaxData from '@/views/import/ImportTaxData.vue';

import UserPermissions from '@/mixins/UserPermissions.vue';
import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';

@Component({
  name: 'import-center',
  components: {
    ImportMatcher,
    ImportTaxData,
    DragAndDropUploader,
  },
})
export default class ImportCenter extends Mixins(UserPermissions, PreventDirtyLeave) {
  private file: File = null;
  private fileLayout: 'partial' | 'full' | 'fixed' | 'escrowTax' = null;

  private isImportDirty: boolean = false;

  isDirty() {
    return this.isImportDirty;
  }
}
