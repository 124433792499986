import Axios from 'axios';

import IServiceTransaction from '@/services/api/models/IServiceTransaction';

interface TransactionsResponse {
  count: any,
  transactions: IServiceTransaction[],
}

export default {
  async getFullHistory(params: any): Promise<TransactionsResponse> {
    const response = await Axios.get<TransactionsResponse>('/history', {
      params,
    });

    return response.data;
  },
  async attemptRollback(params: any): Promise<any> {
    const response = await Axios.delete<any>('/history', {
      params,
    });

    return response.data;
  },
};
