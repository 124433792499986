import { CancelToken } from 'axios';

import LoanApiService from '@/services/api/LoanApiService';
import Loan from '@/entities/Loan';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import EntityCountResponse from '../api/models/EntityCountResponse';
import LoanCollection from './LoanCollection';

interface AdvancedSearch {
  agency_numbers?: string[],
  agency_states?: string[],
  lender_numbers?: string[],
  delinquent_tax_collecting_offices?: string[],
  delinquent_filter?: string,
  parcel_type_in?: ('E' | 'EN' | 'N')[],
}

interface LoanSearchParams {
  limit?: number,
  offset?: number,
  sort_by?: string,
  order_by?: 'asc' | 'desc',
  search_field?: string,
  search_value?: string,
  advanced_search?: AdvancedSearch,
  search_type?: string,
}

class LoanService {
  private lastRetrieval: string;

  async getAllLoans(params: LoanSearchParams, cancelToken?: CancelToken): Promise<LoanCollection> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);

    const response = await LoanApiService.getAllLoans(stringifiedParams, cancelToken);

    if (response.error) {
      console.log('Error:', response.error.routine)
      return {
        count: 0,
        results: [],
      }
    }
    return {
      count: parseInt(response.count, 10),
      results: response.loans.map((serviceLoan) => new Loan(serviceLoan)),
    };
  }

  async getTotalLoans(params: LoanSearchParams, cancelToken?: CancelToken): Promise<EntityCountResponse> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);

    const response = await LoanApiService.getTotalLoans(stringifiedParams, cancelToken);

    if (response.error) {
      console.log('Error:', response.error.routine)
      return {
        count: 0,
      }
    }
    return response
  }

  async getLoan(id: string, cancelToken?: CancelToken): Promise<Loan> {
    const response = await LoanApiService.getLoan(id, cancelToken);
    this.lastRetrieval = response.headers.etag;

    return new Loan(response.data);
  }

  async updateLoan(id: string, payload: JsonPatchPayload): Promise<Loan> {
    const response = await LoanApiService.updateLoan(id, payload, this.lastRetrieval);
    this.lastRetrieval = response.headers.etag;

    return new Loan(response.data);
  }

  async createLoan(payload: any): Promise<Loan> {
    const response = await LoanApiService.createLoan(payload);
    return new Loan(response);
  }

  async batchPatchLoans(payload: JsonPatchPayload): Promise<any> {
    const response = await LoanApiService.batchPatchLoans(payload);
    return response;
  }

  async uploadFile(id: string, file: File): Promise<void> {
    const formData: FormData = new FormData();
    formData.set('file', file);
    await LoanApiService.uploadFile(id, formData);
  }

  async deleteLoan(id: string): Promise<any> {
    const response = await LoanApiService.deleteLoan(id);

    return new Loan(response);
  }
}

export { LoanService as default };
