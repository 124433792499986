






























































import { Vue, Component } from 'vue-property-decorator';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  name: 'import-header',
})
export default class ImportHeader extends Vue {
  private params: IHeaderParams & { api: any } = null;

  private ascSort: string = null;
  private descSort: string = null;
  private noSort: string = null;

  onMenuClicked() {
    this.params.showColumnMenu(this.$refs.menuButton as HTMLElement);
  }

  onSortChanged() {
    this.ascSort = 'inactive';
    this.descSort = 'inactive';
    this.noSort = 'inactive';

    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  onSortRequested(order: 'asc' | 'desc', event: KeyboardEvent) {
    this.params.setSort(order, event.shiftKey);
  }

  describeColumn(index: number) {
    this.params.api.frameworkComponentWrapper.parent.$emit('column-describe', index);
  }

  hideColumn(index: number) {
    this.params.api.frameworkComponentWrapper.parent.$emit('column-remove', index);
  }

  openColumnWriteDialog(index: number) {
    this.params.api.frameworkComponentWrapper.parent.$emit('column-assign', index);
  }

  splitAddressColumn(index: number) {
    this.params.api.frameworkComponentWrapper.parent.$emit('column-split', index);
  }

  selectColumn(index: number, header: string | undefined) {
    this.params.api.frameworkComponentWrapper.parent.$emit(
      'column-select',
      index,
      header === undefined ? null : header,
    );
  }
}
