
import {
  Component,
  Vue,
} from 'vue-property-decorator';

import { CellKeyPressEvent } from 'ag-grid-community';

@Component({
  name: 'keyboard-navigation',
})
export default class KeyboardNavigation extends Vue {
  // Methods
  navigateToNextRow(event: CellKeyPressEvent) {
    const keyPressed: string = (event.event as KeyboardEvent).key;
    const isShifted: boolean = (event.event as KeyboardEvent).shiftKey;

    if (keyPressed === 'Enter' && !isShifted) {
      event.api.setFocusedCell(
        event.rowIndex + 1,
        event.column,
      );

      event.api.startEditingCell({
        rowIndex: event.rowIndex + 1,
        colKey: event.column,
      });
    }
  }
}
