























































































































































import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { DateTime } from 'luxon';
import jsPDF from 'jspdf';
import { ColDef, SortChangedEvent } from 'ag-grid-community';
import { DataTableHeader } from 'vuetify';

import LenderService from '@/services/lenders';
import ParcelService from '@/services/parcels';

import ReportPdfBuilder from '@/helpers/exports/pdf/ReportPdfBuilder';

import Lender from '@/entities/Lender';
import Loan from '@/entities/Loan';
import Term from '@/entities/Term';
import Agency from '@/entities/Agency';
import Parcel from '@/entities/Parcel';
import Address from '@/entities/Address';
import Verified from '@/entities/Verified';

import states from '@/data/states';
import ParcelCollection from '@/services/parcels/ParcelCollection';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import LoanSearch from '@/views/loans/LoanSearch.vue';
import AgencySearch from '@/views/agencies/AgencySearch.vue';
import Axios, { AxiosResponse, CancelToken } from 'axios';
import SsrmGridReport from './SsrmGridReport.vue';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ExportDataParams from './models/ExportDataParams';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';
import ReportName from './models/ReportName';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';

interface ReportRow {
  agencyNumber: string,
  agencyName: string,
  agencyAddress: Verified<Address>,
  collectingScheduleDueDate: Verified<string>,

  year: string,
  term: string,
  existing: boolean,

  loanNumber: string,
  lenderNumber: string,
  parcelNumber: string,
  name: string,
  address: string,
  dueDate: string,
  legal: string,
}

@Component({
  name: 'tax-bill-request-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
    LoanSearch,
    AgencySearch,
  },
})
export default class TaxBillRequestReport extends SsrmGridReport<Parcel, ReportRow> {
  protected pageSizes = [500, 10000];

  // PDF
  private isGeneratingPDF = false;
  private generatePDFText = 'Create PDF';

  // Services
  private service: ParcelService = new ParcelService();
  private lenderService: LenderService = new LenderService();

  // User options
  private states: DataTableHeader[] = states;
  private escrowYearItems: string[] = [];
  private termItems = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  // User inputs
  private pickedState: string = '';
  private year: string = '';
  private term: string = '';
  private title: string = '';
  private loanNumbersString: string = '';

  private selectedAgencies: Agency[] = [];

  private selectedLenders: string[] = [];
  private lenders: (Lender & { displayString?: string })[] = [];
  private lendersSearchText: string = null;

  private selectedLoans: Loan[] = [];

  private agencyInclusion: 'include' | 'exclude' = 'include';
  private lenderInclusion: 'include' | 'exclude' = 'include';
  private loanInclusion: 'include' | 'exclude' = 'include';

  private showLegalDescription: boolean = false;

  private rowClassRules: any = {
    'new-row': (params: any) => {
      if (params.data) {
        return !params.data.existing
      }
      return false
    },
  }

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
      width: 225,
      ...defaultTextFilterParams,
      valueGetter(params) {
        const {
          node: { data },
          colDef: { field },
        } = params;
        let value;
        if (data && field) {
          value = data[field];
        }
        return value === undefined ? '' : value;
      },
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      type: 'date',
      sortable: false,
    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
      width: 100,
      valueGetter(params) {
        const {
          node: { data },
          colDef: { field },
        } = params;
        let value;
        if (data && field) {
          value = data[field];
        }
        return value === undefined ? '' : value;
      },
    },
    {
      headerName: 'Term',
      field: 'term',
      sortable: false,
      minWidth: 100,
      flex: 1,
      valueGetter(params) {
        const {
          node: { data },
          colDef: { field },
        } = params;
        let value;
        if (data && field) {
          value = data[field];
        }
        return value === undefined ? '' : value;
      },
    },
    {
      headerName: 'Legal Description',
      field: 'legal',
      sortable: false,
      minWidth: 100,
      width: 225,
      resizable: true,
      flex: 1,
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  // Computed
  get hasRequiredInput(): boolean {
    return Boolean(this.year) && Boolean(this.term) && Boolean(this.pickedState);
  }

  get loanNumbersTextIsntBlank(): boolean {
    return Boolean(this.loanNumbersString) && this.loanNumbersString !== '';
  }

  get advancedSearch() {
    const advancedSearch: any = {
      agency_states: null,
      agency_numbers: null,
      excluded_agency_numbers: null,
      lender_numbers: null,
      excluded_lender_numbers: null,
      loan_numbers: null,
      excluded_loan_numbers: null,
      return_agency_collecting_schedule: true,
      history_year_in: [this.year],
      history_term_in: [this.term],
      parcel_type_in: ['E', 'EN'],
    };

    if (this.selectedAgencies && this.selectedAgencies.length > 0) {
      if (this.agencyInclusion === 'include') {
        advancedSearch.agency_numbers = this.selectedAgencies.map((a) => a.capAgency);
      } else {
        advancedSearch.excluded_agency_numbers = this.selectedAgencies.map((a) => a.capAgency);
      }
    }

    if (this.selectedLenders && this.selectedLenders.length > 0) {
      if (this.lenderInclusion === 'include') {
        advancedSearch.lender_numbers = this.selectedLenders;
      } else {
        advancedSearch.excluded_lender_numbers = this.selectedLenders;
      }
    }

    if (this.loanNumbersTextIsntBlank) {
      if (this.loanInclusion === 'include') {
        advancedSearch.loan_numbers = this.loanNumbersString.split(',').map((s) => s.trim());
      } else {
        advancedSearch.excluded_loan_numbers = this.loanNumbersString.split(',').map((s) => s.trim());
      }
    }

    if (this.pickedState && this.pickedState !== '') {
      advancedSearch.agency_states = [this.pickedState];
    }

    return advancedSearch;
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 2; i >= 0; i -= 1) {
      const relevantYear = currentYear + i - 1;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [, this.year] = this.escrowYearItems;
    this.term = this.termItems[1].value;

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = currentYear - i;
      this.escrowYearItems.push(relevantYear.toString());
    }

    await this.getAllLenders();
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
    // Commented as setSortModel method not available in latest version
    // this.gridApi.setSortModel([{
    //   colId: 'parcelNumber',
    //   sort: 'asc',
    // }]);
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);

    if (this.hasRequiredInput) {
      this.refreshRows();
    }
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      include_agency: true,
      search_type: 'escrow_history',
    };
  }

  async refreshRows() {
    this.latestResults = [];
    this.results = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<Parcel, ReportRow>(
      ReportName.TaxBillRequest,
      this.service.getAllParcels,
      this.service.getTotalParcels,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build();
  }

  convertResults(results: Parcel[]): ReportRow[] {
    return results.reduce<ReportRow[]>((allSummaries, parcel) => {
      // Build the parcel summaries to show the user
      // Every parcel agency remaining matches term picked
      parcel.agencies.forEach((agency) => {
        const scheduleEntry = agency.collectingSchedule.find((entry) => entry.term === this.term);
        const reportRow = {
          loanNumber: parcel.loanNumber,
          lenderNumber: parcel.lenderNumber,
          name: parcel.name,
          address: parcel.address.value.address1,
          agencyNumber: agency.capAgency,
          agencyName: agency.name,
          agencyAddress: agency.address,
          year: this.year,
          term: Term[this.term as keyof typeof Term],
          collectingScheduleDueDate: scheduleEntry ? scheduleEntry.dueDate : null,
          parcelNumber: parcel.altParcelNumber !== null
            ? ''.concat(parcel.parcelNumber, ' (', parcel.altParcelNumber, ')')
            : parcel.parcelNumber,
          legal: parcel.legal,
        };

        if (agency.escrowHistory.length === 0) {
          allSummaries.push(Object.assign(reportRow, {
            dueDate: scheduleEntry ? scheduleEntry.dueDate.value : null,
            existing: false,
          }));
          return;
        }

        agency.escrowHistory.forEach((escrowHistory) => {
          allSummaries.push(Object.assign(reportRow, {
            dueDate: escrowHistory.dueDate,
            existing: true,
          }));
        });
      });

      return allSummaries;
    }, []);
  }

  exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'TaxBillRequestReport',
      }),
      this.service.getAllParcels,
    )
  }

  async createPDF() {
    this.generatePDFText = 'Creating File';
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally();
      this.generatePDFOnServer();
      this.generatePDFText = 'Create PDF';
      this.gridApi.hideOverlay();
    }, 100);
  }
  async generatePDFOnServer() {
    console.log('------generate pdf on server all data is', this.results);
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllParcels(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const res = await this.exportPdf(
      newResults,
      '/pdf/tax-bill-request',
      {
        resData: JSON.stringify(newResults),
        title: this.title,
        term: this.term,
        year: this.year,
        showLegalDescription: this.showLegalDescription ? 'true' : 'false',
      },
      'results',
    );
    this.isGeneratingPDF = false;
    return window.open(res.data.link, '_blank');
  }

  async generatePDFLocally() {
    const pdfBuilder: ReportPdfBuilder<ReportRow> = new ReportPdfBuilder<ReportRow>(this.results, {
      footer: 15,
      groupBy: 'agencyNumber',
      exclusions: ['agencyNumber', 'agencyName', 'agencyAddress', 'collectingScheduleDueDate', 'dueDate', 'existing', 'term', 'year', (this.showLegalDescription ? null : 'legal')],
      columnNames: new Map([
        ['lenderNumber', 'Lender #'],
        ['loanNumber', 'Loan #'],
        ['parcelNumber', 'Parcel #'],
      ]),
      columnStyles: {
        legal: {
          cellWidth: 140,
        },
      },
    });

    pdfBuilder.setHeaderDraw((doc: jsPDF, height, width, pageNumber, grouping: ReportRow[]) => {
      const {
        agencyName,
        agencyAddress,
        agencyNumber,
        collectingScheduleDueDate: groupingDueDate,
      } = grouping[0];

      doc.setFontSize(12);
      doc.text(`TOTAL PARCELS: ${this.results.filter((item) => item.agencyNumber === agencyNumber).length}`, 40, height - 5);
      doc.text(agencyNumber, 40, height - 5);
      doc.setFontSize(24);
      doc.text('CAPITAL REAL ESTATE TAX SERVICES, INC.', width / 2.0, 28, { align: 'center' });
      doc.setFontSize(20);
      if (this.title && this.title !== '') {
        doc.text(this.title, width / 2.0, 48, { align: 'center' });
      } else {
        doc.text(`${this.year} ${Term[this.term as keyof typeof Term].toUpperCase()} TAX BILL REQUEST`, width / 2.0, 48, { align: 'center' });
      }
      doc.setFontSize(18);
      doc.text(
        `${agencyName} OF ${agencyAddress && agencyAddress.value && agencyAddress.value.county ? agencyAddress.value.county.toUpperCase() : 'UNKNOWN'} COUNTY, ${agencyAddress && agencyAddress.value && agencyAddress.value.state ? agencyAddress.value.state.toUpperCase() : 'UNKNOWN'}`,
        width / 2.0,
        66,
        { align: 'center' },
      );

      doc.setFontSize(12);
      doc.text(
        `TAXES DUE: ${groupingDueDate ? groupingDueDate.value : 'N/A'}`,
        width - 40,
        height - 5,
        { align: 'right' },
      );
    });

    pdfBuilder.setFooterDraw((doc: jsPDF, height, width, pageNumber, grouping, startY) => {
      const contactSize = 12;
      const bottomMargin = 10;
      const contactStartX = 0.66 * width;
      const { height: docHeight } = doc.internal.pageSize;

      doc.setFontSize(contactSize);

      doc.text(DateTime.local().toFormat('MM/dd/yyyy'), 40, docHeight - bottomMargin);

      doc.text('MAIL TAX BILLS TO:', contactStartX, docHeight - contactSize * 5 - bottomMargin)
      doc.text('CAPITAL REAL ESTATE TAX SERVICES, INC', contactStartX, docHeight - contactSize * 4 - bottomMargin);
      doc.text('1300 COMBERMERE DRIVE, TROY, MI 48083', contactStartX, docHeight - contactSize * 3 - bottomMargin);
      doc.text('PHONE - 800.335.3635', contactStartX, docHeight - contactSize * 2 - bottomMargin);
      doc.text('FAX - 800.401.9179', contactStartX, docHeight - contactSize - bottomMargin);
      doc.text('EMAIL - escrow@capitaltax.us', contactStartX, docHeight - bottomMargin);
    });

    const pdf = pdfBuilder.build();

    pdf.save(`TaxBillRequest-${this.term}-${this.year}.pdf`);
  }

  determineLenderName(item: Lender): string {
    return `${item.name} - ${item.id}`;
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_desc: false,
    };
    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
      this.lenders.forEach((lender) => {
        lender.displayString = `${lender.name} - ${lender.id}`;
      });
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
  }

  remove(item: any, items: any[], key?: string) {
    const index = items.indexOf(key ? item[key] : item);
    if (index >= 0) items.splice(index, 1);
  }
}
