import { IServerSideGetRowsRequest } from 'ag-grid-community'
import _ from 'lodash'
import ReportName from '../../../models/ReportName'
import IHttpRequestParams from '../params/IHttpRequestBuilderParams'

export default class HttpRequestBuilder {
  private reportName: ReportName
  private getParams: () => { advanced_search: any }

  constructor(
    httpRequestParams: IHttpRequestParams,
  ) {
    this.reportName = httpRequestParams.reportName
    this.getParams = httpRequestParams.getParams
  }

  public buildRequest(request: IServerSideGetRowsRequest) {
    const {
      filterModel,
      startRow,
      endRow,
      sortModel,
    } = request
    const { quickSearch, columnFilter } = this.checkFilterType(filterModel)
    const queryParams = this.getParams()
    const entitySearchParams: any = {
      limit: endRow - startRow,
      report: ReportName[this.reportName],
      offset: startRow,
    }
    Object.assign(entitySearchParams, {
      ...queryParams,
    })
    Object.assign(entitySearchParams, {
      filterModel,
    })
    this.buildSortModel(entitySearchParams, sortModel)
    return {
      quickSearch,
      columnFilter,
      entitySearchParams,
    }
  }

  private checkFilterType(filterModel: any) {
    let columnFilter: boolean = false
    let quickSearch: boolean = false
    if (_.isEmpty(filterModel)) {
      return { quickSearch, columnFilter }
    }
    Object.keys(filterModel).forEach((k) => {
      if (k === '-') {
        quickSearch = true
      } else {
        columnFilter = true
      }
    })
    return { quickSearch, columnFilter }
  }

  private buildSortModel(parcelSearchParams: any, sortModel: any) {
    if (sortModel) {
      const sortArray = sortModel.sort()
      if (sortArray && sortArray.length > 0) {
        const sortElement = sortArray[0]
        if (sortElement) {
          Object.assign(parcelSearchParams, {
            sort_by: sortElement.colId,
            order_by: sortElement.sort,
          })
        }
      }
    }
  }
}
