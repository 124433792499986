import { IServerSideGetRowsParams } from 'ag-grid-community'
import ISuccessCallback from './ISuccessCallBack'
import ISuccessCallbackParams from '../params/ISuccessCallbackParams'
import LastRowFetcher from '../rowFetcher/LastRowFetcher'

export default class SuccessCallback<RR> implements ISuccessCallback {
  private onSuccess: (rows: any[]) => any[]
  private lastRowFetcher: LastRowFetcher
  public lastRowFetched: boolean
  public lastRow: number

  constructor(successCallbackParams: ISuccessCallbackParams) {
    this.onSuccess = successCallbackParams.onSuccess
    this.lastRowFetcher = successCallbackParams.lastRowFetcher
    this.lastRowFetched = false
    this.lastRow = -1
  }

  public reset() {
    this.lastRow = -1
    this.lastRowFetched = false
  }

  async call(
    serverSideGetRowsParams: IServerSideGetRowsParams,
    searchQueryParams: any,
    searchQueryResults: any[],
  ): Promise<number> {
    const reportRows: RR[] = this.onSuccess(searchQueryResults)
    if (this.lastRowFetched) {
      serverSideGetRowsParams.success({ rowData: reportRows, rowCount: this.lastRow })
    } else {
      serverSideGetRowsParams.success({ rowData: reportRows, rowCount: reportRows.length })
      const { count: lastRow } = await this.lastRowFetcher.fetch(searchQueryParams)
      // console.log(lastRow)
      this.afterLastRowFetched(lastRow, serverSideGetRowsParams, reportRows)
      return lastRow
    }
    return Promise.resolve(this.lastRow)
  }

  private afterLastRowFetched(lastRow: number, serverSideGetRowsParams: IServerSideGetRowsParams, reportRows: RR[]) {
    this.lastRow = lastRow
    this.lastRowFetched = true
    serverSideGetRowsParams.success({ rowData: reportRows, rowCount: lastRow })
    serverSideGetRowsParams.api.refreshHeader()
  }
}
