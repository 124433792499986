


















































































































import { Component } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { ColDef, SortChangedEvent } from 'ag-grid-community'
import jsPDF from 'jspdf'
import { DateTime } from 'luxon'
import autoTable from 'jspdf-autotable'
import {
  cloneDeep, each, groupBy, reduce, uniqBy,
} from 'lodash'
import { DataTableHeader } from 'vuetify'

import LenderService from '@/services/lenders'
import NonEscrowHistoryService from '@/services/nonEscrowHistories'

import Lender from '@/entities/Lender'
import Agency from '@/entities/Agency'
import NonEscrowHistory from '@/entities/NonEscrowHistory'
import AgencySearch from '@/views/agencies/AgencySearch.vue'
import Axios from 'axios'
import formatters from '@/components/ag-grid/formatters'
import { formatCurrency } from '@/components/ag-grid/formatters/CurrencyFormatter'
import states from '@/data/states'
import NonEscrowHistoryCollection from '@/services/nonEscrowHistories/NonEscrowHistoryCollection'
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue'
import SsrmGridReport from './SsrmGridReport.vue'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ReportName from './models/ReportName'
import ReportDatasource from './ag-grid/datasource/ReportDatasource'
import ExportDataParams from './models/ExportDataParams'
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams'
import quickSearchParams from './ag-grid/params/quickSearchParams'
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams'
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams'
import ReportDatasourceParamBuilder from './ag-grid/datasource/builder/ReportDatasourceParamBuilder'

interface ReportRow {
  lenderNumber: string,
  loanNumber: string,
  agencyNumber: string,

  loanType: string,
  name: string,
  address: string,
  city: string,
  county: string,
  state: string,
  zipCode: string,
  legal: string,
  lot: string,
  block: string,
  unit: string,
  parcelType: string,

  agencyName: string,
  altParcelNumber: string,
  year: string,
  status: string,
  parcelNumber: string,

  reportedDate: string,
  base: number,
  amountDue: number,
  penalties: number,
  notes: string,
  dtco: Agency
}

interface PageGroup { [index: string]: ReportRow[] }

@Component({
  name: 'tax-data-sheet-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
    AgencySearch,
  },
})
export default class TaxDataSheetReport extends SsrmGridReport<NonEscrowHistory, ReportRow> {
  // Parent overrides
  protected pageSizes = [500, 10000]

  private isGeneratingPDF = false

  // Root entities
  private pages: PageGroup = {}
  private lenders: (Lender & { displayString?: string})[] = []

  private generatePDFText = 'Create PDF'

  // Services
  private service: NonEscrowHistoryService = new NonEscrowHistoryService()
  private lenderService: LenderService = new LenderService()

  // User options
  private states: DataTableHeader[] = states

  // User inputs
  private lender: Lender = null
  private dtcos: Agency[] = []
  private pickedState: string = null
  private parcelType: 'all' | 'e_and_en' | 'n_and_en' = 'all'
  private paidStatus: 'all' | 'paid' | 'not_paid' | 'paid_and_unpaid' = 'all'
  private title: string = ''
  private loanNumbersString: string = ''
  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  // Input rules
  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      width: 225,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'E/N',
      field: 'loanType',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Status',
      field: 'status',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Base',
      field: 'base',
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Penalties / Interest',
      field: 'penalties',
      type: 'currency',
      sortable: false,
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Taxes Due',
      field: 'amountDue',
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      type: 'date',
      flex: 1,
      minWidth: 150,
      ...defaultDateFilterParams,
    },
    {
      headerName: 'DTCO',
      field: 'dtco.capAgency',
      flex: 1,
      minWidth: 150,
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ]

  //  private callbacks: IServerSideDatasourceCallbacks = {
  //    onSuccess: (rows: any[]) => this.onResultsChanged(rows),
  //    onSortModelChanged: (sortModel: SortModelItem[]) => {
  //      [this.sortModel] = sortModel
  //    },
  //  }

  private parcelColumnDefs = [
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Address',
      field: 'address',
    },
    {
      headerName: 'City',
      field: 'city',
    },
    {
      headerName: 'State',
      field: 'state',
    },
    {
      headerName: 'Zip Code',
      field: 'zipCode',
    },
    {
      headerName: 'Legal',
      field: 'legal',
    },
    {
      headerName: 'Lot',
      field: 'lot',
    },
    {
      headerName: 'Block',
      field: 'block',
    },
    {
      headerName: 'Unit',
      field: 'unit',
    },
  ]

  private taxOfficeColumnDefs = [
    {
      headerName: 'Parcel Escrow Type',
      field: 'parcelType',
    },
    {
      headerName: 'Counties',
      field: 'allAgencyCounties',
    },
    {
      headerName: 'Tax Offices',
      field: 'allAgencyNames',
    },
    {
      headerName: 'Alt Parcel #',
      field: 'altParcelNumber',
    },
  ]

  private historyColumnDefs = [
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'status',
    },
    {
      headerName: 'Base',
      field: 'base',
      valueFormatter: formatters.currencyFormatter,
    },
    {
      headerName: 'Penalties / Interest',
      field: 'penalties',
      valueFormatter: formatters.currencyFormatter,
    },
    {
      headerName: 'Taxes Due',
      field: 'amountDue',
      valueFormatter: formatters.currencyFormatter,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
    },
    {
      headerName: 'Notes',
      field: 'notes',
    },
  ]

  // Computed
  get hasRequiredInput(): boolean {
    return true
  }

  get advancedSearch() {
    const advancedSearch: any = {}

    if (this.lender && this.lender.id) {
      advancedSearch.lender_numbers = [this.lender.id]
    }

    if (this.parcelType && this.parcelType !== 'all') {
      if (this.parcelType === 'e_and_en') {
        advancedSearch.parcel_type_in = ['E', 'EN']
      } else if (this.parcelType === 'n_and_en') {
        advancedSearch.parcel_type_in = ['N', 'EN']
      }
    }

    if (this.parcelType && this.paidStatus !== 'all') {
      advancedSearch.delinquent_filter = this.paidStatus
    }

    if (this.loanNumbersTextIsntBlank) {
      Object.assign(advancedSearch, {
        loan_number_in: this.loanNumbersString.split(',').map((s) => s.trim()),
      })
    }

    if (this.dtcos.length > 0) {
      advancedSearch.delinquent_tax_collecting_offices = this.dtcos.map((dtco) => dtco.capAgency)
    }

    if (this.pickedState) {
      advancedSearch.dtco_state = this.pickedState
    }

    return advancedSearch
  }

  get loanNumbersTextIsntBlank(): boolean {
    return this.loanNumbersString && this.loanNumbersString !== ''
  }

  // Hooks
  async created() {
    this.getAllLenders()
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay()
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params)
    // TARP-1051 comment starts
    // The updated sort model is available in request object of getRows method of datasource
    // So no need to call refreshRows method as changes applied by ag-grid API
    // this.refreshRows()
    // TARP-1051 comment ends
  }

  refreshRows() {
    this.latestResults = []
    this.results = []
    this.pages = {}
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    const {
      onSortModelChanged,
      rowFetcherParams,
      httpRequestParams,
    } = new ReportDatasourceParamBuilder<NonEscrowHistory, ReportRow>(
      ReportName.TaxDataSheet,
      this.service.getAllNonEscrowHistories,
      this.service.getTotalNonEscrowHistories,
      this.sortModel,
      this.onResultsChanged,
      this.getParams,
    ).build()
    return new ReportDatasource<NonEscrowHistory, ReportRow>(
      onSortModelChanged,
      this.setLoading,
      this.resetLoading,
      rowFetcherParams,
      httpRequestParams,
    )
  }

  // private buildReportDatasourceParams(reportName: ReportName, getAll: GetAllType, getTotal: GetTotalType) {
  //   const onSortModelChanged = (sortModel: SortModelItem[]) => {
  //     [this.sortModel] = sortModel
  //   }
  //   const lastRowFetcher = new LastRowFetcher(
  //     reportName,
  //     getTotal,
  //   )
  //   const successCallbackParams = {
  //     lastRowFetcher,
  //     onSuccess: (rows: any[]) => this.onResultsChanged(rows),
  //   }
  //   const rowFetcherParams: IRowFetcherParams = {
  //     getAll,
  //     successCallbackParams,
  //   }
  //   const httpRequestParams = {
  //     reportName,
  //     getParams: this.getParams,
  //   }
  //   return { onSortModelChanged, rowFetcherParams, httpRequestParams }
  // }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      include_agency: true,
    }
  }

  convertResults(results: NonEscrowHistory[]): ReportRow[] {
    const newResults: ReportRow[] = results.map((nonEscrowHistory) => {
      const result: ReportRow = {
        lenderNumber: nonEscrowHistory.lenderNumber,
        loanNumber: nonEscrowHistory.loanNumber,
        agencyNumber: nonEscrowHistory.agency.capAgency,
        loanType: nonEscrowHistory.parcelType,
        name: nonEscrowHistory.name,
        address: nonEscrowHistory.address,
        city: nonEscrowHistory.city,
        state: nonEscrowHistory.state,
        zipCode: nonEscrowHistory.zipCode,
        legal: nonEscrowHistory.legal,
        lot: nonEscrowHistory.lot,
        block: nonEscrowHistory.block,
        unit: nonEscrowHistory.unit,
        parcelType: nonEscrowHistory.parcelType,
        county: nonEscrowHistory.county,
        agencyName: nonEscrowHistory.agency.name,
        altParcelNumber: nonEscrowHistory.altParcelNumber,
        year: nonEscrowHistory.year,
        status: nonEscrowHistory.status ? nonEscrowHistory.status.value : '',
        parcelNumber: nonEscrowHistory.parcelNumber,
        reportedDate: nonEscrowHistory.reportedDate,
        base: nonEscrowHistory.base,
        penalties: nonEscrowHistory.penalties,
        amountDue: nonEscrowHistory.amountDue,
        notes: nonEscrowHistory.notes,
        dtco: nonEscrowHistory.delinquentTaxCollectingOffice,
      }

      const idString = `${nonEscrowHistory.loanId}${nonEscrowHistory.parcelId}`
      if (!this.pages[idString]) {
        this.pages[idString] = []
      }
      this.pages[idString].push(result)

      return result
    })

    return newResults
  }

  determineName(item: Lender): string {
    let nameString = item.name

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`
    }

    nameString += `, ${item.id}`

    return nameString
  }

  async exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'TaxDataSheetReport',
      }),
      this.service.getAllNonEscrowHistories,
    )
  }

  createPDF() {
    this.generatePDFText = 'Creating File'
    this.isGeneratingPDF = true
    setTimeout(() => {
      // this.generatePDFLocally()
      this.generatePDFOnServer()
      this.generatePDFText = 'Create PDF'
      this.gridApi.hideOverlay()
    }, 100)
  }

  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllNonEscrowHistories(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const pages = Object.values(this.pages)
    const serializedPages = JSON.stringify(pages)

    const res = await this.exportPdf(
      newResults,
      '/pdf/tax-data-sheet-report',
      {
        title: this.title,
        pages: serializedPages,
        lenders: JSON.stringify(this.lenders),
        parcelColumnDefs: JSON.stringify(this.parcelColumnDefs),
        taxOfficeColumnDefs: JSON.stringify(this.taxOfficeColumnDefs),
        historyColumnDefs: JSON.stringify(this.historyColumnDefs),
      },
      'results',
    );
    this.isGeneratingPDF = false
    return window.open(res.data.link, '_blank')
  }

  generatePDFLocally() {
    const reportPdf: jsPDF = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',
    })

    const margin: number = 10
    const xLineMargin = 5
    const pages = Object.values(this.pages)

    // Sort the pages by loan number and then by parcel number
    pages.sort((a, b) => {
      const aString = `${a[0].loanNumber}${a[0].parcelNumber}`
      const bString = `${b[0].loanNumber}${b[0].parcelNumber}`
      return aString.localeCompare(bString)
    })

    // Create an actual PDF page for each page entry
    pages.forEach((page, index) => {
      const firstPageEntry = page[0]
      const pageIsDelq = page.some((pageEntry) => pageEntry.status && pageEntry.status.toLowerCase().includes('delq'))
      const agencyGroups = groupBy(page, (pageEntry) => pageEntry.agencyNumber)
      const dtcoGroups: PageGroup = groupBy(page, (pageEntry) => pageEntry.dtco.capAgency)

      let cursor = 5
      const lender = this.lenders.find((l) => l.id === firstPageEntry.lenderNumber)
      const lenderName = lender ? lender.name : ''

      // Page header section
      reportPdf.setFontSize(10)
      reportPdf.text(`${firstPageEntry.lenderNumber} ${lenderName}`, 10, cursor += 5)
      reportPdf.text('CAPITAL REAL ESTATE TAX SERVICES, INC.', reportPdf.internal.pageSize.width - margin, cursor, { align: 'right' })
      reportPdf.setFontStyle('bold')
      reportPdf.text((this.title && this.title !== '') ? this.title : `${DateTime.local().toFormat('yyyy')} MICHIGAN DELINQUENT TAX SEARCH`, reportPdf.internal.pageSize.width / 2, cursor += 5, { align: 'center' })
      reportPdf.setFontSize(14)
      reportPdf.text('TAX DATA SHEET', reportPdf.internal.pageSize.width / 2, cursor += 5, { align: 'center' })

      // Table header section
      cursor += 5
      reportPdf.line(xLineMargin, cursor, reportPdf.internal.pageSize.getWidth() - xLineMargin, cursor)
      cursor += 5

      const loanNumber = `LOAN # ${firstPageEntry.loanNumber}`
      const parcelNumber = `PARCEL # ${firstPageEntry.parcelNumber}`
      const status = pageIsDelq ? 'DELQ' : 'PAID'
      // reportPdf.text(`${loanNumber}  ${parcelNumber}  ${status}`, margin, cursor += 5)
      reportPdf.text(loanNumber, margin, cursor += 5)
      reportPdf.text(parcelNumber, reportPdf.internal.pageSize.getWidth() / 2, cursor, { align: 'center' })
      reportPdf.text(status, reportPdf.internal.pageSize.getWidth() - margin, cursor, { align: 'right' })

      // Create the first table in a page
      autoTable(reportPdf, {
        columns: this.parcelColumnDefs.map((header) => ({
          dataKey: header.field,
          header: header.headerName,
        })),
        styles: {
          fontSize: 8,
          cellWidth: 'wrap',
        },
        bodyStyles: {
          fontSize: 8,
          cellWidth: 'auto',
        },
        body: [firstPageEntry].map((row) => row as any),
        //      theme: 'grid',
        startY: cursor += 5,
        margin: 10,
        tableWidth: 'auto',
        didDrawPage: ((data: any) => {
          cursor = data.cursor.y
        }),
      })

      // Create the second table in a page
      autoTable(reportPdf, {
        columns: this.taxOfficeColumnDefs.map((header) => ({
          dataKey: header.field,
          header: header.headerName,
        })),
        styles: {
          fontSize: 8,
          cellWidth: 'wrap',
        },
        bodyStyles: {
          fontSize: 8,
          cellWidth: 'auto',
        },
        body: [firstPageEntry].map((row) => {
          const summaryData: any = cloneDeep(row)
          summaryData.allAgencyCounties = reduce(agencyGroups, (fullString, agencyGroup) => {
            const agencyCounty = agencyGroup[0].address
              ? `${agencyGroup[0].county}, ${agencyGroup[0].state}`
              : null

            if (agencyCounty) {
              return `${fullString}${fullString ? '\n' : ''}${agencyCounty}`
            }

            return fullString
          }, '')

          summaryData.allAgencyNames = reduce(dtcoGroups, (fullString, dtcoGroup) => {
            const { name } = dtcoGroup[0].dtco

            if (name) {
              return `${fullString}${fullString ? '\n' : ''}${name}`
            }

            return fullString
          }, '')

          return summaryData
        }),
        //      theme: 'grid',
        startY: cursor += 5,
        margin: 10,
        tableWidth: 'auto',
        didDrawPage: ((data) => {
          cursor = data.cursor.y
        }),
      })

      reportPdf.setFontStyle('bold')
      cursor += 5
      reportPdf.line(xLineMargin, cursor, reportPdf.internal.pageSize.getWidth() - xLineMargin, cursor)
      cursor += 5
      reportPdf.setFontStyle('regular')

      // Sort the histories by year
      page.sort((a, b) => b.year.localeCompare(a.year))

      // Create the histories tables
      each(dtcoGroups, (agencyGroup) => {
        reportPdf.setFontStyle('bold')
        reportPdf.text(`${agencyGroup[0].dtco.name} - ${agencyGroup[0].dtco.capAgency}`, margin, cursor += 5)
        reportPdf.setFontStyle('regular')
        autoTable(reportPdf, {
          columns: this.historyColumnDefs.map((header) => {
            if (header.valueFormatter) {
              return {
                dataKey: header.field,
                header: header.headerName,
              }
            }
            return {
              dataKey: header.field,
              header: header.headerName,
            }
          }),
          styles: {
            fontSize: 8,
            cellWidth: 'wrap',
          },
          bodyStyles: {
            fontSize: 8,
            cellWidth: 'auto',
          },
          body: uniqBy(agencyGroup.map((row) => {
            const entry: any = cloneDeep(row)
            entry.amountDue = formatCurrency(entry.amountDue)
            entry.base = formatCurrency(entry.base)
            entry.penalties = formatCurrency(entry.penalties)
            return entry
          }), 'year').sort((a, b) => (a.year > b.year ? -1 : 1)),
          //      theme: 'grid',
          startY: cursor += 5,
          margin,
          tableWidth: 'auto',
          didDrawPage: ((data) => {
            cursor = data.cursor.y
          }),
        })

        cursor += 5
      })

      // Start a new page if not out of data yet
      if (index !== Object.values(this.pages).length - 1) {
        reportPdf.addPage()
      }
    })

    reportPdf.save(`TaxDataSheet-${DateTime.local().toFormat('F')}.pdf`)
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_desc: false,
    }
    try {
      const lenderSummary = await this.lenderService.getAllLenders(params)
      this.lenders = lenderSummary.lenders
      this.lenders.forEach((lender) => {
        lender.displayString = `${lender.name} - ${lender.id}`
      })
      this.isLoading = false
    } catch (e) {
      console.log(e)
      this.lenders = []
    }
  }
}
