













import {
  Component, Prop, Emit, Vue, Watch,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { DataOptions } from 'vuetify';

@Component({
  name: 'date-field-optional',
})
export default class DateFieldOptional extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  readonly noYear!: boolean;
  @Prop({
    type: Boolean,
    default: () => false,
  })
  readonly readonly!: boolean;
  @Prop({
    type: String,
    default: () => '',
  })
  readonly value!: string;
  @Prop({
    type: String,
    default: () => 'MM/DD/YYYY',
  })
  readonly label!: string;
  private rules: any = {
    validDate(value: string, noYear: any) {
      if (value === undefined || value === '') return true;
      const isValid = (DateTime.fromFormat(value, this.noYear ? 'MM/dd' : 'MM/dd/yyyy').isValid
      && DateTime.fromFormat(value, this.noYear ? 'MM/dd' : 'MM/dd/yyyy').year >= 1582)
      || `Must be a valid ${this.noYear ? 'MM/DD' : 'MM/DD/YYYY'} date`
      return isValid;
    },
  };
  private dateStr: any = '';
  private dateTimeout: any = null;
  async mounted() {
    this.updateDate();
  }
  updateDate() {
    function emitDate(that: any, updateDate: string | undefined) {
      clearTimeout(that.dateTimeout);
      that.dateTimeout = setTimeout(() => {
        that.dateStr = updateDate;
        that.$emit('input', updateDate);
        that.$emit('change', updateDate);
      }, 0);
    }

    // separate date components
    if (!this.dateStr) {
      emitDate(this, undefined);
      return;
    }
    let monthStr = '';
    let dateStr = '';
    let yearStr = '';
    let dateStrCopy: any = this.dateStr || '';
    // add slahses in proper places if not present
    if (!dateStrCopy.includes('/') && dateStrCopy.length === 3) {
      dateStrCopy = `${dateStrCopy.slice(0, 2)}/${dateStrCopy.slice(2)}`;
    }
    if (!this.noYear && dateStrCopy.length > 5 && !dateStrCopy.slice(4).includes('/')) {
      dateStrCopy = `${dateStrCopy.slice(0, 5)}/${dateStrCopy.slice(5)}`;
    }
    const dateSplitRes = dateStrCopy.split('/');
    if (dateSplitRes.length >= 1) {
      [monthStr] = dateSplitRes;
      monthStr = monthStr.slice(0, 2);
    }
    if (dateSplitRes.length >= 2) {
      [, dateStr] = dateSplitRes;
      dateStr = dateStr.slice(0, 2);
    }
    if (!this.noYear && dateSplitRes.length >= 3) {
      [, , yearStr] = dateSplitRes;
      yearStr = yearStr.slice(0, 4);
    }
    // validate date
    const month = Number(monthStr);
    const date = Number(dateStr);
    const year = Number(yearStr);
    // validate each character by character...
    let dateStrUpdate: any = '';
    let monthValidatedStr = '';
    let dateValidatedStr = '';
    let yearValidatedStr = '';

    // month validation
    if (monthStr && monthStr.length) {
      monthValidatedStr = monthStr;
    }
    if (dateStr && dateStr.length) {
      dateValidatedStr = dateStr;
    }
    if (!this.noYear && yearStr && yearStr.length) {
      yearValidatedStr = yearStr;
    }
    if (monthValidatedStr.length) {
      dateStrUpdate = monthValidatedStr;
    }
    if (dateValidatedStr.length) {
      dateStrUpdate += `/${dateValidatedStr}`;
    }
    if (!this.noYear && yearValidatedStr.length) {
      dateStrUpdate += `/${yearValidatedStr}`;
    }
    if (dateStrUpdate[dateStrUpdate.length - 1] === '/') {
      dateStrUpdate = dateStrUpdate.slice(0, dateStrUpdate.length - 1);
    }
    emitDate(this, dateStrUpdate);
  }

  @Watch('dateStr', { deep: true })
  async onDateStrChanged(e: DataOptions, oldE: DataOptions) {
    if (e !== oldE) {
      this.updateDate();
    }
  }
}
