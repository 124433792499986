import { DateTime, Zone } from 'luxon';

import User from '@/entities/User';
import HistoryEntry from '@/entities/history/HistoryEntry';
import IServiceTransaction from '@/services/api/models/IServiceTransaction';

export enum TransactionOperation {
  create = 'created',
  patch = 'updated',
  import = 'imported',
  delete = 'deleted',
  other = 'did something with',
}

export enum TransactionType {
  PATCH,
  'Batch PATCH',
  IMPORT,
  DELETE,
  CREATE,
}

export default class Transaction {
  id: string;
  timestamp: Date;
  type: TransactionOperation;
  description: string;
  rolledBack: boolean;
  user?: User;
  history: HistoryEntry[] = [];

  constructor(serviceObj: IServiceTransaction) {
    this.id = serviceObj.id;
    this.timestamp = DateTime.fromISO(serviceObj.timestamp).toLocal().toJSDate();
    this.description = serviceObj.description;
    this.rolledBack = serviceObj.rolled_back;
    this.user = serviceObj.person
      ? new User(serviceObj.person.id, serviceObj.person.given_name, serviceObj.person.family_name)
      : undefined;

    switch (serviceObj.type) {
      case 'PATCH':
      case 'Batch PATCH':
        this.type = TransactionOperation.patch;
        break;

      case 'IMPORT':
        this.type = TransactionOperation.import;
        break;

      case 'DELETE':
        this.type = TransactionOperation.delete;
        break;

      case 'agency add':
      case 'lender add':
      case 'loan add':
      case 'CREATE':
        this.type = TransactionOperation.create;
        break;

      default:
        this.type = TransactionOperation.other;
        break;
    }

    if (serviceObj.history && serviceObj.history.length > 0) {
      this.history = serviceObj.history.map((entry) => new HistoryEntry(entry));
    }
  }

  static getTypes(): string[] {
    return Object.keys(TransactionType)
      .filter((k) => typeof TransactionType[k as keyof typeof TransactionType] === 'string')
      .map((k) => TransactionType[k as keyof typeof TransactionType].toString());
  }

  toString() {
    const userString = this.user ? `${this.user.givenName} ${this.user.familyName}` : 'A user';
    return `${userString} ${this.type} ${this.description}`;
  }
}
