
















import { Vue, Component } from 'vue-property-decorator';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  name: 'combobox-editor',
})
export default class ComboboxEditor extends Vue {
  private params: ICellEditorParams = null;
  private value: string = '';

  private isCleared: boolean = false;
  private debounced: boolean = false;

  // Lifecycle hooks
  created() {
    if (this.params.charPress) {
      this.value = this.params.charPress;
    } else {
      this.value = this.params.value;
    }

    setTimeout(() => {
      this.debounced = true;
    }, 250);
  }

  // Events
  inputHandler(value: string) {
    this.isCleared = value === null;
  }

  enterHandler() {
    if (this.debounced) {
      this.params.stopEditing();
    }
  }

  // Editor
  afterGuiAttached() {
    ((this.$refs.combobox as Vue).$refs.input as HTMLInputElement).click();
  }

  getValue() {
    if (this.isCleared) {
      return null;
    }

    return this.value ? this.value : this.params.value;
  }
}
