import { DateTime } from 'luxon';

export function fileSizeValidator(size: number, errorMessage?: string): (files: File[] | File) => boolean | string {
  return (files: File[] | File) => {
    const fileList = Array.isArray(files) ? files : [files];

    if (!fileList) return true;
    for (let fileIndex = 0; fileIndex < fileList.length; fileIndex += 1) {
      const file = fileList[fileIndex];
      if (file.size > size) {
        return errorMessage || 'File is too big.';
      }
    }

    return true;
  }
}

const fullDate = (value: any) => (!value) || (value.length === 10 && DateTime.fromFormat(value, 'MM/dd/yyyy').isValid) || 'Invalid Date.';
const shortDate = (value: any) => (!value) || (value.length === 5 && DateTime.fromFormat(value, 'MM/dd').isValid) || 'Invalid Date.';

export { fileSizeValidator as default, fullDate, shortDate };
