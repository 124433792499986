






























import {
  Component, Watch, Vue, Emit, Prop,
} from 'vue-property-decorator';
import { debounce } from 'lodash';

import AgencyService from '@/services/agencies';

import Agency from '@/entities/Agency';

@Component({
  name: 'agency-search',
})
export default class AgencyCountySearch extends Vue {
  // Props
  @Prop() states: string[] | undefined;

  // Services
  private service: AgencyService = new AgencyService();

  private agencyCountySearchText: string = '';
  private agencyDebounce: Function = null;
  private stateDebounce: Function = null;
  private foundAgencies: Agency[] = [];
  private selectedAgencies: {}[] = [];

  private isSearching: boolean = false;

  // Watchers
  @Watch('agencyCountySearchText')
  async onAgencyCountySearchTextChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.agencyDebounce) {
      this.agencyDebounce = debounce(async () => {
        let searchField = 'county_and_name';
        if (this.states && this.states.length > 0) {
          searchField = 'county_and_name_states';
        }

        const response = await this.service.getAllAgencies({
          search_field: searchField,
          search_value: this.agencyCountySearchText,
          state_value: this.states,
          limit: 100,
          agency_selector_search: true,
        });

        this.foundAgencies = response.results;
      }, 500);
    }

    this.agencyDebounce();
  }

  // Methods
  determineAgencyName(item: Agency): string {
    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString = `${item.address.value.county}, COUNTY, ${item.address.value.state}`;
    }

    return nameString;
  }

  remove(item: any) {
    const newValue = [...this.selectedAgencies];
    newValue.splice(
      this.selectedAgencies.findIndex((value) => value === item),
      1,
    );

    this.update(newValue);
  }

  @Emit('input')
  update(items: any) {
    this.selectedAgencies = items;
    return this.selectedAgencies;
  }
}
