import Axios, { AxiosResponse, CancelToken } from 'axios';

import IServiceAgency from '@/services/api/models/IServiceAgency';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import EntityCountResponse from './models/EntityCountResponse';

interface ServicesResponse {
  count: any,
  agencies: IServiceAgency[],
}

export default {
  async getAllAgencies(params: any, cancelToken?: CancelToken): Promise<ServicesResponse> {
    const config = {
      params,
    };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<ServicesResponse>('/agencies', config);

    return response.data;
  },

  async getTotalAgencies(params: any, cancelToken?: CancelToken): Promise<EntityCountResponse> {
    const config = {
      params,
    };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<EntityCountResponse>('/agencies/count', config);

    return response.data;
  },

  async getAgency(id: string, cancelToken?: CancelToken): Promise<AxiosResponse> {
    const config = {};
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    return Axios.get<IServiceAgency>(`/agencies/${id}`, config);
  },

  async updateAgency(id: string, payload: JsonPatchPayload, etag: string): Promise<AxiosResponse> {
    const response = await Axios.patch<IServiceAgency>(`/agencies/${id}`, payload, {
      headers: {
        'If-Match': etag,
      },
    });
    return response;
  },

  async addAgency(payload: any): Promise<any> {
    const response = await Axios.post<IServiceAgency, AxiosResponse<IServiceAgency>>('/agencies', payload);
    return response.data;
  },

  async uploadFile(id: string, formData: FormData): Promise<void> {
    await Axios.post<void, AxiosResponse<void>>(`/agencies/${id}/files`, formData);
  },

  async deleteAgency(id: string): Promise<any> {
    const response = await Axios.delete<any>(`/agencies/${id}`);

    return response.data;
  },

  async deleteFile(id: string, name: string): Promise<void> {
    await Axios.delete<void, AxiosResponse<void>>(`/agencies/${id}/files/${name}`);
  },

  async batchPatchAgencies(payload: JsonPatchPayload): Promise<void> {
    const response = await Axios.patch<void>('/agencies', payload);
    return response.data;
  },
};
