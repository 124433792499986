import Transaction from '@/entities/history/Transaction';
import HistoryApiService from '@/services/api/HistoryApiService';

interface TransactionCollection {
  count: number,
  transactions: Transaction[],
}

interface HistoryParams {
  limit: number,
  offset: number,
  users?: string | string[],
  date_range?: [string, string],
  transaction_types?: string | string[],
  history_types?: string | string[],
  tables?: string | string[],
  q?: string,
  entity_ids?: string | string[],
  entity_info?: boolean,
}

class HistoryService {
  async getFullHistory(params: HistoryParams): Promise<TransactionCollection> {
    const response = await HistoryApiService.getFullHistory(params);

    return {
      count: parseInt(response.count, 10),
      transactions: response.transactions.map((transaction) => new Transaction(transaction)),
    }
  }

  async attemptRollback(entry: Transaction): Promise<any> {
    let response;
    try {
      response = await HistoryApiService.attemptRollback({
        transaction_id: entry.id,
      });
    } catch (e) {
      console.log(e);
    }

    return response;
  }
}

export { HistoryService as default };
