import ImportApiService, { ImportResult } from '@/services/api/ImportApiService';

class ImportService {
  async postPartialFileImport(rows: any[], fileName: string, preview: boolean = false, remove: boolean = false): Promise<ImportResult> {
    return remove ? ImportApiService.postPartialDeleteImport({
      rows,
      file_name: fileName,
    }, preview) : ImportApiService.postPartialAddImport({
      rows,
      file_name: fileName,
    }, preview);
  }

  async postFullFileImport(rows: any[], fileName: string, preview: boolean = false): Promise<ImportResult> {
    return ImportApiService.postFullFileImport({
      file_name: fileName,
      rows,
    }, preview);
  }
}

export { ImportService as default };
