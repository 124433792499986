



























































import {
  Component,
  Emit,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';

import Transaction from '@/entities/history/Transaction';

@Component({
  name: 'history-list',
})
export default class HistoryList extends Vue {
  @Prop({
    type: Array,
    default: () => [] as Transaction[],
  }) private readonly value!: Transaction[];

  @Prop({
    type: Object,
    default: () => {},
  }) private readonly options!: Partial<DataOptions>;

  @Prop({
    type: Number,
    default: 0,
  }) private readonly totalItems!: number;

  private activeEntry?: Transaction;

  // Table/iterator controls
  private itemsPerPage: number = 250;

  @Watch('options', { deep: true, immediate: true })
  async onOptionsChanged(options: DataOptions, oldOptions: DataOptions) {
    console.log(options)
    this.$emit('update:options', options);
  }

  @Emit('rollback')
  async triggerRollback(entry?: Transaction) {
    const targetEntry = entry || this.activeEntry;
    this.activeEntry = targetEntry;

    return targetEntry;
  }
}
