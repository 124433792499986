














































































































import Axios, { AxiosResponse, CancelToken } from 'axios';
import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { DateTime } from 'luxon';
import jsPDF from 'jspdf';
import { ColDef, SortChangedEvent } from 'ag-grid-community';
import { DataTableHeader, InputValidationRule } from 'vuetify';

import EscrowHistoryService from '@/services/escrowHistories';
import ParcelService from '@/services/parcels';

import { inchesToMM } from '@/helpers/reportHelpers';

import Lender from '@/entities/Lender';
import Term from '@/entities/Term';
import EscrowHistory from '@/entities/EscrowHistory';
import { ICollectingSchedule } from '@/entities/IAgency';
import Parcel from '@/entities/Parcel';

import states from '@/data/states';

import AgencySearch from '@/views/agencies/AgencySearch.vue';
import LenderSearch from '@/views/lenders/LenderSearch.vue';

import DateField from '@/components/inputs/dates/DateField.vue';
import { fullDate } from '@/validations';
import EscrowHistoryCollection from '@/services/escrowHistories/EscrowHistoryCollection';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue'
import SsrmGridReport from './SsrmGridReport.vue'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ReportName from './models/ReportName'
import ReportDatasource from './ag-grid/datasource/ReportDatasource';
import ExportDataParams from './models/ExportDataParams'
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams'
import quickSearchParams from './ag-grid/params/quickSearchParams'
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams'
import ReportDatasourceParamBuilder from './ag-grid/datasource/builder/ReportDatasourceParamBuilder'

interface ReportRow {
  lenderId?: string,
  lenderNumber: string,
  loanId: string,
  loanNumber: string,
  loanType: string,
  name: string,
  address: string,
  lenderName: string,
  lenderAddress1: string,
  lenderAddress2: string,
  lenderShortAddress: string,
  agencyId: string,
  agencyNumber: string,
  agencyName: string,
  agencyCounty: string,
  agencyState: string,
  agencyShortAddress: string,
  agencyAddressLine1: string,
  agencyAddressLine2: string,
  agencyAddressLine3: string,
  year: string,
  term: string,
  parcelAgencyId: string,
  parcelId: string,
  parcelNumber: string,
  reportedDate: string,
  amountReported: number,
  dueDate: string,
  legalDescription: string,
  billNumber: string,
  block: string,
  lot: string,
  unit: string,
  noLenderDueDate: string,
  lenderDueDate: string,
  altParcelNumber: string,

  existing: boolean,
}

interface PageGroup { [index: string]: ReportRow[] }

@Component({
  name: 'escrow-memo-bill-report',
  components: {
    DateField,
    AgGridVue,
    SsrmGridOmnifilter,
    LenderSearch,
    AgencySearch,
  },
})
export default class EscrowMemoBillReport extends SsrmGridReport<Parcel, ReportRow> {
  // Parent overrides
  protected pageSizes = [500, 10000];

  // Indicators
  private pages: PageGroup = {}
  private isDoneLoading = false;
  private isGeneratingPDF = false;
  private generatePDFText = 'Create PDF';

  // Services
  // private service: EscrowHistoryService = new EscrowHistoryService();
  private service: ParcelService = new ParcelService();

  // User options
  private states: DataTableHeader[] = states;
  private escrowYearItems: string[] = [];
  private termItems: any[] = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));
  private reportedDateMode: 'all' | 'null' | 'notNull' | 'specificDate' | 'dateRange' = 'all';

  // User inputs
  private pickedState: string = '';
  private year: string = '';
  private term: string = '';
  private reportedDates: Date[] = [];
  private loanNumbersString: string = '';

  private selectedLenders: Lender[] = [];
  private selectedAgencies: string[] = [];
  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  // Input rules
  private rules: { [index: string]: InputValidationRule } = {
    validDate: fullDate,
  };

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan E/N',
      field: 'loanType',
      width: 100,
    },
    {
      headerName: 'Name',
      field: 'name',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Term',
      field: 'term',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Taxes Due',
      field: 'amountReported',
      sortable: false,
      editable: true,
      type: 'currency',
      flex: 1,
    },
    {
      ...quickSearchParams,
    },
  ];

  private rowClassRules: any = {
    'new-row': (params: any) => {
      if (params.data) {
        return !params.data.existing
      }
      return false
    },
    'old-row': (params: any) => {
      if (params.data) {
        return params.data.existing
      }
      return false
    },
  };

  // Computed
  get advancedSearch() {
    const advancedSearch: any = {
      parcel_type_in: ['E', 'EN'],
      report_date: this.reportedDateMode,
      history_year_in: [this.year],
      history_term_in: [this.term],
      return_agency_collecting_scehdule: true,
    };

    if (this.selectedLenders && this.selectedLenders.length !== 0) {
      advancedSearch.lender_numbers = this.selectedLenders.map((lender) => lender.id);
    }

    if (this.selectedAgencies && this.selectedAgencies.length !== 0) {
      advancedSearch.agency_numbers = this.selectedAgencies.map((a: any) => a.capAgency);
    }

    if (this.loanNumbersTextIsntBlank) {
      advancedSearch.loan_numbers = this.loanNumbersString.split(',').map((s) => s.trim());
    }

    switch (this.reportedDateMode) {
      case 'specificDate':
        [advancedSearch.specific_date] = this.reportedDates;
        break;

      case 'dateRange':
        [advancedSearch.start_date, advancedSearch.end_date] = this.reportedDates;
        break;

      default:
        break;
    }

    return advancedSearch;
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 2; i >= 0; i -= 1) {
      const relevantYear = currentYear + i - 1;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [this.year] = this.escrowYearItems;
    this.term = this.termItems[1].value;

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = currentYear - i;
      this.escrowYearItems.push(relevantYear.toString());
    }
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params)
  }

  get loanNumbersTextIsntBlank(): boolean {
    return Boolean(this.loanNumbersString) && this.loanNumbersString !== '';
  }

  refreshRows() {
    this.latestResults = []
    this.results = []
    this.pages = {}
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    const {
      onSortModelChanged,
      rowFetcherParams,
      httpRequestParams,
    } = new ReportDatasourceParamBuilder<Parcel, ReportRow>(
      ReportName.EscrowMemoBill,
      // this.service.getAllEscrowHistories,
      // this.service.getTotalEscrowHistories,
      this.service.getAllParcels,
      this.service.getTotalParcels,
      this.sortModel,
      this.onResultsChanged,
      this.getParams,
    ).build()
    return new ReportDatasource<Parcel, ReportRow>(
      onSortModelChanged,
      this.setLoading,
      this.resetLoading,
      rowFetcherParams,
      httpRequestParams,
    )
  }

  convertResults(results: Parcel[]): ReportRow[] {
    // this.recalculateTotalTaxesDue()
    const getDueDate = (collectingSchedule: ICollectingSchedule, lenderId: string = null) => {
      const collectingSchedulesForTerm = collectingSchedule.filter((s) => s.term === this.term);
      const qualifyingCollectingSchedule = collectingSchedulesForTerm.find((s) => s.lenderId === lenderId);

      return qualifyingCollectingSchedule && qualifyingCollectingSchedule.dueDate && qualifyingCollectingSchedule.dueDate.value ? qualifyingCollectingSchedule.dueDate.value : '';
    }

    return results.reduce<ReportRow[]>((allSummaries, parcel) => {
      // Build the parcel summaries to show the user
      // Every parcel agency remaining matches term picked
      parcel.agencies.forEach((agency) => {
        const scheduleEntry = agency.collectingSchedule.find((entry) => entry.term === this.term);
        if (agency.escrowHistory.length === 0) {
          allSummaries.push({
            loanId: parcel.loanId,
            parcelId: parcel.parcelId,
            agencyId: agency.agencyId,
            parcelAgencyId: agency.parcelAgencyId,

            lenderNumber: parcel.lenderNumber,
            lenderShortAddress: `${parcel.lenderCity ? `${parcel.lenderCity}, ` : ''}${parcel.lenderState || ''} ${parcel.lenderZipCode || ''}`,
            loanNumber: parcel.loanNumber,
            loanType: parcel.parcelType,
            name: parcel.name,
            address: parcel.address.value.address1,
            agencyNumber: agency.capAgency,
            agencyName: agency.name,
            agencyCounty: agency.address && agency.address.value ? agency.address.value.county : '',
            agencyState: agency.address && agency.address.value ? agency.address.value.state : '',
            year: this.year,
            // eslint-disable-next-line
            term: Term[this.term as keyof typeof Term],
            parcelNumber: parcel.parcelNumber,
            altParcelNumber: parcel.altParcelNumber,
            reportedDate: null,
            dueDate: scheduleEntry ? scheduleEntry.dueDate.value : null,
            amountReported: null,
            noLenderDueDate: getDueDate(agency.collectingSchedule),
            lenderDueDate: getDueDate(agency.collectingSchedule, parcel.lenderId),

            lenderId: parcel.lenderId,
            lenderName: parcel.lenderName,
            lenderAddress1: parcel.lenderAddress1,
            lenderAddress2: parcel.lenderAddress2,
            agencyShortAddress: `${agency.address.value.city ? `${agency.address.value.city}, ` : ''}${agency.address.value.state || ''} ${agency.address.value.zipCode || ''}`,
            agencyAddressLine1: agency.address.value.address1,
            agencyAddressLine2: agency.address.value.address2 ? agency.address.value.address2 : `${agency.address.value.city ? `${agency.address.value.city}, ` : ''}${agency.address.value.state || ''} ${agency.address.value.zipCode || ''}`,
            agencyAddressLine3: agency.address.value.address2 ? `${agency.address.value.city ? `${agency.address.value.city}, ` : ''}${agency.address.value.state || ''} ${agency.address.value.zipCode || ''}` : null,
            legalDescription: parcel.legal,
            billNumber: parcel.billNumber,
            block: parcel.address.value.block,
            lot: parcel.address.value.lot,
            unit: parcel.address.value.unit,

            existing: false,
          });

          return;
        }

        agency.escrowHistory.forEach((escrowHistory) => {
          allSummaries.push({
            loanId: parcel.loanId,
            parcelId: parcel.parcelId,
            agencyId: agency.agencyId,
            parcelAgencyId: agency.parcelAgencyId,

            lenderShortAddress: `${parcel.lenderCity ? `${parcel.lenderCity}, ` : ''}${parcel.lenderState || ''} ${parcel.lenderZipCode || ''}`,
            lenderNumber: parcel.lenderNumber,
            loanNumber: parcel.loanNumber,
            loanType: parcel.parcelType,
            name: parcel.name,
            address: parcel.address.value.address1,
            agencyNumber: agency.capAgency,
            agencyName: agency.name,
            agencyCounty: agency.address && agency.address.value ? agency.address.value.county : '',
            agencyState: agency.address && agency.address.value ? agency.address.value.state : '',
            year: this.year,
            // eslint-disable-next-line
            term: Term[this.term as keyof typeof Term],
            parcelNumber: parcel.parcelNumber,
            altParcelNumber: parcel.altParcelNumber,
            reportedDate: escrowHistory.reportedDate,
            dueDate: escrowHistory.dueDate,
            amountReported: escrowHistory.amountReported,
            noLenderDueDate: getDueDate(agency.collectingSchedule),
            lenderDueDate: getDueDate(agency.collectingSchedule, parcel.lenderId),

            lenderId: parcel.lenderId,
            lenderName: parcel.lenderName,
            lenderAddress1: parcel.lenderAddress1,
            lenderAddress2: parcel.lenderAddress2,
            agencyShortAddress: `${agency.address.value.city ? `${agency.address.value.city}, ` : ''}${agency.address.value.state || ''} ${agency.address.value.zipCode || ''}`,
            agencyAddressLine1: agency.address.value.address1,
            agencyAddressLine2: agency.address.value.address2 ? agency.address.value.address2 : `${agency.address.value.city ? `${agency.address.value.city}, ` : ''}${agency.address.value.state || ''} ${agency.address.value.zipCode || ''}`,
            agencyAddressLine3: agency.address.value.address2 ? `${agency.address.value.city ? `${agency.address.value.city}, ` : ''}${agency.address.value.state || ''} ${agency.address.value.zipCode || ''}` : null,
            legalDescription: parcel.legal,
            block: parcel.address.value.block,
            lot: parcel.address.value.lot,
            unit: parcel.address.value.unit,
            billNumber: parcel.billNumber,

            existing: true,
          });
        });
      });

      return allSummaries;
    }, []);
  }

  getParams() {
    return {
      include_agency: true,
      advanced_search: this.advancedSearch,
      search_type: 'escrow_history',
    };
  }

  drawRectangle(pdf: jsPDF, x: number, y: number, w: number, h: number) {
    pdf.line(x, y, x, y + h); // left
    pdf.line(x + w, y, x + w, y + h); // right
    pdf.line(x, y, x + w, y); // top
    pdf.line(x, y + h, x + w, y + h); // bottom
  }

  drawPerforatedLines(pdf: jsPDF) {
    const y1 = inchesToMM(3.75);
    const y2 = y1 + inchesToMM(3.375);
    for (let i = 0; i < 63; i += 1) {
      const x1 = inchesToMM(0.375 + (i * 0.125));
      const x2 = x1 + inchesToMM(0.0625);
      pdf.line(x1, y1, x2, y1);
      pdf.line(x1, y2, x2, y2)
    }
  }

  async exportTable() {
    this.exportReportTable(new ExportDataParams({
      file: 'EscrowMemoBillSheet',
    }), this.service.getAllParcels);
  }

  createPDF() {
    this.generatePDFText = 'Creating File';
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally(this.results);
      this.generatePDFOnServer();
      this.generatePDFText = 'Create PDF';
      this.gridApi.hideOverlay();
    }, 100);
  }
  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllParcels(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    console.log(newResults)
    const res = await this.exportPdf(
      newResults,
      '/pdf/escrow-memo-bill-report',
      {
        rows: JSON.stringify(newResults),
      },
      'results',
    );
    this.isGeneratingPDF = false;
    return window.open(res.data.link, '_blank');
  }

  generatePDFLocally(rows: any) {
    // currently only generating on server
    // keeping this here for the note that switching endpoints broke this function
    // so if we need to generate locally again in the future, this we need to rewriten
  }
}
