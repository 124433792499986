








































































































































































import {
  Component, Watch,
} from 'vue-property-decorator';
import jsPDF from 'jspdf';
import { AgGridVue } from 'ag-grid-vue';
import { CellValueChangedEvent, ColDef, SortChangedEvent } from 'ag-grid-community';
import { DataTableHeader } from 'vuetify';
import { cloneDeep } from 'lodash';

import AgencyService from '@/services/agencies';

import { inchesToMM } from '@/helpers/reportHelpers';

import Agency from '@/entities/Agency';
import Lender from '@/entities/Lender';

import states from '@/data/states';

import AgencySearch from '@/views/agencies/AgencySearch.vue';
import LenderSearch from '@/views/lenders/LenderSearch.vue';

import SimpleChipAutocomplete from '@/components/inputs/SimpleChipAutocomplete.vue';
import ReportedDateSelection, { ReportedDateOption } from '@/components/inputs/queries/ReportedDateSelection.vue';

import Term from '@/entities/Term';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import ExportDataParams from './models/ExportDataParams';
import SsrmGridReport from './SsrmGridReport.vue';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import ReportName from './models/ReportName';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';
import quickSearchParams from './ag-grid/params/quickSearchParams';

interface LabelRow {
  agency_number: string,
  attn_line: string,
  name: string,
  street_1: string,
  street_2: string,
  city: string,
  state: string,
  zip_code: string,
}

@Component({
  name: 'agency-label-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
    ReportedDateSelection,
    AgencySearch,
    LenderSearch,
    SimpleChipAutocomplete,
  },
})
export default class AgencyLabelReport extends SsrmGridReport<Agency, LabelRow> {
  private generatePDFText = 'Generate Labels';
  private isGeneratingPDF: boolean = false;
  private pullAgenciesByState: Boolean = true;
  private attnLineDialog: boolean = false;
  private overwriteCheckbox: boolean = false;
  private attnLine: string = '';

  private pickedAgencies: Agency[] = [];

  private states: DataTableHeader[] = states;
  private termItems: { text: string, value: string }[] = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  private agencies: Agency[] = [];
  private latestAgencies: Agency[] = [];

  private agencyService: AgencyService = new AgencyService();

  // User options
  private escrowYearItems: string[] = [];

  private selectedLenders: Lender[] = [];

  private dateOption: ReportedDateOption = {
    reported_date_mode: 'all',
  };
  private parcelType: 'e_and_en' | 'n_and_en' = 'e_and_en';
  private term: string = 'all';
  private pickedStates: string[] = [];

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Agency Code',
      field: 'agency_number',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'ATTN',
      field: 'attn_line',
      editable: true,
      // ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency Name',
      field: 'name',
      editable: true,
      width: 250,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address 1',
      field: 'street_1',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address 2',
      field: 'street_2',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'City',
      field: 'city',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'State',
      field: 'state',
      width: 100,
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'ZIP',
      field: 'zip_code',
      editable: true,
      flex: 1,
      minWidth: 100,
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  @Watch('latestAgencies')
  onAgenciesChanged(val: Agency[]) {
    const newResults = val.map((agency: Agency) => {
      const agencyRow = {
        agency_number: agency.capAgency,
        attn_line: agency.payableTo,
        name: agency.name,
        street_1: agency.address.value.address1,
        street_2: agency.address.value.address2,
        city: agency.address.value.city,
        state: agency.address.value.state,
        zip_code: agency.address.value.zipCode,
      }

      if (this.parcelType === 'n_and_en') {
        agencyRow.attn_line = agency.delinquentTaxCollectingOffice.payableTo;
        agencyRow.street_1 = agency.delinquentTaxCollectingOffice.address1;
        agencyRow.street_2 = agency.delinquentTaxCollectingOffice.address2;
        agencyRow.city = agency.delinquentTaxCollectingOffice.city;
        agencyRow.state = agency.delinquentTaxCollectingOffice.state;
        agencyRow.zip_code = agency.delinquentTaxCollectingOffice.zipCode;
      }

      return agencyRow;
    });

    this.results = newResults;

    if (this.results.length > 0) {
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // Computed
  get hasRequiredInput(): boolean {
    if (this.pullAgenciesByState && this.pickedStates.length === 0) {
      return false;
    }

    if (!this.pullAgenciesByState && this.pickedAgencies.length === 0) {
      return false;
    }

    return true;
  }

  get advancedSearch() {
    const advancedSearch: any = {
      parcel_type: this.parcelType === 'e_and_en' ? ['E', 'EN'] : ['N', 'EN'],
    };

    if (this.pullAgenciesByState) {
      advancedSearch.states = this.pickedStates;
    } else {
      advancedSearch.agency_ids = this.pickedAgencies.map((a) => a.agencyId);
    }

    if (this.selectedLenders && this.selectedLenders.length !== 0) {
      advancedSearch.lender_numbers = this.selectedLenders.map((selectedLender) => selectedLender.id);
    } else if (this.isLenderUser) {
      advancedSearch.lender_numbers = [this.user.lenders[0].lenderNumber];
    }

    // Report date handling
    advancedSearch.reported_date_mode = this.dateOption.reported_date_mode;

    if (this.dateOption.reported_date_mode === 'between' || this.dateOption.reported_date_mode === 'equal') {
      advancedSearch.reported_dates = this.dateOption.reported_dates;
    }

    if (this.term !== 'all' && this.parcelType === 'e_and_en') {
      advancedSearch.collecting_schedule_term_in = [this.term];
    }

    return advancedSearch;
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 2; i >= 0; i -= 1) {
      const relevantYear = currentYear + i - 1;
      this.escrowYearItems.push(relevantYear.toString());
    }

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = currentYear - i;
      this.escrowYearItems.push(relevantYear.toString());
    }

    this.termItems.unshift({
      text: 'All',
      value: 'all',
    });
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
    // this.refreshRows();
  }

  refreshRows() {
    this.agencies = [];
    this.latestAgencies = [];
    this.results = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<Agency, LabelRow>(
      ReportName.AgencyLabel,
      this.agencyService.getAllAgencies,
      this.agencyService.getTotalAgencies,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
      this.showLoading,
      this.handleFailure,
      this.finallyCallback,
    ).build()
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    // This only works if we let ag grid handle the row ID assignment
    const index = parseInt(event.node.id, 10);

    if (Number.isNaN(index)) {
      throw new Error('Index could not be determined for cell. Custom ID?');
    }

    this.results[index].attn_line = event.newValue as string;
  }

  public onResultsChanged(agencies: Agency[]): LabelRow[] {
    this.latestAgencies = agencies;
    this.agencies = agencies;
    return this.convertResults(agencies)
  }

  protected convertResults(agencies: Agency[]): LabelRow[] {
    this.onAgenciesChanged(agencies)
    return this.results
  }

  private showLoading() {
    if (this.gridApi !== null) {
      this.$nextTick(() => {
        this.gridApi.showLoadingOverlay();
      });
    }
    this.isLoading = true;
    this.loadingOverlayComponentParams = {
      context: this.gridOptions.context,
      api: this.gridApi,
      columnApi: this.columnApi,
      text: 'Loading',
    };
  }

  private handleFailure(e: any) {
    this.resetResults();
  }

  private finallyCallback() {
    this.isLoading = false;
  }

  getParams() {
    const params = {
      include_DTCO: true,
      advanced_search: this.advancedSearch,
    };

    if (this.sortModel) {
      Object.assign(params, {
        order_by: this.sortModel.colId,
        order_by_desc: this.sortModel.sort === 'desc',
      });
    }
    return params
  }

  exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'AgencyLabelReport',
      }),
      this.agencyService.getAllAgencies,
    )
  }

  determineAgencyName(item: Agency): string {
    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`;
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`;
    }

    nameString += `, ${item.capAgency}`;

    return nameString;
  }

  insertLabelText(pdf: jsPDF, agency: any, x_location: number, y_location: number) {
    pdf.setFontStyle('normal');
    pdf.setFontSize(9);

    const text_vertical_climb = (pdf.getTextDimensions('ABCD') as any).h * 1.1; // 10% buffer // TODO: h is not a prop on the types

    if (agency.agency_number && agency.agency_number.trim() !== '') {
      pdf.text(agency.agency_number, x_location, y_location += text_vertical_climb);
    }
    if (agency.name && agency.name.trim() !== '') {
      pdf.text(agency.name, x_location, y_location += text_vertical_climb);
    }
    if (agency.attn_line && agency.attn_line.trim() !== '') {
      pdf.text(`ATTN: ${agency.attn_line}`, x_location, y_location += text_vertical_climb);
    }
    if (agency.street_1 && agency.street_1.trim() !== '') {
      pdf.text(agency.street_1, x_location, y_location += text_vertical_climb);
    }
    if (agency.street_2 && agency.street_2.trim() !== '') {
      pdf.text(agency.street_2, x_location, y_location += text_vertical_climb);
    }
    pdf.text(`${agency.city}, ${agency.state} ${agency.zip_code}`, x_location, y_location += text_vertical_climb);
  }

  async createPDF() {
    this.generatePDFText = 'Generating Labels';
    this.isGeneratingPDF = true;
    setTimeout(async () => {
      await this.generatePDF(this.results);
      this.generatePDFText = 'Generate Labels';
      this.gridApi.hideOverlay();
      this.isGeneratingPDF = false;
    }, 100);
  }

  protected async getAllAgencies() {
    const searchParams: any = this.buildSearchParams()
    const val = await this.agencyService.getAllAgencies(searchParams)
    const newResults = this.convertResults(val.results)
    return newResults
  }

  async generatePDF(results: LabelRow[]) {
    const pdf: jsPDF = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',
    });

    // https://www.techwalla.com/articles/how-to-lay-out-avery-5160
    const topMargin = inchesToMM(0.5);
    const verticalPitch = inchesToMM(1.0);
    const leftMargin = inchesToMM(0.19);
    const horizontalPitch = inchesToMM(2.75);
    const labelsPerRow = 3;
    const labelsPerPage = 30;

    const labelHorizontalMargin = inchesToMM(0.1);
    const labelVerticalMargin = inchesToMM(0.05);

    let x_location = leftMargin + labelHorizontalMargin;
    let y_location = topMargin + labelVerticalMargin;

    let iterator = 0;

    for (const agency of results) { // eslint-disable-line
      this.insertLabelText(pdf, agency, x_location, y_location);

      x_location += horizontalPitch;

      iterator += 1;

      if (iterator % labelsPerRow === 0) {
        y_location += verticalPitch;
        x_location = leftMargin + labelHorizontalMargin;

        if (iterator % labelsPerPage === 0 && iterator < this.results.length) {
          pdf.addPage();
          y_location = topMargin + labelVerticalMargin;
        }
      }
    }

    pdf.setProperties({
      title: 'Agency Labels',
    });

    window.open(pdf.output('bloburl').toString(), '_blank');
  }

  markAll(key: string, value: any) {
    this.results = this.results.map((parcel: any, index: number) => {
      const newResult = cloneDeep(parcel);
      if (this.overwriteCheckbox || (!this.overwriteCheckbox && (!newResult[key] || newResult[key].length === 0))) {
        console.log(newResult[key], key, index, value)
        newResult[key] = value;
        console.log(newResult)
      }

      return newResult;
    });

    this.gridApi.refreshCells({
      columns: [key],
    });
  }
}
