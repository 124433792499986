













import { Vue, Component } from 'vue-property-decorator';
import { ICellEditorParams } from 'ag-grid-community';
import { DateTime } from 'luxon';

@Component({
  name: 'simple-date-cell-editor',
})
export default class SimpleDateCellEditor extends Vue {
  private params: ICellEditorParams = null;
  private value: any = null;

  // Events
  changeHandler(value: string) {
    const formattedValue = DateTime.fromFormat(value, 'MM/dd/yyyy');

    if (formattedValue.invalidReason) {
      this.value = null;
    } else {
      this.value = formattedValue.toFormat('MM/dd/yyyy');
    }
  }

  // Editor
  afterGuiAttached() {
    ((this.$refs.field as any).$refs.input as HTMLInputElement).click();
  }

  getValue() {
    return this.value;
  }
}
