
































































































import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { ColDef, CellValueChangedEvent, SortChangedEvent } from 'ag-grid-community';
import { DataTableHeader } from 'vuetify';

import ParcelService from '@/services/parcels';
import LoanService from '@/services/loans';

import Parcel from '@/entities/Parcel';
import User from '@/entities/User';

import { JsonPatchOperator } from '@/helpers/vuelidateToPatch';

import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import SsrmGridReport from '@/views/reports/SsrmGridReport.vue';
import DateField from '@/components/inputs/dates/DateField.vue';

import { AxiosError } from 'axios';
import ReportName from './models/ReportName';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ExportDataParams from './models/ExportDataParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';

interface ReportRow {
  loanId: string,
  parcelId: string,

  lenderNumber: string,
  loanNumber: string,
  parcelNumber: string,
  parcelType: string,
  dateAdded: string,
  name: string,
  address: string,
  city: string,
  state: string,
  zipCode: string,
  agencyCounty: string,
  verified: boolean,
}

@Component({
  name: 'pnv-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
    DateField,
  },
})
export default class PnvReport extends SsrmGridReport<Parcel, ReportRow> {
  protected pageSizes = [500, 10000];

  private isUpdating = false;
  private showUpdateDialog = false;

  private parcelVerifyList: ReportRow[] = [];

  private searchLoanEscrowType: string = null;
  private searchStartDate: string = null;
  private searchEndDate: string = null;
  private parcelType: 'e_and_en' | 'n_and_en' | 'all' = 'e_and_en';

  private service: ParcelService = new ParcelService();
  private loanService: LoanService = new LoanService();

  private serverSideStoreType = 'full'
  private cacheBlockSize = 100
  private rowModelType = 'serverSide';
  private paginationPageSize = 15;

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel E/N',
      field: 'parcelType',
      width: 125,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Date Added',
      field: 'dateAdded',
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'City',
      field: 'city',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'State',
      field: 'state',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Zip',
      field: 'zipCode',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'County',
      field: 'agencyCounty',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Verified',
      field: 'verified',
      sortable: false,
      flex: 1,
      editable: true,
      type: 'boolean',
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  private loanEscrowTypes: DataTableHeader[] = [
    {
      text: 'All',
      value: null,
    },
    {
      text: 'Escrow',
      value: 'E',
    },
    {
      text: 'Non-Escrow',
      value: 'N',
    },
    {
      text: 'Escrow / Non-Escrow',
      value: 'EN',
    },
  ];

  // Computed
  get hasRequiredInput(): boolean {
    return true;
  }

  get advancedSearch() {
    let selectedParcelType = [] as string[]
    if (this.parcelType && this.parcelType !== 'all') {
      if (this.parcelType === 'e_and_en') {
        selectedParcelType = ['E', 'EN'];
      } else if (this.parcelType === 'n_and_en') {
        selectedParcelType = ['N', 'EN'];
      }
      return {
        parcel_verified: false,
        added_between: [this.searchStartDate, this.searchEndDate],
        parcel_type_in: selectedParcelType,
      };
    }
    return {
      parcel_verified: false,
      added_between: [this.searchStartDate, this.searchEndDate],
    };
  }

  // Mixins
  isDirty() {
    return this.parcelVerifyList.length > 0;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
  }

  refreshRows() {
    if (this.parcelVerifyList.length > 0 && !this.showUpdateDialog) {
      this.showUpdateDialog = true;
      return;
    }

    this.latestResults = [];
    this.results = [];
    this.parcelVerifyList = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<Parcel, ReportRow>(
      ReportName.PNV,
      this.service.getAllParcels,
      this.service.getParcelTotal,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build()
  }

  convertResults(results: Parcel[]): ReportRow[] {
    const rows = results.map((parcel: Parcel) => Object.seal({
      loanId: parcel.loanId,
      parcelId: parcel.parcelId,

      lenderNumber: parcel.lenderNumber,
      loanNumber: parcel.loanNumber,
      parcelNumber: parcel.parcelNumber,
      parcelType: parcel.parcelType,
      dateAdded: parcel.dateAdded,
      name: parcel.name,
      address: `${parcel.address.value.address1 || ''}\n ${parcel.address.value.address2 || ''}`,
      city: parcel.address.value.city,
      state: parcel.address.value.state,
      zipCode: parcel.address.value.zipCode,
      agencyCounty: (parcel.agencies[0] && parcel.agencies[0].address && parcel.agencies[0].address.value) ? parcel.agencies[0].address.value.county : '',
      simpleVerified: parcel.verified,
      originalVerified: parcel.verified,
      otherVerified: parcel.verified,
      verified: parcel.verified,
      verifiedBy: parcel.verifiedBy,
      verifiedOn: parcel.verifiedOn,
    }));
    return rows
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      include_verified: true,
    };
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    // This only works if we let ag grid handle the row ID assignment
    const index = parseInt(event.node.id, 10);

    if (Number.isNaN(index)) {
      throw new Error('Index could not be determined for cell. Custom ID?');
    }

    if (!event.colDef.field.toLowerCase().includes('verified')) {
      throw new Error('Only verified should be updated.');
    }

    this.addToChangeList(event.newValue.verified, index);
  }

  addToChangeList(value: any, index: number) {
    const row = this.results[index];
    const set = new Set(this.parcelVerifyList);

    // If the value is falsy, the user has untoggled verified
    if (row.verified) {
      set.add(row);
    } else {
      set.delete(row);
    }

    this.parcelVerifyList = Array.from(set);
  }

  async submitChanges() {
    this.isUpdating = true;

    const updatePayload = this.parcelVerifyList.reduce((payload, parcel) => {
      payload.push({
        op: JsonPatchOperator.replace,
        path: `/${parcel.loanId}/parcels/${parcel.parcelId}/verified`,
        value: true,
      });
      return payload;
    }, []);

    try {
      const response = await this.loanService.batchPatchLoans(updatePayload);
      this.showSuccess(`Updated ${updatePayload.length} parcel(s) successfully.`);
    // Cast err to AxiosError - Property 'response' does not exist on type 'unknown'.Vetur
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update parcels - ${e.response.data.message}`);
      }
    } finally {
      this.isUpdating = false;
      this.parcelVerifyList = [];
    }

    await this.refreshRows();
  }

  async exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'ParcelsNotVerified',
        exclusions: ['loanId', 'parcelId', 'parcelAgencyId', 'taxHistoryEntryId'],
      }),
      this.service.getAllParcels,
    )
  }
}
