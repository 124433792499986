























































































































































































import Axios, { AxiosError, AxiosResponse, CancelToken } from 'axios';
import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
  CellValueChangedEvent, ColDef, SortChangedEvent, GridOptions,
} from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { InputValidationRule } from 'vuetify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateTime } from 'luxon';

import LenderService from '@/services/lenders';
import LoanService from '@/services/loans';
import ParcelService from '@/services/parcels';
import EscrowHistoryService from '@/services/escrowHistories';

import { JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';

import Agency from '@/entities/Agency';
import Lender from '@/entities/Lender';
import Term from '@/entities/Term';
import Parcel from '@/entities/Parcel';
import Verified from '@/entities/Verified';
import EscrowHistory from '@/entities/EscrowHistory';

import formatters from '@/components/ag-grid/formatters';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import { formatCurrency } from '@/components/ag-grid/formatters/CurrencyFormatter';
import ReportedDateSelection, { ReportedDateOption } from '@/components/inputs/queries/ReportedDateSelection.vue';

import AgencySearch from '@/views/agencies/AgencySearch.vue';
import LenderSearch from '@/views/lenders/LenderSearch.vue';

import capitalTaxLogo from '@/assets/logo.jpg';
import { fullDate } from '@/validations/vuetify';
import SsrmGridReport from './SsrmGridReport.vue';
import ReportName from './models/ReportName';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ExportDataParams from './models/ExportDataParams';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';

interface ReportRow {
  loanId: string,
  parcelId: string,
  agencyId: string,
  parcelAgencyId: string,
  parcelEscrowHistoryId?: string,

  lenderNumber: string,
  loanNumber: string,
  loanType: string,
  name: string,
  address: string,
  agencyNumber: string,
  agencyName: string,
  agencyCounty: string,
  agencyState: string,
  year: string,
  term: string,
  parcelNumber: string,
  altParcelNumber: string,
  reportedDate: string,
  dueDate: string,
  amountReported: number,
  collectingScheduleDueDate: string,

  path: string,

  existing: boolean,
}

@Component({
  name: 'escrow-balance-sheet-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
    ReportedDateSelection,
    AgencySearch,
    LenderSearch,
  },
})
export default class EscrowBalanceSheetReport extends SsrmGridReport<EscrowHistory, ReportRow> {
  protected pageSizes = [500, 10000];

  // Indicators
  public isUpdating = false;
  private showUpdateDialog = false;
  private isGeneratingPDF = false;
  private generatePDFText = 'Create PDF';

  // Update trackers
  private escrowTaxesDueList: [ReportRow, { original: number, new: number }][] = [];

  // Services
  private service: EscrowHistoryService = new EscrowHistoryService();
  private lenderService: LenderService = new LenderService();
  private loanService: LoanService = new LoanService();

  // User options
  private escrowYearItems: string[] = [];
  private termItems = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  // User inputs
  private selectedAgencies: Agency[] = [];
  private escrowYear: string = '';
  private term: string = '';
  private year: string = '';

  private selectedLenders: Lender[] = [];
  private lenders: (Lender & { displayString?: string })[] = [];

  private reportedDateMode: 'all' | 'isNull' | 'isNotNull' | 'dateRange' | 'specificDate' = 'isNull';
  private dateOption: ReportedDateOption = {
    reported_date_mode: 'all',
  };
  private reportedDates: Date[] = [];
  private pageNumberOnOff: 'on' | 'off' = 'on';
  private footer: string = '';
  private taxesDue: 'null' | 'not_null' = 'not_null';
  private hideLoanNumber: boolean = false;
  private hideAltParcel: boolean = false;

  private batchNumber: string = '';

  private termOverride: string = '';

  private calculatedTotalTaxesDue: string = '0.00';
  private calculatedSelectedTaxesDue: string = '0.00';
  private paymentReportMode: boolean = false;

  // Input rules
  private rules: { [index: string]: InputValidationRule } = {
    validDate: fullDate,
  };

  private rowClassRules: any = {
    'new-row': (params: any) => {
      if (params.data) {
        return !params.data.existing
      }
      return false
    },
    'old-row': (params: any) => {
      if (params.data) {
        return params.data.existing
      }
      return false
    },
  };

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: ' ',
      field: '_selected',
      width: 72,
      editable: true,
      maxWidth: 72,
      minWidth: 1,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      hide: true,
    },
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency Name',
      field: 'agencyName',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency County',
      field: 'agencyCounty',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency State',
      field: 'agencyState',
      sortable: false,
      width: 125,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan E/N',
      field: 'loanType',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Term',
      field: 'term',
      sortable: false,
      width: 150,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Taxes Due',
      field: 'amountReported',
      editable: true,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      type: 'shortDate',
      flex: 1,
      sortable: false,
      ...defaultDateFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  private pdfColumnDefs: ColDef[] = [
    {
      headerName: 'Tax Due',
      field: 'amountReported',
      valueFormatter: formatters.currencyFormatter,
      cellEditor: 'currencyCellEditor',
      sortable: false,
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      sortable: false,
    },
    {
      headerName: 'Owner',
      field: 'name',
      width: 100,
    },
  ];

  protected gridOptions: GridOptions = {
    onRowSelected: (event) => { this.recalculateSelectedTotalTaxesDue(); },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    enableCellTextSelection: true,
    postSort: (rowNodes): void => {
      rowNodes.sort((a, b) => {
        if (a.data.agencyName && b.data.agencyName && a.data.agencyName.localeCompare(b.data.agencyName) !== 0) {
          return a.data.agencyName.localeCompare(b.data.agencyName);
        }

        if (a.data.lenderNumber && b.data.lenderNumber) {
          return a.data.lenderNumber.localeCompare(b.data.lenderNumber);
        }

        return 0;
      })
    },
  };

  // Computed
  get hasRequiredInput(): boolean {
    if (!this.year || !this.term) {
      return false;
    }

    if (this.reportedDateMode === 'dateRange' && (!this.reportedDates[0] || !this.reportedDates[1])) {
      return false;
    }

    return true;
  }

  get hasExistingRow(): boolean {
    return this.results.some((result) => result.existing);
  }

  get advancedSearch() {
    const advancedSearch: any = {
      // To get parcels with 0 tax due
      // non_zero_base_amount: true,
      history_year_in: [this.year],
      history_term_in: [this.term],
      return_agency_collecting_schedule: true,
      parcel_type_in: ['E', 'EN'],
      amount_reported: this.taxesDue,
    };

    // Report date handling
    const OPTION_MAP = {
      all: 'all',
      null: 'isNull',
      not_null: 'isNotNull',
      between: 'dateRange',
      equal: 'specificDate',
    };

    advancedSearch.report_date = OPTION_MAP[this.dateOption.reported_date_mode];

    if (this.batchNumber && this.batchNumber !== '') {
      advancedSearch.batch_number_in = [this.batchNumber];
    }

    if (this.dateOption.reported_date_mode === 'between') {
      [advancedSearch.start_date, advancedSearch.end_date] = this.dateOption.reported_dates;
    } else if (this.dateOption.reported_date_mode === 'equal') {
      [advancedSearch.specific_date] = this.dateOption.reported_dates;
    }

    if (this.selectedAgencies && this.selectedAgencies.length > 0) {
      advancedSearch.agency_numbers = this.selectedAgencies.map((a) => a.capAgency);
    }

    if (this.selectedLenders && this.selectedLenders.length > 0) {
      advancedSearch.lender_numbers = this.selectedLenders.map((lender) => lender.id);
    }

    return advancedSearch;
  }

  convertResults(results: EscrowHistory[]): ReportRow[] {
    this.recalculateTotalTaxesDue()

    return results.reduce<ReportRow[]>((allSummaries, parcel) => {
      // Build the parcel summaries to show the user
      // Every parcel agency remaining matches term picked
      const scheduleEntry = parcel.agency.collectingSchedule.find((entry) => entry.term === this.term);
      allSummaries.push({
        loanId: parcel.loanId,
        parcelId: parcel.parcelId,
        agencyId: parcel.agencyId,
        parcelAgencyId: parcel.parcelAgencyId,
        parcelEscrowHistoryId: parcel.parcelEscrowHistoryId,

        lenderNumber: parcel.lenderNumber,
        loanNumber: parcel.loanNumber,
        loanType: parcel.parcelType,
        name: parcel.name,
        address: parcel.address,
        agencyNumber: parcel.agency.capAgency,
        agencyName: parcel.agency.name,
        agencyCounty: parcel.agency.address && parcel.agency.address.value ? parcel.agency.address.value.county : '',
        agencyState: parcel.agency.address && parcel.agency.address.value ? parcel.agency.address.value.state : '',
        year: this.year,
        term: Term[this.term as keyof typeof Term],
        parcelNumber: parcel.parcelNumber,
        altParcelNumber: parcel.altParcelNumber,
        reportedDate: parcel.reportedDate,
        dueDate: parcel.dueDate,
        amountReported: parcel.amountReported,
        collectingScheduleDueDate: parcel.dueDate,

        path: '',

        existing: true,
      });

      return allSummaries;
    }, []);
  }

  handleUpdatedResults() {
    this.recalculateTotalTaxesDue()
  }

  get subtotal(): number {
    return this.results.reduce((total, row) => total + row.amountReported, 0);
  }

  getSelectedSubtotal(): number {
    let rv: number = 0;
    this.gridApi.getSelectedRows().forEach((value) => {
      rv += Number(value.amountReported);
    });
    return rv;
  }

  onRowSelect(event: any) {
    this.recalculateSelectedTotalTaxesDue();
  }

  mounted() {
    if (this.columnApi) {
      this.columnApi.setColumnVisible('_selected', this.paymentReportMode);
    }
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 2; i >= 0; i -= 1) {
      const relevantYear = currentYear + i - 1;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [this.year] = this.escrowYearItems;
    this.term = this.termItems[1].value;

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = currentYear - i;
      this.escrowYearItems.push(relevantYear.toString());
    }

    await this.getAllLenders();
  }

  // Mixins
  isDirty() {
    return this.escrowTaxesDueList.length > 0;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onGridSortChanged(params);
    // this.refreshRows();
  }

  refreshRows() {
    if (this.escrowTaxesDueList.length > 0 && !this.showUpdateDialog) {
      this.showUpdateDialog = true;
      return;
    }
    this.results = [];
    this.escrowTaxesDueList = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<EscrowHistory, ReportRow>(
      ReportName.EscrowBalanceSheet,
      this.service.getAllEscrowHistories,
      this.service.getTotalEscrowHistories,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build()
  }

  getParams() {
    const params: any = {
      advanced_search: this.advancedSearch,
      include_agency: true,
      search_type: 'escrow_history',
    };

    return params;
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    // This only works if we let ag grid handle the row ID assignment
    const index = parseInt(event.node.id, 10);

    if (Number.isNaN(index)) {
      throw new Error('Index could not be determined for cell. Custom ID?');
    }

    this.addToChangeList(event.newValue, index);

    this.recalculateTotalTaxesDue();
    if (this.paymentReportMode) {
      this.recalculateSelectedTotalTaxesDue();
    }
  }

  addToChangeList(value: any, index: number) {
    const row = this.results[index];
    const trimmedValue = value ? value.trim() : null;

    const updateList = this.escrowTaxesDueList;
    const updateMap = new Map(updateList);
    if (!updateMap.has(row) || updateMap.get(row).original !== trimmedValue) {
      if (updateMap.has(row)) {
        updateMap.get(row).new = trimmedValue;
      } else {
        updateMap.set(row, {
          original: row.amountReported,
          new: trimmedValue,
        });
      }
    } else {
      updateMap.delete(row);
    }

    this.escrowTaxesDueList = Array.from(updateMap);
  }

  toggleReportMode() {
    this.paymentReportMode = !this.paymentReportMode;
    if (this.columnApi) {
      this.columnApi.setColumnVisible('_selected', this.paymentReportMode);
    }
  }

  async submitPayment() {
    try {
      this.isUpdating = true;
      const updateHistoryPayload: JsonPatchPayload = Array.from(this.results).filter((entry) => entry.amountReported > 0).map((p) => ({
        op: JsonPatchOperator.replace,
        path: `/${p.loanId}/parcels/${p.parcelId}/agencies/${p.parcelAgencyId}/escrow_history/${p.parcelEscrowHistoryId}/amount_paid`,
        value: p.amountReported,
      }));

      const response = await this.loanService.batchPatchLoans(updateHistoryPayload);
      this.showSuccess(`Updated ${response.length} ${response.length === 1 ? 'history' : 'histories'} successfully.`);
    // Cast err to AxiosError - Property 'response' does not exist on type 'unknown'.Vetur
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update loans - ${e.response.data.message}`);
      }
    } finally {
      this.isUpdating = false;
      this.escrowTaxesDueList = [];
      this.refreshRows();
      this.recalculateTotalTaxesDue();
      this.recalculateSelectedTotalTaxesDue();
    }
  }

  async submitChanges() {
    try {
      this.isUpdating = true;
      const updateHistoryPayload: JsonPatchPayload = Array.from(this.escrowTaxesDueList).filter((entry) => entry[0].existing).map((p) => ({
        op: JsonPatchOperator.replace,
        path: `/${p[0].loanId}/parcels/${p[0].parcelId}/agencies/${p[0].parcelAgencyId}/escrow_history/${p[0].parcelEscrowHistoryId}/amount_reported`,
        value: p[1].new,
      }));

      const createHistoryPayload: JsonPatchPayload = Array.from(this.escrowTaxesDueList).filter((entry) => !entry[0].existing).map((p) => ({
        op: JsonPatchOperator.add,
        path: `/${p[0].loanId}/parcels/${p[0].parcelId}/agencies/${p[0].parcelAgencyId}/escrow_history/-`,
        value: {
          term: this.term,
          year: this.year,
          parcel_id: p[0].parcelId,
          agency_id: p[0].agencyId,
          parcel_agency_id: p[0].parcelAgencyId,
          amount_reported: p[1].new,
        },
      }));

      const allPatchOps = updateHistoryPayload.concat(createHistoryPayload);

      const response = await this.loanService.batchPatchLoans(allPatchOps);
      this.showSuccess(`Updated ${response.length} ${response.length === 1 ? 'history' : 'histories'} successfully.`);
    // Cast err to AxiosError - Property 'response' does not exist on type 'unknown'.Vetur
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update loans - ${e.response.data.message}`);
      }
    } finally {
      this.isUpdating = false;
      this.escrowTaxesDueList = [];
      this.refreshRows();
      this.recalculateTotalTaxesDue();
    }
  }

  exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'EscrowBalanceSheet',
        exclusions: this.hideLoanNumber ? ['loanNumber'] : [],
        suppliedData: this.paymentReportMode ? this.gridApi.getSelectedRows() : this.results,
      }),
      this.service.getAllEscrowHistories,
    )
  }

  createPDF() {
    this.generatePDFText = 'Generating File';
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally();
      this.generatePDFOnServer();
      this.generatePDFText = 'Create PDF';
      this.gridApi.hideOverlay();
    }, 100);
  }
  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllEscrowHistories(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const res = await this.exportPdf(
      newResults,
      '/pdf/escrow-balance-sheet-report',
      {
        lenders: JSON.stringify(this.lenders),
        pdfColumnDefs: JSON.stringify(this.pdfColumnDefs),
        termOverride: this.termOverride,
        hideLoanNumber: this.hideLoanNumber.toString(),
        hideAltParcel: this.hideAltParcel.toString(),
        pageNumberOnOff: this.pageNumberOnOff,
        footer: this.footer,
      },
      'results',
    );
    this.isGeneratingPDF = false;
    return window.open(res.data.link, '_blank');
  }

  generatePDFLocally() {
    const objectOfParcels: any = {};
    this.gridApi.getSelectedNodes().filter((node) => node.data.existing && ((this.paymentReportMode && node.isSelected) || !this.paymentReportMode)).forEach((result) => {
      const lender = this.lenders.find((l) => l.id === result.data.lenderNumber);
      const lenderName = lender ? lender.name : '';
      const uniqueString = `${result.data.agencyName}${result.data.lenderNumber}${result.data.agencyCounty}${result.data.agencyState}${lenderName}${result.data.year}${Object.keys(Term).indexOf(result.data.term)}`
      if (!objectOfParcels[uniqueString]) {
        objectOfParcels[uniqueString] = []
      }
      objectOfParcels[uniqueString].push({
        history: result.data,
        lenderName,
      });
    });
    const sortedStrings = Object.keys(objectOfParcels).sort((a, b) => a.localeCompare(b));
    const arrayOfPages: any[] = [];
    sortedStrings.forEach((string) => {
      arrayOfPages.push(objectOfParcels[string].map((object: any) => ({
        ...object.history,
        lenderName: object.lenderName,
      })))
    });

    // Start PDF creation
    const reportPdf: jsPDF = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'letter',
    });
    let pageNumber = 1;

    const margin: number = 15;

    const img = document.createElement('img');
    img.src = capitalTaxLogo;

    arrayOfPages.forEach((page, index) => {
      const firstPageEntry = page[0];

      // Page header section
      reportPdf.addImage(img, 'PNG', 15, 10, 49, 30);
      reportPdf.setLineWidth(0.75);
      reportPdf.line(10, 40, 200, 40);
      reportPdf.setFontStyle('normal');
      reportPdf.setFontSize(12);
      reportPdf.text('1300 COMBERMERE DRIVE, TROY, MI 48083', margin, 50);
      reportPdf.text('TEL 800.335.3635 FAX 800.401.9179', margin, 55);
      reportPdf.text('EMAIL escrow@capitaltax.us', margin, 60);

      const baseY = 70;
      reportPdf.text(`${DateTime.local().toFormat('MM/dd/yyyy')}    TAX DETAIL REPORT        ${firstPageEntry.agencyName} OF ${firstPageEntry.agencyCounty} COUNTY, ${firstPageEntry.agencyState}       ${firstPageEntry.agencyNumber}`, margin, baseY);
      let termString: string = firstPageEntry.term;
      if (this.termOverride && this.termOverride !== '') {
        termString = this.termOverride;
      }
      termString = termString.toUpperCase();
      reportPdf.text(`${firstPageEntry.year} ${termString}  REAL ESTATE PROPERTY TAXES    -    DIRECT PAY    -    DUE DATE: ${firstPageEntry.collectingScheduleDueDate ? firstPageEntry.collectingScheduleDueDate.value : 'N/A'}`, margin, baseY + 5);
      reportPdf.setFontStyle('normal');

      reportPdf.text(`${firstPageEntry.lenderNumber} ${firstPageEntry.lenderName}`, margin, baseY + 15);

      function displayPageNumber() {
        reportPdf.setFontSize(12);
        reportPdf.text(`Page ${pageNumber}`, margin, reportPdf.internal.pageSize.height - margin / 2); // print number bottom right
        pageNumber += 1;
      }

      // Page data table section
      let newHeight = 0;
      const loanNumberFieldArray: ColDef[] = this.hideLoanNumber ? [] : [{ headerName: 'Loan #', field: 'loanNumber' }];
      reportPdf.autoTable({
        columns: loanNumberFieldArray.concat(this.pdfColumnDefs).map((header: any) => ({
          dataKey: header.field,
          header: header.headerName,
        })),
        body: page.map((row: any) => {
          const entry: any = cloneDeep(row);
          entry.amountReported = formatCurrency(entry.amountReported);
          if (!this.hideAltParcel && entry.altParcelNumber != null) {
            entry.parcelNumber = `${entry.parcelNumber} (${entry.altParcelNumber})`;
          }
          return entry;
        }),
        // theme: 'grid',
        startY: baseY + 20,
        margin,
        didDrawPage: ((data: any) => {
          newHeight = data.cursor.y;
          if (this.pageNumberOnOff === 'on') {
            displayPageNumber();
          }
        }),
        styles: {
          lineWidth: 0,
        },
      });

      const subtotal: number = page.reduce(
        (acc: number, cur: { amountReported: number }) => acc + cur.amountReported,
        0,
      );
      reportPdf.text(`LENDER SUBTOTAL      ${formatCurrency(subtotal)}`, margin, newHeight + 10);
      reportPdf.setFontStyle('bold');
      reportPdf.setFontSize(16);
      reportPdf.setFontStyle('normal');
      reportPdf.setFontSize(12);

      reportPdf.setFontSize(12);
      reportPdf.text(this.footer, reportPdf.internal.pageSize.width - 10, reportPdf.internal.pageSize.height - 10, { align: 'right' });

      if (index !== arrayOfPages.length - 1) {
        reportPdf.addPage();
        pageNumber = 1;
      }
    })

    reportPdf.save(`EscrowBalanceSheet-${DateTime.local().toFormat('F')}.pdf`);
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_desc: false,
    };
    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
      this.lenders.forEach((lender) => {
        lender.displayString = `${lender.name} - ${lender.id}`;
      });
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
  }

  determineLenderName(item: Lender): string {
    return `${item.name} - ${item.id}`;
  }

  remove(item: any, items: any[], key?: string) {
    const index = items.indexOf(key ? item[key] : item);
    if (index >= 0) items.splice(index, 1);
  }

  recalculateTotalTaxesDue() {
    this.calculatedTotalTaxesDue = this.formatAsCurrency(this.subtotal);
  }

  recalculateSelectedTotalTaxesDue() {
    this.calculatedSelectedTaxesDue = this.formatAsCurrency(this.getSelectedSubtotal());
  }

  formatAsCurrency(val: number) {
    let temp = String(val.toFixed(2));
    if (temp.length > 6) { // "ddd.cc"
      temp = `${temp.substring(0, temp.length - 6)},${temp.slice(temp.length - 6)}`;
    }
    if (temp.length > 10) { // "ddd,ddd.cc"
      temp = `${temp.substring(0, temp.length - 10)},${temp.slice(temp.length - 10)}`;
    }
    if (temp.length > 14) { // "ddd,ddd,ddd.cc"
      temp = `${temp.substring(0, temp.length - 14)},${temp.slice(temp.length - 14)}`;
    }
    return `$${temp}`;
  }
}
