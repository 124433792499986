































import {
  Component, Watch, Vue, Emit,
} from 'vue-property-decorator';
import { debounce } from 'lodash';

import LenderService from '@/services/lenders';

import Lender from '@/entities/Lender';

@Component({
  name: 'lender-search',
})
export default class LenderSearch extends Vue {
  // Services
  private service: LenderService = new LenderService();

  private lenderSearch: string = '';
  private lenderDebounce: Function = null;
  private foundLenders: Lender[] = [];
  private selectedLenders: Lender[] = [];

  private isSearching: boolean = false;

  // Watchers
  @Watch('lenderSearch')
  async onLenderSearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.lenderDebounce) {
      this.lenderDebounce = debounce(async () => {
        const response = await this.service.getAllLenders({
          search_field: 'name_or_number',
          search_value: this.lenderSearch,
          limit: 100,
          agency_selector_search: true,
        });

        this.foundLenders = response.lenders;
      }, 500);
    }

    this.lenderDebounce();
  }

  // Methods
  determineLenderName(item: Lender): string {
    return `${item.name} (${item.id})`;
  }

  remove(item: any) {
    const newValue = [...this.selectedLenders];
    newValue.splice(
      this.selectedLenders.findIndex((value) => value === item),
      1,
    );

    this.update(newValue);
  }

  @Emit('input')
  update(items: Lender[]) {
    this.selectedLenders = items;
    return this.selectedLenders.map((lender) => (this.$attrs['item-value'] ? (lender as any)[this.$attrs['item-value']] : lender));
  }
}
