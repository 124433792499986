import _ from 'lodash';
import { DateTime } from 'luxon';

import ILoan from '@/entities/ILoan';
import Parcel from '@/entities/Parcel';
import User from '@/entities/User';
import Verified from '@/entities/Verified';

import IServiceLoan from '@/services/api/models/IServiceLoan';
import Agency from '@/entities/Agency';
import IFile from '@/entities/IFile';

export default class Loan implements ILoan {
  loanId: string;
  loanNumber: string;
  lenderNumber: string;
  lenderId: string;

  loanType?: string;
  notes?: string;
  contractId?: string;
  idFlag?: string;
  cvtNumber?: string;
  purposeCode?: string;
  classCode?: string;

  name?: string;
  borrowerName?: string;
  borrowerName2?: string;
  companyName?: string;
  problem: Verified<void>;
  loanLocationType?: string;
  branchNumber?: string;
  billingNotes?: string;

  dateAdded?: string;
  dateReceived?: string;
  loanDateInactive?: string;

  problem_reported_by?: User;
  problem_reported_on?: Date;

  loanAddedBy?: User;
  loanRemovedBy?: User;

  parcels: Parcel[] = [];
  agencies: Agency[] = [];

  files: IFile[] = [];

  active: boolean;
  deleted: boolean;

  static upper(obj: any): any {
    if (!obj) return obj;

    Object.keys(obj).forEach((prop: string) => {
      if (prop === 'capAgency' || prop === 'term' || prop === 'collectingFrequency' || prop === 'nonEscrowCollectingYear' || prop === 'collectingYear' || prop === 'parcelType' || prop === 'countyLines' || prop === 'loanLocationType' || prop === 'url' || prop === 'email') return;
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = obj[prop].toUpperCase();
        }
        if (typeof obj[prop] === 'object') {
          this.upper(obj[prop]);
        }
      }
    });

    return obj;
  }

  constructor(serviceObj: IServiceLoan) {
    this.loanId = serviceObj.loan_id;
    this.lenderId = serviceObj.lender_id;
    this.lenderNumber = serviceObj.lender_number;
    this.loanNumber = serviceObj.loan_number;
    this.dateReceived = serviceObj.date_received ? DateTime.fromISO(serviceObj.date_received).toFormat('MM/dd/yyyy') : undefined;

    this.name = serviceObj.name;
    this.borrowerName = serviceObj.borrower_name;
    this.borrowerName2 = serviceObj.borrower_name_2;
    this.companyName = serviceObj.company_name;
    this.loanType = serviceObj.loan_type;
    this.notes = serviceObj.notes;
    this.contractId = serviceObj.contract_id;
    this.dateAdded = serviceObj.date_added ? DateTime.fromISO(serviceObj.date_added).toFormat('MM/dd/yyyy') : undefined;
    this.idFlag = serviceObj.id_flag;
    this.purposeCode = serviceObj.purpose_code;
    this.classCode = serviceObj.class_code;

    let problemReportedUser;
    if (serviceObj.problem_reported_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.problem_reported_by;

      problemReportedUser = new User(id, given_name, family_name, email);
    }

    this.problem = new Verified(
      undefined,
      serviceObj.problem,
      problemReportedUser,
      serviceObj.problem_reported_on ? DateTime.fromISO(serviceObj.problem_reported_on).toJSDate() : undefined,
    );

    this.loanLocationType = serviceObj.loan_location_type;
    this.loanDateInactive = serviceObj.loan_date_inactive ? DateTime.fromISO(serviceObj.loan_date_inactive).toFormat('MM/dd/yyyy') : undefined;
    this.branchNumber = serviceObj.branch_number;
    this.billingNotes = serviceObj.billing_notes;

    let loanAddedUser;
    if (serviceObj.loan_added_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.loan_added_by;

      loanAddedUser = new User(id, given_name, family_name, email);
    }
    this.loanAddedBy = loanAddedUser;

    let loanRemovedUser;
    if (serviceObj.loan_removed_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.loan_removed_by;

      loanRemovedUser = new User(id, given_name, family_name, email);
    }
    this.loanRemovedBy = loanRemovedUser;

    this.parcels = serviceObj.parcels ? serviceObj.parcels.map((p) => new Parcel(p, serviceObj)) : [];
    this.parcels = this.parcels.map((p) => ({ ...p, lenderNumber: this.lenderNumber }));

    this.agencies = this.parcels.reduce((allAgencies: Agency[], parcel: Parcel) => {
      allAgencies.push(...parcel.agencies);

      return allAgencies;
    }, []).filter(
      (agency, index, agencies) => agencies.findIndex((filterAgency) => agency.agencyId === filterAgency.agencyId) === index,
    );

    this.files = serviceObj.files || [];

    this.active = serviceObj.active;
    this.deleted = serviceObj.deleted;

    return Loan.upper(this);
  }

  static clone(instance: Loan): Loan {
    const copy = new (instance.constructor as { new (): Loan })();
    Object.assign(copy, instance);
    return copy;
  }
}
