import { DateTime } from 'luxon';

import { IServiceNonEscrowHistory } from '@/services/api/models/IServiceParcel';
import { INonEscrowHistory } from './IParcel';
import Verified from './Verified';
import EscrowType from './EscrowType';
import Agency from './Agency';
import User from './User';

export default class NonEscrowHistory implements INonEscrowHistory {
  parcelNonEscrowHistoryId: string;
  agencyId?: string;
  parcelId?: string;
  parcelAgencyId?: string;
  year?: string;
  base?: number;
  amountDue?: number;
  status?: Verified<string>;
  priorYearStatus?: string;
  notes?: string;
  dueDate?: string;
  reportedDate?: string;
  reportedBy?: User;
  updatedOn?: string;
  updatedBy?: User;

  // Full return
  loanId?: string;
  loanNumber?: string;
  parcelNumber?: string;
  parcelType?: string;
  loanType?: string;
  capAgency?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  legal?: string;
  billNumber?: string;
  county?: string;
  lot?: string;
  block?: string;
  unit?: string;
  building?: string;
  name?: string;
  altParcelNumber?: string;
  lenderNumber?: string;
  lenderName?: string;
  lenderAddress1?: string;
  lenderAddress2?: string;
  lenderCity?: string;
  lenderState?: string;
  lenderZipCode?: string;
  parcelProblem?: string;
  parcelDateAdded?: string;

  // Subentities
  agency?: Agency;
  delinquentTaxCollectingOffice?: Agency;

  // Computed
  penalties?: number;

  static upper(obj: any): any {
    if (!obj) return obj;

    Object.keys(obj).forEach((prop: string) => {
      if (prop === 'collectingFrequency' || prop === 'collectingYear') return;
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = obj[prop].toUpperCase();
        }
        if (typeof obj[prop] === 'object') {
          this.upper(obj[prop]);
        }
      }
    });

    return obj;
  }

  constructor(serviceObj: IServiceNonEscrowHistory) {
    this.parcelNonEscrowHistoryId = serviceObj.parcel_non_escrow_history_id;
    this.agencyId = serviceObj.agency_id;
    this.parcelId = serviceObj.parcel_id;
    this.parcelAgencyId = serviceObj.parcel_agency_id;
    this.year = serviceObj.year;
    this.base = serviceObj.base;
    this.amountDue = serviceObj.amount_due;
    this.status = new Verified(
      serviceObj.status,
      true,
      serviceObj.status_updated_by,
      serviceObj.status_updated_on ? DateTime.fromISO(serviceObj.status_updated_on).toJSDate() : undefined,
    );
    this.priorYearStatus = serviceObj.prior_year_status;
    this.notes = serviceObj.notes;
    this.dueDate = serviceObj.due_date ? DateTime.fromISO(serviceObj.due_date).toFormat('MM/dd/yyyy') : undefined;
    this.reportedDate = serviceObj.reported_date ? DateTime.fromISO(serviceObj.reported_date).toFormat('MM/dd/yyyy') : undefined;
    if (serviceObj.reported_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.reported_by;

      this.reportedBy = new User(id, given_name, family_name, email);
    }
    this.updatedOn = serviceObj.updated_on ? DateTime.fromISO(serviceObj.updated_on).toFormat('MM/dd') : undefined;

    if (serviceObj.updated_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.updated_by;

      this.updatedBy = new User(id, given_name, family_name, email);
    }

    // Full return
    this.loanId = serviceObj.loan_id;
    this.loanNumber = serviceObj.loan_number;
    this.lenderNumber = serviceObj.lender_number;
    this.parcelNumber = serviceObj.parcel_number;
    this.capAgency = serviceObj.cap_agency;
    this.address = serviceObj.address;
    this.address2 = serviceObj.address_2;
    this.city = serviceObj.city;
    this.state = serviceObj.state;
    this.zipCode = serviceObj.zip_code;
    this.county = serviceObj.county;
    this.lot = serviceObj.lot;
    this.block = serviceObj.block;
    this.unit = serviceObj.unit;
    this.building = serviceObj.building;
    this.legal = serviceObj.legal;
    this.billNumber = serviceObj.bill_number;
    this.name = serviceObj.name;
    this.altParcelNumber = serviceObj.alt_parcel_number;
    this.lenderNumber = serviceObj.lender_number;
    this.lenderName = serviceObj.lender_name;
    this.lenderAddress1 = serviceObj.lender_address_1;
    this.lenderAddress2 = serviceObj.lender_address_2;
    this.lenderCity = serviceObj.lender_city;
    this.lenderState = serviceObj.lender_state;
    this.lenderZipCode = serviceObj.lender_zip_code;
    this.parcelProblem = serviceObj.parcel_problem;
    this.parcelDateAdded = serviceObj.parcel_date_added ? DateTime.fromISO(serviceObj.parcel_date_added).toFormat('MM/dd/yyyy') : undefined;

    switch (serviceObj.parcel_type) {
      case 'EN':
        this.parcelType = EscrowType.both;
        break;

      case 'E':
        this.parcelType = EscrowType.escrow;
        break;

      case 'N':
        this.parcelType = EscrowType.nonEscrow;
        break;

      default:
        this.parcelType = EscrowType.neither;
        break;
    }

    switch (serviceObj.loan_type) {
      case 'EN':
        this.parcelType = EscrowType.both;
        break;

      case 'E':
        this.parcelType = EscrowType.escrow;
        break;

      case 'N':
        this.parcelType = EscrowType.nonEscrow;
        break;

      default:
        this.parcelType = EscrowType.neither;
        break;
    }

    // Subentities
    if (serviceObj.agency) {
      this.agency = new Agency(serviceObj.agency);
    }

    if (serviceObj.delinquent_tax_collecting_office) {
      this.delinquentTaxCollectingOffice = new Agency(serviceObj.delinquent_tax_collecting_office);
    }

    // Computed
    if (this.base) {
      this.penalties = this.amountDue ? Number((this.amountDue - this.base).toFixed(2)) : 0.00;
    }

    NonEscrowHistory.upper(this);
  }
}
