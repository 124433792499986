

















































import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { CellValueChangedEvent, ColDef, SortChangedEvent } from 'ag-grid-community';
import { JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import { AxiosError } from 'axios';
import { snakeCase } from 'change-case';

import ParcelService from '@/services/parcels';
import LoanService from '@/services/loans';
import AgencyService from '@/services/agencies';

import Parcel from '@/entities/Parcel';

import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import ExportDataParams from './models/ExportDataParams';
import SsrmGridReport from './SsrmGridReport.vue';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';
import ReportName from './models/ReportName';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

interface ReportRow {
  [index: string]: any,

  parcelId: string,
  loanId: string,

  lenderNumber: string,
  loanNumber: string,
  parcelNumber: string,
  name: string,
  verified: any,
  problem: boolean,
  parcelType: string,
  dateAdded: string,
  address: string,
  city: string,
  state: string,
  zipCode: string,
  agencyCode: string,
  agencyName: string,
  agencyCounty: string,
  parcelAgencyId: string,
}

@Component({
  name: 'parcel-format-flag-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
  },
})
export default class ParcelFormatFlagReport extends SsrmGridReport<Parcel, ReportRow> {
  protected pageSizes = [500, 10000];
  copyResults = true;

  private service: ParcelService = new ParcelService();
  private loanService: LoanService = new LoanService();
  private agencyService: AgencyService = new AgencyService();

  private isUpdating = false;
  private updateMap: Map<ReportRow, Map<string, {
    parcelAgencyId?: any; original: any, new: any
}>> = new Map();

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Verified',
      field: 'verified',
      sortable: false,
      editable: true,
      type: 'boolean',
      flex: 1,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Problem',
      field: 'problem',
      width: 100,
      type: 'boolean',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'E/N',
      field: 'parcelType',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Date Added',
      field: 'dateAdded',
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Name/Company Name',
      field: 'name',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'City',
      field: 'city',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'State',
      field: 'state',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Zip',
      field: 'zipCode',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'County',
      field: 'agencyCounty',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency Code',
      field: 'agencyCode',
      editable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency Name',
      field: 'agencyName',
      ...defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    // this.onBackgroundSortChanged(params);
    // this.refreshRows();
    this.onSsrmSortChanged(params);
  }

  refreshRows() {
    this.results = [];
    this.latestResults = [];
    // this.gridApi.setRowData([]);

    // this.getAllData();
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<Parcel, ReportRow>(
      ReportName.ParcelFormatFlag,
      this.service.getAllParcels,
      this.service.getTotalParcels,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build()
  }

  convertResults(results: Parcel[]): ReportRow[] {
    return results.map((parcel: Parcel) => ({
      lenderNumber: parcel.lenderNumber,
      loanNumber: parcel.loanNumber,
      loanId: parcel.loanId,
      parcelNumber: parcel.parcelNumber,
      name: parcel.name,
      verified: parcel.verified,
      originalVerified: parcel.verified,
      problem: parcel.problem.verified,
      parcelType: parcel.parcelType,
      dateAdded: parcel.dateAdded || '12/31/9999', // parcel.dateAdded.toString().split("00:00")[0],
      address: `${parcel.address.value.address1 || ''}, ${parcel.address.value.address2 || ''}`,
      city: parcel.address.value.city || '',
      state: parcel.address.value.state || '',
      zipCode: parcel.address.value.zipCode || '',
      parcelId: parcel.parcelId,
      agencyCode: parcel.agencies[0] ? parcel.agencies[0].capAgency : '',
      agencyName: parcel.agencies[0] ? parcel.agencies[0].name : '',
      agencyCounty: (parcel.agencies[0] && parcel.agencies[0].address && parcel.agencies[0].address.value) ? parcel.agencies[0].address.value.county : '',
      parcelAgencyId: parcel.agencies[0].parcelAgencyId,
    }));
  }

  getParams() {
    return {
      advanced_search: {
        has_matching_configuration: false,
      },
      include_collecting_schedules: false,
      report: ReportName.ParcelFormatFlag,
    };
  }

  getRows(params: any, limit: number, offset: number) {
    const finalParams = { limit, offset, ...params };

    // Get all the loans
    return this.makeCancellableRequest(this.service.getAllParcels, finalParams)
      .then((response) => {
        const { results } = response;
        return results;
      });
  }

  async exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'ParcelFormatFlagReport',
      }),
      this.service.getAllParcels,
    )
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    // This only works if we let ag grid handle the row ID assignment
    const index = parseInt(event.node.id, 10);

    if (Number.isNaN(index)) {
      throw new Error('Index could not be determined for cell. Custom ID?');
    }

    this.addToChangeList(event.newValue, index, event.colDef.field);
  }

  addToChangeList(value: any, index: number, header: string) {
    const row = this.results[index];
    const originalRow = this.original[index];

    let finalValue = value;

    if (typeof finalValue === 'string') {
      finalValue = finalValue.trim();
    }

    let rowMap;

    if (this.updateMap.has(row)) {
      rowMap = this.updateMap.get(row);
    } else {
      rowMap = new Map();
      this.updateMap.set(row, rowMap);
    }

    if (!rowMap.has(header) || rowMap.get(header).original !== finalValue) {
      if (rowMap.has(header)) {
        rowMap.get(header).new = finalValue;
      } else {
        rowMap.set(header, {
          original: originalRow ? originalRow[header] : null,
          new: finalValue,
          parcelAgencyId: originalRow.parcelAgencyId,
        });
      }
    } else {
      rowMap.delete(header);
      if (rowMap.size === 0) {
        this.updateMap.delete(row);
      }
    }

    this.updateMap = new Map(this.updateMap);
  }

  async submitChanges() {
    this.isUpdating = true;

    let updatePayload: JsonPatchPayload = [];
    const updateRows = Array.from(this.updateMap.entries());
    const updatePromises = updateRows.map(async (row) => {
      await Promise.all(Array.from(row[1]).map(async ([key, value]) => {
        if (key === 'agencyCode') {
          try {
            const agencies = await this.agencyService.getAllAgencies({
              search_field: 'agency_code',
              search_value: value.new,
            })
            if (agencies.results.length > 0) {
              updatePayload.push({
                op: JsonPatchOperator.add,
                path: `/${row[0].loanId}/parcels/${row[0].parcelId}/agencies`,
                value: agencies.results[0].agencyId,
              });
              updatePayload.push({
                op: JsonPatchOperator.remove,
                path: `/${row[0].loanId}/parcels/${row[0].parcelId}/agencies/${value.parcelAgencyId}`,
              });
            } else {
              updatePayload = [];
              this.showError('Could not update agency or incorrect Agency Code entered')
              return;
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          updatePayload.push({
            op: JsonPatchOperator.replace,
            path: `/${row[0].loanId}/parcels/${row[0].parcelId}/${snakeCase(key)}`,
            value: value.new,
          });
        }
      }));
    });
    await Promise.all(updatePromises);
    if (updatePayload.length > 0) {
      await this.updateLoans(updatePayload);
    } else {
      this.isUpdating = false;
      this.updateMap = new Map();
    }
  }

  async updateLoans(updatePayload: JsonPatchPayload) {
    try {
      const response = await this.loanService.batchPatchLoans(updatePayload);
      this.showSuccess(`Updated ${response.length} loan(s) successfully.`);
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update loans - ${e.response.data.message}`);
      }
    } finally {
      this.isUpdating = false;
      this.updateMap = new Map();
    }
    this.refreshRows();
  }
}
