import { DateTime } from 'luxon';

import { IServiceEscrowHistory } from '@/services/api/models/IServiceParcel';
import { IEscrowHistory } from './IParcel';
import EscrowType from './EscrowType';
import Agency from './Agency';
import Verified from './Verified';
import User from './User';

export default class EscrowHistory implements IEscrowHistory {
  parcelEscrowHistoryId: string;
  agencyId?: string;
  parcelId?: string;
  year?: string;
  term?: string;
  dueDate?: string;
  reportedDate?: string;
  amountPaid?: number;
  amountReported?: number;
  zeroVerified: Verified<void>;
  zeroVerifiedReason?: string;
  reportedBy?: User;
  reportNotes?: string;
  recentCorrection?: string;
  batchNumber?: string;
  block?: string;
  lot?: string;
  unit?: string;
  legal?: string;
  billNumber?: string;

  // Full return
  parcelAgencyId?: string;
  loanId?: string;
  loanNumber?: string;
  parcelNumber?: string;
  altParcelNumber?: string;
  parcelType?: string;
  loanType?: string;
  capAgency?: string;
  address?: string;
  county?: string;
  state?: string;
  name?: string;
  lenderId?: string;
  lenderNumber?: string;
  lenderName?: string;
  lenderAddress1?: string;
  lenderAddress2?: string;
  lenderCity?: string;
  lenderState?: string;
  lenderZipCode?: string;

  // Subentities
  agency?: Agency;

  static upper(obj: any): any {
    if (!obj) return obj;

    Object.keys(obj).forEach((prop: string) => {
      if (prop === 'term' || prop === 'collectingFrequency' || prop === 'collectingYear') return;
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = obj[prop].toUpperCase();
        }
        if (typeof obj[prop] === 'object') {
          this.upper(obj[prop]);
        }
      }
    });

    return obj;
  }

  constructor(serviceObj: IServiceEscrowHistory) {
    this.parcelEscrowHistoryId = serviceObj.parcel_escrow_history_id;
    this.agencyId = serviceObj.agency_id;

    let zeroVerifyUser;
    if (serviceObj.zero_verified && serviceObj.zero_verified_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.zero_verified_by;

      zeroVerifyUser = new User(id, given_name, family_name, email);
    }

    this.zeroVerified = new Verified(
      undefined,
      serviceObj.zero_verified,
      zeroVerifyUser,
      serviceObj.zero_verified_on ? DateTime.fromISO(serviceObj.zero_verified_on).toJSDate() : undefined,
    );
    this.zeroVerifiedReason = serviceObj.zero_verified_reason;

    this.term = serviceObj.term;
    this.parcelId = serviceObj.parcel_id;
    this.year = serviceObj.year;
    this.dueDate = serviceObj.due_date ? DateTime.fromISO(serviceObj.due_date).toFormat('MM/dd') : undefined;
    this.reportedDate = serviceObj.reported_date ? DateTime.fromISO(serviceObj.reported_date).toFormat('MM/dd/yyyy') : undefined;
    this.amountPaid = serviceObj.amount_paid;
    this.amountReported = serviceObj.amount_reported;
    if (serviceObj.reported_by) {
      const {
        id, given_name, family_name, email,
      } = serviceObj.reported_by;

      this.reportedBy = new User(id, given_name, family_name, email);
    }
    this.reportNotes = serviceObj.report_notes;
    this.recentCorrection = serviceObj.recent_correction;
    this.batchNumber = serviceObj.batch_number;
    this.block = serviceObj.block;
    this.lot = serviceObj.lot;
    this.unit = serviceObj.unit;
    this.legal = serviceObj.legal;
    this.billNumber = serviceObj.bill_number;

    // Full return
    this.parcelAgencyId = serviceObj.parcel_agency_id;
    this.loanId = serviceObj.loan_id;
    this.loanNumber = serviceObj.loan_number;
    this.parcelNumber = serviceObj.parcel_number;
    this.altParcelNumber = serviceObj.alt_parcel_number;
    this.capAgency = serviceObj.cap_agency;
    this.address = serviceObj.address;
    this.county = serviceObj.county;
    this.state = serviceObj.state;
    this.name = serviceObj.name;
    this.lenderId = serviceObj.lender_id;
    this.lenderNumber = serviceObj.lender_number;
    this.lenderName = serviceObj.lender_name;
    this.lenderAddress1 = serviceObj.lender_address_1;
    this.lenderAddress2 = serviceObj.lender_address_2;
    this.lenderCity = serviceObj.lender_city;
    this.lenderState = serviceObj.lender_state;
    this.lenderZipCode = serviceObj.lender_zip_code;

    switch (serviceObj.parcel_type) {
      case 'EN':
        this.parcelType = EscrowType.both;
        break;

      case 'E':
        this.parcelType = EscrowType.escrow;
        break;

      case 'N':
        this.parcelType = EscrowType.nonEscrow;
        break;

      default:
        this.parcelType = EscrowType.neither;
        break;
    }

    switch (serviceObj.loan_type) {
      case 'EN':
        this.parcelType = EscrowType.both;
        break;

      case 'E':
        this.parcelType = EscrowType.escrow;
        break;

      case 'N':
        this.parcelType = EscrowType.nonEscrow;
        break;

      default:
        this.parcelType = EscrowType.neither;
        break;
    }

    // Subentities
    if (serviceObj.agency) {
      this.agency = new Agency(serviceObj.agency);
    }

    EscrowHistory.upper(this);
  }
}
