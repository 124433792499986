























import {
  ColumnApi, GridApi,
} from 'ag-grid-community';
import { debounce } from 'lodash';
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component
export default class SsrmGridOmnifilter extends Vue {
  @Prop({ required: true }) readonly gridApi!: GridApi | null;
  @Prop({ required: true }) readonly columnApi!: ColumnApi | null;
  @Prop({ default: 'field' }) readonly columnKey!: string;
  @Prop({ required: true }) readonly updateTotalCount!: Function;
  @Prop({ required: true }) readonly totalDisplayedRows!: number;
  @Prop({ required: true }) readonly lastRow!: number;
  @Prop({ required: true }) readonly filteredLastRow!: number;

  // Watchers
  @Watch('gridApi')
  onGridApiChanged(gridApi: GridApi | null) {
    if (gridApi) {
      gridApi.addEventListener('modelUpdated', this.updateTotalCount);
    }
  }

  // Hooks
  beforeDestroy() {
    if (this.gridApi) {
      this.gridApi.removeEventListener('modelUpdated', this.updateTotalCount);
    }
  }

  private updateSearchValue(value: string) {
    this.$emit('updateSearchValue');
    this.gridApi.paginationGoToPage(0)
    this.gridApi.setFilterModel({ '-': { filter: value } })
  }

  public debouncedCallback = debounce((newValue, oldValue) => {
    this.updateSearchValue(newValue)
  }, 1000);

  public getLastRow() {
    switch (this.lastRow) {
      case -1:
        return 'Loading...'
      case 0:
        return 0
      default:
        return this.lastRow;
    }
  }

  public getTotalDisplayedRows() {
    switch (this.totalDisplayedRows) {
      case -1:
        return null
      case 0:
      {
        if (this.filteredLastRow > 0) {
          return `${this.totalDisplayedRows}/${this.filteredLastRow}`;
        }
        return '0'
      }
      default:
      {
        if (this.filteredLastRow > 0) {
          return `${this.totalDisplayedRows}/${this.filteredLastRow}`;
        }
        if (this.filteredLastRow === -2) {
          return `${this.totalDisplayedRows}/Loading...`;
        }
        return this.totalDisplayedRows
      }
    }
  }
}
