















































































































import {
  Component,
  Prop,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef,
  SortChangedEvent,
} from 'ag-grid-community';
import { Route } from 'vue-router';

import QueryService from '@/services/queries';
import UserService from '@/services/users';

import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';
import QueryDefinition, { QueryInputType } from '@/views/reports/builder/QueryDefinition.vue';

import GridReport from '@/views/reports/GridReport.vue';
import { DateTime } from 'luxon';
import { cloneDeep, isObject, startCase } from 'lodash';
import { JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import Query from '@/entities/queries/Query';
import Axios, { AxiosError, Canceler, CancelTokenSource } from 'axios';
import ExportDataParams from '../models/ExportDataParams';

export interface SearchProperty {
  text: string,
  field: string,
  type: QueryInputType,
  options?: string[],
}

interface SearchSelection {
  table: string,
  field: string,
  type: QueryInputType,
}

interface Operator {
  text: string,
  value: string,
  numValues: number,
}

interface SearchOperator {
  conjunction?: 'and' | 'or',
  table: string,
  field: string,
  operator: Operator,
  values?: any[],
}

@Component({
  name: 'query-detail',
  components: {
    QueryDefinition,
    AgGridVue,
    GridOmnifilter,
  },
  async beforeRouteEnter(to: Route, from: Route, next: Function) {
    const service = new UserService();

    try {
      await service.getUserQuery(to.params.id);

      next();
    } catch (err) {
      const e = err as AxiosError
      next({ ...e, intendedRoute: to });
    }
  },
})
export default class QueryDetail extends GridReport {
  @Prop({
    type: String,
    default: 'Query Builder',
  }) private readonly title!: string;

  @Prop({
    type: Map,
    default: () => new Map(),
  }) private readonly selections!: Map<string, (string | SearchProperty)[]>;

  private nameEditMode: boolean = false;
  private descriptionEditMode: boolean = false;

  private nameChanged: boolean = false;
  private descriptionChanged: boolean = false;
  private queryChanged: boolean = false;

  // TODO: Make this part of the config
  private tableNames: Map<string, string> = new Map([
    ['agencycollectingschedule', 'Collecting Schedule'],
    ['parcelescrowhistory', 'Escrow History'],
    ['parcelnonescrowhistory', 'Non Escrow History'],
    ['parcelagency', 'Parcel Agency'],
    ['dtco', 'DTCO'],
  ]);

  private service: QueryService = new QueryService();
  private userService: UserService = new UserService();

  private query: any = {
    name: 'Loading...', query: null, id: null, owner: null, creator: null, viewable: true, tags: [],
  };

  private queryRunning: boolean = false;

  private showQueryError: boolean = false;
  private queryError: any = null;

  protected sortModel: { colId: string, sort: 'asc' | 'desc' } = null;

  // Computed
  get defaultQueryColDef() {
    return Object.assign(this.defaultColDef, {
      flex: 1,
    });
  }

  get runnableQuery() {
    return !this.query.query || this.query.query.select.length === 0 || this.query.query.search.length === 0;
  }

  get columnFields(): string[] {
    return this.columnDefs.map((colDef) => colDef.field);
  }

  get colDefs(): ColDef[] {
    if (!this.query.query) return [];

    return this.query.query.select.map((selection: any): ColDef => {
      const colDef: any = {
        field: this.getFullFieldName(selection.table, selection.field),
        type: selection.type || 'string',
        colId: null,
      };

      const configEntry = this.selections.get(selection.table).find((config) => {
        if (isObject(config)) {
          return config.field === selection.field;
        }

        return config === selection.field;
      });

      let headerName;
      if (isObject(configEntry)) {
        headerName = configEntry.text ? configEntry.text : startCase(configEntry.field);
      } else {
        headerName = startCase(configEntry);
      }
      Object.assign(colDef, {
        headerName: `${this.resolveTableName(selection.table)} - ${headerName}`,
      });
      colDef.colId = colDef.field;

      return colDef;
    });
  }

  async created() {
    this.query = await this.userService.getUserQuery(this.$route.params.id);
  }

  async updateQuery(query: QueryDefinition) {
    const queryPayload: JsonPatchPayload = [{
      op: JsonPatchOperator.replace,
      path: '/query',
      value: query,
    }];

    await this.userService.updateUserQuery((this.query as Query).id, queryPayload);

    this.queryChanged = false;
  }

  async updateName(name: string) {
    if (this.nameChanged) {
      const namePayload: JsonPatchPayload = [{
        op: JsonPatchOperator.replace,
        path: '/name',
        value: name,
      }];

      await this.userService.updateUserQuery((this.query as Query).id, namePayload);
    }

    this.nameEditMode = false;
    this.nameChanged = false;
  }

  async updateDescription(description: string) {
    if (this.descriptionChanged) {
      const descriptionPayload: JsonPatchPayload = [{
        op: JsonPatchOperator.replace,
        path: '/description',
        value: description,
      }];

      await this.userService.updateUserQuery((this.query as Query).id, descriptionPayload);
    }

    this.descriptionEditMode = false;
    this.descriptionChanged = false;
  }

  runQuery() {
    this.queryRunning = true;
    if (this.sortModel) {
      this.query.query.sort = {
        sort_by: this.sortModel.colId,
        sort_order: this.sortModel.sort,
      }
    } else {
      this.query.query.sort = null;
    }

    this.makeCancellableRequest(this.service.runQuery, this.query.query)
      .then((response) => {
        this.results = response.results.map((result: any) => Object.keys(result).reduce((object: any, key: string) => {
          const newKey = key.replace('.', '-');
          object[newKey] = result[key];
          return object;
        }, {}));

        // These results are not of a concrete entity, so we need to do date conversions here
        const resultKeys = Object.keys(this.results[0]).filter((key) => !key.includes('_id'));
        const dateKeys = resultKeys.filter((resultKey) => {
          const selection = this.query.query.select.find(
            (select: any) => this.getFullFieldName(select.table, select.field) === resultKey,
          );
          return selection.type === 'date';
        });
        const shortDateKeys = resultKeys.filter((resultKey) => {
          const selection = this.query.query.select.find(
            (select: any) => this.getFullFieldName(select.table, select.field) === resultKey,
          );
          return selection.type === 'shortDate';
        });

        if (dateKeys.length > 0 || shortDateKeys.length > 0) {
          this.results = this.results.map((result) => {
            const newResult = cloneDeep(result);
            dateKeys.forEach((dateKey) => {
              newResult[dateKey] = newResult[dateKey] ? DateTime.fromISO(newResult[dateKey]).toFormat('MM/dd/yyyy') : undefined;
            });
            shortDateKeys.forEach((dateKey) => {
              newResult[dateKey] = newResult[dateKey] ? DateTime.fromISO(newResult[dateKey]).toFormat('MM/dd') : undefined;
            });
            return newResult;
          });
        }
      })
      .catch((e) => {
        this.showQueryError = e.response && e.response.data && e.response.data.detail;
        this.queryError = e.response && e.response.data && e.response.data.detail;
        this.resetResults();
      })
      .finally(() => {
        this.queryRunning = false;
      });
  }

  exportTable() {
    this.exportData(new ExportDataParams({
      file: this.title.replace(/\s+/g, ''),
      colDefs: this.colDefs,
    }))
  }

  private copyError(e: Error) {
    const errorSummary = {
      error: e,
    };

    this.$copyText(JSON.stringify(errorSummary));
  }

  private getFullFieldName(table: string, column: string): string {
    return `${table}-${column}`;
  }

  private resolveTableName(table: string): string {
    if (this.tableNames.has(table)) {
      return this.tableNames.get(table);
    }

    return startCase(table);
  }

  onSortChanged(params: SortChangedEvent) {
    this.onGridSortChanged(params);
    this.refreshRows();
  }

  onGridSortChanged(params: SortChangedEvent) {
    // Commenting this line because, the Property 'getSortModel' does not exist on type 'GridApi<any>'
    // [this.sortModel] = params.api.getSortModel();
  }

  refreshRows() {
    this.results = [];
    // this.gridApi.setRowData([]);
    this.runQuery();
  }
}
