













































































import {
  Component, Prop, Vue, Emit,
} from 'vue-property-decorator';
import VueJsonPretty from 'vue-json-pretty';
import { ImportResult } from '@/services/api/ImportApiService';
import Axios from 'axios'

@Component({
  name: 'import-results',
  components: {
    VueJsonPretty,
  },
})
export default class ImportResults extends Vue {
  @Prop({
    type: [Object, String],
    default: () => {},
  })
  readonly results!: ImportResult;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly previewMode!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly processingImport!: boolean;

  @Prop({
    type: Number,
    default: 0,
  })
  readonly importProgressPercent: Number;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly progressMode!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly deleteOperation!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly showViewData!: boolean;

  private selected: number = 0;
  private dataViewLimit: number = 10;
  private viewMoreAmount = 10;
  private importProgressPercentOld = 0;

  viewMoreData() {
    this.dataViewLimit += this.viewMoreAmount;
  }

  get filteredResults(): any[] {
    if (this.selected === 0) {
      return [].concat(
        this.results.added_loans,
        this.results.deleted_loans,
        this.results.updated_loans,
      );
    }
    return this.results.failed_rows;
  }

  get deletedLoans() {
    return this.results && this.results.deleted_loans && this.results.deleted_loans.length
      ? []
      : this.results.deleted_loans.filter((loan) => !loan.parcel_number);
  }

  get deletedParcels() {
    return this.results && this.results.deleted_loans && this.results.deleted_loans.length
      ? []
      : this.results.deleted_loans.filter((loan) => loan.parcel_number);
  }

  get updatedLoans() {
    return this.results.updated_loans;
  }

  get processingImportText() {
    if (this.previewMode) {
      if (this.deleteOperation) {
        return 'Previewing Delete...';
      }
      return 'Previewing Import...';
    }
    if (this.deleteOperation) {
      return 'Deleting...';
    }
    return 'Importing...';
  }

  get addedParcels() {
    return this.results.added_loans;
  }

  get slicedFilteredResults() {
    return this.filteredResults.slice(0, this.dataViewLimit);
  }

  @Emit('confirm')
  confirm() {}

  @Emit('cancel')
  cancel() {}

  async updateImportProgress() {
    if (this.results && this.results.job_id) {
      this.$emit('processingImport', true);
      try {
        const res = await Axios.get(`/progress?job_id=${this.results.job_id}`);
        if (res.data.error) {
          await this.updateImportProgress();
        }
        if (res.data && Number.isInteger(res.data.progress_percent)) {
          this.importProgressPercentOld = res.data.progress_percent;
          if (this.importProgressPercentOld < 100) {
            await this.updateImportProgress();
          } else {
            // fetch the processed queue data for final results
            try {
              const allDataRes = await Axios.get(`/progress?job_id=${this.results.job_id}&all=true&result_mode=true`);
              if (allDataRes.data) {
                this.$emit('resultsUpdate', allDataRes.data);
                this.$emit('showViewData', true);
              }
            } catch (err) {
              console.log('error in getting results', err);
            }
          }
        }
      } catch (error) {
        console.log('error in getting progress parent', error);
      }
    }
  }
}
