import { ColDef } from 'ag-grid-community'
import IExportDataParams from './IExportDataParams'

export default class ExportDataParams implements IExportDataParams {
    file: string
    inclusions: string[]
    exclusions: string[]
    ignoreGrid: boolean
    format: 'csv' | 'xlsx'
    colDefs?: ColDef[]
    suppliedData?: any[]

    constructor(options: IExportDataParams) {
      this.file = options.file
      this.inclusions = options.inclusions ? options.inclusions : []
      this.exclusions = options.exclusions ? options.exclusions : []
      this.ignoreGrid = options.ignoreGrid ? options.ignoreGrid : false
      this.format = options.format ? options.format : 'xlsx'
      this.colDefs = options.colDefs ? options.colDefs : null
      this.suppliedData = options.suppliedData ? options.suppliedData : null
    }
}
