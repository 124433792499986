
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({
  beforeRouteLeave(to: Route, from: Route, next: Function) {
    const that = this as PreventDirtyLeave;

    if (that.isDirty() && !that.forceLeave && to.path !== '/login') {
      that.showEditDialog = true;
      that.intendedRoute = to;
      next(false);
      return;
    }

    next();
  },
})
export default class PreventDirtyLeave extends Vue {
  // Navigation concerns
  public forceLeave: boolean = false;
  private showEditDialog: boolean = false;
  private intendedRoute?: Route;

  // Hooks
  created() {
    window.addEventListener('beforeunload', this.unloadHandler);
  }

  destroyed() {
    window.removeEventListener('beforeunload', this.unloadHandler);
  }

  unloadHandler(event: BeforeUnloadEvent) {
    if (this.isDirty()) {
      const confirmationMessage = 'You have pending changes that will be lost on a reload.';
      (event || window.event).returnValue = confirmationMessage;
    }
  }

  isDirty() {
    return this.$v ? this.$v.$anyDirty : false;
  }

  navigateAway() {
    this.forceLeave = true;

    if (this.intendedRoute) {
      this.$router.push(this.intendedRoute.path);
    }
  }

  dismissEditDialog() {
    this.showEditDialog = false;
  }
}
