<template>
  <div class="floating-corner-modal">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.floating-corner-modal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background-color: var(--light-and-dark-background);
  min-width: 300px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
}
</style>
