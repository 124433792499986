








































































































































import {
  Component,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { capitalCase } from 'change-case';
import { ColumnInput } from 'jspdf-autotable';
import { DataTableHeader } from 'vuetify';
import { isObject, cloneDeep } from 'lodash';
import jsPDF from 'jspdf';

import { ColDef, SortChangedEvent } from 'ag-grid-community';
import LenderService from '@/services/lenders';
import ParcelService from '@/services/parcels';

import ReportPdfBuilder from '@/helpers/exports/pdf/ReportPdfBuilder';

import Lender from '@/entities/Lender';
import Term from '@/entities/Term';
import Address from '@/entities/Address';
import Agency from '@/entities/Agency';

import states from '@/data/states';
import Axios from 'axios'

import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import SsrmGridReport from '@/views/reports/SsrmGridReport.vue';
import AgencySearch from '@/views/agencies/AgencySearch.vue';
import SimpleChipAutocomplete from '@/components/inputs/SimpleChipAutocomplete.vue';
import Parcel from '@/entities/Parcel';
import Verified from '@/entities/Verified';
import AgencyCountySearch from '@/views/agencies/AgencyCountySearch.vue';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ExportDataParams from './models/ExportDataParams';
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams';
import quickSearchParams from './ag-grid/params/quickSearchParams';
import ReportName from './models/ReportName';
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';

interface ReportRow {
  agencyNumber: string,
  agencyName: string,
  agencyCounty: string,
  agencyState: string,
  agencyAddress: Verified<Address>,
  year: string,
  term: string,
  dueDate: string,
  loanType: string,

  legal: string,
  lot: string,
  block: string,

  billReceived: boolean,
  parcelNumber: string,
  altParcelNumber: string,
  lenderNumber: string,
  loanNumber: string,
  name: string,
  address: string,
  amountReported: number,
  existing: boolean,
}

@Component({
  name: 'escrow-id-sheet-report',
  components: {
    AgGridVue,
    SsrmGridOmnifilter,
    AgencySearch,
    SimpleChipAutocomplete,
    AgencyCountySearch,
  },
})
export default class EscrowIDSheetReport extends SsrmGridReport<Parcel, ReportRow> {
  // Parent overrides
  protected pageSizes = [500, 10000];

  // Indicators
  private isGeneratingPDF = false;
  private generatePDFText = 'Create PDF';

  // Services
  private service: ParcelService = new ParcelService();
  private lenderService: LenderService = new LenderService();

  // User options
  private states: DataTableHeader[] = states;
  private escrowYearItems: string[] = [];
  private termItems = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  // User inputs
  private pickedState: string = '';
  private county: Agency = null;
  private year: string = '';
  private term: string = '';
  private reportedDate: string = 'all';
  private taxesDue: string = 'all';
  private hideAltParcel: boolean = false;

  private pickedAgencies: Agency[] = [];

  private selectedLenders: string[] = [];
  private lenders: Lender[] = [];
  private lendersSearchText: string = null;

  // Grid setup (some in mounted hooks)
  private rowClassRules: any = {
    'new-row': (params: any) => {
      if (params.data) {
        return !params.data.existing
      }
      return false
    },
  }

  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency County',
      field: 'agencyCounty',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'E/N',
      field: 'loanType',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      width: 100,
      type: 'shortDate',
      ...defaultDateFilterParams,

    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
      width: 100,
    },
    {
      headerName: 'Term',
      field: 'term',
      sortable: false,
    },
    {
      headerName: 'Missing Bill Amount',
      field: 'amountReported',
      sortable: false,
      editable: true,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Bill Received',
      field: 'billReceived',
      sortable: false,
      editable: true,
      minWidth: 150,
      flex: 1,
      type: 'boolean',
      // ...this.defaultTextFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  // Computed
  get hasRequiredInput(): boolean {
    return Boolean(this.year) && Boolean(this.term);
  }

  get advancedSearch() {
    const advancedSearch: any = {
      history_year_in: [this.year],
      history_term_in: [this.term],
      report_date: this.reportedDate,
      amount_reported: this.taxesDue,
      parcel_type_in: ['E', 'EN'],
    };

    if (this.pickedAgencies && this.pickedAgencies.length > 0) {
      advancedSearch.agency_numbers = this.pickedAgencies.map((a) => a.capAgency);
    }

    if (this.pickedState && this.pickedState !== '') {
      advancedSearch.agency_states = [this.pickedState];
    }

    if (this.county) {
      advancedSearch.agency_county = this.county.address.value.county;
    }

    if (this.selectedLenders && this.selectedLenders.length > 0) {
      advancedSearch.lender_numbers = this.selectedLenders;
    }

    return advancedSearch;
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    this.term = this.termItems[1].value;

    for (let i = 0; i <= 20; i += 1) {
      const relevantYear = currentYear + 1 - i;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [, this.year] = this.escrowYearItems;

    await this.getAllLenders();
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onGridSortChanged(params);
    this.refreshRows();
  }

  async refreshRows() {
    this.results = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    return new ReportDatasourceBuilder<Parcel, ReportRow>(
      ReportName.EscrowIdSheet,
      this.service.getAllParcels,
      this.service.getTotalParcels,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build()
  }

  getParams() {
    return {
      include_agency: true,
      advanced_search: this.advancedSearch,
      search_type: 'escrow_history',
      include_name: true,
    };
  }

  createRowObject(row: any): ReportRow {
    return {
      billReceived: false,
      parcelNumber: row.parcelNumber,
      altParcelNumber: row.altParcelNumber,
      lenderNumber: row.lenderNumber,
      loanNumber: row.loanNumber,
      loanType: row.parcelType,
      name: row.name,
      address: row.address ? Address.fullAddress(row.address.value) : null,
      agencyNumber: row.agency ? row.agency.capAgency : null,
      agencyName: row.agency ? row.agency.name : null,
      agencyCounty: row.agency && row.agency.address ? row.agency.address.value.county : null,
      agencyState: row.agency && row.agency.address ? row.agency.address.value.state : null,
      agencyAddress: row.agency ? row.agency.address : null,
      year: row.year,
      term: Term[row.term as keyof typeof Term],
      dueDate: row.dueDate,
      amountReported: row.amountReported,
      legal: row.legal,
      lot: row.address ? row.address.value.lot : null,
      block: row.address ? row.address.value.block : null,
      existing: row.agency.escrowHistory.length !== 0,
    }
  }

  convertResults(results: Parcel[]): ReportRow[] {
    const convertedResults: any[] = [];
    results.forEach((result) => {
      result.agencies.forEach((agency: any) => {
        if ((!agency.escrowHistory || agency.escrowHistory.length === 0)) {
          const row: any = cloneDeep(result);
          row.agency = agency;

          // Go through collecting schedule to find the due date (if available)
          agency.collectingSchedule.forEach((schedule: any) => {
            if (schedule.term === this.term) {
              row.dueDate = schedule.dueDate.value;
            }
          });

          convertedResults.push(this.createRowObject(row));
        } else {
          agency.escrowHistory.forEach((history: any) => {
            const row: any = cloneDeep(result);
            row.agency = agency;
            row.dueDate = history.dueDate;
            row.year = history.year;
            row.term = history.term;
            row.amountReported = history.amountReported;
            convertedResults.push(this.createRowObject(row));
          })
        }
      })
    });
    return convertedResults;
  }

  createPDF() {
    this.generatePDFText = 'Generating Document';
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally();
      this.generatePDFOnServer();
      this.generatePDFText = 'Create PDF';
      this.gridApi.hideOverlay();
    }, 100);
  }

  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllParcels(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const res = await this.exportPdf(
      newResults,
      '/pdf/escrow-id-sheet-report',
      {
        hideAltParcel: this.hideAltParcel ? 'true' : 'false',
        term: this.term,
      },
      'results',
    );
    this.isGeneratingPDF = false;

    return window.open(res.data.link, '_blank');
  }
  async generatePDFLocally() {
    const data = this.results.slice();

    data.sort((a, b) => {
      const aAgencyName = a.agencyName || '';
      const bAgencyName = b.agencyName || '';
      if (aAgencyName.localeCompare(bAgencyName) !== 0) {
        return aAgencyName.localeCompare(bAgencyName);
      }

      if (a.agencyAddress.value.county && b.agencyAddress.value.county) {
        return a.agencyAddress.value.county.localeCompare(b.agencyAddress.value.county);
      }

      return 0;
    })

    const exclusions: (keyof ReportRow)[] = ['billReceived', 'agencyNumber', 'agencyAddress', 'dueDate', 'year', 'term', 'loanType', 'amountReported', 'agencyName', 'agencyCounty', 'agencyState', 'existing', 'altParcelNumber'];

    const columnMap = new Map([
      ['lenderNumber', 'Lender #'],
      ['parcelNumber', 'Parcel #'],
      ['loanNumber', 'Loan #'],
    ]);

    let columns = [{
      title: 'Bill\nReceived',
      dataKey: 'checkbox',
    } as ColumnInput].concat(Object.keys(data[0]).map<ColumnInput>((key) => ({
      title: columnMap.has(key) ? columnMap.get(key) : capitalCase(key),
      dataKey: key,
    })));

    columns = columns.filter((column: ColumnInput) => !exclusions.find((key) => isObject(column) && key === column.dataKey));

    columns.push({
      title: 'Missing Bill $',
      dataKey: 'box',
    });

    const baseMargin = 15;

    data.forEach((row) => {
      if (!this.hideAltParcel) {
        if (row.parcelNumber.indexOf(')') !== row.parcelNumber.length) {
          row.parcelNumber = row.altParcelNumber ? `${row.parcelNumber} (${row.altParcelNumber})` : row.parcelNumber;
        }
      } else if (this.hideAltParcel && row.parcelNumber.indexOf(')') === row.parcelNumber.length - 1) {
        row.parcelNumber = row.parcelNumber.substring(0, row.parcelNumber.indexOf('('));
      }
    })

    const pdfBuilder: ReportPdfBuilder<ReportRow> = new ReportPdfBuilder<ReportRow>(data, {
      groupBy: 'agencyNumber',
      columns,
      exclusions,
      headStyles: {
        fontSize: 8,
      },
      bodyStyles: {
        fontSize: 8,
        minCellHeight: 40,
      },
      columnStyles: {
        checkbox: {
          cellWidth: 45,
        },
        parcelNumber: {
          cellWidth: 'auto',
        },
        lenderNumber: {
          cellWidth: 55,
        },
        loanNumber: {
          cellWidth: 60,
        },
        name: {
          cellWidth: 100,
        },
        address: {
          cellWidth: 150,
        },
        legal: {
          cellWidth: 75,
        },
        lot: {
          cellWidth: 50,
        },
        block: {
          cellWidth: 50,
        },
        box: {
          cellWidth: 110,
        },
      },
      margin: {
        left: baseMargin,
        right: baseMargin,
      },
      rowPageBreak: 'avoid',
    });

    pdfBuilder.setHeaderDraw((doc: jsPDF, height, width, pageNumber, grouping: ReportRow[]) => {
      const { agencyName, agencyAddress, agencyNumber } = grouping[0];

      // Draw agency # in header
      doc.setFontSize(18);
      doc.text(`${agencyNumber}`, baseMargin, 28, { align: 'left' });

      doc.setFontSize(24);
      doc.text('CAPITAL REAL ESTATE TAX SERVICES, INC.', width / 2.0, 28, { align: 'center' });
      doc.setFontSize(20);
      doc.text(`ID BILL REPORT - ${Term[this.term as keyof typeof Term].toUpperCase()} TAX`, width / 2.0, 48, { align: 'center' });
      doc.setFontSize(18);
      doc.text(
        `${agencyName} OF ${agencyAddress && agencyAddress.value && agencyAddress.value.county ? agencyAddress.value.county.toUpperCase() : 'UNKNOWN'} COUNTY, ${agencyAddress && agencyAddress.value && agencyAddress.value.state ? agencyAddress.value.state.toUpperCase() : 'UNKNOWN'}`,
        width / 2.0, 66,
        { align: 'center' },
      );

      doc.setFontSize(12);
      doc.text(`TAXES DUE: ${grouping[0].dueDate ? grouping[0].dueDate : 'N/A'}`, width - 40, height - 5, { align: 'right' });
    });

    pdfBuilder.setGroupFooterDraw((doc: jsPDF, height, width, pageNumber, grouping: ReportRow[], startY) => {
      const textSize = 12;
      const contactStartX = 40;

      doc.setFontSize(textSize);
      doc.text(`PARCEL COUNT: ${grouping.length}`, contactStartX, startY + textSize + 5);
    });

    const pdf = pdfBuilder.build();

    pdf.save(`EscrowIDSheet-${this.term}-${this.year}.pdf`);
  }

  exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: 'EscrowIDSheet',
        inclusions: ['agencyName', 'agencyCounty', 'agencyState'],
      }),
      this.service.getAllParcels,
    )
  }

  determineLenderName(item: Lender): string {
    return `${item.name} - ${item.id}`;
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_desc: false,
    };

    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
  }
}
