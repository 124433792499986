import { CancelToken } from 'axios';

import ParcelApiService from '@/services/api/ParcelApiService';
import Parcel from '@/entities/Parcel';
import ParcelCollection from './ParcelCollection';

interface AdvancedSearch {
  agency_numbers?: string[],
  agency_states?: string[],
  lender_numbers?: string[],
  delinquent_tax_collecting_offices?: string[],
  delinquent_filter?: string,
}

interface ParcelSearchParams {
  limit: number,
  offset: number,
  sort_by: string,
  order_by: boolean,
  search_field: string,
  search_value: string,
  advanced_search: AdvancedSearch,
  search_type: string,
}

class ParcelService {
  async getAllParcels(params: ParcelSearchParams, cancelToken?: CancelToken): Promise<ParcelCollection> {
    const response = await ParcelApiService.getAllParcels(params, cancelToken);

    return {
      count: parseInt(response.count, 10),
      results: response.results.map((serviceParcel) => new Parcel(serviceParcel)),
    };
  }

  async getTotalParcels(params: any, cancelToken?: CancelToken): Promise<any> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);
    return ParcelApiService.getTotalParcels(stringifiedParams);
  }

  async getParcel(id: string): Promise<Parcel> {
    const response = await ParcelApiService.getParcel(id);

    return new Parcel(response);
  }

  async getParcelTotal(params: ParcelSearchParams) {
    return ParcelApiService.getTotalParcels(params)
  }
}

export { ParcelService as default };
