import { IServerSideGetRowsParams } from 'ag-grid-community'
import IReportServerSideDatasource from './IReportServerSideDatasource'

export default class EmptyReportDatasource implements IReportServerSideDatasource {
  public lastRow: number = 0;
  public filteredLastRow: number = 0;

  cancel() {}

  reset(advancedSearch: any) {}

  onFilterChanged() {}

  getRows(params: IServerSideGetRowsParams<any>) {
    params.success({
      rowData: [],
      rowCount: 0,
    })

    params.api.showNoRowsOverlay()
  }
}
