




























import {
  Component, Prop, Emit, Vue,
} from 'vue-property-decorator';

@Component({
  name: 'simple-chip-box',
})
export default class SimpleChipBox extends Vue {
  @Prop({
    type: Array,
    default: () => [] as string[],
  }) readonly value!: string[];

  @Prop({
    type: String,
    default: 'all',
  }) readonly type!: string;

  private searchInput: string = '';

  @Emit('input')
  update(newValue: string[]) {
    this.searchInput = '';
    return newValue;
  }

  remove(item: string) {
    const newValue = [...this.value];
    newValue.splice(
      this.value.findIndex((value) => value === item),
      1,
    );

    this.update(newValue);
  }

  handleSearchUpdate(search: string) {
    if (!search) {
      return;
    }

    if (this.type === 'number') {
      this.$nextTick(() => {
        this.searchInput = search.replace(/\D/g, '');
      });
    }
  }
}
