import PromiseWorker from 'promise-worker';
import XLSXWorker, { XLSXWorkerOptions } from 'worker-loader!./worker'; // eslint-disable-line

export type XLSXWorkerConfig = string | XLSXWorkerOptions;

const promiseWorker = new PromiseWorker(new XLSXWorker());

const send = (message: string | XLSXWorkerOptions) => promiseWorker.postMessage({
  type: 'message',
  message,
});

export default {
  send,
};
