
import { Component } from 'vue-property-decorator'
import {
  FilterChangedEvent,
  KeyCode,
  SortChangedEvent,
} from 'ag-grid-community'
import { cloneDeep, forEach } from 'lodash'

import GridReport from '@/views/reports/GridReport.vue'
import Axios, { CancelToken } from 'axios'
import IReportServerSideDatasource from './ag-grid/datasource/IReportServerSideDatasource'
import EmptyReportDatasource from './ag-grid/datasource/EmptyReportDatasource'
import ExportDataParams from './models/ExportDataParams'

@Component({
  name: 'ssrm-grid-report',
})
export default class SsrmGridReport<T, R = any> extends GridReport<R> {
  protected pageSizes: number[] = [500]
  protected latestResults: T[] = []
  protected dataRetrievalToken: { cancel: Function } = null
  protected copyResults: boolean = false
  protected filterEnabled: boolean = false
  protected datasource: IReportServerSideDatasource = new EmptyReportDatasource()
  protected totalDisplayedRows: number = 0

  setNewDatasource(datasource: IReportServerSideDatasource) {
    this.latestResults = [];
    this.results = [];

    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = datasource
        this.gridApi.setServerSideDatasource(this.datasource)
        this.resetLoading()
      }
    }, 0)
  }

  cancelQuery() {
    this.datasource.cancel()
    this.setNewDatasource(new EmptyReportDatasource())
  }

  onResultsChanged(val: T[]) {
    this.results = []
    const newResults = this.convertResults(val)

    this.results.push(...newResults)

    if (this.copyResults) {
      this.original.push(...cloneDeep(newResults))
    }

    if (this.results.length > 0) {
      this.gridApi.hideOverlay()
    } else {
      this.gridApi.showNoRowsOverlay()
    }

    this.handleUpdatedResults();

    return newResults
  }

  // Hooks
  async created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keydown', this.handleKeyPress)
    }
  }

  beforeDestroy() {
    this.cancel()

    if (typeof window !== 'undefined') {
      document.removeEventListener('keydown', this.handleKeyPress)
    }
  }

  get hasRequiredInput() {
    return true
  }

  handleKeyPress(b: KeyboardEvent): any {
    if (b.metaKey && b.key === KeyCode.ENTER && this.hasRequiredInput) {
      if (this.isLoading) {
        this.cancelQuery()
      } else {
        this.refreshRows()
      }
    }

    return '123'
  }

  // Methods
  onGridComplete(): void {}

  protected refreshRows() {}

  protected onSsrmSortChanged(event: SortChangedEvent) {
    this.cancel()
  }

  protected cancel() {
    if (this.dataRetrievalToken) {
      this.dataRetrievalToken.cancel()
      this.dataRetrievalToken = null
    }
  }

  protected convertResults(results: T[]): R[] { return [] }

  protected handleUpdatedResults() {}

  protected getQueryParams(): any { return {} }

  protected getRows(params: any, limit: number, offset: number): Promise<any[]> { return Promise.resolve([]) }

  protected onFilterChanged(params: FilterChangedEvent) {
    setTimeout(() => this.datasource.onFilterChanged(), 0)
  }

  public updateTotalCount() {
    this.totalDisplayedRows = this.countAllNodes()
    return this.totalDisplayedRows
  }

  public countAllNodes(): number {
    if (this.gridApi === null) {
      return 0
    }
    let totalCount = 0
    this.gridApi.forEachNode(() => {
      totalCount += 1
    })
    return totalCount
  }

  /**
  * resize all columns
  */
  public setResizeAllColumnOption() {
    this.gridOptions.columnApi.autoSizeAllColumns()
  }

  protected resetRowCounts() {
    this.datasource.lastRow = -1
    this.datasource.filteredLastRow = -1
    this.totalDisplayedRows = -1
  }

  protected getParams(): any {
    return null;
  }

  protected async exportReportTable(
    exportDataParams: ExportDataParams,
    getAll: (params: any, cancelToken?: CancelToken) => Promise<any>,
  ) {
    const searchParams: any = this.buildSearchParams()
    const val = await getAll(searchParams)
    const newResults = this.convertResults(val.results)
    this.exportData({
      ...exportDataParams,
      suppliedData: newResults,
    })
  }

  protected async exportPdf(
    newResults: any,
    pdfEndpoint: string,
    additionalProps: any = {},
    dataKey: string = 'rows',
  ) {
    const formData = new FormData();
    forEach(additionalProps, (value, prop) => {
      console.log(prop, value);
      formData.append(prop, value);
    });
    formData.append(dataKey, JSON.stringify(newResults));

    return Axios.post(pdfEndpoint, formData);
  }

  protected buildSearchParams() {
    const parcelSearchParams: any = {
      ...this.getParams(),
      offset: 0,
    }
    if (this.sortModel) {
      Object.assign(parcelSearchParams, {
        sort_by: this.sortModel.colId,
        order_by: this.sortModel.sort,
      })
    }
    return parcelSearchParams
  }
}
