




























































import { debounce, isString } from 'lodash';
import {
  Component, Emit, Prop, Vue, Watch,
} from 'vue-property-decorator';

import User from '@/entities/User';
import UsersService from '@/services/users';

@Component({
  name: 'user-search',
})
export default class UserSearch extends Vue {
  @Prop({
    type: [String, Object],
    default: null,
  }) private readonly selected!: string | string[] | User | User[];

  @Prop({
    type: String,
    default: null,
  }) private readonly itemValue!: keyof User;

  @Prop({
    type: Boolean,
    default: true,
  }) private readonly multiple!: boolean;

  // Services
  private service: UsersService = new UsersService();

  private userSearch: string = '';
  private debounce: Function = null;
  private foundUsers: User[] = [];
  private selectedUsers: User | User[] = [];

  private isSearching: boolean = false;

  // Watchers
  @Watch('userSearch')
  async onUserSearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.debounce) {
      this.debounce = debounce(async () => {
        this.isSearching = true;
        const response = await this.service.getUsers(this.userSearch);
        this.isSearching = false;

        this.foundUsers = response;
      }, 500);
    }

    this.debounce();
  }

  @Watch('selected', { immediate: true })
  async onSelectedChanged(val: string | string[] | User | User[]) {
    if (!val) {
      return;
    }

    const grabUsers = async (value: string | User) => {
      if (isString(value)) {
        return this.service.getUserById(value);
      }

      return value;
    };

    const valueList = Array.isArray(val) ? val : [val];

    const users = await Promise.all(
      valueList.map(grabUsers),
    );

    this.foundUsers = users;

    this.selectedUsers = this.multiple ? users : users[0];
  }

  // Methods
  getUserProfilePic(userId: string) {
    return `${this.$baseUrl}/users/${userId}/files/profile`;
  }

  getUserDescription(user: User) {
    return `${user} (${user.email})`;
  }

  filterUsers(item: User, queryText: string, itemText: string) {
    return item.toString().toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || (item.email && item.email.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1);
  }

  remove(item: any) {
    if (!Array.isArray(this.selectedUsers)) {
      throw new Error('Removal should not be called from a singular.');
    }

    const newValue = [...this.selectedUsers];
    newValue.splice(
      this.selectedUsers.findIndex((value) => value === item),
      1,
    );

    this.update(newValue);
  }

  @Emit('input')
  update(items: User[]) {
    this.selectedUsers = items;

    const valueMapper = (user: User) => (this.itemValue ? (user as any)[this.itemValue] : user)

    if (Array.isArray(items)) {
      return items.map(valueMapper);
    }

    return valueMapper(items);
  }

  @Emit('change')
  change(items: User | User[]) {
    this.userSearch = null;

    const valueMapper = (user: User) => (this.itemValue ? (user as any)[this.itemValue] : user)

    if (Array.isArray(items)) {
      return items.map(valueMapper);
    }

    return valueMapper(items);
  }
}
