






















































































































































import {
  Component,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { AgGridVue } from 'ag-grid-vue';
import jsPDF from 'jspdf';
import { ColDef, SortChangedEvent } from 'ag-grid-community';
import { DataTableHeader, InputValidationRule } from 'vuetify';
import { uniqBy } from 'lodash';
import Axios, { AxiosResponse, CancelToken } from 'axios';

import EscrowHistoryService from '@/services/escrowHistories';
import LenderService from '@/services/lenders';

import { inchesToMM } from '@/helpers/reportHelpers';
import ReportPdfBuilder from '@/helpers/exports/pdf/ReportPdfBuilder';

import Lender from '@/entities/Lender';
import Term from '@/entities/Term';
import Agency from '@/entities/Agency';
import EscrowHistory from '@/entities/EscrowHistory';

import states from '@/data/states';

import AgencySearch from '@/views/agencies/AgencySearch.vue';

import { fullDate, shortDate } from '@/validations/vuetify';

import capitalTaxLogo from '@/assets/logo.jpg';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue';
import { formatCurrency } from '@/components/ag-grid/formatters/CurrencyFormatter';
import DateField from '@/components/inputs/dates/DateField.vue';
import ExportDataParams from './models/ExportDataParams';
import SsrmGridReport from './SsrmGridReport.vue';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams';
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams';
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams';
import ReportDatasourceParamBuilder from './ag-grid/datasource/builder/ReportDatasourceParamBuilder';
import ReportName from './models/ReportName';
import ReportDatasource from './ag-grid/datasource/ReportDatasource';
import quickSearchParams from './ag-grid/params/quickSearchParams';

interface ReportRow {
  lenderNumber: string,
  loanNumber: string,
  loanType: string,
  name: string,
  address: string,
  agencyNumber: string,
  year: string,
  term: string,
  parcelNumber: string,
  reportedDate: string,
  amountReported: number,
  dueDate: string,
  interests: number,

  agencyName: string,
  agencyState: string,
  agencyCounty: string,
  agencyPayableTo: string,
  duplicateBillAmount: number,
}

interface PackListRow {
  state: string,
  dueDate: string,
  taxOffice: string,
  county: string,
  parcelCount: number,
  payableTo: string,
  duplicateBillAmount: string,

  lenderNumber: string,
  agencyNumber: string,
}

@Component({
  name: 'pack-list-report',
  components: {
    DateField,
    AgGridVue,
    SsrmGridOmnifilter,
    AgencySearch,
  },
})
export default class PackListReport extends SsrmGridReport<EscrowHistory, ReportRow> {
  protected pageSizes = [500, 10000];

  // Indicators
  private isGeneratingPDF = false;

  // Services
  private service: EscrowHistoryService = new EscrowHistoryService();
  private lenderService: LenderService = new LenderService();

  // User options
  private states: DataTableHeader[] = states;
  private escrowYearItems: string[] = [];
  private termItems: any[] = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));
  private reportedDateMode: 'all' | 'isNull' | 'isNotNull' | 'specificDate' | 'dateRange' = 'all';
  private dueDateMode: 'all' | 'specificDate' | 'dateRange' | 'before' = 'all';
  private amountDueValue = 'not_null';

  // User inputs
  private pickedState: string = '';
  private year: string = '';
  private term: string = null;
  private reportedDates: Date[] = [];
  private dueDates: Date[] = [];
  private title: string = '';
  private batchNumber: string = '';
  private hideDuplicateBillFee = false;
  private hidePayableTo = false;

  private selectedAgencies: Agency[] = [];
  private lenders: (Lender & { displayString?: string })[] = [];

  // Input rules
  private rules: { [index: string]: InputValidationRule } = {
    validDate: fullDate,
    validShortDate: shortDate,
  };
  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan E/N',
      field: 'loanType',
      width: 100,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Term',
      field: 'term',
      sortable: false,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      sortable: false,
      type: 'shortDate',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Taxes Due',
      field: 'amountReported',
      sortable: false,
      editable: true,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Interest',
      field: 'interests',
      sortable: false,
      editable: true,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      minWidth: 150,
      flex: 1,
      type: 'date',
      ...defaultDateFilterParams,
    },
    {
      headerName: 'Payable To',
      field: 'agencyPayableTo',
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Duplicate Bill Fee',
      field: 'duplicateBillAmount',
      sortable: false,
      editable: false,
      type: 'currency',
      ...defaultNumberFilterParams,
    },
    {
      ...quickSearchParams,
    },
  ];

  convertResults(results: EscrowHistory[]): ReportRow[] {
    return results.map((escrowHistory: EscrowHistory) => ({
      lenderNumber: escrowHistory.lenderNumber,
      loanNumber: escrowHistory.loanNumber,
      loanType: escrowHistory.parcelType,
      name: escrowHistory.name,
      address: escrowHistory.address,
      agencyNumber: escrowHistory.agency ? escrowHistory.agency.capAgency : '',
      year: escrowHistory.year,
      term: Term[escrowHistory.term as keyof typeof Term],
      parcelNumber: escrowHistory.parcelNumber,
      reportedDate: escrowHistory.reportedDate,
      amountReported: escrowHistory.amountReported,
      dueDate: escrowHistory.dueDate,
      interests: 0.00,

      agencyName: escrowHistory.agency.name,
      agencyCounty: (escrowHistory.agency.address && escrowHistory.agency.address.value && escrowHistory.agency.address.value.county) || '',
      agencyState: (escrowHistory.agency.address && escrowHistory.agency.address.value && escrowHistory.agency.address.value.state) || '',
      agencyPayableTo: escrowHistory.agency.payableTo,
      duplicateBillAmount: escrowHistory.agency.duplicateBillAmount,
    }));
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      include_agency: true,
    };
  }

  // Computed
  get hasRequiredInput(): boolean {
    if (!this.year || !this.term) {
      return false;
    }

    if (this.reportedDateMode === 'dateRange' && (!this.reportedDates[0] || !this.reportedDates[1])) {
      return false;
    }

    if (this.reportedDateMode === 'specificDate' && (!this.reportedDates[0])) {
      return false;
    }

    if (this.dueDateMode === 'dateRange' && (!this.dueDates[0] || !this.dueDates[1])) {
      return false;
    }

    if (this.dueDateMode === 'specificDate' && (!this.dueDates[0])) {
      return false;
    }

    return true;
  }

  get advancedSearch() {
    const advancedSearch: any = {
      amount_due_value: this.amountDueValue,
      history_year_in: [this.year],
      history_term_in: [this.term],
      parcel_type_in: ['E', 'EN'],
    };

    if (this.batchNumber && this.batchNumber !== '') {
      advancedSearch.batch_number_in = [this.batchNumber];
    }

    if (this.reportedDateMode) {
      advancedSearch.report_date = this.reportedDateMode;
      if (this.reportedDateMode === 'dateRange') {
        [advancedSearch.start_date, advancedSearch.end_date] = this.reportedDates;
      } else if (this.reportedDateMode === 'specificDate') {
        [advancedSearch.specific_date] = this.reportedDates;
      }
    }

    if (this.dueDateMode) {
      advancedSearch.due_date = this.dueDateMode;
      if (this.dueDateMode === 'dateRange') {
        [advancedSearch.due_date_start, advancedSearch.due_date_end] = this.dueDates;
      } else if (this.dueDateMode === 'specificDate') {
        [advancedSearch.due_date_specific] = this.dueDates;
      } else if (this.dueDateMode === 'before') {
        [advancedSearch.due_date_before] = this.dueDates;
      }
    }

    if (this.selectedAgencies.length !== 0) {
      advancedSearch.agency_numbers = this.selectedAgencies.map((a) => a.capAgency);
    }

    if (this.pickedState && this.pickedState !== '') {
      advancedSearch.agency_states = [this.pickedState];
    }

    return advancedSearch;
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 0; i <= 20; i += 1) {
      const relevantYear = currentYear - i + 1;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [, this.year] = this.escrowYearItems;
    this.term = this.termItems[1].value;
    await this.getAllLenders();
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
  }

  refreshRows() {
    this.latestResults = [];
    this.results = [];
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }

  private reportDatasource() {
    const {
      onSortModelChanged,
      rowFetcherParams,
      httpRequestParams,
    } = new ReportDatasourceParamBuilder<EscrowHistory, ReportRow>(
      ReportName.PackList,
      this.service.getAllEscrowHistories,
      this.service.getTotalEscrowHistories,
      this.sortModel,
      this.onResultsChanged,
      this.getParams,
    ).build()
    return new ReportDatasource<EscrowHistory, ReportRow>(
      onSortModelChanged,
      this.setLoading,
      this.resetLoading,
      rowFetcherParams,
      httpRequestParams,
    )
  }
  drawRectangle(pdf: jsPDF, x: number, y: number, w: number, h: number) {
    pdf.line(x, y, x, y + h); // left
    pdf.line(x + w, y, x + w, y + h); // right
    pdf.line(x, y, x + w, y); // top
    pdf.line(x, y + h, x + w, y + h); // bottom
  }

  drawPerforatedLines(pdf: jsPDF) {
    const y1 = inchesToMM(3.75);
    const y2 = y1 + inchesToMM(3.375);
    for (let i = 0; i < 63; i += 1) {
      const x1 = inchesToMM(0.375 + (i * 0.125));
      const x2 = x1 + inchesToMM(0.0625);
      pdf.line(x1, y1, x2, y1);
      pdf.line(x1, y2, x2, y2)
    }
  }

  async exportTable() {
    this.exportReportTable(
      new ExportDataParams({
        file: `PackList-${this.term}-${this.year}`,
      }),
      this.service.getAllEscrowHistories,
    )
  }

  async createPDF() {
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally(this.results);
      this.generatePDFOnServer();
      this.gridApi.hideOverlay();
    }, 100);
  }

  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllEscrowHistories(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const res = await this.exportPdf(
      newResults,
      '/pdf/pack-list-report',
      {
        resData: JSON.stringify(newResults),
        title: this.title,
        lenders: JSON.stringify(this.lenders),
        year: this.year,
        term: this.term,
        hidePayableTo: this.hidePayableTo.toString(),
        hideDuplicateBillFee: this.hideDuplicateBillFee.toString(),
      },
      'results',
    );
    this.isGeneratingPDF = false;
    return window.open(res.data.link, '_blank');
  }

  async generatePDFLocally(rows: any) {
    // Separate into groups if necessary
    const groupedData: Map<string, Map<string, ReportRow[]>> = new Map();

    rows.forEach((row: any) => {
      const lenderEntry = groupedData.get(row.lenderNumber);
      if (!lenderEntry) {
        const map = new Map<string, ReportRow[]>();
        map.set(row.agencyNumber, [row]);
        groupedData.set(row.lenderNumber, map);
      } else {
        const agencyEntry = lenderEntry.get(row.agencyNumber);
        if (!agencyEntry) {
          lenderEntry.set(row.agencyNumber, [row]);
        } else {
          agencyEntry.push(row);
        }
      }
    });

    const packListRows: PackListRow[] = [];
    groupedData.forEach((agencyMap, lenderKey) => {
      agencyMap.forEach((reportRows, agencyKey) => {
        packListRows.push({
          state: reportRows[0].agencyState,
          dueDate: reportRows[0].dueDate,
          taxOffice: reportRows[0].agencyName,
          county: reportRows[0].agencyCounty,
          parcelCount: uniqBy(reportRows, 'parcelNumber').length,
          payableTo: reportRows[0].agencyPayableTo,
          duplicateBillAmount: formatCurrency(reportRows[0].duplicateBillAmount),

          lenderNumber: lenderKey,
          agencyNumber: agencyKey,
        });
      });
    });

    packListRows.sort((a, b) => {
      if (a.taxOffice.localeCompare(b.taxOffice) !== 0) {
        return a.taxOffice.localeCompare(b.taxOffice)
      }
      return a.county.localeCompare(b.county);
    })

    const hiddenFields: (keyof PackListRow)[] = [
      'lenderNumber',
      'agencyNumber',
      this.hideDuplicateBillFee ? 'duplicateBillAmount' : 'lenderNumber',
      this.hidePayableTo ? 'payableTo' : 'lenderNumber',
    ];

    const pdfBuilder: ReportPdfBuilder<PackListRow> = new ReportPdfBuilder<PackListRow>(packListRows, {
      groupBy: 'lenderNumber',
      exclusions: [...hiddenFields],
      footer: 5,
      orientation: 'p',
      header: 20,
      groupSort: 'asc',
    });

    const img = document.createElement('img');
    img.src = capitalTaxLogo;

    pdfBuilder.setHeaderDraw((doc: jsPDF, height, width, pageNumber, grouping: PackListRow[]) => {
      const marginLeft = 40;
      const lender = this.lenders.find((l) => l.id === grouping[0].lenderNumber);
      const lenderName = lender ? lender.name : '';

      // Header image
      doc.addImage(img, 'PNG', marginLeft, 24, 150, 91);

      // Header text
      const defaultFontSize = 20;
      doc.setFontSize(defaultFontSize);
      const headerText = `${grouping[0].lenderNumber} ${lenderName}`;
      const headerUnits = doc.getStringUnitWidth(headerText);
      const defaultWidth = defaultFontSize * headerUnits;
      const maxWidth = doc.internal.pageSize.getWidth() - (marginLeft * 2);

      if (defaultWidth >= maxWidth) {
        const bestFitFontSize = Math.floor(maxWidth / headerUnits);
        doc.setFontSize(bestFitFontSize >= 6 ? bestFitFontSize : 6);
      }

      doc.text(`${grouping[0].lenderNumber} ${lenderName}`, marginLeft, 134, { align: 'left' });
      if (this.title && this.title !== '') {
        doc.text(this.title, marginLeft, 158, { align: 'left' });
      } else {
        doc.text(`${this.year} ${Term[this.term as keyof typeof Term].toUpperCase()} REAL ESTATE TAX BILL PACKLIST`, marginLeft, 158, { align: 'left' });
      }
    });

    pdfBuilder.setFooterDraw((doc: jsPDF, height, width, pageNumber, grouping: PackListRow[], startY) => {
      const textSize = 12;
      const contactStartX = 40;

      doc.setFontSize(textSize);

      const printedDate = DateTime.fromJSDate(new Date()).toFormat('MM/dd/yyyy');
      doc.text(`SHIPPING DATE: ${printedDate}`, contactStartX, doc.internal.pageSize.height - 10);
    });

    pdfBuilder.setGroupFooterDraw((doc: jsPDF, height, width, pageNumber, grouping: PackListRow[], startY) => {
      const textSize = 12;
      const contactStartX = 40;

      doc.setFontSize(textSize);
      const totalGroupParcels = grouping.reduce((total, current) => {
        total += current.parcelCount;
        return total;
      }, 0)
      doc.text(`TOTAL PARCELS: ${totalGroupParcels}`, contactStartX, startY + textSize + 5);
    });

    const pdf = pdfBuilder.build();

    pdf.save(`PackList-${this.term}-${this.year}.pdf`);
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_desc: false,
    };
    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
      this.lenders.forEach((lender) => {
        lender.displayString = `${lender.name} - ${lender.id}`;
      });
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
  }
}
