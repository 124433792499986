import Axios, { CancelToken } from 'axios';

import IServiceParcel from '@/services/api/models/IServiceParcel';
import IEntityCountResponse from './models/IEntityCountResponse';

interface ParcelsResponse {
  count: any;
  results: IServiceParcel[];
}

export default {
  async getAllParcels(params: any, cancelToken?: CancelToken): Promise<ParcelsResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<ParcelsResponse>('/ssrm/parcels', config);

    return response.data;
  },

  async getParcel(id: string): Promise<IServiceParcel> {
    const response = await Axios.get<IServiceParcel>(`/parcels/${id}`);

    return response.data;
  },
  async getTotalParcels(params: any, cancelToken?: CancelToken): Promise<IEntityCountResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }
    const response: any = await Axios.get<any>('/ssrm/parcels/count', config);
    return response.data;
  },
};
