































import {
  Component, Watch, Vue, Emit,
} from 'vue-property-decorator';
import { debounce } from 'lodash';

import AgencyService from '@/services/agencies';

import Agency from '@/entities/Agency';

@Component({
  name: 'agency-search',
})
export default class AgencySearch extends Vue {
  // Services
  private service: AgencyService = new AgencyService();

  private agencySearch: string = '';
  private agencyDebounce: Function = null;
  private foundAgencies: Agency[] = [];
  private selectedAgencies: Agency[] = [];

  private isSearching: boolean = false;

  // Watchers
  @Watch('agencySearch')
  async onAgencySearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.agencyDebounce) {
      this.agencyDebounce = debounce(async () => {
        const response = await this.service.getAllAgencies({
          search_field: 'name_or_number',
          search_value: this.agencySearch,
          limit: 100,
          agency_selector_search: true,
        });

        this.foundAgencies = response.results;
      }, 500);
    }

    this.agencyDebounce();
  }

  // Methods
  determineAgencyName(item: Agency): string {
    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`;
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`;
    }

    nameString += `, ${item.capAgency}`;

    return nameString;
  }

  remove(item: any) {
    const newValue = [...this.selectedAgencies];
    newValue.splice(
      this.selectedAgencies.findIndex((value) => value === item),
      1,
    );

    this.update(newValue);
  }

  @Emit('input')
  update(items: Agency[]) {
    this.selectedAgencies = items;
    return this.selectedAgencies.map((agency) => (this.$attrs['item-value'] ? (agency as any)[this.$attrs['item-value']] : agency));
  }
}
