


































































































































































import {
  Component,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import jsPDF from 'jspdf';
import { AgGridVue } from 'ag-grid-vue';
import { ColDef, SortChangedEvent } from 'ag-grid-community';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { DataTableHeader } from 'vuetify';
import Axios, { AxiosResponse, CancelToken } from 'axios';

import states from '@/data/states';

import ParcelService from '@/services/parcels';

import Lender from '@/entities/Lender';
import Agency from '@/entities/Agency';
import Address from '@/entities/Address';
import Parcel from '@/entities/Parcel';

import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';
import { formatCurrency } from '@/components/ag-grid/formatters/CurrencyFormatter';

import DateField from '@/components/inputs/dates/DateField.vue'
import LenderSearch from '@/views/lenders/LenderSearch.vue';
import AgencySearch from '@/views/agencies/AgencySearch.vue';
import BackgroundGridReport from '@/views/reports/BackgroundGridReport.vue';
import SsrmGridOmnifilter from '@/components/inputs/SsrmGridOmnifilter.vue'
import SsrmGridReport from './SsrmGridReport.vue'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ReportName from './models/ReportName'
import ReportDatasource from './ag-grid/datasource/ReportDatasource'
import ExportDataParams from './models/ExportDataParams'
import defaultTextFilterParams from './ag-grid/params/defaultTextFilterParams'
import quickSearchParams from './ag-grid/params/quickSearchParams'
import defaultNumberFilterParams from './ag-grid/params/defaultNumberFilterParams'
import defaultDateFilterParams from './ag-grid/params/defaultDateFilterParams'
import ReportDatasourceParamBuilder from './ag-grid/datasource/builder/ReportDatasourceParamBuilder'
import ReportDatasourceBuilder from './ag-grid/datasource/builder/ReportDatasourceBuilder';

type PdfColDef = ColDef & {
  pdfWidth?: number,
};

interface ReportRow {
  dtcoName: string,
  dtcoCounty: string,
  dtcoState: string,

  lenderNumber: string,
  loanNumber: string,
  parcelNumber: string,
  parcelType: string,

  addressLine: string,
  state: string,
  county: string,
  legal: string,
  lot: string,
  block: string,
  unit: string,
  building: string,
  reportedDate: string,
  name: string,
  status: string,
  year: string,
  base: string,
  paidDelqCheckBoxes: string,
  reportYear1: string,
  pastYears: string,
  agencies: string,
}

@Component({
  name: 'audit-search-report',
  components: {
    DateField,
    AgGridVue,
    SsrmGridOmnifilter,
    LenderSearch,
    AgencySearch,
  },
})
export default class AuditSearchReport extends SsrmGridReport<Parcel, ReportRow> {
  protected pageSizes = [500, 10000];
  private selectedLenders: Lender[] = [];

  private title: string = '';
  private showPageNumber: boolean = false;
  private reportYear1: string = new Date().getFullYear().toString();

  private searchLoanEscrowType: string = null;
  private searchParcelStartDate: string = null;
  private searchParcelEndDate: string = null;
  private searchDTCOState: string = null;
  private searchHistoryStatus: string = null;
  private includeExcludeLenders: string = 'include';
  private parcelType: 'e_and_en' | 'n_and_en' | 'all' = 'e_and_en';

  private states: DataTableHeader[] = states;

  private service: ParcelService = new ParcelService();

  private pages: { [index: string]: { [index: string]: { [index: string]: { row: ReportRow }}}} = {};

  private exportText = 'EXPORT';
  private isGeneratingPDF = false;
  private generatePDFText = 'Create PDF';
  private yearOptions: string[] = [];
  private selectedAgencies: Agency[] = [];
  private hideTaxOffices = false;

  private hideLegalInPdf = false;
  private hideLotBlockUnitInPdf = false;
  protected serverSideStoreType = 'full'
  protected cacheBlockSize = 100
  protected rowModelType = 'serverSide'
  protected paginationPageSize = 15

  protected columnDefs: PdfColDef[] = [
    {
      headerName: 'Parcel E/N',
      field: 'parcelType',
      width: 110,
      pdfWidth: 10,
      sortable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      pdfWidth: 20,
      sortable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      ...defaultTextFilterParams,
      width: 100,
      pdfWidth: 14,
      sortable: true,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
      pdfWidth: 30,
      ...defaultTextFilterParams,
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      pdfWidth: 20,
      sortable: true,
      ...defaultTextFilterParams,
    },
    {
      headerName: 'Address',
      field: 'addressLine',
      width: 225,
      pdfWidth: 25,
      ...defaultTextFilterParams,
      sortable: true,
    },
    {
      headerName: 'Legal',
      field: 'legal',
      pdfWidth: 32,
      ...defaultTextFilterParams,
      sortable: true,
    },
    {
      headerName: 'Lot',
      field: 'lot',
      pdfWidth: 12,
      ...defaultTextFilterParams,
      sortable: true,
    },
    {
      headerName: 'Block',
      field: 'block',
      pdfWidth: 12,
      ...defaultTextFilterParams,
      sortable: true,
    },
    {
      headerName: 'Unit',
      field: 'unit',
      pdfWidth: 12, // 10,
      ...defaultTextFilterParams,
      sortable: true,
    },
    {
      ...quickSearchParams,
    },
  ];

  protected previewTableColumnDefs: PdfColDef[] = this.columnDefs.concat([
    {
      headerName: 'Paid / Delq',
      field: 'status',
      sortable: false,
      ...defaultTextFilterParams,
      pdfWidth: 10,
      flex: 1,
    },
  ])

  // Computed
  get hasRequiredInput(): boolean {
    return (this.searchDTCOState !== null && this.searchDTCOState !== '') || (this.selectedAgencies !== null && this.selectedAgencies.length !== 0);
  }

  get canCreatePDF(): boolean {
    return this.hasRequiredInput
      && this.reportYear1 !== null && this.reportYear1 !== '';
  }

  get advancedSearch() {
    const advancedSearch: any = {
      dtco_state: this.searchDTCOState,
      dtco_in_array: this.selectedAgencies && this.selectedAgencies.length > 0 ? this.selectedAgencies.map((a) => a.agencyId) : null,
      added_between: [this.searchParcelStartDate, this.searchParcelEndDate],
      delinquent_filter: this.searchHistoryStatus,
    };

    let selectedParcelType = [] as string[]
    if (this.parcelType && this.parcelType !== 'all') {
      if (this.parcelType === 'e_and_en') {
        selectedParcelType = ['E', 'EN'];
      } else if (this.parcelType === 'n_and_en') {
        selectedParcelType = ['N', 'EN'];
      }
      advancedSearch.parcel_type_in = selectedParcelType
    }

    if (this.selectedLenders.length > 0) {
      if (this.includeExcludeLenders === 'include') {
        advancedSearch.lender_numbers = this.selectedLenders.map((lender) => lender.id);
      } else if (this.includeExcludeLenders === 'exclude') {
        advancedSearch.excluded_lender_numbers = this.selectedLenders.map((lender) => lender.id);
      }
    }

    if (this.searchHistoryStatus === 'paid') {
      advancedSearch.history_year_in = [this.reportYear1];
    }

    return advancedSearch;
  }

  async created() {
    const nextYear = (new Date()).getFullYear() + 1;

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = nextYear - i;
      this.yearOptions.push(relevantYear.toString());
    }
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
  }

  onSortChanged(params: SortChangedEvent) {
    this.onSsrmSortChanged(params);
  }

  refreshRows() {
    this.latestResults = []
    this.results = []
    this.pages = {}
    setTimeout(() => {
      if (this.gridApi) {
        this.resetRowCounts()
        this.datasource = this.reportDatasource()
        this.gridApi.setServerSideDatasource(this.datasource)
      }
    }, 0)
  }
  private reportDatasource() {
    return new ReportDatasourceBuilder<Parcel, ReportRow>(
      ReportName.AuditSearch,
      this.service.getAllParcels,
      this.service.getTotalParcels,
      this.sortModel,
      this.setLoading,
      this.resetLoading,
      this.onResultsChanged,
      this.getParams,
    ).build()
  }

  convertResults(results: Parcel[]): ReportRow[] {
    const parcelNumberSet = new Set(results.map((r) => r.parcelNumber));
    parcelNumberSet.delete(null)
    const parcelNumberArrayFromSet = Array.from(parcelNumberSet)
    const parcelsByParcelNumber: { [index: string]: Parcel[] } = {};
    results.forEach((r) => {
      if (!parcelsByParcelNumber[r.parcelNumber]) {
        parcelsByParcelNumber[r.parcelNumber] = [];
      }
      parcelsByParcelNumber[r.parcelNumber].push(r);
    });

    let rows: ReportRow[] = parcelNumberArrayFromSet.map((p) => parcelsByParcelNumber[p]).map((parcels) => {
      const loanNumbers = Array.from(new Set(parcels.map((parcel) => parcel.loanNumber)));
      const lenderNumbers = Array.from(new Set(parcels.map((parcel) => parcel.lenderNumber)));
      const names = Array.from(new Set(parcels.map((parcel) => parcel.name)));
      const addresses = Array.from(new Set(parcels.map((parcel) => Address.fullAddress(parcel.address.value))));
      const agencies = Array.from(new Set(parcels.map((parcel) => parcel.agencies.map((agency) => agency.name)).flat()));
      let historyForYear: any = null;
      parcels.forEach((parcel) => parcel.agencies.forEach((agency) => {
        const history = agency.nonEscrowHistory.find((nonEscrowHistory) => nonEscrowHistory.year === this.reportYear1);
        historyForYear = history || historyForYear;
      }));
      const parcel = parcels[0];
      const row: ReportRow = {
        dtcoName: parcel.agencies && parcel.agencies[0] && parcel.agencies[0].delinquentTaxCollectingOffice ? parcel.agencies[0].delinquentTaxCollectingOffice.name : '',
        dtcoCounty: parcel.agencies && parcel.agencies[0] && parcel.agencies[0].delinquentTaxCollectingOffice ? parcel.agencies[0].delinquentTaxCollectingOffice.county : '',
        dtcoState: parcel.agencies && parcel.agencies[0] && parcel.agencies[0].delinquentTaxCollectingOffice ? parcel.agencies[0].delinquentTaxCollectingOffice.state : '',

        loanNumber: loanNumbers.join(', '),
        lenderNumber: lenderNumbers.join(', '),
        parcelNumber: parcel.parcelNumber,
        parcelType: parcel.parcelType,
        state: parcel.address && parcel.address.value ? parcel.address.value.state : null,
        county: parcel.address && parcel.address.value ? parcel.address.value.county : null,
        legal: parcel.legal,
        lot: parcel.address && parcel.address.value ? parcel.address.value.lot : null,
        block: parcel.address && parcel.address.value ? parcel.address.value.block : null,
        unit: parcel.address && parcel.address.value ? parcel.address.value.unit : null,
        building: parcel.address && parcel.address.value ? parcel.address.value.building : null,
        reportedDate: parcel.dateAdded,
        name: names.join('; '),
        status: historyForYear && historyForYear.status ? historyForYear.status.value : '',
        year: historyForYear ? historyForYear.year : this.reportYear1,
        base: historyForYear ? historyForYear.base : '',
        addressLine: addresses.join('; '),
        paidDelqCheckBoxes: `__ Paid   __ Delq${parcel.problem.verified ? '\n* Problem *' : ''}`,
        reportYear1: historyForYear ? formatCurrency(historyForYear.base) : '',
        pastYears: Array.from(
          new Set(
            parcels.reduce((arr: string[], p) => {
              p.agencies.forEach((a) => {
                arr.push(
                  ...a.nonEscrowHistory.filter(
                    (h) => h.base && (parseInt(this.reportYear1, 10) > parseInt(h.year as string, 10)),
                  ).map(((h) => `${h.year}: ${formatCurrency(h.base)}`)),
                );
              })
              return arr;
            }, []),
          ),
        ).sort((a, b) => -a.localeCompare(b)).join('\n'),
        agencies: agencies.join('\n'),
      };

      const parcelsByDTCO: { [index: string]: Parcel[] } = {};
      parcels.forEach((theParcel) => {
        theParcel.agencies.forEach((agency) => {
          if (agency.delinquentTaxCollectingOffice) {
            if (!parcelsByDTCO[agency.delinquentTaxCollectingOffice.agencyId]) {
              parcelsByDTCO[agency.delinquentTaxCollectingOffice.agencyId] = [];
            }
            parcelsByDTCO[agency.delinquentTaxCollectingOffice.agencyId].push(theParcel);
          }
        })
      });

      Object.values(parcelsByDTCO).forEach((parcelsInDTCO) => {
        const { delinquentTaxCollectingOffice } = parcelsInDTCO[0].agencies[0];
        if (delinquentTaxCollectingOffice && delinquentTaxCollectingOffice.state && delinquentTaxCollectingOffice.county) {
          const stateCountyString = `${delinquentTaxCollectingOffice.state}-${delinquentTaxCollectingOffice.county}`;
          if (!this.pages[stateCountyString]) {
            this.pages[stateCountyString] = {};
          }
          if (!this.pages[stateCountyString][delinquentTaxCollectingOffice.capAgency]) {
            this.pages[stateCountyString][delinquentTaxCollectingOffice.capAgency] = {};
          }
          this.pages[stateCountyString][delinquentTaxCollectingOffice.capAgency][parcelsInDTCO[0].parcelId] = {
            row,
          };
        }
      });

      return row;
    });

    if (this.searchHistoryStatus === 'paid') {
      rows = rows.filter((r) => r.status.toUpperCase().includes('PAID'))
    }

    return rows;
  }

  getParams() {
    const params: any = {
      advanced_search: this.advancedSearch,
      search_type: 'non_escrow_history',
      include_name: true,
    };

    return params;
  }

  getRows(params: any, limit: number, offset: number) {
    const finalParams = { limit, offset, ...params };

    // Get all the loans
    return this.makeCancellableRequest(this.service.getAllParcels, finalParams)
      .then((response) => {
        const { results } = response;
        return results;
      });
  }

  async exportTable() {
    this.exportText = 'Creating File';
    this.exportReportTable(
      new ExportDataParams({
        file: 'AuditSearchReport',
        inclusions: ['dtcoName', 'status', 'year', 'base'],
      }),
      this.service.getAllParcels,
    )
    this.exportText = 'EXPORT';
  }

  async createPDF() {
    this.generatePDFText = 'Creating File';
    this.isGeneratingPDF = true;
    setTimeout(() => {
      // this.generatePDFLocally();
      this.generatePDFOnServer();
      this.generatePDFText = 'Create PDF';
      this.gridApi.hideOverlay();
    }, 100);
  }

  async generatePDFOnServer() {
    const searchParams = this.buildSearchParams();
    const getAllResults = await this.service.getAllParcels(searchParams);
    const newResults = this.convertResults(getAllResults.results);
    const res = await this.exportPdf(
      newResults,
      '/pdf/audit-search-report',
      {
        title: this.title,
        pages: JSON.stringify(Object.values(this.pages)),
        showPageNumber: this.showPageNumber ? 'true' : 'false',
        reportYear1: this.reportYear1,
        columnDefs: JSON.stringify(this.columnDefs.slice(0, this.columnDefs.length - 1)),
        hideLegalInPdf: this.hideLegalInPdf ? 'true' : 'false',
        hideLotBlockUnitInPdf: this.hideLotBlockUnitInPdf ? 'true' : 'false',
        hideTaxOffices: this.hideTaxOffices ? 'true' : 'false',
      },
      'results',
    );
    this.isGeneratingPDF = false;
    return window.open(res.data.link, '_blank');
  }

  generatePDFLocally() {
    if (this.results.length === 0) {
      return;
    }

    const reportPdf: jsPDF = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'letter',
    });

    const pdfColumnDefs: PdfColDef[] = this.columnDefs.concat([
      {
        headerName: 'Paid / Delq',
        field: 'paidDelqCheckBoxes',
        sortable: false,
        pdfWidth: 25,
      },
      {
        headerName: this.reportYear1,
        field: 'reportYear1',
        pdfWidth: 20,
      },
      {
        headerName: 'Past Years',
        field: 'pastYears',
        pdfWidth: 25,
      },
    ]).filter((value) => {
      if (value.field === 'addressLine') {
        value.pdfWidth = 25 + (this.hideLegalInPdf ? 35 : 0) + (this.hideLotBlockUnitInPdf ? 36 : 0);
      }

      if (value.field === 'legal' && this.hideLegalInPdf) {
        return false;
      }

      if ((value.field === 'lot' || value.field === 'block' || value.field === 'unit') && this.hideLotBlockUnitInPdf) {
        return false;
      }

      return true;
    });

    Object.values(this.pages).sort((a, b) => {
      const aExample = Object.values(a)[0];
      const bExample = Object.values(b)[0];
      const aExample2ndLevel = Object.values(aExample)[0];
      const bExample2ndLevel = Object.values(bExample)[0];
      return aExample2ndLevel.row.dtcoName.localeCompare(bExample2ndLevel.row.dtcoName)
    }).forEach((page) => {
      const dtcos = Object.values(page);
      const exampleObjects = Object.values(dtcos[0]);
      reportPdf.setFontSize(10);
      reportPdf.text(this.title && this.title !== '' ? this.title : 'AUDIT REPORT', 10, 10);
      reportPdf.setFontSize(14);
      reportPdf.text(
        `County: ${exampleObjects[0].row.dtcoCounty || 'N/A'}    State: ${exampleObjects[0].row.dtcoState || 'N/A'}`,
        10,
        15,
      );

      let cursor = 20;
      dtcos.sort((a, b) => {
        const aExample = Object.values(a)[0];
        const bExample = Object.values(b)[0];
        return aExample.row.dtcoName.localeCompare(bExample.row.dtcoName);
      }).forEach((dtco) => {
        const rows = Object.values(dtco);
        reportPdf.text(`Delinquent Tax Collecting Office: ${rows[0].row.dtcoName}`, 10, cursor);

        const columns = pdfColumnDefs.filter((column) => column.headerName !== '').map((header) => {
          if (header.field === 'parcelNumber') {
            return {
              dataKey: 'parcelAndTaxOffices',
              header: `Parcel # ${this.hideTaxOffices ? '' : '\nTax Offices'}`,
            }
          }

          if (header.field === 'parcelType') {
            return {
              dataKey: 'parcelType',
              header: 'Tax\nType',
            }
          }

          if (header.field === 'lenderNumber') {
            return {
              dataKey: 'lenderNumber',
              header: 'Lender\nNumber',
            }
          }

          return {
            dataKey: header.field,
            header: header.headerName,
          }
        });

        const body = rows.sort(
          (a, b) => {
            if (!a.row.parcelNumber) {
              return -1;
            }

            if (!b.row.parcelNumber) {
              return 1;
            }

            return a.row.parcelNumber.localeCompare(b.row.parcelNumber)
          },
        ).reduce((rowAcc: any, r) => {
          const row: ReportRow & { parcelAndTaxOffices?: string } = { ...r.row };
          row.parcelAndTaxOffices = r.row.parcelNumber;
          const agenciesArray = r.row.agencies.split('\n').map((a: any) => {
            const agencyRow = {
              parcelAndTaxOffices: a,
            }
            return agencyRow;
          });
          return this.hideTaxOffices ? rowAcc.concat(row) : rowAcc.concat(row).concat(agenciesArray);
        }, []);

        const autoTableData: UserOptions = {
          columns,
          styles: {
            fontSize: 8,
          },
          headStyles: {
            fontSize: 8,
          },
          columnStyles: {
            parcelAndTaxOffices: {
              fontSize: 9,
            },
          },
          body,
          theme: 'grid',
          startY: cursor + 5,
          margin: {
            left: 5,
            right: 10,
            bottom: 15,
            top: 5,
          },
          didDrawPage: ((data: any) => {
            cursor = data.cursor.y;
          }),
          rowPageBreak: 'avoid',
        };

        pdfColumnDefs.forEach((column: any, columnIndex: number) => {
          const existingStyle = autoTableData.columnStyles[columns[columnIndex].dataKey];
          const defaultStyle = { cellWidth: column.pdfWidth ? column.pdfWidth : 'auto' };

          if (existingStyle) {
            Object.assign(existingStyle, defaultStyle);
          } else {
            autoTableData.columnStyles[columns[columnIndex].dataKey] = defaultStyle;
          }
        });

        autoTable(reportPdf, autoTableData);
        cursor += 10;
      });

      reportPdf.addPage();
    });

    reportPdf.deletePage(reportPdf.internal.getNumberOfPages());

    if (this.showPageNumber) {
      const pageCount = reportPdf.internal.getNumberOfPages();
      for (let i = 0; i < pageCount; i += 1) {
        reportPdf.setPage(i);
        const pageCurrent = reportPdf.internal.getCurrentPageInfo().pageNumber;
        reportPdf.setFontSize(10);
        reportPdf.text(`Page ${pageCurrent}`, 10, reportPdf.internal.pageSize.height - 5);
      }
    }

    const pageCount = reportPdf.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i += 1) {
      reportPdf.setPage(i);
      reportPdf.setFontSize(10);
      reportPdf.text(DateTime.local().toFormat('MM/dd/yyyy'), reportPdf.internal.pageSize.width - 30, reportPdf.internal.pageSize.height - 5);
    }

    reportPdf.save(`audit_search_report_${DateTime.local().toFormat('F')}.pdf`);
  }
}
