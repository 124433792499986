import { IServerSideGetRowsParams } from 'ag-grid-community'
import SearchResponse from '@/services/responses/SearchResponse'
import IRowFetcher from './IRowFetcher'
import IRowFetcherParams from '../params/IRowFetcherParams'
import SuccessCallback from '../successCallback/SuccessCallback'
import { GetAllType } from '../types'

export default class RowFetcher<T, RR> implements IRowFetcher {
  private successCallback: SuccessCallback<RR>
  private getAll: GetAllType<T>

  private cancelled: boolean = false

  constructor(rowFetcherParams: IRowFetcherParams<T>) {
    this.getAll = rowFetcherParams.getAll
    this.successCallback = new SuccessCallback<RR>(rowFetcherParams.successCallbackParams)
  }

  public reset() {
    this.successCallback.reset()
  }

  public cancel() {
    this.cancelled = true;
  }

  public async getRows(
    searchQueryParams: any,
    serverSideGetRowsParams: IServerSideGetRowsParams<any>,
  ): Promise<number> {
    try {
      const response = await this.getAll(searchQueryParams)

      if (this.cancelled) {
        return Promise.resolve(0);
      }

      return await this.successCallback.call(
        serverSideGetRowsParams,
        searchQueryParams,
        response.results,
      )
    } catch (error) {
      console.error(error)
      serverSideGetRowsParams.fail()
      return Promise.reject(error)
    }
  }
}
