
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  RowDataTransaction, SortChangedEvent,
} from 'ag-grid-community';
import { cloneDeep } from 'lodash';

import GridReport from '@/views/reports/GridReport.vue';
import Axios from 'axios';

@Component({
  name: 'background-grid-report',
})
export default class BackgroundGridReport<T, R = any> extends GridReport<R> {
  protected pageSizes: number[] = [500];

  protected latestResults: T[] = [];

  protected dataRetrievalToken: { cancel: Function } = null;

  protected copyResults: boolean = false;

  // Watchers
  @Watch('latestResults')
  onResultsChanged(val: T[]) {
    const newResults = this.convertResults(val);

    this.results.push(...newResults);

    if (this.copyResults) {
      this.original.push(...cloneDeep(newResults));
    }

    const transaction: RowDataTransaction = {
      add: newResults,
    };

    this.gridApi.applyTransaction(transaction);

    if (this.results.length > 0) {
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // Hooks
  beforeDestroy(): void {
    this.cancel();
  }

  // Methods
  onGridComplete(): void {}

  onBackgroundSortChanged(params: SortChangedEvent) {
    this.cancel();
    this.onGridSortChanged(params);
  }

  protected cancel() {
    if (this.dataRetrievalToken) {
      this.dataRetrievalToken.cancel();
      this.dataRetrievalToken = null;
    }
  }

  protected convertResults(results: T[]): R[] { return []; }

  protected getParams(): any { return {}; }

  protected getRows(params: any, limit: number, offset: number): Promise<any[]> { return Promise.resolve([]) }

  async getAllData() {
    const params = this.getParams();

    if (this.sortModel) {
      const duplicateIndex = this.sortModel.colId.indexOf('_');
      const strippedColId = this.sortModel.colId.substring(
        0,
        duplicateIndex === -1 ? this.sortModel.colId.length : duplicateIndex,
      );
      Object.assign(params, {
        sort_by: strippedColId,
        order_by: this.sortModel.sort,
      });
    }

    if (this.gridApi !== null) {
      this.$nextTick(() => {
        this.gridApi.showLoadingOverlay();
      });
    }

    this.isLoading = true;
    this.loadingOverlayComponentParams = {
      context: this.gridOptions.context,
      api: this.gridApi,
      columnApi: this.columnApi,
      text: 'Loading',
    };

    const retrieveAllData = new Promise<void>((resolve, reject) => {
      const that = this;
      this.dataRetrievalToken = {
        cancel: () => {
          resolve();
        },
      };

      function recursiveRetrieval(limit: number, offset: number): Promise<void> {
        if (that.pageSizes.length === 0) {
          throw new Error('You must assign at least one page size for background data retrieval.');
        }

        let page = 0;
        return that.getRows(params, limit, offset)
          .then((data) => {
            if (data.length === 0) {
              that.latestResults = [];
              return Promise.resolve();
            }

            that.latestResults = data;

            page += 1;
            const pageIndex = that.pageSizes.length <= page ? that.pageSizes.length - 1 : page;
            const newLimit = that.pageSizes[pageIndex];

            return recursiveRetrieval(newLimit, offset + limit);
          })
          .catch((e: Error) => {
            if (Axios.isCancel(e)) {
              that.resetResults();
            }

            that.latestResults = [];
            return Promise.reject();
          });
      }

      recursiveRetrieval(that.pageSizes[0], 0)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });

    retrieveAllData.finally(() => {
      this.isLoading = false;
    });

    return retrieveAllData;
  }
}
