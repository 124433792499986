

























































































































































































































import {
  Component, Prop, Watch, Mixins,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import Papa, { ParseResult } from 'papaparse';
import ParseAddress, { ParseAddressResult } from 'parse-address';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { snakeCase } from 'change-case';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, GridApi, GridReadyEvent, GridOptions, ColumnApi,
} from 'ag-grid-community';
import {
  tap, cloneDeep, pick, flatten,
} from 'lodash';
import { DateTime } from 'luxon';

import ImportService from '@/services/import';
import { ImportResult } from '@/services/api/ImportApiService';

import ImportHeader from '@/views/import/ImportHeader.vue';
import ImportResults from '@/views/import/ImportResults.vue';
import FloatingCornerModal from '@/views/import/FloatingCornerModal.vue';

import LoadingCircleOverlay, { LoadingCircleOverlayParams } from '@/components/ag-grid/overlays/LoadingCircleOverlay.vue';
import CheckboxCellRenderer from '@/components/ag-grid/CheckboxCellRenderer.vue';
import SimpleDateCellEditor from '@/components/ag-grid/SimpleDateCellEditor.vue';
import ShortDateCellEditor from '@/components/ag-grid/ShortDateCellEditor.vue';
import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';

import formatters from '@/components/ag-grid/formatters';
import CurrencyCellEditor from '@/components/ag-grid/CurrencyCellEditor.vue';

import buildOnePageWorkBook, { convertColDefToXLSWriteOptions } from '@/helpers/exports/xls/ExcelUtil';

import KeyboardNavigation from '@/mixins/KeyboardNavigation.vue';

import xlsxWorker, { XLSXWorkerConfig } from '@/workers/xlsx';

interface HiddenColumn {
  index: number;
  entry: string;
  selected: string;
}

type TypedHeader = DataTableHeader & {
  type?: string,
};

@Component({
  name: 'import-matcher',
  components: {
    ImportResults,
    FloatingCornerModal,
    AgGridVue,
    GridOmnifilter,
  },
})
export default class ImportMatcher extends Mixins(KeyboardNavigation) {
  @Prop({
    type: File,
  }) readonly file: File | undefined;

  @Prop({
    type: String,
    default: null,
    validator: (val) => ['partial', 'full', 'fixed', 'escrowTax'].findIndex((elt) => elt === val) !== -1,
  }) readonly fileLayout: 'partial' | 'full' | 'fixed' | 'escrowTax';

  private processingImport: boolean = false;
  private showViewData: boolean = false;
  private importProgressPercent = 0;

  // File
  private worksheets: string[] = [];
  private selectedWorksheet: string = null;

  // Indicators
  private isCommitting: boolean = false;
  private isPreviewLoading: boolean = false;
  private importResultJobId: boolean = null;

  // Import
  private importResults: ImportResult = null;
  private showPreviewDialog: boolean = false;
  private showResultDialog: boolean = false;
  private showReturnFileErrorsDialog: boolean = false;
  private showColumnDescription: boolean = false;

  // Constants
  private placeholderHeader: string = 'placeholder_header_';

  // Services
  private service = new ImportService();

  // Dialogs
  private columnWriteDialog: boolean = false;
  private columnWriteValue: string = '';
  private columnWriteIndex: number = -1;
  private describeColumnKeyedList = [''];
  private describeColumnTitle: String = 'Title';
  private describeColumnKeyedMap = [
    {
      key: 'parcel_type',
      value: ['E', 'N', 'EN'],
    },
    {
      key: 'zipCode',
      value: ['99999', '99999-9999'],
    },
    {
      key: 'loan_type',
      value: ['E', 'N', 'EN'],
    },
    {
      key: 'mailing_zip_code',
      value: ['99999', '99999-9999'],
    },
    {
      key: 'county_lines',
      value: ['OCL - Web', 'OCL - Parcel', 'OCL - Phone', 'ICL - Web', 'ICL - Parcel', 'ICL - Phone'],
    },
  ];

  // Controls
  private headerRow: boolean = false;
  private deleteOperation: boolean = false;

  // Table data
  private parseResult: ParseResult<any> = null;
  private gridData: any[] = [];
  private displayData: any[] = [];
  private headerFields: string[] = [];
  private selectedHeaders: string[] = [];
  private hiddenColumns: HiddenColumn[] = [];

  private originalFixedFile: File = null;
  private returnErrorRows: any[] = [];

  // Grid setup
  private gridApi: GridApi = null;
  private columnApi: ColumnApi = null;
  private gridOptions: GridOptions = {
    suppressColumnVirtualisation: true,
    enableCellTextSelection: true,
    enterMovesDownAfterEdit: true,
    columnTypes: {
      currency: {
        valueFormatter: formatters.currencyFormatter,
        cellEditor: 'currencyCellEditor',
      },
      date: {
        cellEditor: 'dateEditor',
      },
      shortDate: {
        cellEditor: 'shortDateEditor',
      },
      boolean: {
        cellRenderer: 'checkboxRenderer',
      },
    },
  };
  private defaultColDef: ColDef = {
    editable: true,
    resizable: true,
    sortable: false,
    minWidth: 200,
  };
  private frameworkComponents: any = null;
  protected loadingOverlayComponentParams: LoadingCircleOverlayParams = {
    context: this.gridOptions.context,
    api: this.gridApi,
    columnApi: this.columnApi,
    text: 'Loading',
  };
  private gridReadyPromise: Promise<void>;
  private gridReadyResolve: Function;

  // Column requirement definitions
  private requiredDeleteColumns: TypedHeader[] = [
    {
      text: 'Lender Number',
      value: 'lender_number',
    }, {
      text: 'Loan Number',
      value: 'loan_number',
    },
  ];

  private requiredAddColumns: TypedHeader[] = this.requiredDeleteColumns.concat([
    {
      text: 'Parcel Escrow Type',
      value: 'parcel_type',
    },
  ]);

  private needOneOrTheOther: TypedHeader[] = [
    {
      text: 'Parcel Number',
      value: 'parcel_number',
    }, {
      text: 'Street Address 1',
      value: 'address',
    },
  ];

  private allColumns: TypedHeader[] = this.requiredAddColumns.concat(this.needOneOrTheOther).concat([
    {
      text: 'Street Address 2',
      value: 'address2',
    }, {
      text: 'City',
      value: 'city',
    }, {
      text: 'State',
      value: 'state',
    }, {
      text: 'ZIP',
      value: 'zip_code',
    }, {
      text: 'County',
      value: 'county',
    }, {
      text: 'Agency Number',
      value: 'agency_number',
    }, {
      text: 'Legal Description',
      value: 'legal',
    }, {
      text: 'Borrower Name',
      value: 'borrower_name',
    },
    { text: 'Borrower Name 2', value: 'borrower_name_2' },
    { text: 'Company Name', value: 'company_name' },
    {
      text: 'Branch Number',
      value: 'branch_number',
    }, {
      text: 'Date Received',
      value: 'date_received',
      type: 'date',
    }, {
      text: 'Added Date',
      value: 'date_added',
      type: 'date',
    }, {
      text: 'Sequence Number',
      value: 'sequence_number',
    },
    { text: 'Loan Type', value: 'loan_type' },
    { text: 'Loan Notes', value: 'notes' },
    { text: 'Contract Id', value: 'contract_id' },
    { text: 'Id Flag', value: 'id_flag' },
    { text: 'Cvt Number', value: 'cvt_number' },
    { text: 'Purpose Code', value: 'purpose_code' },
    { text: 'Class Code', value: 'class_code' },
    { text: 'Loan Location Type', value: 'loan_location_type' },
    { text: 'Billing Notes', value: 'billing_notes' },
    { text: 'Parcel Lot', value: 'lot' },
    { text: 'Parcel Block', value: 'block' },
    { text: 'Parcel Unit', value: 'unit' },
    { text: 'Parcel Building', value: 'building' },
    { text: 'Parcel Date Coded', value: 'date_coded', type: 'date' },
    {
      text: 'Parcel Escrow Date Delq Searched',
      value: 'escrow_date_delq_searched',
      type: 'date',
    },
    {
      text: 'Parcel Escrow Delq Search Notes',
      value: 'escrow_delq_search_notes',
    },
    { text: 'Parcel Mailing County', value: 'mailing_county' },
    { text: 'Parcel Mailing Address 1', value: 'mailing_address_1' },
    { text: 'Parcel Mailing Address 2', value: 'mailing_address_2' },
    { text: 'Parcel Mailing City', value: 'mailing_city' },
    { text: 'Parcel Mailing State', value: 'mailing_state' },
    { text: 'Parcel Mailing Zip Code', value: 'mailing_zip_code' },
    { text: 'Parcel County Lines', value: 'county_lines' },
    { text: 'Parcel Id Tag', value: 'id_tag' },
    { text: 'Parcel Notes', value: 'parcel_notes' },
    { text: 'Parcel Problem', value: 'problem', type: 'boolean' },
    {
      text: 'Parcel Active Principal Balance',
      value: 'active_principal_balance',
      type: 'currency',
    },
    { text: 'Parcel Type Description', value: 'type_description' },
    {
      text: 'Parcel Collected',
      value: 'collected',
      type: 'currency',
    },
    {
      text: 'Parcel Tax Last Paid Amount',
      value: 'tax_last_paid_amount',
      type: 'currency',
    },
    { text: 'Parcel Tax Period Paid', value: 'tax_period_paid' },
    { text: 'Parcel Tax Paid Date', value: 'tax_paid_date', type: 'date' },
    { text: 'Parcel Maturity Date', value: 'maturity_date', type: 'date' },
    { text: 'Parcel Lender Notes', value: 'lender_notes' },
    { text: 'Parcel Legal Notes', value: 'legal_notes' },
    {
      text: 'Parcel Original Note Date',
      value: 'original_note_date',
      type: 'date',
    },
    { text: 'Parcel Alt Parcel Number', value: 'alt_parcel_number' },
  ]);

  @Watch('file', { immediate: true, deep: true })
  async onFileChanged(val: File) {
    // Reset the worksheet controls
    this.selectedWorksheet = null;
    this.worksheets = [];

    // If it is Excel, check for worksheets
    if (val.name.endsWith('.xls') || val.name.endsWith('.xlsx')) {
      this.worksheets = XLSX.read(await val.arrayBuffer(), { type: 'array', bookSheets: true }).SheetNames;
      if (this.worksheets.length > 1 && !this.selectedWorksheet) {
        return;
      }
    }

    // Start processing
    this.processFile(val);
  }

  @Watch('selectedWorksheet')
  onSelectedWorksheetChanged(val: string) {
    if (val && this.worksheets.length > 1) {
      this.processFile(this.file);
    }
  }

  @Watch('headerRow')
  async onHeadersChanged() {
    if (this.displayData.length === 0) {
      return;
    }

    this.calculateHeaders();
  }

  @Watch('columnWriteDialog')
  onColumnWriteDialogChanged(val: boolean) {
    if (!val) this.closeDialog();
  }

  processFile(val: File): Promise<void> {
    return this.gridReadyPromise
      .then(() => {
        this.loadingOverlayComponentParams = {
          context: this.gridOptions.context,
          api: this.gridApi,
          columnApi: this.columnApi,
          text: 'Parsing file...',
        };
        this.$nextTick(() => {
          this.gridApi.showLoadingOverlay();
        });
        return this.parseFile(val);
      })
      .then((file: string | File) => {
        this.loadingOverlayComponentParams = {
          context: this.gridOptions.context,
          api: this.gridApi,
          columnApi: this.columnApi,
          text: 'Parsing file data...',
        };
        this.$nextTick(() => {
          this.gridApi.hideOverlay();
          this.$nextTick(() => {
            this.gridApi.showLoadingOverlay();
          });
        });
        return this.parseFileData(file);
      })
      .then(() => {
        this.calculateHeaders();
        this.gridApi.hideOverlay();
      });
  }

  generateGridData(val: any) {
    if (!val || val.length === 0) {
      this.displayData = [];
      return;
    }

    this.displayData = val.map((entry: any, index: number) => {
      const keys: string[] = Object.keys(entry);
      const dataObject: any = {};

      keys.forEach((key) => {
        if (key === '') {
          dataObject[this.placeholderHeader] = entry[key];
        } else {
          dataObject[snakeCase(key)] = entry[key];
        }
      });

      dataObject.index = index;

      return dataObject;
    });
  }

  @Watch('hasSelections')
  hasSelectionsChanged(val: boolean) {
    if (val) {
      this.$emit('update');
    } else {
      this.$emit('clear');
    }
  }

  // Computed
  get hasSelections(): boolean {
    const selectedHeaderChanges = (this.selectedHeaders && this.selectedHeaders.length === 0)
      ? false
      : this.selectedHeaders.findIndex((header) => header !== null) !== -1;

    return selectedHeaderChanges;
  }

  get columnFields(): string[] {
    return this.gridHeaders.map((colDef) => colDef.field);
  }

  get successfulResultRows(): number {
    if (!this.importResults || !this.importResults.added_loans || !this.importResults.updated_loans || !this.importResults.deleted_loans) return 0;

    return this.importResults.added_loans.length + this.importResults.updated_loans.length + this.importResults.deleted_loans.length;
  }

  get totalResultRows(): number {
    if (!this.importResults || !this.importResults.failed_rows) return 0;

    return this.successfulResultRows + this.importResults.failed_rows.length;
  }

  get actionText(): string {
    if (this.fileLayout === 'full') return 'Submit';
    return this.deleteOperation ? 'Preview / Delete' : 'Preview / Add';
  }

  get actionColor(): string {
    if (this.fileLayout === 'full') return 'primary';
    return this.deleteOperation ? 'error' : 'success';
  }

  get gridHeaders(): ColDef[] {
    return this.headerFields.map((field: string, index: number): ColDef => {
      const column: ColDef = {
        // eslint-disable-next-line no-nested-ternary
        headerName: this.headerRow
          ? this.gridData[0][field] || ''
          : this.fileLayout === 'fixed' ? field : '',
        field: snakeCase(field),
        headerComponentParams: {
          index,
          selectedHeader: this.selectedHeaders[index],
          allColumns: this.allColumns,
        },
      };

      const selectedHeader = this.selectedHeaders[index];
      const columnDef = this.allColumns.find(
        (col) => col.text === selectedHeader || col.value === selectedHeader,
      );

      if (columnDef) {
        switch (columnDef.type) {
          case 'currency':
            Object.assign(column, {
              type: 'currency',
            });
            break;

          case 'date':
            Object.assign(column, {
              type: 'date',
            });
            this.displayData.forEach((row) => {
              if (row[field]) {
                const date = DateTime.fromFormat(row[field], 'M/d/yy');
                row[field] = date.isValid ? date.toFormat('MM/dd/yyyy') : '';
              }
            });
            break;

          case 'shortDate':
            Object.assign(column, {
              type: 'shortDate',
            });
            this.displayData.forEach((row) => {
              if (row[field]) {
                const date = DateTime.fromFormat(row[field], 'M/d');
                row[field] = date.isValid ? date.toFormat('MM/dd') : '';
              }
            });
            break;

          case 'boolean':
            Object.assign(column, {
              type: 'boolean',
            });
            this.displayData.forEach((row) => {
              row[field] = Boolean(row[field]);
            });
            break;

          default:
            break;
        }
      }

      // TODO: Flex isn't working for some reason
      if (this.headerFields && index === this.headerFields.length - 1) {
        Object.assign(column, {
          flex: 1,
        });
      }

      return column;
    });
  }

  get selectedData(): any[] {
    const keyPairs: any[][] = this.selectedHeaders.reduce(
      (fullKeyPairs: any, selectedHeader: any, index: number) => {
        if (selectedHeader) {
          fullKeyPairs.push([selectedHeader, this.gridHeaders[index].field]);
        }

        return fullKeyPairs;
      },
      [],
    );

    const arrayOfEntryArrays = this.displayData.map((row: any) => {
      const importEntryArray: any[] = [{}];

      keyPairs.forEach((keyPair) => {
        importEntryArray[0][keyPair[0]] = row[keyPair[1]] ? row[keyPair[1]].trim() : null;
      });
      const seenCombos = [`${row.tax_auth_1_parcel ? row.tax_auth_1_parcel.trim() : ''} ${row.tax_auth_1 ? row.tax_auth_1.trim() : ''}`]
      for (let i = 2; i <= 5; i += 1) {
        if (row[`tax_auth_${i}_parcel`] && row[`tax_auth_${i}_parcel`].trim() !== '') {
          const comboString = `${row[`tax_auth_${i}_parcel`].trim()} ${row[`tax_auth_${i}`] ? row[`tax_auth_${i}`].trim() : ''}`;
          if (!seenCombos.includes(comboString)) {
            const newEntry = cloneDeep(importEntryArray[0]);
            newEntry.parcel_number = row[`tax_auth_${i}_parcel`].trim();
            delete newEntry.agency_number;
            if (row[`tax_auth_${i}`] && row[`tax_auth_${i}`].trim() !== '') {
              newEntry.agency_number = row[`tax_auth_${i}`].trim();
            }
            importEntryArray.push(newEntry)
            seenCombos.push(comboString)
          }
        }
      }

      return importEntryArray;
    });

    return flatten(arrayOfEntryArrays).filter((row: any) => {
      if (('lender_number' in row) && row.lender_number !== null && row.lender_number !== '') {
        return true;
      }
      return false;
    });
  }

  // Check that all required columns have been assigned
  get missingFields(): any[] {
    const requiredList = this.deleteOperation ? this.requiredDeleteColumns : this.requiredAddColumns;

    const missingRequiredFields = requiredList.reduce((missing, requiredColumn) => {
      if (this.selectedHeaders.findIndex((header) => header === requiredColumn.value) === -1) {
        missing.push(requiredColumn);
      }
      return missing;
    }, []);

    if (!this.deleteOperation) {
      const missingOneOrOtherFields = this.needOneOrTheOther.reduce((missing, requiredColumn) => {
        if (this.selectedHeaders.findIndex((header) => header === requiredColumn.value) === -1) {
          missing.push(requiredColumn);
        }
        return missing;
      }, []);

      if (missingOneOrOtherFields.length === 2) {
        missingRequiredFields.push({ text: `${missingOneOrOtherFields[0].text} or ${missingOneOrOtherFields[1].text}` })
      }
    }

    return missingRequiredFields;
  }

  // Hooks
  beforeCreate() {
    this.gridReadyPromise = new Promise<void>((resolve) => {
      this.gridReadyResolve = resolve;
    });
  }

  beforeMount() {
    this.frameworkComponents = {
      agColumnHeader: ImportHeader,
      checkboxRenderer: CheckboxCellRenderer,
      dateEditor: SimpleDateCellEditor,
      shortDateEditor: ShortDateCellEditor,
      currencyCellEditor: CurrencyCellEditor,

      loadingCircleOverlay: LoadingCircleOverlay,
    };
  }

  beforeDestroy() {
    this.$emit('clear');
  }

  // Methods
  parseFile(input: File): Promise<string | File> {
    if (this.fileLayout === 'fixed') {
      this.originalFixedFile = input;
      this.headerRow = false;
      return this.csvifyFixedFile(input);
    }

    if (input.name.endsWith('.xls') || input.name.endsWith('.xlsx')) {
      return this.csvifyExcelFile(input);
    }

    return Promise.resolve(input);
  }

  async parseFileData(parsedFile: string | File) {
    this.parseResult = await this.parsePapa(parsedFile);
    // Eliminate any blank rows that the parser introduces
    this.parseResult.data = this.parseResult.data.filter((row) => {
      if (row.length === 1 && row[0] === '') {
        return false;
      }
      return true;
    });
    this.gridData = this.determineData(this.parseResult.data, this.generateHeaders(this.parseResult.data[0]));
  }

  calculateHeaders() {
    this.hiddenColumns = [];
    this.headerFields = this.generateHeaders(this.parseResult.data[0]);
    this.displayData = this.gridData.slice(this.headerRow ? 1 : 0);

    if (this.headerRow) {
      // Try to auto-compute headers if there were none already.
      if (this.selectedHeaders.length === 0 || this.selectedHeaders.filter((h) => h).length === 0) {
        this.selectedHeaders = [];
        this.headerFields.forEach((entry) => {
          const gridHeader = this.gridData[0][entry];
          const possibleMatch = this.allColumns.find((column) => column.text === gridHeader || column.value === gridHeader);
          if (possibleMatch) {
            this.selectedHeaders.push(possibleMatch.value);
          } else {
            this.selectedHeaders.push(null);
          }
        });
      }
    } else { // First Row Header is not selected
      // eslint-disable-next-line no-lonely-if
      if (this.fileLayout === 'fixed') {
        this.setFixedFileHeaders();
        this.reformatFixedFileData();
        this.hideUnusedFixedFileColumns();
      } else if (this.selectedHeaders.length === 0) {
        this.parseResult.data[0].forEach((entry: any, index: number) => {
          this.selectedHeaders.push(null);
        });
      }
    }
  }

  openColumnWriteDialog(index: number) {
    this.columnWriteIndex = index;
    this.columnWriteDialog = true;
  }

  closeDialog() {
    this.columnWriteDialog = false;
    this.columnWriteValue = '';
  }

  writeColumn(index: number) {
    this.displayData = this.displayData.map((row: any) => {
      const newRow = { ...row, [snakeCase(this.headerFields[index])]: this.columnWriteValue };

      return newRow;
    });

    this.closeDialog();
  }

  addColumn() {
    const newHeader = `${this.placeholderHeader}${this.headerFields.length + this.hiddenColumns.length}`;
    this.headerFields.push(newHeader);
    this.displayData.forEach((row: any) => {
      row[newHeader] = '';
    });
  }

  describeColumn(index: number) {
    const titleMatch = this.allColumns.find((column: any) => column.text === this.selectedHeaders[index] || column.value === this.selectedHeaders[index]);
    this.describeColumnTitle = titleMatch.text;
    const possibleMapMatch = this.describeColumnKeyedMap.find((column: any) => column.key === titleMatch.value);
    if (possibleMapMatch && possibleMapMatch !== undefined) {
      this.describeColumnKeyedList = possibleMapMatch.value;
    } else {
      this.describeColumnKeyedList = ['Text field (key any value)'];
    }

    this.showColumnDescription = true;
  }

  hideColumn(index: number) {
    // Remove from headers
    this.hiddenColumns.push({
      index,
      entry: this.headerFields[index],
      selected: this.selectedHeaders[index],
    });
    this.headerFields.splice(index, 1);
    this.selectedHeaders.splice(index, 1);
  }

  restoreColumns() {
    if (this.hiddenColumns.length === 0) {
      return;
    }

    while (this.hiddenColumns.length !== 0) {
      const latestColumn: HiddenColumn = this.hiddenColumns.pop();
      this.headerFields.splice(latestColumn.index, 0, latestColumn.entry);
      this.selectedHeaders.splice(latestColumn.index, 0, latestColumn.selected);
    }
  }

  splitAddressColumn(index: number) {
    function valueOrEmpty(value?: string): string {
      return value || '';
    }

    this.displayData = this.displayData.map((row: any): any => {
      const address = row[snakeCase(this.headerFields[index])];
      const addressResult: ParseAddressResult = ParseAddress.parseLocation(address);

      if (!addressResult) return row;

      const newRow = {
        ...row,
        address: `${valueOrEmpty(addressResult.prefix)} ${valueOrEmpty(addressResult.number)} ${valueOrEmpty(addressResult.street)} ${valueOrEmpty(addressResult.type)}`.trim(),
        city: valueOrEmpty(addressResult.city),
        state: valueOrEmpty(addressResult.state),
        zip_code: valueOrEmpty(addressResult.zip),
      };

      return newRow;
    });

    this.headerFields.splice(index, 1, ...[
      'Address', 'City', 'State', 'ZIP Code',
    ]);

    this.selectedHeaders.splice(index, 1, ...[
      'address', 'city', 'state', 'zip_code',
    ]);
  }

  selectColumn(index: number, header: string | null) {
    if (header !== null) {
      const existingHeaderIndex = this.selectedHeaders.findIndex((selectedHeader) => selectedHeader === header);
      if (existingHeaderIndex !== -1) {
        this.selectedHeaders[existingHeaderIndex] = null;
      }
    }

    const newHeaders = tap(cloneDeep(this.selectedHeaders), (v) => {
      v[index] = header;
    });

    this.selectedHeaders = newHeaders;
  }

  exportTable(format: 'csv' | 'xlsx' = 'xlsx') {
    const parsedData = this.displayData.map((row) => pick(row, this.columnFields));

    const fileName = this.file ? `${this.file.name.replace('.csv', '')}-updated.csv` : 'exported.csv';

    if (format === 'xlsx') {
      const wb = buildOnePageWorkBook(parsedData, convertColDefToXLSWriteOptions(this.gridHeaders), 'Import');
      XLSX.writeFile(wb, `${fileName}.xlsx`, {
        type: 'file',
      });
    } else if (format === 'csv') {
      const csv = Papa.unparse(parsedData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

      saveAs(blob, `${fileName}.csv`);
    } else {
      console.warn('Unsupported export format attempted.');
    }
  }

  generateHeaders(data: any[]): string[] {
    if (this.fileLayout === 'fixed') {
      return ['[unused]', 'State/County Code', 'Customer Number', '[unused]', 'Transaction Code', 'Branch Code', '[unused]', 'Contract Number', 'Order Date', 'Service Type', 'Issue Date', 'Contract Terms', 'Contract Fee', 'Loan ID', '[unused]', '[unused]', 'Fee Billing Type', 'Loan Amount', '[unused]', 'Borrower\'s Name', 'County Parcel Code', 'Street Address', 'City', 'State', 'ZIP', 'Previous Owner', '[unused]', 'Legal Description', 'Tax Auth 1 - Code', 'Tax Auth 1 - HIT Code', 'Tax Auth 1', 'Tax Auth 1 - Name', 'Tax Auth 1 - Rate-Area', 'Tax Auth 1 - Parcel', 'Tax Auth 2 - Code', 'Tax Auth 2 - HIT Code', 'Tax Auth 2', 'Tax Auth 2 - Name', 'Tax Auth 2 - Rate-Area', 'Tax Auth 2 - Parcel', 'Tax Auth 3 - Code', 'Tax Auth 3 - HIT Code', 'Tax Auth 3', 'Tax Auth 3 - Name', 'Tax Auth 3 - Rate-Area', 'Tax Auth 3 - Parcel', 'Tax Auth 4 - Code', 'Tax Auth 4 - HIT Code', 'Tax Auth 4', 'Tax Auth 4 - Name', 'Tax Auth 4 - Rate-Area', 'Tax Auth 4 - Parcel', 'Tax Auth 5 - Code', 'Tax Auth 5 - HIT Code', 'Tax Auth 5', 'Tax Auth 5 - Name', 'Tax Auth 5 - Rate-Area', 'Tax Auth 5 - Parcel', 'Tax Authority', 'Previous Servicer', '[unused]'];
    }

    return data.map((entry: string, index: number) => `${this.placeholderHeader}${index}`);
  }

  reformatFixedFileData() {
    const parseData = this.displayData.map((row: any) => {
      const newRow = {
        ...row, // Cut leading zeros from Lender Number
        customer_number: (`${row.customer_number}`).trim().replace(/^0+/, ''),
        // Chop up Loan number
        loan_id: (`${row.loan_id}`).trim().substring(2, (`${row.loan_id}`).indexOf('-') - 4),
        order_date: `${(`${row.order_date}`).substring(2, 4)}/${(`${row.order_date}`).substring(4, 6)}/20${(`${row.order_date}`).substring(0, 2)}`,

        tax_auth_1: (String(row.tax_auth_1).trim() === '') ? '' : `${(`${row.tax_auth_1}`).substring(0, 7)}0${(`${row.tax_auth_1}`).substring(7)}`,
        tax_auth_2: (String(row.tax_auth_2).trim() === '') ? '' : `${(`${row.tax_auth_2}`).substring(0, 7)}0${(`${row.tax_auth_2}`).substring(7)}`,
        tax_auth_3: (String(row.tax_auth_3).trim() === '') ? '' : `${(`${row.tax_auth_3}`).substring(0, 7)}0${(`${row.tax_auth_3}`).substring(7)}`,
        tax_auth_4: (String(row.tax_auth_4).trim() === '') ? '' : `${(`${row.tax_auth_4}`).substring(0, 7)}0${(`${row.tax_auth_4}`).substring(7)}`,
        tax_auth_5: (String(row.tax_auth_5).trim() === '') ? '' : `${(`${row.tax_auth_5}`).substring(0, 7)}0${(`${row.tax_auth_5}`).substring(7)}`,

        loan_amount: (`${row.loan_amount}`).trim().replace(/^0+/, ''),

        zip: ((`${row.zip}`).trim().length === 9) ? (`${(`${row.zip}`).trim().substring(0, 5)}-${(`${row.zip}`).trim().substring(5)}`) : (`${row.zip}`),

        service_type: ((`${row.service_type}`).trim() === 'C') ? 'E' : 'N',
      };

      return newRow;
    });

    parseData.forEach((row) => {
      // couple together the street number and street name with 1 space between.
      // However, in the case that the street address used all available chars, don't modify it.
      const rawAddress = row.street_address.trim();
      const spaceIndex = rawAddress.indexOf(' ');
      if (spaceIndex < 9) {
        row.street_address = `${rawAddress.slice(0, spaceIndex)} ${rawAddress.slice(spaceIndex).trim()}`;
      }
    });

    this.generateGridData(parseData);
  }

  hideUnusedFixedFileColumns() {
    // reverse chronology as hideColumn functionality is currently broken.
    const unusedToken = '[unused]';
    while (this.headerFields.includes(unusedToken)) {
      console.log(`hiding: ${this.headerFields.indexOf(unusedToken)}`);
      this.hideColumn(this.headerFields.indexOf(unusedToken));
    }
  }

  setFixedFileHeaders() {
    this.selectedHeaders.push(null, null, 'lender_number', null, null, 'branch_number', null, null, 'date_received', 'parcel_type', null, null, null, 'loan_number', null, null, null, null, null, 'borrower_name', '', 'address', 'city', 'state', 'zipCode', null, null, 'legal', null, null, 'agency_number', null, null, 'parcel_number', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  determineData(dataMatrix: any[][], headerFields: string[]): any[] {
    return dataMatrix.map(
      (row: any[], index) => {
        const dataObject = row.reduce(
          (dataEntry: any, entry: string, entryIndex: number) => {
            // eslint-disable-next-line no-param-reassign
            dataEntry[this.fileLayout ? snakeCase(headerFields[entryIndex]) : headerFields[entryIndex]] = entry;
            return dataEntry;
          },
          {},
        );

        dataObject.index = index;
        return dataObject;
      },
    );
  }

  async parsePapa(input: File | string): Promise<ParseResult<any>> {
    return new Promise((resolve) => {
      Papa.parse(input, {
        complete: (results) => {
          resolve(results);
        },
      });
    });
  }

  async csvifyExcelFile(input: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const workerMessage: XLSXWorkerConfig = this.selectedWorksheet
          ? {
            input: reader.result as string,
            worksheet: this.selectedWorksheet,
          }
          : reader.result as string;

        xlsxWorker.send(workerMessage)
          .then((csv) => {
            resolve(csv);
          });
      };

      reader.readAsBinaryString(input);
    });
  }

  async csvifyFixedFile(input: File): Promise<string> {
    // Tasks:
    //  * fixed file needs to be manipulated for any fields that are e.g. 9(9)V99.
    //  * trim all cells (I think?) in all file types before using the data in the table (via export or pushing to endpoint)

    const commaPoints = [1, 5, 7, 1, 1, 2, 11, 8, 6, 1, 6, 3, 7, 21, 1, 30, 2, 12, 1, 36, 3, 36, 25, 2, 9, 38, 1, 640,
      /* tax1 */ 1, 1, 9, 36, 6, 50,
      /* tax2 */ 1, 1, 9, 36, 6, 50,
      /* tax3 */ 1, 1, 9, 36, 6, 50,
      /* tax4 */ 1, 1, 9, 36, 6, 50,
      /* tax5 */ 1, 1, 9, 36, 6, 50, 1, 25,
      /* ,43 */];

    let text = await input.text();
    text = text.replace(/,/g, ' '); // replace any extraneous commas
    let adjustedFileSize = input.size; // adjusts as data inserted

    let pos = 0;
    while (pos < adjustedFileSize) {
      commaPoints.forEach((cp) => { // eslint-disable-line
        pos += cp; // move our pointer forward the proper amount
        text = [text.slice(0, pos), ',', text.slice(pos)].join(''); // explicit comma
        pos += 1; // move forward past the comma
        adjustedFileSize += 1;
      });

      while (pos < adjustedFileSize && text[pos] !== '\r' && text[pos] !== '\n') { // until we hit newline
        pos += 1;
      }
      while (pos < adjustedFileSize && (text[pos] === '\r' || text[pos] === '\n')) { // past newline
        pos += 1;
      }
    }

    return new Promise((resolve, error) => {
      resolve(text);
    });
  }

  async startImportProcess(preview: boolean = false) {
    // submit import continuouly and determine the progress percent
    const chunkSize = 3000;
    console.log('selected data is', this.selectedData);
    const totalChunkSize = this.selectedData.length;
    this.importProgressPercent = 0;
    this.showViewData = false;
    const totalChunks = Math.ceil(totalChunkSize / chunkSize);

    console.log('total chunk size', totalChunkSize);
    console.log('total chunks', totalChunks);

    // split into chunks and send individual requests to api
    const noImportResultUpdate: boolean = true;
    let tempImportResults: any = {
      added_loans: [],
      updated_loans: [],
      deleted_loans: [],
      failed_rows: [],
    };
    // clear/init out import results;
    this.importResults = tempImportResults;
    this.showPreviewDialog = true;
    this.processingImport = true;
    const selectedData = [...this.selectedData]

    for (let i = 0; i < totalChunks; i += 1) {
      /* eslint-disable no-await-in-loop */
      const start = i * chunkSize;
      const end = start + chunkSize;
      console.log('loop chunk start', start, 'end', end);
      const chunkData = selectedData.slice(start, end);
      console.log('chunk data', chunkData);
      try {
        const importResults: any = await this.submitImport(preview, chunkData, noImportResultUpdate);
        // merge import results
        console.log('import results', importResults);
        tempImportResults = this.mergeImportResults(tempImportResults, importResults);
        console.log('merge import result', tempImportResults);
        this.importProgressPercent = Math.ceil(((i + 1) / totalChunks) * 100);
        if (this.importProgressPercent > 100) {
          this.importProgressPercent = 100;
        }
      } catch (err) {
        console.log('error in submitting import', err);
      }
    }

    if (this.importProgressPercent === 100) {
      // its set when view data is clicked...
      // this.processingImport = false;
      this.showViewData = true;
      this.processingImport = false;
      this.importResults = tempImportResults;
    }
  }

  mergeImportResults(result1: any, result2: any) {
    console.log('merge import results', result1, result2);
    const newResult: any = {
      added_loans: [],
      updated_loans: [],
      deleted_loans: [],
      failed_rows: [],
    };
    if (!result1) {
      result1 = { ...newResult };
    }
    if (!result2) {
      result2 = { ...newResult };
    }
    newResult.added_loans = [...result1.added_loans, ...result2.added_loans];
    newResult.updated_loans = [
      ...result1.updated_loans,
      ...result2.updated_loans,
    ];
    newResult.deleted_loans = [
      ...result1.deleted_loans,
      ...result2.deleted_loans,
    ];
    newResult.failed_rows = [...result1.failed_rows, ...result2.failed_rows];
    return newResult;
  }

  async submitImport(preview: boolean = false, importData: any = null, noImportResultUpdate: boolean = false) {
    if (!this.file && preview) {
      console.log('condition of submit import: !this.file && preview', !this.file && preview);
      return undefined;
    }
    if (this.missingFields.length > 0 && preview) {
      console.log('condition of submit import: this.missingFields.length > 0', this.missingFields.length > 0);
      return undefined;
    }
    if (!importData) {
      importData = this.selectedData;
    }

    this.isCommitting = !preview;
    this.isPreviewLoading = preview;
    let importResults: any = [];

    if (this.fileLayout === 'full') {
      importResults = await this.service.postFullFileImport(importData, this.file.name, preview);
    } else {
      importResults = await this.service.postPartialFileImport(importData, this.file.name, preview, this.deleteOperation);
      if (this.fileLayout === 'fixed' && !preview) {
        this.generateReturnFile();
      }
    }

    if (!preview) {
      this.selectedHeaders = [];
    }

    this.isCommitting = false;
    this.isPreviewLoading = false;

    this.showPreviewDialog = preview;
    this.showResultDialog = !preview;
    if (!noImportResultUpdate) {
      this.importResults = importResults;
    }
    console.log('result of submit report importResults:', importResults)
    return importResults;
  }

  exportErrors() {
    // Match off failed rows to index on results
    const failuresWithData = this.importResults.failed_rows.reduce<any[]>((data, failedRow) => {
      const selectedDataRow = this.selectedData.find(((row) => row.index === failedRow.index));
      data.push({ ...selectedDataRow, ...failedRow });
      return data;
    }, []);

    const csv = Papa.unparse(failuresWithData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    saveAs(blob, this.file ? `${this.file.name.replace('.csv', '')}-failures.csv` : 'failures.csv');
  }

  exportSuccesses() {
    const csv = Papa.unparse([].concat(this.importResults.added_loans, this.importResults.deleted_loans, this.importResults.updated_loans).map((row: any) => {
      const deletedOrUpdated = this.importResults.deleted_loans.includes(row) ? 'DELETE' : 'UPDATE';
      const statusCol = this.importResults.added_loans.includes(row)
        ? 'ADD'
        : deletedOrUpdated;
      row.op = statusCol;
      if (row.loan_id) delete row.loan_id;
      if (row.lender_id) delete row.lender_id;
      if (row.index) delete row.index;
      return row;
    }));

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    saveAs(blob, this.file ? `${this.file.name.replace('.csv', '')}-successes.csv` : 'successes.csv');
  }

  padFrontZero(text: string, val: number) {
    if (text.length < val) {
      text = '0'.repeat(val - text.length) + text;
    }
    return text;
  }

  getRowStart(text: string, row: any) {
    const fixedFileRowLength = 1500;
    let localIndex = 0;

    while (localIndex < text.length && localIndex + fixedFileRowLength <= text.length) {
      const textLender = text.substr(localIndex + 6, 7).trim();
      let textLoan = text.substr(localIndex + 59, 21).trim();
      // Remove first two zeros and stop at the dash.  Special logic for Lender 810.
      if (textLoan.indexOf('-') > 0 && textLoan.length > 2) {
        textLoan = textLoan.substr(2, textLoan.indexOf('-') - 2);
      }
      const textParcel1 = text.substr(localIndex + 969, 50).trim();
      const textParcel2 = text.substr(localIndex + 1072, 50).trim();
      const textParcel3 = text.substr(localIndex + 1175, 50).trim();
      const textParcel4 = text.substr(localIndex + 1278, 50).trim();
      const textParcel5 = text.substr(localIndex + 1381, 50).trim();
      const textStreet1 = (`${text.substr(localIndex + 165, 9).trim()} ${text.substr(localIndex + 174, 27).trim()}`).trim();
      const textCity = text.substr(localIndex + 201, 25).trim();
      const textState = text.substr(localIndex + 226, 2).trim();
      const textZip9 = text.substr(localIndex + 228, 9).trim();

      // try to match on lender/loan/PRIMARY parcel (sub parcels cannot be assigned back a Contract ID)
      // if it does match a subparcel, but does not match a primary parcel, this may not be an error.
      if (row.lender_number && textLender === this.padFrontZero(row.lender_number.trim(), 7)
         && row.loan_number && textLoan === row.loan_number.trim()
         && row.parcel_number && textParcel1 === row.parcel_number.trim()) {
        return localIndex;
      }
      const parcelNumber = row.parcel_number.trim();
      if (row.lender_number && textLender === this.padFrontZero(row.lender_number.trim(), 7)
          && row.loan_number && textLoan === row.loan_number.trim()
          && parcelNumber && (textParcel1 === parcelNumber
          || textParcel2 === parcelNumber
          || textParcel3 === parcelNumber
          || textParcel4 === parcelNumber
          || textParcel5 === parcelNumber)) {
        return localIndex;
      }
      if (textParcel1 === ''
                 && row.lender_number && textLender === this.padFrontZero(row.lender_number.trim(), 7)
                 && row.loan_number && textLoan === row.loan_number.trim()
                 && row.address && textStreet1 === row.address.trim() // Confirm how the address string is formatted.
                 && row.city && textCity === row.city.trim()
                 && row.state && textState === row.state.trim()) {
        return localIndex;
      }

      localIndex += fixedFileRowLength;

      // Advance past the newline
      while (localIndex < text.length && (text[localIndex] === '\r' || text[localIndex] === '\n')) {
        localIndex += 1;
      }
    }
    return -1;
  }

  matchAndInsertFixedRow(text: string, row: any) {
    // Returns the updated text on success, or null on error
    const contractIdOffset = 28;
    // TODO:  Confirm that a user clicking EXPORT SUCCESSES doesn't screw with our index while we are generating this.
    // TODO:  Insert SEQUENCE NUMBER
    const textIndex = this.getRowStart(text, row);

    if (textIndex === -1) {
      return null;
    }
    if ('contractId' in row) {
      text = text.slice(0, textIndex + 28) + this.padFrontZero(row.contractId, 8) + text.slice(textIndex + 36);
    }

    // Inserting today's date as we are only updating rows that have been ADDED.
    text = text.slice(0, textIndex + 43) + DateTime.local().toFormat('yyMMdd') + text.slice(textIndex + 49);

    return text;
  }

  async generateReturnFile() {
    const successRows: any[] = ([].concat(this.importResults.added_loans, this.importResults.deleted_loans, this.importResults.updated_loans));

    if (successRows.length === 0) {
      return;
    }

    let text = await this.originalFixedFile.text();

    // match off each row in the combined successRows, and insert it back into our original data object, return that as a single file blob
    successRows.forEach((row) => {
      const retVal = this.matchAndInsertFixedRow(text, row);
      if (retVal == null) {
        this.returnErrorRows.push(row);
      } else {
        text = retVal;
      }
    });

    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    const filename = this.file ? `return_${this.file.name}` : 'return_file.txt';

    saveAs(blob, filename);

    if (this.returnErrorRows.length > 0) {
      this.showReturnFileErrorsDialog = true;
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    if (this.gridReadyPromise) {
      this.gridReadyResolve();
    }
  }
}
