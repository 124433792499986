import { ColDef } from 'ag-grid-community';
import XLSX, {
  CellObject, ExcelDataType, WorkBook, WorkSheet,
} from 'xlsx';

export type XLSTypes = 'string' | 'currency' | 'date' | 'shortDate' | 'number' | 'boolean'

export interface XLSWriteOptions {
  header: string | number,
  type: XLSTypes,
  format?: string,
}

interface ColumnOptions {
  index: number,
  type: ExcelDataType,
  format?: string,
}

const currencyFormat = '$0.00';

const TYPE_MAP = {
  string: 's',
  currency: 'n',
  date: 'd',
  shortDate: 'd',
  number: 'n',
  boolean: 'b',
};

const FORMAT_MAP: { [index: string]: string } = {
  shortDate: 'mm/dd',
  date: 'mm/dd/yyyy',
  currency: currencyFormat,
};

function buildWorkSheet(data: any, options?: XLSWriteOptions[]): WorkSheet {
  const ws = XLSX.utils.json_to_sheet(data);

  const fullRange = XLSX.utils.decode_range(ws['!ref']);
  const lastColumnIndex = fullRange.e.c;
  const headerNames: any[] = [];
  for (let i = 0; i <= lastColumnIndex; i += 1) {
    const ref = XLSX.utils.encode_cell({ r: 0, c: i });
    const cell: CellObject = ws[ref];
    headerNames.push(cell.v);
  }

  // Build column customization array
  const columns = options.map((option) => {
    const index = headerNames.findIndex((header) => header === option.header);

    if (index === -1) {
      throw new Error(`Could not find header ${option.header} in given data`);
    }

    const obj: ColumnOptions = {
      index,
      type: TYPE_MAP[option.type] as ExcelDataType,
    };

    if (option.format || FORMAT_MAP[option.type]) {
      obj.format = option.format ? option.format : FORMAT_MAP[option.type];
    }

    return obj;
  });

  if (columns.length > 0) {
    for (let i = fullRange.s.r + 1; i <= fullRange.e.r; i += 1) {
      columns.forEach((column) => {
        const ref = XLSX.utils.encode_cell({ r: i, c: column.index });
        const cell: CellObject = ws[ref];
        if (cell && cell.t !== 'z') {
          cell.t = column.type;
        }

        if (column.format) {
          cell.z = column.format;
        }
      });
    }
  }

  return ws;
}

export function convertColDefToXLSWriteOptions(columns: ColDef[]): XLSWriteOptions[] {
  const typedColumns = columns.filter((columnDef) => Boolean(columnDef.type));

  return typedColumns.map((column) => {
    const type = Array.isArray(column.type) ? column.type[0] : column.type;

    if (type === 'verified') {
      return {
        header: column.field,
        type: 'boolean',
      }
    }

    return {
      header: column.field,
      type: column.type as XLSTypes,
    };
  });
}

export default function buildOnePageWorkBook(data: any, options?: XLSWriteOptions[], sheetName: string = 'Data'): WorkBook {
  // Create the book and the worksheet
  const wb = XLSX.utils.book_new();
  const ws = buildWorkSheet(data, options);

  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  return wb;
}
