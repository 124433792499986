









































































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { validationMixin } from 'vuelidate';

import HistoryService from '@/services/history';

import User from '@/entities/User';
import Transaction from '@/entities/history/Transaction';
import HistoryEntry from '@/entities/history/HistoryEntry';

import UserSearch from '@/views/users/UserSearch.vue';
import HistoryList from '@/views/history/HistoryList.vue';
import DateRangePicker, { DateRange } from '@/components/inputs/dates/DateRangePicker.vue';

import { fullDate } from '@/validations';
import { startCase } from 'lodash';

const validations: any = {
  users: {},
  dateRange: {
    $each: {
      fullDate,
    },
  },
  transactionTypes: {},
  historyTypes: {},
};

@Component({
  name: 'history',
  validations,
  components: {
    UserSearch,
    DateRangePicker,
    HistoryList,
  },
  mixins: [validationMixin],
})
export default class History extends Vue {
  private service: HistoryService = new HistoryService();
  private isLoading: boolean = false;
  private history: Transaction[] = [];
  private options: Partial<DataOptions> = {
    itemsPerPage: 250,
    page: 1,
  };

  private rollbackFailed: boolean = false;

  private activeEntry?: Transaction;

  // Input lists
  private historyOptions: string[] = HistoryEntry.getTypes();
  private transactionOptions: string[] = Transaction.getTypes();
  private tableNames: string[] = [
    'loan',
    'parcel',
    'escrow_history',
    'non_escrow_history',
    'lender',
    'lender_contact',
    'lender_file',
    'related_lender',
    'agency',
    'parcel_agency',
    'agency_contact',
    'agency_file',
    'agency_configuration',
    'agency_cross_reference_code',
    'agency_collecting_schedule',
    'related_agency',
    'user',
    'user_role',
  ];
  private tableOptions: { text: string, value: string }[] = this.tableNames.map((table) => ({
    text: startCase(table),
    value: table,
  }));

  // Inputs
  private users: User[] = [];
  private dateRange: DateRange = [null, null];
  private transactionTypes: string[] = [];
  private historyTypes: string[] = [];
  private tables: string[] = [];
  private query: string = null;

  // Table/iterator controls
  private itemsPerPage: number = 250;
  private totalItems: number = 0;

  @Watch('options', { deep: true })
  async onOptionsChanged(options: DataOptions, oldOptions: DataOptions) {
    if (oldOptions.page && (options.itemsPerPage !== oldOptions.itemsPerPage || options.page !== oldOptions.page)) {
      this.fetchHistory();
    }
  }

  // Computed
  get queryParams() {
    const params = {};

    if (this.users.length > 0) {
      Object.assign(params, {
        users: this.users.map((user) => user.id),
      });
    }

    if (this.dateRange) {
      Object.assign(params, {
        date_range: this.dateRange.map((date) => (date || '')),
      });
    }

    if (this.transactionTypes.length > 0) {
      Object.assign(params, {
        transaction_types: this.transactionTypes,
      });
    }

    if (this.historyTypes.length > 0) {
      Object.assign(params, {
        history_types: this.historyTypes,
      });
    }

    if (this.tables.length > 0) {
      Object.assign(params, {
        tables: this.tables,
      });
    }

    if (this.query) {
      Object.assign(params, {
        q: this.query,
      });
    }

    return params;
  }

  // Hooks
  async mounted() {
    await this.fetchHistory();
  }

  get hasValidInput(): boolean {
    return !this.$v.$invalid;
  }

  async fetchHistory() {
    this.isLoading = true;

    const {
      page, itemsPerPage,
    } = this.options;

    const response = await this.service.getFullHistory({
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage,
      entity_info: true,
      ...this.queryParams,
    });
    this.history = response.transactions;
    this.totalItems = response.count;

    this.isLoading = false;
  }

  async triggerRollback(entry?: Transaction) {
    const targetEntry = entry || this.activeEntry;
    this.activeEntry = targetEntry;
    const response = await this.service.attemptRollback(entry);

    if (response && response.id) {
      this.rollbackFailed = false;
      const transaction = this.history.filter((h:any) => h.id === response.id).forEach((h: any) => {
        h.rolledBack = true;
      })
    } else {
      this.rollbackFailed = true;
    }
  }
}
