import Axios, { CancelToken } from 'axios';

import { IServiceNonEscrowHistory } from '@/services/api/models/IServiceParcel';
import SearchResponse from '@/services/api/responses/SearchResponse';
import IEntityCountResponse from './models/IEntityCountResponse';

type NonEscrowHistoryResponse = SearchResponse<IServiceNonEscrowHistory>

export default {
  async getAllNonEscrowHistories(params: any, cancelToken?: CancelToken): Promise<NonEscrowHistoryResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<NonEscrowHistoryResponse>('/non-escrow-histories', config);

    return response.data;
  },

  async getNonEscrowHistory(id: string, cancelToken?: CancelToken): Promise<IServiceNonEscrowHistory> {
    const config = {};
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<IServiceNonEscrowHistory>(`/non-escrow-histories/${id}`, config);

    return response.data;
  },

  async getTotalNonEscrowHistories(params: any, cancelToken?: CancelToken): Promise<IEntityCountResponse> {
    const config = { params };
    if (cancelToken) {
      Object.assign(config, { cancelToken });
    }

    const response = await Axios.get<IEntityCountResponse>('/non-escrow-histories/count', config);

    return response.data;
  },
};
