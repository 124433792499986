import jsPDF from 'jspdf';
import autoTable, { ColumnInput, Styles } from 'jspdf-autotable';
import { capitalCase } from 'change-case';
import { cloneDeep, defaults, isNumber } from 'lodash';

import PdfBuilder, { PdfBuilderOptions } from './PdfBuilder';

export interface ReportPdfBuilderOptions<T> extends PdfBuilderOptions {
  header: number,
  footer: number,
  columns?: ColumnInput[],
  columnNames?: Map<string, string>,
  dataFormatters?: Map<string, (data: any) => any>,
  groupBy?: keyof T | (keyof T)[],
  exclusions?: (keyof T)[],
  styles?: Partial<Styles>,
  bodyStyles?: Partial<Styles>,
  columnStyles?: { [key: string]: Partial<Styles> },
  headStyles?: Partial<Styles>,
  margin?: {
    left: number,
    right: number,
  },
  rowPageBreak?: 'avoid' | 'auto',
  groupSort?: 'asc' | 'desc' | 'none',
}

type HeaderDrawFunction = (doc: jsPDF, height: number, width: number, pageNumber: number, grouping: any) => void;
type FooterDrawFunction = (doc: jsPDF, height: number, width: number, pageNumber: number, grouping: any, startY: number) => void;

class ReportPdfBuilder<T extends any> extends PdfBuilder {
  private rowData: T[];
  private columns: ColumnInput[];
  private options: ReportPdfBuilderOptions<T>;
  private dataFormatters: Map<string, (data: any) => any>;

  // Drawing functions
  private headerDraw?: HeaderDrawFunction;
  private footerDraw?: FooterDrawFunction;
  private groupFooterDraw?: FooterDrawFunction;

  constructor(data: T[], options: Partial<ReportPdfBuilderOptions<T>> = {}) {
    const realOptions = defaults<Partial<ReportPdfBuilderOptions<T>>, ReportPdfBuilderOptions<T>>(options, {
      header: 15,
      footer: 0,
      orientation: 'landscape',
      unit: 'pt',
      margin: {
        left: 40,
        right: 40,
      },
      groupSort: 'none',
    });

    super(realOptions);

    this.options = realOptions;

    this.rowData = data;

    const { columnNames } = this.options;
    this.columns = options.columns ? options.columns : Object.keys(data[0]).map<ColumnInput>((key) => ({
      title: columnNames && columnNames.has(key) ? columnNames.get(key) : capitalCase(key),
      dataKey: key,
    }));

    this.dataFormatters = (this.options && this.options.dataFormatters) || new Map();

    if (this.options.exclusions) {
      this.columns = this.columns.filter((column: ColumnInput) => !this.options.exclusions.find((key) => key === (column as { dataKey: string }).dataKey))
    }
  }

  setHeaderDraw(drawFn: HeaderDrawFunction) {
    this.headerDraw = drawFn;
  }

  setFooterDraw(drawFn: FooterDrawFunction) {
    this.footerDraw = drawFn;
  }

  setGroupFooterDraw(drawFn: FooterDrawFunction) {
    this.groupFooterDraw = drawFn;
  }

  build(): jsPDF {
    // Apply the header
    const { height, width } = this.doc.internal.pageSize;
    const headerHeight = 0.01 * this.options.header * height;
    const footerHeight = 0.01 * this.options.footer * height;

    // Separate into groups if necessary
    const groupedData: Map<any, T[]> = new Map();

    if (!this.options.groupBy) {
      groupedData.set('all', this.rowData);
    } else {
      const keys = Array.isArray(this.options.groupBy) ? this.options.groupBy : [this.options.groupBy];

      if (keys.length > 1) {
        throw new Error('Multigrouping not yet supported.')
      }

      this.rowData.forEach((row) => {
        const key = row[keys[0]];
        const entry = groupedData.get(key);
        if (!entry) {
          groupedData.set(key, [row])
        } else {
          entry.push(row);
        }
      });
    }

    const groupedDataList = Array.from(groupedData);

    if (this.options.groupSort !== 'none') {
      const isAsc = this.options.groupSort === 'asc';
      const sortFn = (a: [any, T[]], b: [any, T[]]) => {
        const aKey = a[0];
        const bKey = b[0];

        if (isNumber(aKey)) {
          return isAsc ? a[0] - b[0] : b[0] - a[0];
        }

        return isAsc
          ? (a[0] as string).localeCompare(b[0])
          : (b[0] as string).localeCompare(a[0]);
      };

      groupedDataList.sort(sortFn);
    }

    let entryIndex = 0;
    console.log(`Generating ${groupedDataList.length} group(s)`);
    groupedDataList.forEach((groupedDataEntry, index) => {
      console.log(`Group ${index}`);
      const [, groupedRows] = groupedDataEntry;

      autoTable(this.doc, {
        body: groupedRows.map((result) => {
          const row: any = cloneDeep(result);

          this.dataFormatters.forEach((formatter, key) => {
            row[key] = formatter(row[key]);
          });

          return row;
        }),
        columns: this.columns,
        margin: {
          top: headerHeight,
          bottom: footerHeight,
          left: this.options.margin.left,
          right: this.options.margin.right,
        },
        styles: this.options.styles,
        bodyStyles: this.options.bodyStyles,
        headStyles: this.options.headStyles,
        columnStyles: this.options.columnStyles,
        didDrawPage: (data) => {
          if (this.headerDraw) {
            this.headerDraw(data.doc, headerHeight, width, data.pageNumber, groupedRows);
          }

          if (this.footerDraw) {
            this.footerDraw(data.doc, footerHeight, width, data.pageNumber, groupedRows, height - footerHeight);
          }
        },
        didDrawCell: (data) => {
          if (data.column.dataKey === 'checkbox' && data.section === 'body') {
            this.doc.setLineWidth(1.5);
            const checkBoxSide = 10;
            const x1 = data.cell.x + (data.cell.width / 2) - (checkBoxSide / 2);
            const y1 = data.cell.y + (data.cell.height / 2) - (checkBoxSide / 2);
            this.doc.line(x1, y1, x1 + checkBoxSide, y1);
            this.doc.line(x1 + checkBoxSide, y1, x1 + checkBoxSide, y1 + checkBoxSide);
            this.doc.line(x1 + checkBoxSide, y1 + checkBoxSide, x1, y1 + checkBoxSide);
            this.doc.line(x1, y1 + checkBoxSide, x1, y1);
          }

          if (data.column.dataKey === 'box' && data.section === 'body') {
            this.doc.setLineWidth(1.5);
            const emptyBoxWidth = data.column.width - 5;
            const emptyBoxHeight = data.cell.height - 5;
            const x1 = data.cell.x + (data.cell.width / 2) - (emptyBoxWidth / 2);
            const y1 = data.cell.y + (data.cell.height / 2) - (emptyBoxHeight / 2);
            this.doc.line(x1, y1, x1 + emptyBoxWidth, y1);
            this.doc.line(x1 + emptyBoxWidth, y1, x1 + emptyBoxWidth, y1 + emptyBoxHeight);
            this.doc.line(x1 + emptyBoxWidth, y1 + emptyBoxHeight, x1, y1 + emptyBoxHeight);
            this.doc.line(x1, y1 + emptyBoxHeight, x1, y1);
          }
        },
        rowPageBreak: this.options.rowPageBreak,
      });

      // Draw a group footer if we have one
      if (this.groupFooterDraw) {
        const { finalY } = this.doc.lastAutoTable;
        this.groupFooterDraw(this.doc, height - finalY, width, this.doc.getNumberOfPages(), groupedRows, finalY)
      }

      // Page break per group
      entryIndex += 1;
      if (entryIndex !== groupedData.size) {
        this.doc.addPage();
        this.doc.setPage(this.doc.getNumberOfPages());
      }
    });

    return this.doc;
  }
}

export { ReportPdfBuilder as default };
