






























import {
  ColumnApi, GetQuickFilterTextParams, GridApi, ModelUpdatedEvent,
} from 'ag-grid-community';
import { capitalCase } from 'change-case';
import { get } from 'lodash';
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component
export default class GridOmnifilter extends Vue {
  @Prop({ required: true }) readonly gridApi!: GridApi | null;
  @Prop({ required: true }) readonly columnApi!: ColumnApi | null;
  @Prop({ default: 'field' }) readonly columnKey!: string;

  private totalCount: number = 0;
  private filteredCount: number = 0;
  private searchInput: string = null;
  private allValue: string = 'vue_grid_omnifilter_all';
  private defaultOption: DataTableHeader = {
    text: 'All',
    value: this.allValue,
  };
  private loanIds: string[] = [];

  private noReturnFilter: (params: GetQuickFilterTextParams) =>string = () => '';

  // Watchers
  @Watch('gridApi')
  onGridApiChanged(val: GridApi | null) {
    if (!val) {
      return;
    }

    val.addEventListener('modelUpdated', this.updateTotalCount);
  }

  // Computed
  get options(): DataTableHeader[] {
    const defaultOptions: DataTableHeader[] = [this.defaultOption];

    if (!this.columnApi) {
      return defaultOptions;
    }

    const additionalOptions = this.columnApi.getAllColumns()
      .map((column) => column.getColDef())
      .map((colDef) => ({
        text: this.columnKey === 'field' ? colDef.headerName : capitalCase(get(colDef, this.columnKey) || ''),
        value: get(colDef, this.columnKey),
      }))
      .filter((option) => Boolean(option.text));

    return defaultOptions.concat(additionalOptions);
  }

  // Hooks
  beforeDestroy() {
    if (this.gridApi) {
      this.gridApi.removeEventListener('modelUpdated', this.updateTotalCount);
    }
  }

  updateRows() {
    this.$emit('filteredResults', this.loanIds);
  }

  // Methods
  private updateTotalCount(event: ModelUpdatedEvent) {
    const model = this.gridApi.getModel();
    this.totalCount = this.countAllNodes();
    this.filteredCount = model.getTopLevelRowCount();

    this.loanIds = [];
    for (let x = 0; x < this.filteredCount; x += 1) {
      const node: any = model.getRow(model.getTopLevelRowDisplayedIndex(x));
      this.loanIds.push(node.data.loanId);
    }

    this.updateRows();
  }

  private updateSearchField(option: string) {
    const columns = this.columnApi.getAllColumns();
    const colDefs = columns.map((column) => column.getColDef());

    const newColDefs = colDefs.map((colDef) => {
      const def = { ...colDef };
      if (option !== get(colDef, this.columnKey) && option !== this.allValue) {
        def.getQuickFilterText = this.noReturnFilter;
      } else {
        def.getQuickFilterText = undefined;
      }

      return def;
    });

    columns.forEach((column, index) => {
      column.setColDef(newColDefs[index], null);
    });

    // Quick filter is smart enough to detect same input but not smart enough to auto-update results
    // Force it to recognize new input
    this.gridApi.setQuickFilter('');
    this.gridApi.setQuickFilter(this.searchInput);
  }

  private updateSearchValue(value: string) {
    this.gridApi.setQuickFilter(value);
    this.filteredCount = this.gridApi.getModel().getTopLevelRowCount();
  }

  private countAllNodes(): number {
    if (this.gridApi === null) {
      return 0;
    }

    let totalCount = 0;

    this.gridApi.forEachNode((node) => {
      totalCount += 1;
    });

    return totalCount;
  }
}
