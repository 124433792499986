








































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import UserService from '@/services/users';
import Query from '@/entities/queries/Query';
import UserPermissions from '@/mixins/UserPermissions.vue';
import { ReportConfig } from '@/store/reports/reports';

@Component({
  name: 'reports',
})
export default class Reports extends Mixins(UserPermissions) {
  @State((state) => state.reports.reports) reportConfigs!: ReportConfig[];
  @State((state) => state.reports.lenderReports) lenderReportConfigs!: ReportConfig[];
  @State((state) => state.reports.capitalReports) capitalReportConfigs!: ReportConfig[];

  @Prop({ type: String }) private readonly view!: string;

  @Prop({ type: Boolean, default: false }) private readonly oneColumn!: boolean;

  private service: UserService = new UserService();

  private userQueries: Query[] = [];

  private queryFilter: string = null;

  // Computed
  get viewableQueries() {
    return (this.userQueries as Query[]).filter((userQuery) => userQuery.viewable);
  }

  get filteredReports() {
    let reports: ReportConfig[] = [];

    if (this.isAdmin) {
      reports = this.reportConfigs;
    } else if (this.isTrainee) {
      reports = this.capitalReportConfigs;
    } else if (this.isLenderUser) {
      reports = this.lenderReportConfigs
    }

    const finalReports = this.queryFilter
      ? reports.filter((config) => config.name.trim().toLowerCase().includes(this.queryFilter.trim().toLowerCase()))
      : reports;

    return finalReports.sort((reportA, reportB) => reportA.name.localeCompare(reportB.name));
  }

  get filteredQueries() {
    if (!this.queryFilter) {
      return this.viewableQueries;
    }

    return this.viewableQueries.filter((query) => query.name.trim().toLowerCase().includes(this.queryFilter.trim().toLowerCase()));
  }

  // Hooks
  async created() {
    this.userQueries = await this.service.getAllUserQueries();
  }

  openLink(name: string, query?: Query) {
    if (name === 'Agency Label Report') {
      this.$router.push({ path: '/agency-label-report' });
    } else if (name === 'Add Parcel Report') {
      this.$router.push({ path: '/add-parcel-report' });
    } else if (name === 'Add Tax Office Report') {
      this.$router.push({ path: '/add-tax-office-report' });
    } else if (name === 'Parcels Not Verified') {
      this.$router.push({ path: '/pnv-report' });
    } else if (name === 'Parcel Format Flag') {
      this.$router.push({ path: '/parcel-format-flag' });
    } else if (name === 'Agency Data Report') {
      this.$router.push({ path: '/agency-data-report' });
    } else if (name === 'Loan Data Report') {
      this.$router.push({ path: '/loan-data-report' });
    } else if (name === 'Lender Data Report') {
      this.$router.push({ path: '/lender-data-report' });
    } else if (name === 'Delinquent Memo Bill') {
      this.$router.push({ path: '/delinquent-memo-bill-report' });
    } else if (name === 'Escrow Balance Sheet') {
      this.$router.push({ path: '/escrow-balance-sheet-report' });
    } else if (name === 'Escrow ID Sheet') {
      this.$router.push({ path: '/escrow-id-sheet-report' });
    } else if (name === 'Escrow Memo Bill') {
      this.$router.push({ path: '/escrow-memo-bill-report' });
    } else if (name === 'Tax Bill Request') {
      this.$router.push({ path: '/tax-bill-request' });
    } else if (name === 'Tax Data Sheet') {
      this.$router.push({ path: '/tax-data-sheet' });
    } else if (name === 'Audit Report') {
      this.$router.push({ path: '/audit-report' });
    } else if (name === 'Billing Report') {
      this.$router.push({ path: '/billing' });
    } else if (name === 'Audit Search') {
      this.$router.push({ path: '/audit-search-report' });
    } else if (name === 'Pack List') {
      this.$router.push({ path: '/pack-list-report' });
    } else if (name === 'Modify Reported Date Utility') {
      this.$router.push({ path: '/modify-reported-date-report' });
    } else if (query) {
      this.$router.push({ path: `/queries/${query.id}` });
    } else {
      this.$router.push({ path: '/query-builder' });
    }
  }
}
