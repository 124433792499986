





























import { fullDate } from '@/validations/vuetify';
import {
  Component, Prop, Emit, Vue,
} from 'vue-property-decorator';
import DateField from '@/components/inputs/dates/DateField.vue';
import { InputValidationRule } from 'vuetify';

type ReportedDateModeOptions = 'all' | 'null' | 'not_null' | 'between' | 'equal'

export interface ReportedDateOption {
  reported_date_mode: ReportedDateModeOptions,
  reported_dates?: string[],
}

@Component({
  name: 'reported-data-selection',
  components: {
    DateField,
  },
})
export default class ReportedDateSelection extends Vue {
  @Prop({
    type: Object,
    default: () => ({
      reported_date_mode: 'all',
      reported_dates: null,
    }) as ReportedDateOption,
  }) readonly value!: ReportedDateOption;

  @Prop({
    type: Boolean,
    default: true,
  }) readonly canSetNull!: boolean;

  // Remove Modify Reported Date Report
  @Prop({
    type: Boolean,
    default: true,
  }) removeMRR!: boolean;

  // Input rules
  private rules: { [index: string]: InputValidationRule } = {
    validDate: fullDate,
  };

  get showFirstDate(): boolean {
    return this.value.reported_date_mode === 'equal' || this.value.reported_date_mode === 'between';
  }

  get showSecondDate(): boolean {
    return this.value.reported_date_mode === 'between';
  }

  get firstDateLabel(): string {
    if (!this.showSecondDate) {
      return this.$attrs['specific-date-label'] ? this.$attrs['specific-date-label'] : 'Date 1';
    }

    return this.$attrs['first-date-label'] ? this.$attrs['first-date-label'] : 'Date 1';
  }

  @Emit('input')
  update(newValue: ReportedDateOption) {
    return newValue;
  }

  changeMode(mode: string) {
    const newValue = { ...this.value };

    newValue.reported_date_mode = mode as ReportedDateModeOptions;

    if (newValue.reported_date_mode === 'between' || newValue.reported_date_mode === 'equal') {
      if (!newValue.reported_dates) {
        newValue.reported_dates = [undefined, undefined];
      }
      if (newValue.reported_date_mode === 'equal') {
        newValue.reported_dates = [newValue.reported_dates[0], undefined];
      }
    } else if (newValue.reported_dates) {
      newValue.reported_dates = null;
    }

    this.update(newValue);
  }

  changeDate(date: string, index: 0 | 1) {
    const newValue = { ...this.value };

    if (date) {
      if (!newValue.reported_dates) {
        newValue.reported_dates = [];
      }

      newValue.reported_dates[index] = date;
    } else if (newValue.reported_dates) {
      newValue.reported_dates[index] = date;
    } else if (!newValue.reported_dates[0] && !newValue.reported_dates[1]) {
      delete newValue.reported_dates;
    }

    this.update(newValue);
  }
}
