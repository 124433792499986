





































import {
  Component, Prop, Emit, Vue,
} from 'vue-property-decorator';

import Verified from '@/entities/Verified';
import User from '@/entities/User';

@Component({
  name: 'verified-checkbox',
})
export default class VerifiedCheckbox extends Vue {
  @Prop({ type: String, default: '' }) private label!: String;
  @Prop({ type: Object }) private verifiedField!: Verified | undefined;
  @Prop({ type: Boolean }) private verified!: boolean;
  @Prop({ type: Object }) private verifiedBy!: User;
  @Prop({ type: Date }) private verifiedOn!: Date;
  @Prop({ type: Boolean, default: true }) private showVerificationData!: boolean;
  @Prop({ type: Boolean, default: false }) private dark!: boolean;
  @Prop({ type: Boolean, default: false }) private editMode!: boolean;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Number, default: 1.0 }) private size!: number;

  // Computed
  get verifiedContainer(): any {
    if (this.verifiedField) {
      return this.verifiedField;
    }

    return {
      verified: this.verified,
      verifiedBy: this.verifiedBy,
      verifiedOn: this.verifiedOn,
    };
  }

  @Emit('toggled')
  handleChange(e: any) {}
}
