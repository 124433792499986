import IServiceHistoryEntry from '@/services/api/models/IServiceHistoryEntry';

export enum HistoryEntryOperation {
  update = 'updated',
  add = 'added',
  remove = 'removed',
  delete = 'deleted',
  other = 'modified',
}

export enum HistoryType {
  UPDATE,
  CREATE,
  REMOVE,
  DELETE,
}

class Modification {
  field: string;
  old: any;
  new: any;

  constructor(field: string, oldValue: any, newValue: any) {
    this.field = field;
    this.old = oldValue;
    this.new = newValue;
  }

  toString() {
    return `${this.field} (new: "${this.new}" old: "${this.old}")`
  }
}

export default class HistoryEntry {
  id: string;
  tableName: string;
  foreignKey: string;
  parentId?: string;
  operation: HistoryEntryOperation;
  modifications: Modification[] = [];

  entityId?: string;
  entityName?: string;

  private tableMap = new Map([
    ['parcel', 'Parcel'],
    ['loan', 'Loan'],
    ['parcelescrowhistory', 'Escrow history'],
    ['parcelnonescrowhistory', 'Non escrow history'],
    ['parcelagency', 'Parcel agency'],
    ['lender', 'Lender'],
    ['agency', 'Agency'],
    ['person', 'User'],
    ['user_role', 'User role'],
    ['agencyconfigurations', 'Agency configuration'],
    ['agencycrossreferencecodes', 'Lender payee codes'],
    ['agencycontacttable', 'Agency contact'],
    ['agencytaxprocessor', 'Agency tax processor'],
    ['lenderfileuploads', 'Lender file upload'],
    ['relatedlenders', 'Related lender'],
    ['lendercontacttable', 'Lender contact'],
    ['contacttype', 'Contact type'],
  ]);

  constructor(serviceObj: IServiceHistoryEntry) {
    this.id = serviceObj.id;
    this.tableName = this.tableMap.has(serviceObj.table_name.toLowerCase())
      ? this.tableMap.get(serviceObj.table_name.toLowerCase())
      : serviceObj.table_name;
    this.foreignKey = serviceObj.foreign_key;
    this.parentId = serviceObj.parent_id;

    this.entityId = serviceObj.entity_id;
    this.entityName = serviceObj.entity_name;

    switch (serviceObj.operation) {
      case 'UPDATE':
        this.operation = HistoryEntryOperation.update;
        break;

      case 'CREATE':
        this.operation = HistoryEntryOperation.add;
        break;

      case 'REMOVE':
        this.operation = HistoryEntryOperation.remove;
        break;

      case 'DELETE':
        this.operation = HistoryEntryOperation.delete;
        break;

      default:
        this.operation = HistoryEntryOperation.other;
        break;
    }

    if (serviceObj.old_row && serviceObj.new_row) {
      // Get keys from the old row entry
      const changeKeys = Object.keys(serviceObj.old_row);

      this.modifications = changeKeys.map((key) => new Modification(key, serviceObj.old_row[key], serviceObj.new_row[key]));
    }
  }

  static getTypes(): string[] {
    return Object.keys(HistoryType)
      .filter((k) => typeof HistoryType[k as keyof typeof HistoryType] === 'string')
      .map((k) => HistoryType[k as keyof typeof HistoryType].toString());
  }

  get historyEntityLink(): string {
    let route;

    switch (this.tableName) {
      case 'Loan':
        route = 'loans';
        break;

      case 'Lender':
        route = 'lenders';
        break;

      case 'Agency':
        route = 'agencies';
        break;

      default:
        return null;
    }

    return `/${route}/${this.foreignKey}`;
  }

  get html(): string {
    const entityInfo = `${this.entityId || ''}`;
    const fullEntityInfo = `${this.tableName} ${entityInfo}`;

    if (!this.historyEntityLink) {
      return this.toString();
    }

    return `<a href=${this.historyEntityLink} target="_blank">${fullEntityInfo}</a>&nbsp;was ${this.operation}`;
  }

  toString() {
    const entityInfo = `${this.entityId || ''}`;
    return `${this.tableName} ${entityInfo} was ${this.operation}`;
  }
}
