import { ValueFormatterParams } from 'ag-grid-community';
import { isNumber } from 'lodash';

export function formatCurrency(value: number) {
  if (!value && !isNumber(value)) {
    return '';
  }

  return new Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD' },
  ).format(value);
}

export default function currencyFormatter(params: ValueFormatterParams) {
  return formatCurrency(params.value);
}
